import * as React from "react";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Badge, Button, ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import { showDecisionAlert, showWarningAlert } from "../../../index";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import { ModulesName, UserActions } from "../../General/roleModuleActions";
import PopupModal from "../modal/popupmodal";
import ICDReport from "../modal/reports/icdreport";
import Newresident from "../modal/residentsmodals/newresident";
import AOB from "./aob";
import Insurance from "./insurance";
import NewWound from "./newwound";
import PatientOrders from "./patientorders";
import ResidentDetails from "./residentdetails";
import ResidentNotes from "./residentnotes";
import Wound from "./woundList";
import WoundLocation from "./woundlocation";
import WoundNote from "./woundnote";

var residentDetailsModal, newWoundmodal, patientOrdersModal, ICDReportModal, WoundLocationModal, WoundNoteModal, AOBModal, ResidentNotesModal, AddResidentModal, insuranceModal, woundModal = null;

export function Resident(props) {
  const { user } = useSelector((state) => state.auth);
  const FacilityId = new URLSearchParams(props.location.search).get("fid");
  const [facilityName,setFacilityName]=useState('');
  const [chain,setChainName]=useState('');
  const [organizationName,setOrganizationName]=useState('');

  var UpsertNewresident = null;
  var UpsertNewWound = null;
  const [showModalResident, setShowResident] = useState(false);

  const handleCloseResident = () => setShowResident(false);
  const [residentLoading, setResidentLoading] = useState(false);
  const handleShowDetailResident = (obj) => {
    setResident(obj);
    residentDetailsModal.showModal();
  };

  const handleShowAddResident = (obj) => {
    setResident(obj);
    AddResidentModal.showModal();
  };

  const displatWarningAlert = () => showWarningAlert("Coming soon!!!", "Alert");
  const displayDecisionAlert = (title, message) =>
    showDecisionAlert(
      function () { },
      function () { },
      title == null ? "Are you sure?" : title,
      message == null ? "You want to deactivate this Resident." : message
    );
  const [Resident, setResident] = useState(null);
  const [ResidentID, setResidentID] = useState("");
  const [ResidentName, setResidentName] = useState("");


  const [isLoading, setIsLoading] = useState(false);
  const [residentListUI, setResidentListUI] = useState([]);

  useEffect(() => {
    getAllResident();
    getFacilityByID();
  }, []);

  const getAllResident = () => {
    setIsLoading(false);
    Api.getRequestQry(
      Api.URI_GetAllResidentByFacilityID,
      "?facilityID=" + FacilityId
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          setIsLoading(true);
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              
              setResidentList(res.data.result);
            }
          }
        }
        Common.LoadDataTable("residentsList");
      })
      .catch((ex) => { })
      .then(() => {
        setIsLoading(false);
      });
  };
  const getFacilityByID = () => {
 
    Api.getRequestById(Api.Uri_GetFacilityByID, FacilityId)
            .then((res) => {
                
                 if (res.data.statusCode == 200) {
                  if (res.data.result != null) {
                      setFacilityName(res.data.result.facilityName);
                      setChainName(res.data.result.chain);
                      setOrganizationName(res.data.result.organizationName);

                   
                  }
                }
             
            })
            .catch(function (error) {
                
            });
  };

  const setResidentList = (list) => {
    var rows = [];
    const statusBadgeType = {
      Open: "primary",
      Discharged: "secondary",
      Closed: "success",
      Deceased: "danger",
      "Leave of Absence": "warning",
    };

    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const name = obj.lastName + " " + obj.firstName;
      const gender = Common.replaceNullWithString(obj.genderName);
      const ssn = Common.replaceNullWithString(obj.ssn);
      var dob = new Date(obj.doB);
      const date =
        dob.getMonth() + 1 + "/" + dob.getDate() + "/" + dob.getFullYear();
      let randStatusBadgeType = statusBadgeType[obj.residentStatus];
      const residentStatus = Common.replaceNullWithString(obj.residentStatus);

      rows.push(
        <tr key={i.toString()}>
          <td>
            <button
              className="btn btn-link"
              onClick={() => handleShowDetailResident(obj)}
            >
              {name}
            </button>
          </td>
          <td>{gender}</td>
          <td>{ssn}</td>
          <td>{date}</td>
          <td>
            <Badge variant={randStatusBadgeType}>{residentStatus}</Badge>{" "}
          </td>
          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title="Actions"
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      {/* <Dropdown.Item eventKey="0" onClick={() => residentDetailsModal.showModal()}>View Details</Dropdown.Item> */}
                      <CheckPermission
                        permission={UserActions.Update}
                        module={ModulesName.Module_Residents}
                        yes={() => (
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              handleShowAddResident(obj);
                            }}
                          >
                            Edit Resident
                          </Dropdown.Item>
                        )}
                        no={() => (
                          <Dropdown.Item eventKey="1" disabled="true">
                            Edit Resident
                          </Dropdown.Item>
                        )}
                      />
                      {/* <Dropdown.Item eventKey="2" onClick={displatWarningAlert}>Resident Facesheet</Dropdown.Item> */}
                      <Dropdown.Item
                        eventKey="3"
                        onClick={() => patientOrdersModal.showModal()}
                      >
                        Resident Orders
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        eventKey="4"
                        onClick={() => ICDReportModal.showModal()}
                      >
                        ICD10 Report
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        eventKey="5"
                        onClick={() => AOBModal.showModal()}
                      >
                        AOB
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey="6" className="text-danger" onClick={() => displayDecisionAlert(null, "You want to discharge this patient.")}>Discharge Resident</Dropdown.Item> */}
                      {/* <Dropdown.Item eventKey="7" className="text-danger" onClick={() => displayDecisionAlert("", "This Resident is temporarily leaving the facility for a hospital admission or other medical necessity. The resident will be removed from Fastcare reports till returing to this facility?")}>Temporary Resident</Dropdown.Item> */}
                      <Dropdown.Item
                        eventKey="8"
                        onClick={() => {
                          setResidentID(obj.id);
                          setResidentName(name);
                          ResidentNotesModal.showModal();
                        }}
                      >
                        Resident Notes
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey="9" className="text-danger" onClick={() => displayDecisionAlert(null, null)}>Deactivate</Dropdown.Item> */}
                      <Dropdown.Item
                        eventKey="10"
                        onClick={() => {
                          setResidentID(obj.id);
                          setResidentName(name);
                          insuranceModal.showModal();
                        }}
                      >
                        Insurance
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="10"
                        onClick={() => {
                          setResident(obj);
                          setResidentName(name)
                          woundModal.showModal();
                        }}
                      >
                        Wound
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="9" className="text-primary" onClick={() => { AOBModal.showModal(); }}>AoB Manager</Dropdown.Item>
                    </DropdownType>
                  ))}
                </div>

                {/* {[DropdownButton].map((DropdownType, idx) => (
                  <DropdownType
                    size="xs"
                    variant="dark"
                    title="Wounds"
                    id={`dropdown-button-drop-${idx}`}
                    key={idx}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => newWoundmodal.showModal()}
                    >
                      New Wound
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => newWoundmodal.showModal()}
                    >
                      Edit Wound
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      onClick={() => WoundLocationModal.showModal()}
                    >
                      Location
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => ICDReportModal.showModal()}
                    >
                      Wound History
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="5"
                      onClick={() => WoundNoteModal.showModal()}
                    >
                      Notes
                    </Dropdown.Item>
                  </DropdownType>
                ))} */}
              </ButtonToolbar>
            </>
          </td>
        </tr>
      );
    }

    setResidentListUI(rows);
  };

  const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(
    ShowAdvancedSearch ? true : false
  );
  const showAdvanceSearchSection = () => {
    showAdvanceSearchSectionState(!ShowAdvancedSearch);
  };

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Button
          variant="warning"
          className="mr-5"
          size="sm"
          onClick={showAdvanceSearchSection}
        >
          Find Resident
        </Button>
        <CheckPermission
          permission={UserActions.Create}
          module={ModulesName.Module_Residents}
          yes={() => (
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                handleShowAddResident(null);
              }}
            >
              New Resident
            </Button>
          )}
          no={() => (
            <Button variant="primary" size="sm" disabled="true">
              New Resident
            </Button>
          )}
        />
      </div>
    </div>
  );

  return (
    <>
      {ShowAdvancedSearch && (
        <Animated
          animationIn="fadeInLeft"
          animationInDuration={800}
          animationOut="fadeOut"
          isVisible={ShowAdvancedSearch}
        >
          <Card>
            <CardHeader title="Search Resident" >
            <div className="card-title">
            <span>
              <h3 className="card-label">Resident List</h3>
              <small className="">
                <b>Facility:</b> {facilityName},{" "}
                <b>Chain:</b>{" "} {chain},{" "} 
                <b>Group:</b>{" "} {organizationName},{" "}
                {/* <b>BillingDate:</b>{" "} {Common.getInputStringDate(new Date(props.facility.cycleDate))} */}
              </small>
            </span>
          </div>
          </CardHeader>
            <CardBody>
              <div
                className="alert border-primary bg-light-primary fade show mb-5"
                role="alert"
              >
                <div className="d-flex align-items-center">
                  <div className="alert-icon">
                    <i className="fas fa-info-circle mr-4 text-primary"></i>
                  </div>
                  <div className="flex-1">
                    <span>
                      Find patients directly, for all statuses:
                      <ol>
                        <li>Use the beginning of first or last name</li>
                        <li>Use full date of birth in mm/dd/yyyy format</li>
                        <li>Use full ssn as nnn-nn-nnnn or nnnnnnnnn format</li>
                      </ol>
                      Otherwise, you will get the whole list for this patient
                      status.
                    </span>
                  </div>
                </div>
              </div>
              <form>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="PatientName">
                        Patient Name
                      </label>
                      <input
                        type="text"
                        id="PatientName"
                        className="form-control form-control-sm"
                      />
                      <small>Use 3 or more letters of first or last name</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="DOB">
                        DOB
                      </label>
                      <input
                        type="text"
                        id="DOB"
                        className="form-control form-control-sm"
                      />
                      <small>Use mm/dd/yyyy</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="SSN">
                        SSN
                      </label>
                      <input
                        type="text"
                        id="SSN"
                        className="form-control form-control-sm"
                      />
                      <small>Must be nnn-nn-nnnn or nnnnnnnnn</small>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
            <CardFooter className="d-flex justify-content-end py-3">
              <Button variant="success" className="mr-5 px-10" size="sm">
                Find
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={showAdvanceSearchSection}
              >
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Animated>
      )}

      <Card className="example example-compact">
        <CardHeader  >
            <div className="card-title">
            <span>
              <h3 className="card-label">Resident List</h3>
              <small className="">
                <b>Facility:</b> {facilityName},{" "}
                <b>Chain:</b>{" "} {chain},{" "} 
                <b>Group:</b>{" "} {organizationName},{" "}
                {/* <b>BillingDate:</b>{" "} {Common.getInputStringDate(new Date(props.facility.cycleDate))} */}
              </small>
            </span>
          </div>
          {toolbar}
          </CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : (
              <table
                className="table table-striped table-hover table-sm table-bordered"
                id="residentsList"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>SSN</th>
                    <th>DOB</th>
                    <th>Status</th>
                    <th width="20%">Actions</th>
                  </tr>
                </thead>
                <tbody>{residentListUI}</tbody>
              </table>
            )}
        </CardBody>
      </Card>

      {/** view resident Modal */}
      <PopupModal
        title="Resident Information"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          residentDetailsModal = ref;
        }}
      >
        {" "}
        <ResidentDetails resident={Resident} getAllResident={getAllResident} />{" "}
      </PopupModal>

      {/** new wound Modal */}
      <PopupModal
        title="Add a New Wound"
        dialogClassName="modal-95w"
        module={ModulesName.Module_Facility}
        action={UserActions.Create}
        ref={(ref) => {
          newWoundmodal = ref;
        }}
        buttonPress={() => UpsertNewWound.handleSubmit()}
      >
        {" "}
        <NewWound
          ref={(ref) => {
            UpsertNewWound = ref;
          }}
        />{" "}
      </PopupModal>

      {/** Patient Orders Modal */}
      <PopupModal
        title="Resident Orders"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          patientOrdersModal = ref;
        }}
      >
        {" "}
        <PatientOrders />{" "}
      </PopupModal>

      {/** Wound Detail Modal */}
      <PopupModal
        title="ICD10 Report"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          ICDReportModal = ref;
        }}
      >
        {" "}
        <ICDReport />{" "}
      </PopupModal>

      {/** Wound Location Modal */}
      <PopupModal
        title="Wound Information"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          WoundLocationModal = ref;
        }}
      >
        {" "}
        <WoundLocation />{" "}
      </PopupModal>

      {/** Wound Note Modal */}
      <PopupModal
        title="Add Wound Note"
        size="lg"
        showButton={true}
        ref={(ref) => {
          WoundNoteModal = ref;
        }}
      >
        {" "}
        <WoundNote />{" "}
      </PopupModal>

      {/** Wound Note Modal */}
      <PopupModal
        title="Assignment of Benefits"
        dialogClassName="modal-90w"
        showButton={true}
        ref={(ref) => {
          AOBModal = ref;
        }}
      >
        {" "}
        <AOB />{" "}
      </PopupModal>

      {/* Resdinet Notes Modal */}
      {/** Wound Detail Modal */}
      <PopupModal
        title= {<div className="card-title">
        <span>
          <h3 className="card-label">Resident Notes</h3>
          <small className="">
          <b>Resident:</b>{" "} {ResidentName},{" "}
            <b>Facility:</b> {facilityName},{" "}
            <b>Chain:</b>{" "} {chain},{" "} 
            <b>Group:</b>{" "} {organizationName},{" "}

          </small>
        </span>
      </div> }
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          ResidentNotesModal = ref;
        }}
      >
        {" "}
        <ResidentNotes
          userID={user.userID}
          FacilityId={FacilityId}
          ResidentID={ResidentID}
        />{" "}
      </PopupModal>

      {/* Add/edit resident modal */}

      <PopupModal
        title="Add Resident"
        dialogClassName="modal-97w"
        showButton={true}
        buttonDisabled={residentLoading}
        ref={(ref) => {
          AddResidentModal = ref;
        }}
        buttonPress={() => UpsertNewresident.handleSubmit()}
        module={ModulesName.Module_Residents}
        action={UserActions.Create}
      >
        <Newresident
          ref={(ref) => {
            UpsertNewresident = ref;
          }}
          userID={user.userID}
          user={user}
          FacilityId={FacilityId}
          Resident={Resident}
          setResidentLoading={setResidentLoading}
          close={() => {
            getAllResident();
            setResidentLoading(false)
            AddResidentModal.hideModal();
          }}
        />
      </PopupModal>

      <PopupModal
        title= {<div className="card-title">
        <span>
          <h3 className="card-label">Resident Insurance</h3>
          <small className="">
          <b>Resident:</b>{" "} {ResidentName},{" "}
            <b>Facility:</b> {facilityName},{" "}
            <b>Chain:</b>{" "} {chain},{" "} 
            <b>Group:</b>{" "} {organizationName},{" "}

          </small>
        </span>
      </div> }
        showButton={false}
        ref={(ref) => {
          insuranceModal = ref;
        }}
      >
        {" "}
        <Insurance residentID={ResidentID} />{" "}
      </PopupModal>

      <PopupModal
   
        title= {<div className="card-title">
        <span>
          <h3 className="card-label">Resident Wound</h3>
          <small className="">
          <b>Resident:</b>{" "} {ResidentName},{" "}
            <b>Facility:</b> {facilityName},{" "}
            <b>Chain:</b>{" "} {chain},{" "} 
            <b>Group:</b>{" "} {organizationName},{" "}

          </small>
        </span>
      </div> }
        showButton={false}
        ref={(ref) => {
          woundModal = ref;
        }}
      >
        {" "}
        <Wound user={user} Resident={Resident} />{" "}
      </PopupModal>
    </>
  );
}

export default Resident;

/*eslint-disable */
