import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import WorkQueue from "./workqueue";
import OstomyFacility from "./ostomyFacility";
import UrologicalFacility from "./urologicalFacility";
import TracheostomyFacility from "./tracheostomyFacility";




import * as Common from "../../General/common";


export function WorkQueueWoundOrders(props) {

    return (
        <>
            <Tabs defaultActiveKey="wounds" id="uncontrolled-tab-example">
                <Tab eventKey="wounds" title={
                    <div>
                        Wounds
                        <div>
                            <small>
                                <span className='badge badge-pill badge-primary my-auto '>
                                {/* {Common.getInputStringDateFormat(new Date(props.facility.cycleDate))} */}
                            </span>
                            </small>
                        </div>
                    </div>}>
                    <WorkQueue
                        // backPress={props.backPress}
                        // facility={props.facility}
                    />
                </Tab>
                {/* <Tab eventKey="ostomy" title={
                    <div>
                        Ostomy
                        <div>
                            <small>
                                <span className='badge badge-pill badge-primary my-auto '>
                                02/26/2021
                            </span>
                            </small>
                        </div>
                    </div>
                }>
                        <OstomyFacility  processStatusID={Common.ProcessStatus.workQueueID}
                        />
                  
                </Tab>

                <Tab eventKey="urological" title={
                    <div>
                        Urological
                        <div>
                            <small>
                                <span className='badge badge-pill badge-primary my-auto '>
                                02/26/2021
                            </span>
                            </small>
                        </div>
                    </div>
                }>
                    <UrologicalFacility  processStatusID={Common.ProcessStatus.workQueueID}/>
              
                </Tab>

                <Tab eventKey="tracheostomy" title={
                    <div>
                        Tracheostomy
                        <div>
                            <small>
                                <span className='badge badge-pill badge-primary my-auto '>
                                02/26/2021
                            </span>
                            </small>
                        </div>
                    </div>
                }>
                    <TracheostomyFacility  processStatusID={Common.ProcessStatus.workQueueID}/>
                
                </Tab> */}
            </Tabs>
        </>
    );
}
export default WorkQueueWoundOrders;