
import React from 'react';

const WoundBedDescriptionInput = (props) => {
  return (
    <>
      <div className="form-check form-check-inline">
        <label className="form-check-label" htmlFor={props.id}>
          {props.label}
        </label>
        <input
          id={props.id}
          type="number"
          min={0}
          max={100}
          className="form-control form-control-sm ml-3 w-60px hide-arrows"
          value={props.value || ''}
          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
          onChange={(e) => props.handlePercentageChange(e)} // Pass handlePercentageChange from props
          required={props.hasValue}
          disabled = {props.disabled}
        /><span className='ml-1'>%</span>
      </div>
    </>
  );
};

export default WoundBedDescriptionInput;
