/*eslint-disable */

import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import AddPhysicianmodal from './addphysician';
import NewBrightreePhysician from './newbrightreephysician';

export function Physician() {
    const { user } = useSelector((state) => state.auth);
    //#region  add person
    const [showModalAddPhysician, setShowAddPhysician] = useState(false);

    const [AddPhysicianComponent, setAddPhysicianComponent] = useState(null);


    const handleCloseAdd = () => setShowAddPhysician(false);
    const handleShowAddPhysician = (obj) => {
        setPhysician(obj);
        setAddPhysicianComponent(<AddPhysicianmodal userID={user.userID} physician={physician} close={handleCloseAdd} />)
        setShowAddPhysician(true);
    };

    const handleShowAddBrightreePhysician = (obj) => {
        setPhysician(obj);
        setAddPhysicianComponent(<NewBrightreePhysician userID={user.userID} physician={physician} close={handleCloseAdd} />)
        setShowAddPhysician(true);
    };


    //#endregion

    //#region physician list
    const [physician, setPhysician] = useState(null);
    const [physicianListUI, setPhysicianListUI] = useState([]);
    //#endregion

    useEffect(() => {
        GetPhysicianList();
    });

    
    




    //#region loop to  print the physician firm list

    const GetPhysicianList = () => {
        Api.getRequest(Api.URI_GetAllPhysician).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        setPhysicianList(res.data.result);
                    }
                }
            }

            Common.LoadDataTable("physicianList")
        }).catch((ex) => {
        });
    }

    const setPhysicianList = (list) => {
        let rows = [];
        try {
            for (var i = 0; i < list.length; i++) {
                const obj = list[i];
                rows.push(<tr key={i.toString()}>
                    <td>{Common.replaceNullWithString(obj.lastName) + " " + Common.replaceNullWithString(obj.firstName)}</td>
                    <td>{Common.replaceNullWithString(obj.title) ? obj.title : "N/A"}</td>
                    <td>{Common.replaceNullWithString(obj.address.phone1) != '' ? obj.address.phone1 : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.address.email) != '' ? obj.address.email : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.npi) != '' ? obj.npi : 'N/A'} </td>
                    <td align="center">
                        <div className="text-center">

                            <DropdownButton
                                drop={"down"}
                                size="xs"
                                variant="primary"
                                title={'Actions'}
                                id={`dropdown-button-drop-actions`}
                                key={'down'}>

                                {/* <Dropdown.Item eventKey={"1" + i.toString()} onClick={() => handleShowAdd(obj)}>Edit</Dropdown.Item> */}
                                <Dropdown.Item eventKey={"2" + i.toString()} onClick={() => ConfirmDeletePhysician(obj.physicianId)}>Delete</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </td>
                </tr>);
            }
            setPhysicianListUI(rows);
        } catch (error) {
        }
    }

    //#endregion

    //#region Delete Physician 

    const ConfirmDeletePhysician = (id) => {

        Common.showConfirmation("Are you sure! You want to delete this physician.", "Delete Physician", DeletePhysician, null, id)

    }

    const DeletePhysician = (id) => {
        Api.postRequest(Api.URI_DeletePhysician+"?physicianID="+id).then((res) => {
            // // debugger
            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    Common.showSuccessAlertAutoClose("Physician deleted successfully", "success", "Success!", 3000);
                    GetPhysicianList();
                } 
                else {
                    Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }

    //#endregion

    const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(ShowAdvancedSearch ? true : false);
    const showAdvanceSearchSection = () => {
        showAdvanceSearchSectionState(!ShowAdvancedSearch);
    };

    return (
        <>
            {showModalAddPhysician && <Animated animationIn="fadeInLeft" animationInDuration={1000}>
                
                {AddPhysicianComponent}
            
            </Animated>}

            {!showModalAddPhysician && <Animated animationIn="fadeIn" animationInDuration={1000}>
            <Card className="example example-compact">
                <CardHeader title='Physician List' />
                <CardBody>
                <div className="alert border-primary bg-transparent text-primary fade show" role="alert">
                    <div className="d-flex align-items-center">
                        <div className="alert-icon">
                            <i className="fas fa-info-circle mr-4 text-primary"></i>
                        </div>
                        <div className="flex-1">
                            <span className="h5 m-0 fw-700">Use buttons to do more actions </span>
                        </div>
                        <Button variant="primary" className="mr-5" size="sm" onClick={handleShowAddPhysician}>Add New Physician</Button>
                        <Button variant="primary"  size="sm" onClick={handleShowAddBrightreePhysician}>Add Brightree Physician</Button>
                    </div>
                </div>
                <table className="table table-striped table-hover table-sm table-bordered" id="physicianList">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Pro Designations</th>
                            <th>Telephone #</th>
                            <th>Email</th>
                            <th>NPI #</th>
                            <th width="130px">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {physicianListUI}
                    </tbody>
                </table>
                </CardBody>
            </Card>
                

            </Animated>}
        </>
    );

}

export default Physician;

/*eslint-disable */