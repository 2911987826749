import React from "react";

export default class WoundLocation extends React.Component {

    // constructor(props) {
    //     super(props);

    // }

    render() {
        return (
            <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                    <div className="row">
                        <div className="col-sm-6 col-md-12">
                            <div className="text-center px-6">
                                <button type="button" className="btn btn-primary mt-3 mb-3 mt-sm-0 btn-block">Take Wound Picture</button>
                                <div className="alert bg-secondary mx-auto  show p-2 mb-5" role="alert">
                                    <div className="text-center">
                                        <small >Wound: In landscape mode, controls at right</small>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary my-3 btn-block">Take Document Picture</button>
                                <div className="alert bg-secondary mx-auto show p-2 mb-5" role="alert">
                                    <div className="text-center">
                                        <small >Document: In portrait mode, controls at right</small>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary my-3 btn-block">Refresh Picture</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9">
                        <table className="table table-sm border-0 w-100 table-striped" id="">
                            <tbody>
                                <tr>
                                    <td width="25%" className="font-weight-bolder">Resident</td>
                                    <td>Anthony Fairley</td>
                                    <td className="font-weight-bolder">Location Name</td>
                                    <td>Left Great Toe</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Evaluated on</td>
                                    <td>07/16/2020</td>
                                    <td className="font-weight-bolder">Evaluated By</td>
                                    <td>Colleen Escamilla</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Patient Status</td>
                                    <td>Open</td>
                                    <td className="font-weight-bolder">Date First Observed</td>
                                    <td>07/07/2020</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">PUSH</td>
                                    <td>0</td>
                                    <td className="font-weight-bolder">Length / Width / Depth</td>
                                    <td>0.5 / 0.5 / UTD cm</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Acquired</td>
                                    <td>None</td>
                                    <td className="font-weight-bolder">Undermining</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Tunneling</td>
                                    <td></td>
                                    <td className="font-weight-bolder">Thickness/Stage</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Type of Wound</td>
                                    <td>Mixed Vascular</td>
                                    <td className="font-weight-bolder">Tissue Type</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Odor</td>
                                    <td></td>
                                    <td className="font-weight-bolder">Cleanse</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Pain</td>
                                    <td></td>
                                    <td className="font-weight-bolder">	Pain Medication Ordered</td>
                                    <td>No</td>

                                </tr>
                                <tr>

                                    <td className="font-weight-bolder">Exudate Amount</td>
                                    <td>None</td>
                                    <td className="font-weight-bolder">Exudate Type:   Color</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Debridement Type</td>
                                    <td>N/A</td>
                                    <td className="font-weight-bolder">Debridement Date</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Wound Bed:</td>
                                    <td></td>
                                    <td className="font-weight-bolder">Wound Edges</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Surrounding Skin</td>
                                    <td></td>
                                    <td className="font-weight-bolder">{"Skin & Ulcer Treatmen"}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Odor</td>
                                    <td></td>
                                    <td className="font-weight-bolder">Cleanse</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Primary Dressing</td>
                                    <td></td>
                                    <td className="font-weight-bolder">Secondary Dressing</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Treatment</td>
                                    <td></td>
                                    <td className="font-weight-bolder">General Comments and Observations</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bolder">Wound Pictures</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                </div>
            </div>
        )
    }


}// end of class