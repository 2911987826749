import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min.js";
import React from "react";
import { Button, Form, Alert } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";

export default class AddInsurancemodal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValidated: false,
      findFormValidated: false,
      states: [],
      PhysicianFind: {
        firstName: "",
        lastName: "",
        npi: "",
      },

      Physician: {
        physicianId: 0,
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        suffix: "",
        physicianNote: "",
        npi: "",
        stateMedicaidId: "",
        licenseNo: "",
        TaxonomyCode: "",
        brightreeId: 0,
        doctorGroupId: 0,
        createdDate: new Date(),
        createdBy: this.props.userID,
        modifiedBy: this.props.userID,
        modifiedDate: new Date(),

        address: {
          addressId: 0,
          addressLine1: "",
          addressLine2: "",
          city: "",
          sateId: 1,
          countryId: 1,
          zip: "",
          phone1: "",
          email: "",
          mobile1: "",
          fax: "",
        },
      },
    };
    this.findForm = React.createRef();
  }

  componentDidMount() {
    $("#Phone1").mask("000-000-0000");
    $("#Mobile1").mask("000-000-0000");
    $("#Fax").mask("000-000-0000");

    $("#Phone1").change((e) => {
      Common.updateInputValueInState(
        this.state.Physician.address,
        e,
        this,
        this.state
      );
    });
    $("#Mobile1").change((e) => {
      Common.updateInputValueInState(
        this.state.Physician.address,
        e,
        this,
        this.state
      );
    });
    $("#Fax").change((e) => {
      Common.updateInputValueInState(
        this.state.Physician.address,
        e,
        this,
        this.state
      );
    });

    this.getAllState();
  }
  handleFindSubmit = (e) => {
    if (this.findForm.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      let _state = this.state;
      _state.findFormValidated = true;
      this.setState(_state);
    } else {
      // if successfull validation
      const {
        firstName: FirstName,
        lastName: LastName,
        npi: Npi,
      } = this.state.PhysicianFind;
      Api.getRequest(
        `${Api.apiUrls.searcDoctors}?lastName=${LastName}&firstName=${FirstName}&npi=${Npi}`,
        false
      )
        .then((res) => {
          // // debugger
          if (res.data.success) {
            if (res.data.result != null) {
              Common.showSuccessAlertAutoClose(
                "Physician got Successfully",
                "success",
                "Success!",
                3000
              );
              debugger;
              var nonNulls = Object.entries(res.data.result)
                .filter(([name, value]) => value !== null)
                .map(([name, value]) => ({ name, value }));
              let r = this.state.Physician;
              nonNulls.forEach((element) => {
                r[element.name] = element.value;
              });

              this.setState({
                Physician: r,
              });
              // this.props.close();
            } else {
              Common.showSuccessAlert(
                res.data.message,
                "warning",
                "Failed!",
                3000
              );
            }
          } else {
            Common.showSuccessAlert(
              "No data found against given criteria",
              "warning",
              "Failed!",
              3000
            );
          }
        })
        .catch((ex) => {
          // // debugger
          Common.showSuccessAlertAutoClose(
            "some error occured while processing your request",
            "warning",
            "Failed!",
            3000
          );

        Common.LogError(ex, "Insurance", "HandleFindSubmit");

          // api call
          // on response
        });
    }
    // prepare payload
  };

  render() {
    return (
      <>
        <Form ref={this.findForm} validated={this.state.findFormValidated}>
          <Card className="">
            <CardHeader title="Search Physician" />
            <CardBody>
              <Alert className="mb-5" variant="secondary">
                Fill in one or more fields to search physician.
              </Alert>

              <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="LastName">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="LastName"
                      name="lastName"
                      required
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.PhysicianFind,
                          e,
                          this,
                          this.state
                        )
                      }
                      className="form-control form-control-sm"
                    />
                    <div className="invalid-feedback">
                      Last Name Is Required
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="FirstName">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="FirstName"
                      name="firstName"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.PhysicianFind,
                          e,
                          this,
                          this.state
                        )
                      }
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="NPI">
                      NPI
                    </label>
                    <input
                      type="text"
                      id="NPI"
                      name="npi"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.PhysicianFind,
                          e,
                          this,
                          this.state
                        )
                      }
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
            </CardBody>
            <CardFooter className="d-flex justify-content-end py-3">
              <Button
                variant="success"
                className="mr-5 px-10"
                size="sm"
                onClick={(e) => this.handleFindSubmit(e)}
              >
                Find
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => this.props.close()}
              >
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Form>

        <Form validated={this.state.formValidated}>
          <Card className="">
            <CardHeader title="New Physician" />
            <CardBody>
              <>
                <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                  <legend className="text-primary fw-500">
                    Basic Information{" "}
                  </legend>
                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="SSN">
                          Pro Designations
                        </label>
                        <input
                          type="text"
                          id="Title"
                          name="title"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={this.state.Physician.title ?? ""}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="LastName">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="LastName"
                          name="lastName"
                          required
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={this.state.Physician.lastName ?? ""}
                          className="form-control form-control-sm"
                        />
                        <div className="invalid-feedback">
                          Last Name Is Required
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="FirstName">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="FirstName"
                          name="firstName"
                          required
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={this.state.Physician.firstName ?? ""}
                          className="form-control form-control-sm"
                        />
                        <div className="invalid-feedback">
                          First Name Is Required
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="FirstName">
                          Middle Name
                        </label>
                        <input
                          type="text"
                          id="MiddleName"
                          name="middleName"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={this.state.Physician.middleName ?? ""}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="suffix">
                          Suffix
                        </label>
                        <input
                          type="Suffix"
                          id="suffix"
                          name="suffix"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={this.state.Physician.suffix ?? ""}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                          Doctor Group
                        </label>
                        <select
                          defaultValue={
                            this.state.Physician.doctorGroupId ?? ""
                          }
                          className="form-control form-control-sm"
                          id="DoctorGroupID"
                          name="doctorGroupId"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                        >
                          <option value="0">Select Doctor Group</option>
                          <option value="100">Cardiologist</option>
                          <option value="105">Endocrinologist</option>
                          <option value="101">General Practice</option>
                          <option value="102">Internal Medicine</option>
                          <option value="106">Oncologist</option>
                          <option value="104">Orthopedics</option>
                          <option value="103">Pulmonologist</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="State">
                          Memo
                        </label>
                        <textarea
                          id="Note"
                          rows="3"
                          className="form-control"
                          name="physicianNote"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={
                            this.state.Physician.physicianNote ?? ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                  <legend className="text-primary fw-500">
                    Address Information
                  </legend>
                  <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="State">
                          Address Line 1
                        </label>
                        <input
                          id="address1"
                          name="address.addressLine1"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={
                          //   this.state.Physician.address?.addressLine1 ?? ""
                          // }
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="State">
                          Address Line 2
                        </label>
                        <input
                          id="address1"
                          name="address.addressLine2"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={
                          //   this.state.Physician.address?.addressLine2 ?? ""
                          // }
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="City">
                          City
                        </label>
                        <input
                          type="text"
                          id="City"
                          name="address.city"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={
                          //   this.state.Physician.address?.city ?? ""
                          // }
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="State">
                          State
                        </label>
                        <select
                          defaultValue={
                            this.state.Physician.address?.stateId ?? ""
                          }
                          className="form-control form-control-sm"
                          name="address.stateId"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                        >
                          <option></option>
                          {this.setAllState(this.state.states)}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Country">
                          Country
                        </label>
                        <select
                          // defaultValue={
                          //   this.state.Physician.address?.countryId ?? ""
                          // }
                          className="form-control form-control-sm"
                          name="address.countryId"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          id="Country"
                        >
                          <option value="1">United States</option>
                          <option value="2">Canada</option>
                          <option value="3">Mexico</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Zip">
                          Postal code
                        </label>
                        <input
                          type="text"
                          id="Zip"
                          name="address.zip"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={this.state.Physician.address?.zip ?? ""}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Phone1">
                          Telephone
                        </label>
                        <input
                          type="text"
                          id="Phone1"
                          name="address.phone1"
                          required
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={
                          //   this.state.Physician.address?.phone1 ?? ""
                          // }
                          className="form-control form-control-sm"
                        />
                        <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Mobile1">
                          Mobile
                        </label>
                        <input
                          type="text"
                          id="Mobile1"
                          name="address.mobile1"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={
                          //   this.state.Physician.address?.mobile1 ?? ""
                          // }
                          className="form-control form-control-sm"
                        />
                        <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Fax">
                          Fax
                        </label>
                        <input
                          type="text"
                          id="Fax"
                          name="address.fax"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={this.state.Physician.address?.fax ?? ""}
                          className="form-control form-control-sm"
                        />
                        <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Email">
                          Email
                        </label>
                        <input
                          type="text"
                          id="Email"
                          name="address.email"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician.address,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={
                          //   this.state.Physician.address?.email ?? ""
                          // }
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    {/* End Address */}
                  </div>
                </fieldset>

                <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                  <legend className="text-primary fw-500">
                    Medical Information{" "}
                  </legend>
                  <div className="row">
                    <div className="col-sm-5 col-md-3 col-lg-3 col-xl-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="LicenseNo">
                          License No
                        </label>
                        <input
                          type="text"
                          id="LicenseNo"
                          name="licenseNo"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={this.state.Physician.licenseNo ?? ""}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-5 col-md-3 col-lg-3 col-xl-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="NPI">
                          NPI
                        </label>
                        <input
                          type="text"
                          id="NPI"
                          name="npi"
                          required
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={this.state.Physician.npi ?? ""}
                          className="form-control form-control-sm"
                        />
                        <div className="invalid-feedback">NPI Is Required</div>
                      </div>
                    </div>
                    <div className="col-sm-5 col-md-3 col-lg-3 col-xl-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="TaxonomyCode">
                          Taxonomy Code
                        </label>
                        <input
                          type="text"
                          id="TaxonomyCode"
                          name="taxonomyCode"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Physician,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={this.state.Physician.taxonomyCode ?? ""}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    {/* End Address */}
                  </div>
                </fieldset>
              </>
            </CardBody>
            <CardFooter className="d-flex justify-content-end py-3">
              <Button
                variant="success"
                className="mr-5 px-10"
                size="sm"
                onClick={(e) => this.handleSubmit(e)}
              >
                Save
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => this.props.close()}
              >
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </>
    );
  }

  //Functions

  //#region Get States
  getAllState() {
    Api.getRequest(Api.Uri_GetState)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ states: res.data.result });
            }
          }
        }
      })
      .catch((ex) => { 
        Common.LogError(ex, "Insurance", "GetAllState");

       });
  }

  setAllState(states) {
    return states.map((state) => (
      <option key={state.key} value={state.key}>
        {state.value}
      </option>
    ));
  }
  //#endregion

  handleSubmit = (event) => {
    // // debugger
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      this.URI_UpsertPhysician();
    }
  };

  URI_UpsertPhysician() {
    let obj = this.state.Physician;
    if (obj.address != null) {
      obj.address.phone1 =
        Common.replaceNullWithString(obj.address.phone1) != ""
          ? obj.address.phone1.replace(/\D/g, "")
          : null;
      obj.address.mobile1 =
        Common.replaceNullWithString(obj.address.mobile1) != ""
          ? obj.address.mobile1.replace(/\D/g, "")
          : null;
      obj.address.fax =
        Common.replaceNullWithString(obj.address.fax) != ""
          ? obj.address.fax.replace(/\D/g, "")
          : null;
    }

    // // debugger
    Api.postRequest(Api.URI_UpsertPhysician, obj)
      .then((res) => {
        debugger;
        // // debugger
        if (res.data.success) {
          if (res.data.result != null) {
            Common.showSuccessAlertAutoClose(
              "Physician Created Successfully",
              "success",
              "Success!",
              3000
            );
            this.props.close();
          } else {
            Common.showSuccessAlert(
              res.data.message,
              "warning",
              "Failed!",
              3000
            );
          }
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Failed!",
            3000
          );
        }
      })
      .catch((ex) => {
        // // debugger
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
        Common.LogError(ex, "Insurance", "Add");

      });
  }
} //end of class
