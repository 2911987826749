import { CommentOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
// import $ from 'jquery';
import { Animated } from "react-animated-css";
//import { useState } from 'react';
import { Alert, Badge, Button, ButtonToolbar, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import PopupModal from '../modal/popupmodal';
import AddFacilityUser from './addFacilityUser';




var addFacilityModal = null;

//#endregion
//#region Physician modal
//var physicianModal = null;
//#endregion
export function FacilityUser() {
     
      const [facilityList, setfacilityList] = useState([]);
      const [selectOrgOptions, setSelectOrgOptions] = useState([]);

      const [selectOrgChainOptions, setSelectOrgChainOptions] = useState([]);
      const [selectFacilityOptions, setSelectFacilityOptions] = useState([]);
      const [facilityID, setFacilityID] = useState('');
      const [roleID, setRoleID] = useState('');
      const [isLoading, setIsLoading] = useState(false);
      const [showResident, setShowResident] = useState(false);
      const [operatorsPurchasingGroupID, setOperatorsPurchasingGroupID] = useState('');
      const [ownershipHeadquartersID, setOwnershipHeadquartersID] = useState('');


    useEffect(() => {

         getAllFacility(); 
        
        }, [getAllFacility]);
        const showAddResidentOrganizaion=()=>{

        }
        const showAddResidentChain=()=>{

        }
        const showAddResidentFacility=async()=>{
            if(facilityID>0){
                await  setRoleID(Common.UserRole.LevelII);
                await setShowResident(true)
            }else{
                Common.showSuccessAlert(
                    "Facility is required",
                    "warning",
                    "Warning!",
                    3000
                  );
            }
           
        }
        const getAllFacility = useCallback(() => {
            Api.getRequest(Api.Uri_GetFacility)
                .then((response) => {
                    setfacilityList(response.data.result);
                    debugger
                    if(response.data.result.length > 0)
                    {
                    var facilityGroup = [];
                    facilityGroup = response.data.result.filter((menu) =>
                        menu.fkOrgId !=null
                    )
                        .map(v => (
                            <option key={v.fkOrgId} value={v.fkOrgId}>
                                {v.organizationName}
                            </option>
                        ));
                    const facilityGroupUniqueTags = [];
                    facilityGroup.map(item => {
                        var findItem = facilityGroupUniqueTags.find(x => x.key === item.key);
                        if (!findItem)
                        facilityGroupUniqueTags.push(item);
                    });
                   setSelectOrgOptions(facilityGroupUniqueTags)

                   //selectFacilityOptions
                    
                   var facilityList = [];
                   facilityList = response.data.result.filter((menu) =>
                       menu.fkOrgId ==null
                   )
                       .map(v => (
                           <option key={v.id} value={v.id}>
                               {v.facilityName}
                           </option>
                       ));
                   const facilityListUniqueTags = [];
                   facilityList.map(item => {
                       var findItem = facilityListUniqueTags.find(x => x.key === item.key);
                       if (!findItem)
                       facilityListUniqueTags.push(item);
                   });
                  setSelectFacilityOptions(facilityListUniqueTags)
                }
                  

                })

                .catch(function (error) {
                    Common.LogError(error, "Facility", "getAllFacility");
    
                }).then(() => {
                    Common.LoadDataTable("facilityList");
                });
        })
//  const   getAllFacilityGroup = () => {
//         Api.getRequest(Api.Uri_GetOrganization)
//           .then((response) => {
//             const data = response.data.result;
//             let optionTemplate = data.map((v) => (
//               <option key={v.key} value={v.key}>
//                 {v.value}
//               </option>
//             ));
//             setSelectOrgOptions( optionTemplate);
//           })
//       }
 
    const  getChainByOrgID=(e)=> {
        var orgID =e.target.value;
debugger
setOperatorsPurchasingGroupID(orgID);
var facilities = [];
facilities = facilityList.filter((menu) =>
    menu.fkOrgId ==orgID
)
    .map(v => (
        <option key={v.id} value={v.id}>
            {v.facilityName}
        </option>
    ));
const facilityListUniqueTags = [];
facilities.map(item => {
    var findItem = facilityListUniqueTags.find(x => x.key === item.key);
    if (!findItem)
    facilityListUniqueTags.push(item);
});
setSelectFacilityOptions(facilityListUniqueTags)
// Chain
        var facilityChain = [];
        facilityChain = facilityList.filter((menu) =>
            menu.fkOrgId==orgID && menu.fkChainId!=null
        )
            .map(v => (
                <option key={v.fKChainId} value={v.fKChainId}>
                    {v.chain}
                </option>
            ));
        const facilityChainUniqueTags = [];
        facilityChain.map(item => {
            var findItem = facilityChainUniqueTags.find(x => x.key === item.key);
            if (!findItem)
            facilityChainUniqueTags.push(item);
        });
        setSelectOrgChainOptions(facilityChainUniqueTags)

        // setOperatorsPurchasingGroupID(orgID);
       
        // Api.getRequestById(Api.Uri_GetChainByOrgID, orgID)
        //   .then((response) => {
        //     const data = response.data.result;
        //     let optionTemplate = data.map((v) => (
        //       <option key={v.key} value={v.key}>
        //         {v.value}
        //       </option>
        //     ));
        //     setSelectOrgChainOptions(optionTemplate )
        //   })
    
      }
      const onChangeOwnershipHeadquarters=(e)=>{
            setOwnershipHeadquartersID(e.target.value);

            var facilityList = [];
facilityList = facilityList.filter((menu) =>
    menu.fkOrgId ==operatorsPurchasingGroupID && menu.fkChainId==e.target.value
)
    .map(v => (
        <option key={v.id} value={v.id}>
            {v.facilityName}
        </option>
    ));
const facilityListUniqueTags = [];
facilityList.map(item => {
    var findItem = facilityListUniqueTags.find(x => x.key === item.key);
    if (!findItem)
    facilityListUniqueTags.push(item);
});
setSelectFacilityOptions(facilityListUniqueTags)
      }
    return (
        <>
           
            {showResident && !isLoading && (
                <Animated
                    animationIn="fadeIn"
                    animationInDuration={1000}
                    animationOut="fadeOut"
                    isVisible={showResident}
                >
                    <AddFacilityUser
                        // backPress={() => refreshFacilityList()}
                        ownershipHeadquartersID={ownershipHeadquartersID}
                        operatorsPurchasingGroupID={operatorsPurchasingGroupID}
                        facilityID={facilityID}
                        roleID={roleID}
                        onSaveAPI={()=>setShowResident(false)}
                    />
                </Animated>
            )}
            {!showResident && !isLoading && (
                <Card className="example example-compact">
                    <CardHeader title='Facility User List'  />
                    <CardBody>
                    <div className="row">
                                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="ThisFacilityBelongsTo">
                                                    
                                                    Ownership/Headquarters
                                                </label>
                                                <div className="input-group">
                                                    <select
                                                        className="form-control form-control-sm"
                                                        id="fkOrgId"
                                                        name="fkOrgId"
                                                        required
                                                        onChange={(e) => getChainByOrgID(e)}
                                                        // value={this.state.Facilty.fkOrgId}

                                                    >
                                                        <option></option>
                                                        {selectOrgOptions}
                                                    </select>
                                                    <CheckPermission
                                                        userRole={RBAC.Role_Gentell_Administrator}
                                                        yes={() => (
                                                            <>
                                                                <div className="input-group-append"><button
                                                                    type="button"
                                                                   // onClick={() => { this.SyncFacilityGroup() }}
                                                                   className="btn btn-secondary">Add User</button>
                                                                </div>
                                                            </>
                                                        )}
                                                        no={() => null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="ThisFacilityBelongsTo">
                                                   
                                                Purchasing Group-Chain
                                                </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    name="fkChainId"
                                                    // value={this.state.Facilty.fkChainId}
                                                    onChange={(e) =>
                                                        onChangeOwnershipHeadquarters( e )
                                                    }
                                                >
                                                    <option></option>
                                                    {selectOrgChainOptions}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="ThisFacilityBelongsTo">
                                                    Facility

                                                </label>
                                                <div className="input-group">
                                                <select
                                                    className="form-control form-control-sm"
                                                    name="fkFacilityId"
                                                    // value={this.state.Facilty.fkChainId}
                                                    onChange={(e) =>
                                                        setFacilityID( e.target.value )
                                                    }
                                                >
                                                    <option></option>
                                                    {selectFacilityOptions}
                                                </select>
                                                
                                               
                                                    <CheckPermission
                                                        userRole={RBAC.Role_Gentell_Administrator}
                                                        yes={() => (
                                                            <>
                                                                <div className="input-group-append"><button
                                                                    type="button"
                                                                    onClick={() => { showAddResidentFacility() }}
                                                                    className="btn btn-secondary">Add User</button>
                                                                </div>
                                                            </>
                                                        )}
                                                        no={() => null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                    </CardBody>
                </Card>
            )}
       
        

        </>
    );
}

export default FacilityUser;