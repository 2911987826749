// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react'
import {
    Button, Table
} from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
const IntakeProcessor = (props) => {
    // ** States
    const [residentNoOrderStatus, setProcessors] = useState([])


    const Schema = Yup.object().shape({

        billerId: Yup.number().required("Processor is required").typeError("Processor is required").min(1, "Processor is required"),


    });
    const formik = useFormik({
        initialValues: {
            billerId: props.facility.billerId,
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            handleValidSubmit(values);
            setSubmitting(false);
        },
    });

    //  Get All organization Type 
    useEffect(() => {
        handelGetUserByRoleID()
    }, []);
    const handelGetUserByRoleID =async () => {
        debugger
        var clinicain = await Api.getRequestById(Api.Uri_GetUserByRoleID, Common.LookupsFK.Fk_IntakeProcessorID);
        const clinicainData = clinicain.data.result
        let clinicainOption = clinicainData.map(v => (
            <option key={v.key} value={v.key}>{v.value}</option>
        ));
        setProcessors(clinicainOption)
    }
    const handleValidSubmit = (values) => {
        debugger
        var resident = props.facility;
        resident.billerId = values.billerId;
        Api.postRequest(Api.apiUrls.updateFacilityProcessor, resident).then((res) => {
            if (res.data.success) {
                props.refresh();
              Common.showSuccessAlertAutoClose("Intake Processor Updated Successfully", "success", "Success!", 3000)
            } else {
              Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
            }
          });

    }
    const setOptionsUI = (options) => {
        return options.map((option) => (
            <option key={option.key} value={option.key}>
                {option.value}
            </option>
        ));
    }
    return (
        <Fragment>

            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                        Intake Processor
                        </label>
                        <select
                            className="form-control form-control-sm"
                            id="fkProcessorNoOrderReasonId"
                            name="billerId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billerId}
                        >
                            <option></option>
                            {residentNoOrderStatus}
                        </select>
                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                            {formik.errors.billerId}
                        </div>
                    </div>

                </div>

            </div>
            <div className="d-flex justify-content-end py-3">
                <Button variant="success" className="mr-5 px-10" size="sm" onClick={formik.handleSubmit}>Save</Button>
            </div>

        </Fragment>
    )
}

export default IntakeProcessor
