
import { connect } from 'react-redux';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as roleModuleActions from './roleModuleActions'
import PropTypes from "prop-types";



const check = (userRoles,roles, permission, currentModule) => {
  
  let isAvailable = false

 if(userRoles){
  const role = roles?.roleList.find((role)=>{
    return typeof(userRoles) === "string" ? userRoles === role.roleName : userRoles.includes(role.roleName)
  });

  if(role){
    return true;
  }
 }

  const modules = roles.moduleList;
  if(permission != null && currentModule != null){
    if (modules != null) {
      const module = modules.find((mod) => {
        return mod.moduleName.toLowerCase() == currentModule.toLowerCase()
      })
  
      if (module) {
        if (module.actionList != null) {
          
          const actionlist = module.actionList;
          const action = actionlist.find((obj) => {
            
            return obj.actionName.trim().toLowerCase() === permission.toLowerCase()
          });
  
          if (action) {
            isAvailable = true;
          }
        }
      }
    }
  }

  return isAvailable;
};

const CheckPermission = props =>
  check(props.userRole, props.roles, props.permission, props.module)
    ? props.yes()
    : props.no();

CheckPermission.propTypes = {
  // permission: PropTypes.string.isRequired,
  // module: PropTypes.string.isRequired,
  userRole: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

CheckPermission.defaultProps = {
  yes: () => null,
  no: () => null
};



const mapStateToProps = (state) => {
  const roles = state.auth.user.roles;
  return { roles }
};
export default connect(mapStateToProps)(CheckPermission)