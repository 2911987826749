import React from "react";
import { Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import Loader from "react-loader-spinner";
export default class AddEMRCredentail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IsLoading: true,
            emr:{...this.props.emr}
        }
       
    }
    onSubmit() {
        if (this.form.checkValidity() === false) {
            let _state = this.state;
            _state.formValidated = true;
            this.setState(_state);
        } else {
            this.setState({ IsLoading: false });
          
            const obj = this.state.emr;
            Api.postRequest(Api.apiUrls.upsertEMR, obj).then((res) => {
                if (res.data.success) {
                    this.props.onSaveAPI();
                    this.setState({ IsLoading: true });
                    // Common.showSuccessAlertAutoClose(
                    //     "Data Saved Successfully",
                    //     "success",
                    //     "Success!",
                    //     3000
                    // );
                } else {
                    Common.showSuccessAlertAutoClose(
                        res.data.message,
                        "error",
                        "Error!",
                        3000
                    );
                }
            });
        }
    }

    render() {
        return (
            <>  {
                (this.state.IsLoading == true) ?
                    <div>
                        <Form
                            ref={(ref) => {
                                this.form = ref;
                            }}
                            validated={this.state.formValidated}
                            onSubmit={(e) => this.onSubmit(e)}
                        >
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="doB">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="form-control form-control-sm"
                                            name="name"
                                            required
                                            disabled={this.state.emr.isCredentailUpdate?true:false}
                                            defaultValue={
                                                this.state.emr.name 
                                              }
                                            onChange={(e) =>
                                                Common.updateInputValueInState(
                                                    this.state.emr,
                                                    e,
                                                    this,
                                                    this.state
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="doB">
                                            Description
                                        </label>
                                        <input
                                            type="text"
                                            id="description"
                                            className="form-control form-control-sm"
                                            name="description"
                                            disabled={this.state.emr.isCredentailUpdate?true:false}
                                            defaultValue={
                                                this.state.emr.description 
                                              }
                                            onChange={(e) =>
                                                Common.updateInputValueInState(
                                                    this.state.emr,
                                                    e,
                                                    this,
                                                    this.state
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="doB">
                                            User Name
                                        </label>
                                        <input
                                            type="text"
                                            id="LoginId"
                                            className="form-control form-control-sm"
                                            name="LoginId"
                                            defaultValue={
                                                this.state.emr.loginId 
                                              }
                                            onChange={(e) =>
                                                Common.updateInputValueInState(
                                                    this.state.emr,
                                                    e,
                                                    this,
                                                    this.state
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="doB">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            className="form-control form-control-sm"
                                            name="password"
                                            defaultValue={
                                                this.state.emr.password 
                                              }
                                            onChange={(e) =>
                                                Common.updateInputValueInState(
                                                    this.state.emr,
                                                    e,
                                                    this,
                                                    this.state
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div> :
                    <div className="d-flex justify-content-center">
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={50}
                            width={50}
                        />
                    </div>
            }</>


        );
    }
}

