
import React, { Component } from 'react';
import { Col, Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";


class AddEditUser extends Component {

  constructor(props) {
    super(props);
    debugger
    this.state = {
      UserList: [],
      formValidated: false,
      User:{...this.props.user}
    }
  }
  


  componentDidMount() {
    this.getAllUser();
  }

  onSubmit = (e) => {
    if (this.form.checkValidity() === false) {
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    }

    else {


      const obj = this.state.User




      Api.postRequest("User/create", obj).then((res) => {
        // // debugger
        if(res.data.success){
          
          this.props.onSaveAPI();
        }else{
          Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
        }
       
        //Common.showSuccessAlertAutoClose("User Added Successfully", "success", "Success!", 3000);
      });

      this.setState({
        userName: '',
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        mobile: '',


      })



    }
  }
  getAllUser() {
    var isGentell=this.state.User.forGentell?1:0;
    debugger
    Api.getRequestQry(Api.apiUrls.getAllUsers,"?isForGentell="+isGentell)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ UserList: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {});
  }
  setAllUser(users) {
    return users.map((usr) => (
      <option key={usr.userID} value={usr.userID}>
        {usr.lastName}
      </option>
    ));
  }
  setMobile=(e)=>{
    var mob=e.target.value;
    debugger
        if(mob.length<12){
         
          Common.updateInputValueInState(
            this.state.User,
            e,
            this,
            this.state,
            
          )
          
        }
        
    }
  render() {
    return (

        <Form
        ref={(ref) => { this.form = ref }}
        validated={this.state.formValidated}
        onSubmit={e => this.onSubmit(e)}>
        <Form.Row>
          <Form.Group as={Col} md="4" >
            <Form.Label> First Name</Form.Label>
            <Form.Control
              id="firstName"
              required
              type="text"
              name="firstName"
              value={Common.replaceNullWithString(this.state.User.firstName)}
              onChange={(e) =>
                Common.updateInputValueInState(
                  this.state.User,
                  e,
                  this,
                  this.state
                )
              }
              placeholder="First Name"
            />
            <Form.Control.Feedback></Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
            First Name is Required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" >
            <Form.Label> Middle Name</Form.Label>
            <Form.Control
               id="MiddleName"
             
              type="text"
              name="middleName"
              value={Common.replaceNullWithString(this.state.User.middleName)}

              onChange={(e) =>
                Common.updateInputValueInState(
                  this.state.User,
                  e,
                  this,
                  this.state
                )
              }
              placeholder="Middle Name"
            />
            
          </Form.Group>
          <Form.Group as={Col} md="4" >
            <Form.Label> Last Name</Form.Label>
            <Form.Control
              id="LastName"
              
              type="text"
              name="lastName"
              value={Common.replaceNullWithString(this.state.User.lastName)}

              onChange={(e) =>
                Common.updateInputValueInState(
                  this.state.User,
                  e,
                  this,
                  this.state
                )
              }
              placeholder="Last Name"
            />
            
          </Form.Group>
          
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} md="4" >
            <Form.Label> User Name</Form.Label>
            <Form.Control
              id="UserName"
              required
              type="text"
              disabled={this.props.user.userID>0?true:false}
              name="userName"
              value={Common.replaceNullWithString(this.state.User.userName)}
              onChange={(e) =>
                Common.updateInputValueInState(
                  this.state.User,
                  e,
                  this,
                  this.state
                )
              }
              placeholder="User Name"
            />
            <Form.Control.Feedback></Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
            User Name is Required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" >
            <Form.Label> Email </Form.Label>
            <Form.Control
              id="Email"
              required
              type="text"
              value={Common.replaceNullWithString(this.state.User.email)}
              name="email"
              onChange={(e) =>
                Common.updateEmailInputValueInState(
                  this.state.User,
                  e,
                  this,
                  this.state
                )
              }
              placeholder="Email"
            />
            <Form.Control.Feedback></Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
            Email is Required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" >
            <Form.Label> Mobile </Form.Label>
            <Form.Control
              id="Mobile"
             // required
       
              type="number"
              name="mobile"
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              onChange={(e) =>
               this.setMobile(e)
              
              }
              placeholder="Mobile"
              value={
                this.state.User.mobile 
              }
            />
            <Form.Control.Feedback type="invalid">
            Please Correct Number
            </Form.Control.Feedback>
           
          </Form.Group>
          
          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="State">
                          Manager
                        </label>
                        <select
                          value={
                            this.state.User.fkMangerId 
                          }
                          className="form-control form-control-sm"
                          name="fkMangerId"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.User,
                              e,
                              this,
                              this.state
                            )
                          }
                        >
                          <option></option>
                          {this.setAllUser(this.state.UserList)}
                        </select>
                      </div>
                    </div>
          </Form.Row>


</Form>


    );
  }
}
export default AddEditUser;