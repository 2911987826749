import React from "react";
import { Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import Loader from "react-loader-spinner";
export default class AddFacilityGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       IsLoading: true,
       selectOrgOptions:props.selectOrgOptions,
      facility:
      {
        name:'',
        description:'' ,
        parentId:null
      }
    }
}
onSubmit() {
  if (this.form.checkValidity() === false) {
    let _state = this.state;
    _state.formValidated = true;
    this.setState(_state);
  } else {
  this.setState({ IsLoading: false });
    const obj = this.state.facility;
    Api.postRequest(Api.Uri_CreateFacilityGroup, obj).then((res) => {
      if (res.data.success) {
        this.props.onSaveAPI();
  this.setState({ IsLoading: true });
        Common.showSuccessAlertAutoClose(
          "Data Saved Successfully",
          "success",
          "Success!",
          3000
        );
      } else {
        Common.showSuccessAlert(
          res.data.message,
          "error",
          "Error!",
          3000
        );
      }
    });
  }
}
render() {
    return (
    <>  {
        (this.state.IsLoading == true) ?
            <div>
             <Form
        ref={(ref) => {
          this.form = ref;
        }}
        validated={this.state.formValidated}
        onSubmit={(e) => this.onSubmit(e)}
      >
       <div className="row">
       <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="doB">
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control form-control-sm"
                      name="name"
                      required
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.facility,
                          e,
                          this,
                          this.state
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="doB">
                    Description
                    </label>
                    <input
                      type="text"
                      id="description"
                      className="form-control form-control-sm"
                      name="description"
                      
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.facility,
                          e,
                          this,
                          this.state
                        )
                      }
                    />
                  </div>
                </div>
                {this.props.isHeadquarters&&
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="ThisFacilityBelongsTo">
                  Parent Group    
                   
                  </label>
                  {/* <Select options={this.state.selectOrgOptions} onChange={e => this.getChainByOrgID(e)} /> */}
                  <select
                    className="form-control form-control-sm"
                    id="parentId"
                    name="parentId"

                    required
                    value={Common.replaceNullWithString(
                      this.state.facility.parentId
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        this.state.facility,
                        e,
                        this,
                        this.state
                      )
                    }
                  >
                    {/* <option>Please select any option</option> */}
                    {/* {this.state.selectOrgOptions} */}
                  </select>
                </div>
              </div>
}
           </div>
      </Form>
            </div> :
            <div className="d-flex justify-content-center">
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={50}
                    width={50}
                />
            </div>
    }</>


      );
  }
}

