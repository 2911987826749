import React from 'react';
import Select, { createFilter } from "react-select";
import { Button, Form } from "react-bootstrap";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../_metronic/_partials/controls/Card";
import Loader from "react-loader-spinner";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  getRequest,
  Uri_GetFacility,
  getRequestQry,
  Uri_GetResidentByFacilityId,
  getRequestById,
  URI_GetResidentWoundByResidentID,
  Uri_GetWoundDocumentationByWoundID
} from "../../General/api";
import {
  showSuccessAlertAutoClose,
  LogError,
  getInputStringDateUsingMoment2,
  showSuccessAlert
} from "../../General/common";
import uuid from 'node-uuid'
import moment from 'moment';

export default class WoundEvaluationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValidated: false,
      facilityList: [],
      facilitySelect: null,
      facilityResidentList: [],
      residentSelect: null,
      residentWoundList: [],
      woundSelected: null,
      IsLoading: false,
      showReport: false,
      wound: {
        FacilityId: null,
        ResidentId: null,
        WoundId: null,
      },
      woundEvaluations: {
        residentName: '',
        facilityName: '',
        facilityAddress: '',
        room: '',
        physicianName: '',
        woundLocation: '',
        bodyRegionName: '',
        woundType: '',
        acquisition: '',
        woundEvaluationDetail: []
      }

    }
  }

  componentDidMount() {
    this.getAllFacility();
  }

  getAllFacility() {
    getRequest(Uri_GetFacility + "?isAssessmentView=1")
      .then((res) => {

        if (res.data.statusCode === 200) {
          if (res.data.result != null) {

            if (res.data.result.length > 0) {
              let facilityList = res.data.result.map(facility => (
                { value: facility.id, label: facility.facilityName }
              )).sort((a, b) => a.label.localeCompare(b.label));

              this.setState({ facilityList: facilityList, IsLoading: true })
            }
          }
        }
      }).catch((error) => {
        console.error(error);
        showSuccessAlertAutoClose('Sorry, an error has occurred.', 'warning', 'warning!', 3000)
      });
  }

  onFacilityChange = (facility) => {
    this.setState({
      facilityResidentList: [],
      residentWoundList: [],
      facilitySelect: facility,
      residentSelect: null,
      woundSelected: null,
    })

    if (facility) {
      getRequestQry(Uri_GetResidentByFacilityId, "?facilityId=" + facility.value)
        .then((res) => {
          if (res.data.statusCode === 200 && res.data.result != null && res.data.result.length > 0) {
            const data = res.data.result;
            let optionTemplate = data.map((resident) => (
              { value: resident.key, label: resident.value }
            )).sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ facilityResidentList: optionTemplate })
          }
        })
        .catch((error) => {
          console.log(error)
          showSuccessAlertAutoClose('Sorry, an error has occurred.', 'warning', 'warning!', 3000)
        });
    }
  }

  onResidentChange = (resident) => {
    this.setState({
      residentWoundList: [],
      residentSelect: resident,
      woundSelected: null
    })

    if (resident) {
      getRequestById(URI_GetResidentWoundByResidentID, resident.value)
        .then((res) => {
          if (res.data.statusCode === 200 && res.data.result != null && res.data.result.length > 0) {
            let residentWoundList = res.data.result.map(element => (
              { value: element.wound.id, label: `${element.wound.bodyRegion ? element.wound.bodyRegion : ''}${element.wound.bodyRegion && element.residentWoundLocation ? '/' : ''}${element.residentWoundLocation ? element.residentWoundLocation : ''}` }
            )).sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ residentWoundList: residentWoundList })
          }
        }).catch((error) => {
          console.log(error)
          showSuccessAlertAutoClose('Sorry, an error has occurred.', 'warning', 'warning!', 3000)
          LogError(error, "ResidentWound", "Get");
        });
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state
      _state.formValidated = true;
      this.setState(_state);
    }
    else {
      let obj = this.state.wound;

      getRequestQry(Uri_GetWoundDocumentationByWoundID, "?woundID=" + obj.woundId)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (res.data.result != null) {
              let tempObj = this.state.woundEvaluations;
              tempObj.residentName = res.data.result.residentName;
              tempObj.physicianName = res.data.result.physicianName;
              tempObj.woundLocation = res.data.result.woundLocation;
              tempObj.bodyRegionName = res.data.result.bodyRegionName;
              tempObj.woundId = this.state.wound.woundId;

              tempObj.facilityName = res.data.result.facilityName;
              tempObj.facilityAddress = res.data.result.facilityAddress;
              tempObj.acquisition = res.data.result.acquisition;
              tempObj.woundType = res.data.result.woundType;
              tempObj.room = res.data.result.room;

              tempObj.woundType = res.data.result.woundType;
              tempObj.woundEvaluationDetail = res.data.result.woundEvaluationDetail;
              var evaluationDateArray = [];
              var uvc = 0

              let formattedData = res.data.result.woundEvaluationDetail.sort((a, b) => new Date( a.evaluationDate) - new Date(b.evaluationDate));

              formattedData.forEach(wound => {
                uvc = uvc + 10
                evaluationDateArray.push(
                  {
                    name: getInputStringDateUsingMoment2(moment.utc(wound.evaluationDate).local()), uv: uvc
                  }
                )
              });

              tempObj.evaluationDateArray = evaluationDateArray;
              this.setState({ woundEvaluations: tempObj, showReport: true })
            } else {
              showSuccessAlert("Record not found", "warning", "warning!", 3000);
              this.setState({ showReport: false })
            }
          }
        })
        .catch((error) => {
          console.error(error);
          showSuccessAlertAutoClose('Sorry, an error has occurred.', 'warning', 'warning!', 3000)
        })

    }
  }

  handleClearFilters = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      facilitySelect: null,
      residentSelect: null,
      woundSelected: null,
      facilityResidentList: [],
      residentWoundList: [],
      showReport: false,
      wound: {
        FacilityId: null,
        ResidentId: null,
        WoundId: null,
      },
      woundEvaluations: {
        residentName: '',
        facilityName: '',
        facilityAddress: '',
        room: '',
        physicianName: '',
        woundLocation: '',
        bodyRegionName: '',
        woundType: '',
        acquisition: '',
        woundEvaluationDetail: []
      }
    })

  }

  renderWoundForm() {
    return (
      <>{
        (this.state.IsLoading) ?
          <div >
            <div className='row'>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="FacilityName">Facility Name</label>
                  <Select
                    options={this.state.facilityList}
                    id="facilityId"
                    name="facilityId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={e => this.onFacilityChange(e)}
                    value={this.state.facilitySelect}
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="PatientName">Resident Name</label>
                  <Select
                    options={this.state.facilityResidentList}
                    id="residentId"
                    name="residentId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={e => this.onResidentChange(e)}
                    value={this.state.residentSelect}
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="WoundType">Wound </label>
                  <Select
                    options={this.state.residentWoundList}
                    id="woundId"
                    name="woundId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={e => {
                      if (!e) { this.setState({ woundSelected: e }) }
                      else {
                        this.setState({ wound: { ...this.state.wound, woundId: e.value } });
                        this.setState({ woundSelected: e })
                      }
                    }}
                    value={this.state.woundSelected}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end py-3'>
              <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Search Report</Button>
              <button className='btn btn-danger btn-sm' onClick={e => this.handleClearFilters(e)}>Clear filter</button>
            </div>
          </div> :
          <div className="d-flex justify-content-center">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
      }
      </>
    );
  }

  render() {
    function PrintElem() {
      let tableHeaderElementCanvas;
      let elementProcessed = 0;
      let heightFromTop = 75;
      let facilityNameHeight = 25;
      let residentTableInfoHeight = 40;
      let tableHeaderResultsHeight = 25;
      let tableElementsHeight = 160;
      let sheetWidth = 785;
      let widthFromLeft = 30;
      let nextNewPage = 0;
      const elementsPerPage = 3;
      let doc = new jsPDF('landscape', 'pt', 'a4', false);

      const title = document.getElementById('printit').querySelectorAll('div.mt-5')[0];
      const tableResidentInfos = document.getElementById('printit').querySelectorAll('div.mt-5')[1];
      const tableData = document.getElementById('printit').querySelectorAll('div.mt-5')[2].querySelectorAll('tr');

      let printElements = [title, tableResidentInfos, ...tableData];

      const canvas = document.getElementById('fixedCanvas');
      const ctx = canvas.getContext('2d');

      Array.from(printElements).forEach((item, index) => {
        html2canvas(item, { quality: 3, scale: 3 }).then(canvasItem => {
          canvas.width = 1246
          canvas.height = 300
          ctx.scale(1, 1);
          ctx.drawImage(canvasItem, 0, 0, 1246, 300);

          const imgData = canvas.toDataURL('image/png');
          if (index === 2) {
            tableHeaderElementCanvas = imgData;
          }

          if (index === 5) {
            doc.addPage();
            heightFromTop = 50;
            nextNewPage = index + elementsPerPage;
            doc.addImage(tableHeaderElementCanvas, 'PNG', widthFromLeft, 25, sheetWidth, tableHeaderResultsHeight, `header-${index}`, 'NONE');
          } else if (nextNewPage === index && index > 5) {
            doc.addPage();
            heightFromTop = 50;
            nextNewPage += elementsPerPage;
            doc.addImage(tableHeaderElementCanvas, 'PNG', widthFromLeft, 25, sheetWidth, tableHeaderResultsHeight, `header-${index}`, 'NONE');
          }

          if (index === 0) {
            doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetWidth, facilityNameHeight, index, 'NONE');
            heightFromTop += (45)
          } else if (index === 1) {
            doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetWidth, residentTableInfoHeight, index, 'NONE');
            heightFromTop += (55)
          } else if (index === 2) {
            doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetWidth, tableHeaderResultsHeight, index, 'NONE');
            heightFromTop += (25)
          } else {
            doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetWidth, tableElementsHeight, index, 'NONE');
            heightFromTop += (160)
          }

          elementProcessed++;

          if (elementProcessed === printElements.length) {
            const pageCount = doc.internal.getNumberOfPages();
            doc.setFont('Poppins', 'normal', 'bold');
            doc.setFontSize(8);
            doc.setTextColor(112, 108, 108);
            for (var i = 1; i <= pageCount; i++) {
              doc.setPage(i)
              doc.text('Page ' + i + ' of ' + pageCount, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 10);
            }
            doc.save("Wound_Evaluation_Report.pdf");
          }
        });
      });
    }

    return (
      <>
        <Card>
          <CardHeader className='Text-center' title='Wound Evaluation Report'>
            <CardHeaderToolbar>
              <div className="example-tools">

              </div>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <Form
              validated={this.state.formValidated}
            >
              {this.renderWoundForm()}
            </Form>
            <hr />
            {this.state.showReport && <>
              <div className='row'>
                <div className='col-12'>
                  <button type="button" onClick={PrintElem} className="mr-3 btn btn-success btn-sm float-right">Print Report</button>
                </div>
              </div>
              <div className='wound-evaluation-report'>
                <div id="printit" className='printDiv wound-evaluation-report_content'>
                  <div className='mt-5'>
                    <h2>{this.state.woundEvaluations.facilityName}</h2>
                    <span>{this.state.woundEvaluations.facilityAddress}</span>
                  </div>
                  <div className='mt-5'>
                    <table className='w-100'>
                      <tbody>
                        <tr>
                          <td colSpan={2}>
                            <span className='font-weight-bolder mr-2'>Resident:</span>
                            <span>{this.state.woundEvaluations.residentName}</span>
                          </td>
                          <td>
                            <span className='font-weight-bolder mr-2'>Room:</span>
                            <span>{this.state.woundEvaluations.room}</span>
                          </td>
                          <td>
                            <span className='font-weight-bolder mr-2'>Physician:</span>
                            <span>{this.state.woundEvaluations.physicianName}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className='font-weight-bolder mr-2'>Site#:</span>
                            <span>{this.state.woundEvaluations.woundId}</span>
                          </td>
                          <td>
                            <span className='font-weight-bolder mr-2'>Body Region/Location:</span>
                            <span>{this.state.woundEvaluations.bodyRegionName + " / " + this.state.woundEvaluations.woundLocation}</span>
                          </td>
                          <td>
                            <span className='font-weight-bolder mr-2'>Type of Wound:</span>
                            <span>{this.state.woundEvaluations.woundType}</span>
                          </td>
                          <td>
                            <span className='font-weight-bolder mr-2'>Acquistion:</span>
                            <span>{this.state.woundEvaluations.acquisition}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='mt-5'>
                    <table className='table table-striped table-sm w-100'>
                      <thead className='thead_style_cust'>
                        <tr>
                          <th width="10%" style={{ borderBottom: "1px solid black", backgroundColor: '#fff' }}></th>
                          <th width="14%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>Measurements <span>(cm)</span></th>
                          <th width="12%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>Exudate</th>
                          <th width="12%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>Wound Bed</th>
                          <th width="12%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>Periwound</th>
                          <th width="40%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>Comments</th>
                        </tr>
                      </thead>
                      <tbody className='body_style_cust'>
                        {this.state.woundEvaluations.woundEvaluationDetail?.map((wound) => {
                          return (
                            <tr key={uuid()} style={{ border: "1px solid black" }}>
                              <td>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Date:</span> {getInputStringDateUsingMoment2(
                                    moment.utc(wound.evaluationDate).local())}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Thickness/</span> </p>
                                  <p className='mb-0'><span className='font-weight-bolder'>Stage:</span> {wound.woundThicknessName}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Tissue type:</span> {wound.woundSevereTissueName}</p>
                                </div>
                              </td>
                              <td>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>L</span> {wound.woundLength} <span className='font-weight-bolder'>W</span> {wound.woundWidth} <span className='font-weight-bolder'>D</span> {wound.woundDepth}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Tunneling:</span>{wound.tunneling ? "Yes" : "No"} </p>
                                  <p className='mb-0'><span className='font-weight-bolder'>Depth:</span>{wound.tunnelingDepth}</p>
                                  <p className='mb-0'><span className='font-weight-bolder'>Clock position:</span> {wound.tunnelingClockPosition}</p>
                                </div>
                                {wound.undermining &&
                                  <div className='border_bottom_cus py-2'>
                                    <p className='mb-0'><span className='font-weight-bolder'>Undermining:</span> {wound.undermining ? "Yes" : "No"}</p>
                                    <p className='mb-0'><span className='font-weight-bolder'>Depth:</span> {wound.underminingDepth}</p>
                                    <p className='mb-0'><span className='font-weight-bolder'>From Clock position:</span> {wound.underminingClockPosition}</p>
                                    <p className='mb-0'><span className='font-weight-bolder'>To Clock position:</span> {wound.toUnderminingClockPosition}</p>
                                  </div>
                                }
                              </td>
                              <td>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Color:</span> {wound.exudateColor}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Exudate Type:</span> {wound.exudateTypeName}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Debridement:</span> {wound.debridementTypeName}</p>
                                  <p className='mb-0'><span className='font-weight-bolder'>Last Debridement Date:</span> {wound.debridementDate != null ? getInputStringDateUsingMoment2(moment.utc(wound.debridementDate).local()) : "N/A"}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Amount:</span> {wound.exudateAmountName}</p>
                                </div>
                              </td>
                              <td>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Odor:</span>{wound.odor ? "Yes" : "No"}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Pain:</span>{wound.pain ? "Yes" : "No"} </p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Pain Medication ordered:</span> {wound.painMedicationOrdered ? "Yes" : "No"}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Wound Bed Description:</span></p>
                                  {wound.granulationPerc > 0 && <p className='mb-0'><span className='font-weight-bolder'>Granulation %:</span>{wound.granulationPerc} </p>}
                                  {wound.hyperGranulationPerc > 0 && <p className='mb-0'><span className='font-weight-bolder'>HyperGranulation %:</span>
                                    {wound.hyperGranulationPerc} </p>}
                                  {wound.sloughPerc > 0 && <p className='mb-0'><span className='font-weight-bolder'>Slough %:</span>{wound.sloughPerc} </p>}
                                  {wound.necroticPerc > 0 && <p className='mb-0'><span className='font-weight-bolder'>Necrotic/Eschar %:</span>{wound.necroticPerc} </p>}
                                  {wound.epithelialPerc > 0 && <p className='mb-0'><span className='font-weight-bolder'>Epithelial %:</span>{wound.epithelialPerc} </p>}
                                  {wound.musclePerc > 0 && <p className='mb-0'><span className='font-weight-bolder'>Muscle, Tendon or Bone %:</span>{wound.musclePerc} </p>}
                                  {wound.otherPerc > 0 && <p className='mb-0'><span className='font-weight-bolder'>{wound.otherWoundDesc} %:</span>{wound.otherPerc} </p>}
                                </div>
                              </td>
                              <td>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Wound Edges:</span> {wound.woundEdgesName}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Surrounding Skin:</span> {wound.wounSurroundingSkinName}</p>
                                  {wound.wounSurroundingSkinName === "Other" &&
                                    <p className='mb-0'><span className='font-weight-bolder'>Surrounding Skin Other:</span> {wound.otherSurroundingSkin}</p>
                                  }
                                </div>

                              </td>
                              <td>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Skin & Ulcer treatment:</span> {wound.skinUlcerTreatmentName}</p>
                                  <p className='mb-0'><span className='font-weight-bolder'>Treatment:</span> {wound.treatment} </p>
                                  <p className='mb-0'><span className='font-weight-bolder'>General Comments:</span>{wound.comments}</p>
                                </div>
                                <div className='border_bottom_cus py-2'>
                                  <p className='mb-0'><span className='font-weight-bolder'>Signature:</span> {wound.evaluatedBy}</p>
                                  <p className='mb-0'>[E-signed] {wound.createdDate}</p>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <canvas id='fixedCanvas' width='100' height='100' style={{ display: 'none' }}></canvas>
            </>
            }
          </CardBody>
        </Card>

      </>
    );
  }
}