import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Card, CardBody } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import { connect } from 'react-redux';
import * as Common from "../../General/common";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import Select from 'react-select';
import uuid from 'node-uuid'
var prods = {};
class ProductSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryList: [],
            secondaryList: [],
            fillerList: [],
            traditionalList: [],
            selectedProducts: [],
            selectedOtherPrimaryProducts: [],
            selectedSecondaryProducts: [],
            selectedOtherFillerProducts: [],
            selectedOtherTraditionalProducts: [],
            orderProducts: [],
            otherPrimaryListOptions: [],
            salesOrder: {
                saleOrderId: null,
                otherAdditionalPrimaryDressing: this.props.primaryDressing,
                otherAdditionalSecondaryDressing: this.props.secondaryDressing,
                fkDressingChangeFrequencyId: this.props.frequencyId,
                orderNote: this.props.orderNote,
                WoundDressingChangeFrequency: [],
            },
            selectedFrequencyText: ""
        }

    }

    componentDidMount() {
        prods = {};
        if (this.props.orderProducts != null) {
            this.setState({ orderProducts: this.props.orderProducts });
        }
        // this.setFrequency("QD - One change per day");

        //this.setProductData(data);
        this.setFrequencyLookupsUI();
        this.GetAllProductsApiCall();
        if (this.props.woundDetailId > 0) {
            this.getSalesOrderProduct();
        } else {

        }

        // this.setFrequency("QD - One change per day");
    }

    async setFrequencyLookupsUI() {
        //    var WoundDressingChangeFrequency = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundDressingChangeFrequencyID)
        var WoundDressingChangeFrequency = await Common.getAllBTLookupOptions(Api.Uri_GetAllItemfrequencies)

        this.setState({ WoundDressingChangeFrequency: WoundDressingChangeFrequency });

        if (this.state.salesOrder.fkDressingChangeFrequencyId != null) {
            if (this.state.selectedFrequencyText == "") {

                var fr = document.getElementById('fkDressingChangeFrequencyId');
                var opts = fr.options;
                for (let j = 0; j < opts.length; j++) {
                    if (opts[j].value == this.state.salesOrder.fkDressingChangeFrequencyId) {
                        fr.selectedIndex = j;
                        this.setState({ selectedFrequencyText: opts[j].text })
                        break;
                    }
                }
                // $('#fkDressingChangeFrequencyId').change(function(e){
                //     alert("change"+ e.target.value);
                // });
                // $('#fkDressingChangeFrequencyId').val(this.state.salesOrder.fkDressingChangeFrequencyId).change();
            }
        }
    }

    render() {
        return (
            <>
                <fieldset disabled={this.props.isReadOnly == true ? true : false} className="p-2 mb-2 border rounded">
                    <legend className="text-primary fw-500">
                        Products Information </legend>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fkDressingChangeFrequencyId">Frequency of Dressing Changes - Entire Order</label>
                            <select className="form-control form-control-sm"
                                id="fkDressingChangeFrequencyId"
                                name="fkDressingChangeFrequencyId"
                                required
                                onChange={e => this.onFrequencychange(e)}
                                defaultValue={Common.replaceNullWithString(this.state.salesOrder.fkDressingChangeFrequencyId)}


                            >

                                {this.state.WoundDressingChangeFrequency}
                            </select>
                        </div>
                    </div>


                    <div className="row mt-2" id="changed">

                        <div className="col-md-4" >
                            <h4>Primary Products</h4>
                            {this.renderProductUI(this.state.primaryList, false, this.state.selectedProducts)}

                        </div>
                        <div className="col-md-4" >
                            <h4>Filler Products</h4>
                            {this.renderProductUI(this.state.fillerList, true, this.state.selectedProducts)}
                            <h4 className="mt-12">Traditional Products</h4>
                            {this.renderProductUI(this.state.traditionalList, false, this.state.selectedProducts)}

                        </div>
                        <div className="col-md-4" >
                            <h4>Secondary Products</h4>
                            {this.renderProductUI(this.state.secondaryList, false, this.state.selectedProducts)}

                        </div>

                    </div>


                    <div className="row mt-2" id="changed">

                        {/* <div className="col-sm-6 col-md-4 col-lg-3 col-xl-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Status">
                                Other Primary Products Requested
                            </label>
                            <Select isMulti='true'
                                value={this.state.selectedOtherPrimaryProducts}
                                hideSelectedOptions={true}
                                onChange={this.handleChangeOtherPrimaryProducts}
                                options={this.renderOtherProductUI(this.state.primaryList)} />
                        </div>
                    </div> */}
                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Status">
                                    Other Filler Products Requested
                                </label>
                                <Select isMulti='true'
                                    value={this.state.selectedOtherFillerProducts}
                                    hideSelectedOptions={true}
                                    onChange={this.handleChangeOtherFillerProducts}
                                    options={this.renderOtherProductUI(this.state.fillerList)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Status">
                                    Other Traditional Products Requested
                                </label>
                                <Select isMulti='true'
                                    value={this.state.selectedOtherTraditionalProducts}
                                    hideSelectedOptions={true}
                                    onChange={this.handleChangeOtherTraditionalProducts}
                                    options={this.renderOtherProductUI(this.state.traditionalList)} />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2" id="changed">



                        <div className="col-md-6">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Status">
                                        Other Secondary Products Requested
                                    </label>
                                    <Select isMulti='true'
                                        value={this.state.selectedSecondaryProducts}
                                        onChange={this.handleChangeOtherSecodaryProducts}
                                        hideSelectedOptions={true}
                                        options={this.renderOtherProductUI(this.state.secondaryList)} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label"

                                        htmlFor="otherAdditionalPrimaryDressing">Write in other Primary products</label>
                                    <input type="text" id="otherAdditionalPrimaryDressing"
                                        name="otherAdditionalPrimaryDressing"
                                        onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                        onChange={e => Common.updateInputValueInState(this.state.salesOrder, e, this, this.state)}

                                        defaultValue={Common.replaceNullWithString(this.state.salesOrder.otherAdditionalPrimaryDressing)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label"

                                        htmlFor="otherAdditionalSecondaryDressing">Write in other Secondary products</label>
                                    <input type="text" id="otherAdditionalSecondaryDressing"
                                        name="otherAdditionalSecondaryDressing"
                                        onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                        onChange={e => Common.updateInputValueInState(this.state.salesOrder, e, this, this.state)}
                                        defaultValue={Common.replaceNullWithString(this.state.salesOrder.otherAdditionalSecondaryDressing)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label"
                                        htmlFor="orderNarrative">Order Note</label>
                                    <textarea id="orderNarrative" name="orderNote"
                                        className="form-control form-control-sm" rows="10"
                                        onChange={e => Common.updateInputValueInState(this.state.salesOrder, e, this, this.state)}

                                        defaultValue={Common.replaceNullWithString(this.state.salesOrder.orderNote)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

            </>

        );
    }

    // Functions

    //#region render Product

    handleChangeOtherPrimaryProducts = selectedOtherPrimaryProducts => {
        this.setState({ selectedOtherPrimaryProducts });
    };
    handleChangeOtherFillerProducts = selectedOtherFillerProducts => {
        this.setState({ selectedOtherFillerProducts });
    };
    handleChangeOtherTraditionalProducts = selectedOtherTraditionalProducts => {
        this.setState({ selectedOtherTraditionalProducts });
    };
    handleChangeOtherSecodaryProducts = selectedSecondaryProducts => {
        this.setState({ selectedSecondaryProducts });
    };

    setOrderProducts() {
        const productListByLevel = this.groupBy(this.state.orderProducts, "productLevelName", "productCategoryName");
        var primaryList = [];
        var secondaryList = [];
        var fillerList = [];
        var traditionalList = [];
        if (productListByLevel != null) {


            primaryList = productListByLevel["Primary"] != null ? productListByLevel["Primary"] : [];
            secondaryList = productListByLevel["Secondary"] != null ? productListByLevel["Secondary"] : [];
            fillerList = productListByLevel["Filler"] != null ? productListByLevel["Filler"] : [];
            traditionalList = productListByLevel["Traditional"] != null ? productListByLevel["Traditional"] : [];

            let otherPrimaryProductArray = [];
            let otherSecondaryProductArray = [];
            let otherFillerProductArray = [];
            let otherTraditionalProductArray = [];

            Object.entries(primaryList).forEach(([key, value]) => {

                value.list.map(product => {
                    if (!product.withOtherButtons) {
                        return null;
                    }
                    otherPrimaryProductArray.push(
                        {
                            value: product.id, id: product.saleOrderProductId, label: product.displayName,
                            quantity: product.quantity,
                            qd: product.qd,
                            bid: product.bid,
                            qod: product.qod,
                            tid: product.tid,
                            qid: product.qid,
                            e3D: product.e3D,
                            mwf: product.mwf,
                            wK3: product.wK3,
                            wK2: product.wK2,
                            wK1: product.wK1
                        }
                    )
                }
                )

            })
            this.setState({ selectedOtherPrimaryProducts: otherPrimaryProductArray });


            Object.entries(fillerList).forEach(([key, value]) => {

                value.list.map(product => {
                    if (!product.withOtherButtons) {
                        return null;
                    }
                    otherFillerProductArray.push(
                        {
                            value: product.id, id: product.saleOrderProductId, label: product.displayName,
                            quantity: product.quantity,
                            qd: product.qd,
                            bid: product.bid,
                            qod: product.qod,
                            tid: product.tid,
                            qid: product.qid,
                            e3D: product.e3D,
                            mwf: product.mwf,
                            wK3: product.wK3,
                            wK2: product.wK2,
                            wK1: product.wK1
                        }
                    )
                }
                )

            })
            this.setState({ selectedOtherFillerProducts: otherFillerProductArray });

            Object.entries(traditionalList).forEach(([key, value]) => {

                value.list.map(product => {
                    if (!product.withOtherButtons) {
                        return null;
                    }
                    otherTraditionalProductArray.push(
                        {
                            value: product.id, id: product.saleOrderProductId, label: product.displayName,
                            quantity: product.quantity,
                            qd: product.qd,
                            bid: product.bid,
                            qod: product.qod,
                            tid: product.tid,
                            qid: product.qid,
                            e3D: product.e3D,
                            mwf: product.mwf,
                            wK3: product.wK3,
                            wK2: product.wK2,
                            wK1: product.wK1
                        }
                    )
                }
                )

            })
            this.setState({ selectedOtherTraditionalProducts: otherTraditionalProductArray });

            Object.entries(secondaryList).forEach(([key, value]) => {

                value.list.map(product => {
                    if (!product.withOtherButtons) {
                        return null;
                    }
                    otherSecondaryProductArray.push(
                        {
                            value: product.id, id: product.saleOrderProductId, label: product.displayName,
                            quantity: product.quantity,
                            qd: product.qd,
                            bid: product.bid,
                            qod: product.qod,
                            tid: product.tid,
                            qid: product.qid,
                            e3D: product.e3D,
                            mwf: product.mwf,
                            wK3: product.wK3,
                            wK2: product.wK2,
                            wK1: product.wK1
                        }
                    )
                }
                )

            })
            this.setState({ selectedSecondaryProducts: otherSecondaryProductArray });


        }

        Object.entries(primaryList).forEach(([key, value]) => {

            value.list.map((product) => {
                if (!product.withOtherButtons) {
                    let e = { target: { value: product.quantity } }

                    this.setSelectedProducts(product);
                    this.onchangeQuantity(e, product.id)
                }
            })
        })

        Object.entries(secondaryList).forEach(([key, value]) => {

            value.list.map((product) => {
                if (!product.withOtherButtons) {
                    let e = { target: { value: product.quantity } }
                    this.setSelectedProducts(product);
                    this.onchangeQuantity(e, product.id)
                }
            })
        })

        Object.entries(fillerList).forEach(([key, value]) => {

            value.list.map((product) => {
                if (!product.withOtherButtons) {
                    let e = { target: { value: product.quantity } }
                    this.setSelectedProducts(product);
                    this.onchangeQuantity(e, product.id)
                }
            })
        })

        Object.entries(traditionalList).forEach(([key, value]) => {

            value.list.map((product) => {
                if (!product.withOtherButtons) {
                    let e = { target: { value: product.quantity } }
                    this.setSelectedProducts(product);
                    this.onchangeQuantity(e, product.id)
                }
            })
        })
    }

    setSelectedProducts(product, newProductSelect=false) {
        if (prods[product.id] &&!newProductSelect) {
            prods[product.id] = false;
            const _selectedprod = this.state.selectedProducts.filter(obj => {
                return obj.id != product.id
            })

            this.setState({ selectedProducts: _selectedprod });
        }
        else {
            //BELOW COMMENTED CODE WILL USE IN FUTURE
            //    var role= this.props.roles.roleList.find(x=>x.roleName==RBAC.Role_Gentell_SeniorBiller);
            //     if(role!=undefined &&newProductSelect){
            //         var findCategory = this.state.selectedProducts.find(x => x.fkProductCategoryId == product.fkProductCategoryId )
            //         if(findCategory==undefined){
            //             Common.showConfirmation("You can't select new Category", "warning", "Warning!", 3000);

            //             return false;
            //         }
            //     }
            let _selectedProd = JSON.parse(JSON.stringify(this.state.selectedProducts));
            if( prods[product.id]!=true){
                prods[product.id] = true;
            }
           
            let _selectedProdCatogory = JSON.parse(JSON.stringify(this.state.selectedProducts.filter(obj => {
                return obj.fkProductCategoryId == product.fkProductCategoryId && obj.productLevel == product.productLevel
            })));
            if (_selectedProdCatogory) {
                for (var i = 0; i < _selectedProdCatogory.length; i++) {
                    let item = JSON.parse(JSON.stringify(_selectedProdCatogory[i]));
                    if (prods[item.id]) {
                        prods[item.id] = false;
                        // _selectedProd.pop(item)
                        _selectedProd = _selectedProd.filter(obj => {
                            return obj.id != item.id
                        });


                    }
                }

            }
            let quantity = this.getProductQuantityByFrequency(product, null);
            // let _selectedProd = JSON.parse(JSON.stringify(this.state.selectedProducts.filter(obj => {
            //     return obj.fkProductCategoryId != product.fkProductCategoryId && obj.productLevel!=product.productLevel
            // })));
            _selectedProd.push({
                id: product.id, quantity: quantity, saleOrderProductId: product.saleOrderProductId, btItemID:
                    product.btItemID, fkProductCategoryId: product.fkProductCategoryId,
                productLevel: product.productLevel,
                productLevelName:product.productLevelName,
                productCategoryName:product.productCategoryName,

                qd: product.qd,
                bid: product.bid,
                qod: product.qod,
                tid: product.tid,
                qid: product.qid,
                e3D: product.e3D,
                mwf: product.mwf,
                wK3: product.wK3,
                wK2: product.wK2,
                wK1: product.wK1,
                remainingQuantity: product.remainingQuantity,
                
                isOrderNow: product.isOrderNow,
                isOrderNowProcessed: product.isOrderNowProcessed


            });
            this.setState({ selectedProducts: _selectedProd })

            //Super absurbent de select if certain category product is selectd
            let _selectedProdSuperAbsurbent = this.state.selectedProducts.
                find(x => x.productLevel == Common.ProductLevel.primary
                    && x.fkProductCategoryId == Common.ProductCategories.superAbsorbent);
            if (_selectedProdSuperAbsurbent != undefined) {
                if (Common.ProductLevel.primary == product.productLevel && (
                    product.fkProductCategoryId == Common.ProductCategories.collagen
                    || product.fkProductCategoryId == Common.ProductCategories.collagenAG
                    || product.fkProductCategoryId == Common.ProductCategories.hydrogel
                    || product.fkProductCategoryId == Common.ProductCategories.calciumAlginate
                    || product.fkProductCategoryId == Common.ProductCategories.calciumAlginateAG
                    || product.fkProductCategoryId == Common.ProductCategories.cMCFiber
                    || product.fkProductCategoryId == Common.ProductCategories.honeyFiber)) {
                    const _selectedprodrWithOutSuperAbsurbent = this.state.selectedProducts.filter(obj => {
                        return obj.id != _selectedProdSuperAbsurbent.id
                    })
                    prods[_selectedProdSuperAbsurbent.id] = false;
                    this.setState({ selectedProducts: _selectedprodrWithOutSuperAbsurbent });
                }
            }
            //Super Absorbent Adherent de select if certain category product is selectd
            let _selectedProdSuperAbsurbentAdherent = this.state.selectedProducts.
                find(x => x.productLevel == Common.ProductLevel.primary && x.fkProductCategoryId == Common.ProductCategories.superAbsorbentAdherent);
            if (_selectedProdSuperAbsurbentAdherent != undefined) {
                if (Common.ProductLevel.primary == product.productLevel && (
                    product.fkProductCategoryId == Common.ProductCategories.collagen
                    || product.fkProductCategoryId == Common.ProductCategories.collagenAG
                    || product.fkProductCategoryId == Common.ProductCategories.hydrogel
                    || product.fkProductCategoryId == Common.ProductCategories.calciumAlginate
                    || product.fkProductCategoryId == Common.ProductCategories.calciumAlginateAG
                    || product.fkProductCategoryId == Common.ProductCategories.cMCFiber
                    || product.fkProductCategoryId == Common.ProductCategories.honeyFiber)) {
                    const _selectedprodrWithOutSuperAbsurbentAdherent = this.state.selectedProducts.filter(obj => {
                        return obj.id != _selectedProdSuperAbsurbentAdherent.id
                    })
                    prods[_selectedProdSuperAbsurbentAdherent.id] = false;
                    this.setState({ selectedProducts: _selectedprodrWithOutSuperAbsurbentAdherent });
                }

            }

        }

    }

    onchangeQuantity(event, id) {

        const _selectedprod = this.state.selectedProducts.map(obj => {
            if (obj.id == id) {
                obj.quantity = event.target.value;
            }
            return obj
        })

        this.setState({ selectedProducts: _selectedprod });

    }
    onchangeRemainingQuantity(event, id) {
        if(event.target.value>5){
            Common.showSuccessAlert("Please select 0 to 5 remaining quantity", "warning", "Warning!", 3000);
            return false
        }else{
            const _selectedprod = this.state.selectedProducts.map(obj => {
                if (obj.id == id) {
                    obj.remainingQuantity = event.target.value;
                }
                return obj
            })
    
            this.setState({ selectedProducts: _selectedprod });
        }
     

    }
    onChangeIsOrderNow(event, id) {

        const _selectedprod = this.state.selectedProducts.map(obj => {
            if (obj.id == id) {
                obj.isOrderNow = event.target.checked;
            }
            return obj
        })

        this.setState({ selectedProducts: _selectedprod });

    }
    
    getSelectedProductQuantity(product) {
        let quantity = this.getProductQuantityByFrequency(product, null);

        this.state.selectedProducts.map(obj => {
            if (obj.id == product.id) {
                quantity = obj.quantity;
            }
        })

        return quantity;
    }
    getSelectedRemainingProductQuantity(product) {

        let quantity = null; //this.getProductQuantityByFrequency(product);

        this.state.selectedProducts.map(obj => {
            if (obj.id == product.id) {
                quantity = obj.remainingQuantity;
            }
        })

        return quantity;
    }
    getProductIsOrderNow(product) {

        let isOrderNow = false; //this.getProductQuantityByFrequency(product);

        this.state.selectedProducts.map(obj => {
            if (obj.id == product.id) {
                isOrderNow = obj.isOrderNow;
            }
        })

        return isOrderNow;
    }
    getProductIsOrderNowProcessed(product) {
        let isOrderNowProcessed = false; //this.getProductQuantityByFrequency(product);

        this.state.selectedProducts.map(obj => {
            if (obj.id == product.id) {
                isOrderNowProcessed = obj.isOrderNowProcessed;
            }
        })

        return isOrderNowProcessed;
    }
    renderProductUI(list, isFiller, selectedProducts) {
        if (isFiller) {
        }
        const _list = list;
        let productUIArray = [];

        Object.entries(_list).forEach(([key, value]) => {

            {

                value.list.find(x => x.withOtherButtons === false) &&

                    productUIArray.push(

                        <div className="row mt-5" key={key}>

                            <div className={`col-md-3 justify-content-center my-auto`}>
                                {<label>{Common.replaceNullWithString(value.description, key)}</label>}
                            </div>
                            <div className="col-md-9">

                                {

                                    value.list.map((product) => {
                                        let overlayContainer = React.createRef(null);
                                        var findItem = selectedProducts.find(x => x.btItemID == product.btItemID)
                                        var findCategory = selectedProducts.find(x => x.fkProductCategoryId == 1265)
                                        var findCategorySuperAbsurbentAdherent = selectedProducts.find(x => x.fkProductCategoryId == Common.ProductCategories.superAbsorbentAdherent)


                                        // If super absorbent select as primary then can't selected secondary

                                        var isSuperAbsorbent = false;
                                        var isSuperAbsurbentAdherent = false;

                                        if (findCategory != undefined && product.fkProductCategoryId == 1265) {
                                            if (product.productLevel != findCategory.productLevel) {
                                                isSuperAbsorbent = true;
                                            }
                                        }
                                        if (findCategorySuperAbsurbentAdherent != undefined && product.fkProductCategoryId == Common.ProductCategories.superAbsorbentAdherent) {
                                            if (product.productLevel != findCategorySuperAbsurbentAdherent.productLevel) {
                                                isSuperAbsurbentAdherent = true;
                                            }
                                        }
                                        let _selectedProdOterWithSuperAbsurbent = selectedProducts.
                                            find(x => x.productLevel == Common.ProductLevel.primary
                                                && (x.fkProductCategoryId == Common.ProductCategories.collagen
                                                    || x.fkProductCategoryId == Common.ProductCategories.collagenAG
                                                    || x.fkProductCategoryId == Common.ProductCategories.hydrogel
                                                    || x.fkProductCategoryId == Common.ProductCategories.calciumAlginate
                                                    || x.fkProductCategoryId == Common.ProductCategories.calciumAlginateAG
                                                    || x.fkProductCategoryId == Common.ProductCategories.cMCFiber
                                                    || x.fkProductCategoryId == Common.ProductCategories.honeyFiber
                                                ))
                                        if (_selectedProdOterWithSuperAbsurbent != undefined
                                            && Common.ProductLevel.primary == product.productLevel
                                            && Common.ProductCategories.superAbsorbent == product.fkProductCategoryId) {
                                            isSuperAbsorbent = true;
                                        }
                                        if (_selectedProdOterWithSuperAbsurbent != undefined
                                            && Common.ProductLevel.primary == product.productLevel
                                            && Common.ProductCategories.superAbsorbentAdherent == product.fkProductCategoryId) {
                                            isSuperAbsurbentAdherent = true;
                                        }
                                        // var findCategory=selectProducts.find(x)
                                        if (product.withOtherButtons) {
                                            return null;
                                        }
                                        const btnColor = prods[product.id] ? "btn-success" : "btn-outline-info";
                                        return product.sku != null ?
                                            // prods[product.id] ?
                                                <OverlayTrigger rootClose onClick={() => this.setSelectedProducts(product)} trigger="click" placement="auto" overlay={
                                                    <Popover id="popover-basic"  >
                                                        <div>
                                                            <Card>
                                                                <CardBody>
                                                                    <label className="form-label text text-bold">{product.productName}</label>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label className="form-label" htmlFor="DebridementType">Quantity</label>
                                                                                <input type="number" min="1" id="quantity"
                                                                                    name="quantity"
                                                                                    onChange={(e) => this.onchangeQuantity(e, product.id)}
                                                                                    //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                                                    defaultValue={this.getSelectedProductQuantity(product)}
                                                                                    className="form-control form-control-sm" />

                                                                                {/* <button type="button" className="btn btn-danger mt-5" size="sm" onClick={() => this.setSelectedProducts(product)}>Remove Product</button> */}
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-12">
                                                                            <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                                                                                <label className="checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="m-1"
                                                                                        id="isOrderNow"
                                                                                        name="isOrderNow"
                                                                                        defaultChecked={this.getProductIsOrderNow(product)}
                                                                                      onChange={(e) => this.onChangeIsOrderNow(e , product.id)}
                                                                                    />
                                                                                    <span className="mr-3" />
                                                                                    Is Order Now
                                                                                </label>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                {this.props.isReorder &&
                                                                                    <>
                                                                                        <label className="form-label" htmlFor="DebridementType">Remaining Quantity</label>
                                                                                        <input type="number" id="RemainingQuantity"
                                                                                            name="RemainingQuantity"
                                                                                            onChange={(e) => this.onchangeRemainingQuantity(e, product.id)}
                                                                                            min="0"
                                                                                            max="5"
                                                                                            //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                                                            defaultValue={this.getSelectedRemainingProductQuantity(product)}
                                                                                            className="form-control form-control-sm" />
                                                                                    </>}
                                                                                <button type="button" className="btn btn-danger mt-5" size="sm" onClick={() => this.setSelectedProducts(product)}>Remove Product</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>

                                                            </Card>
                                                        </div>
                                                    </Popover>
                                                }>
                                                    <button type="button" title={product.productName} 
                                                    className={`mr-4 mt-2 btn position-relative  ${btnColor}`}
                                                        id={product.sku}
                                                        disabled={this.getProductIsOrderNowProcessed(product)}
                                                    //onClick={() => this.setSelectedProducts(product)}
                                                    onClick={() => prods[product.id] ? {}:this.setSelectedProducts(product,true)}
                                                    >
                                                        {Common.replaceNullWithString(product.buttonLabel)}
                                                        {prods[product.id] ? 
                                                        
                                                        <small><span className="badge rounded-pill badge-notification position-absolute top-0 left-100 ml-1 translate-middle bg-warning ">{this.getSelectedProductQuantity(product)}</span></small>
                                                        :<></>}
                                                        
                                                        <small><span className="badge rounded-pill badge-notification position-absolute top-0 left-100 ml-1 mt-6 translate-middle bg-primary z-Index-9999">{this.getSelectedRemainingProductQuantity(product)}</span></small>




                                                    </button>
                                                </OverlayTrigger>
                                                // :
                                                // <button key={uuid()} type="button" title={product.productName} disabled={findItem != undefined || isSuperAbsorbent == true || isSuperAbsurbentAdherent == true ? true : false} className={`mr-2 mt-2 btn  ${btnColor}`}
                                                //     id={product.sku}
                                                //     onClick={() => this.setSelectedProducts(product, true)}
                                                // >
                                                //     {Common.replaceNullWithString(product.buttonLabel)}
                                                // </button>

                                            :
                                            <label className="form-label" htmlFor="Status">{Common.replaceNullWithString(product.buttonLabel)}</label>
                                    })
                                }

                            </div>

                        </div>

                    );

            }
        })

        return productUIArray;


    }
    renderOtherProductUI(list) {
        const _list = list;
        let productUIArray = [];

        Object.entries(_list).forEach(([key, value]) => {

            value.list.map(product => {
                if (!product.withOtherButtons) {
                    return null;
                }
                productUIArray.push(
                    {
                        value: product.id, label: product.displayName,
                        quantity: product.quantity,
                        qd: product.qd,
                        bid: product.bid,
                        qod: product.qod,
                        tid: product.tid,
                        qid: product.qid,
                        e3D: product.e3D,
                        mwf: product.mwf,
                        wK3: product.wK3,
                        wK2: product.wK2,
                        wK1: product.wK1

                    }
                )
            }
            )

        })
        return productUIArray;


    }

    //#endregion




    //#region Get/init Product

    groupBy(objectArray, property, subProperty) {
        return objectArray.reduce((acc, obj) => {
            var key = obj[property];
            var subkey = obj[subProperty];

            if (!acc[key]) {
                acc[key] = [];
            }

            let tempAcc = acc[key];

            if (!tempAcc[subkey]) {
                tempAcc[subkey] = { name: subkey, description: obj["productCategoryDescription"], list: [] };
            }

            tempAcc[subkey].list.push(obj);

            return acc;
        }, {});
    }

    setProductData(data) {
        const productListByLevel = this.groupBy(data, "productLevelName", "productCategoryName");

        if (productListByLevel != null) {

            console.log(productListByLevel)
            this.setState({
                primaryList: productListByLevel["Primary"] != null ? productListByLevel["Primary"] : [],
                secondaryList: productListByLevel["Secondary"] != null ? productListByLevel["Secondary"] : [],
                fillerList: productListByLevel["Filler"] != null ? productListByLevel["Filler"] : [],
                traditionalList: productListByLevel["Traditional"] != null ? productListByLevel["Traditional"] : [],


            })
        }
    }



    async GetAllProductsApiCall() {

        Api.getRequest(Api.URI_GetAllProducts).then((res) => {
            if (res.data.success) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        this.setProductData(res.data.result);
                        if (this.props.orderProducts != null) {
                            this.setOrderProducts();
                        }
                    }

                }
                else {
                    Common.showSuccessAlert(res.data.message, "warning", "Warning!", 3000);
                }
            }
            else {
                Common.showSuccessAlert(res.data.message, "warning", "Warning!", 3000);
            }
        }).catch((ex) => {

            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }
    getSalesOrderProduct = () => {
        Api.getRequestQry(Api.URI_GetSaleOrderByResidentWoundID, 
            "?woundDetailId="+ this.props.woundDetailId
            +"&saleOrderId="+ (this.props.salesOrderId>0? this.props.salesOrderId:0)
            ).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    // setSaleOrderId(res.data.result.saleOrderId)
                    var salesOrder = {};
                    salesOrder.saleOrderId = res.data.result.saleOrderId;
                    salesOrder.otherAdditionalPrimaryDressing = res.data.result.otherAdditionalPrimaryDressing;
                    salesOrder.otherAdditionalSecondaryDressing = res.data.result.otherAdditionalSecondaryDressing;
                    salesOrder.fkDressingChangeFrequencyId = res.data.result.fkDressingChangeFrequencyId;
                    salesOrder.orderNote = res.data.result.orderNote;
                    salesOrder.WoundDressingChangeFrequency = [];

                    this.setState({ salesOrder: salesOrder })

                    this.setState({ orderProducts: res.data.result.saleOrderViewModels });
                    // this.setProductData(res.data.result);
                    this.setOrderProducts();
                }
            }

            //Common.LoadDataTable("woundProductList"+props.residentWound.wound.woundDetailId)
        }).catch((ex) => {
        });
    }
    //#endregion

    onFrequencychange(event) {

        Common.updateInputValueInState(this.state.salesOrder, event, this, this.state);

        let flbl = event.target.options[event.target.selectedIndex].text;
        this.setFrequency(flbl);
        //    this.setState({ selectedFrequencyText: flbl });
        //    // const freq = this.state.selectedFrequencyText.split(" ")[0];
        //    const _selectedprod = this.state.selectedProducts.map(obj => {
        //        // if (obj.id == id) {
        //        obj.quantity = this.getProductQuantityByFrequency(obj);
        //        // }
        //        return obj
        //    })
        //    this.setState({ selectedProducts: _selectedprod });

    }
    setFrequency = (flbl) => {

        this.setState({ selectedFrequencyText: flbl });
        // const freq = this.state.selectedFrequencyText.split(" ")[0];
        const _selectedprod = this.state.selectedProducts.map(obj => {
            // if (obj.id == id) {
            obj.quantity = this.getProductQuantityByFrequency(obj, flbl);
            // }
            return obj
        })
        this.setState({ selectedProducts: _selectedprod });

    }



    getProductQuantityByFrequency(product, flbl) {
        var freq = this.state.selectedFrequencyText.split(" ")[0];
        if (flbl != null) {
            freq = flbl.split(" ")[0];
        }
        if (freq != null) {
            freq = freq.toLowerCase()

        }
        if (freq == "1wk") {
            freq = "wK1";
        }
        else if (freq == "2wk") {
            freq = "wK2";

        }
        else if (freq == "3wk") {
            freq = "wK3";

        }
        else if (freq == "4wk") {
            freq = "qod";

        }
        else if (freq == "e3d") {
            freq = "e3D";

        }
        let quantity = product[freq];
        if (quantity == null) {
            return product.qd;
        }
        else {
            return quantity;
        }
    }

    getSelectedProductList = () => {
        var productsList = {}
        productsList.products = [];
        productsList.products = this.state.selectedProducts;
        
        productsList.otherPrimaryProducts = [];
        productsList.otherPrimaryProducts = this.state?.selectedOtherPrimaryProducts?.map(obj => {
            obj.quantity = this.getProductQuantityByFrequency(obj, null);
            return obj
        })

        // this.state.selectedOtherPrimaryProducts;
        productsList.selectedSecondaryProducts = [];
        productsList.selectedSecondaryProducts = this.state?.selectedSecondaryProducts?.map(obj => {
            obj.quantity = this.getProductQuantityByFrequency(obj, null);
            return obj
        })

        // this.state.selectedSecondaryProducts;
        productsList.selectedOtherFillerProducts = [];
        productsList.selectedOtherFillerProducts = this.state?.selectedOtherFillerProducts?.map(obj => {
            obj.quantity = this.getProductQuantityByFrequency(obj, null);
            return obj
        })
        // this.state.selectedOtherFillerProducts;
        productsList.selectedOtherTraditionalProducts = [];
        productsList.selectedOtherTraditionalProducts = this.state?.selectedOtherTraditionalProducts?.map(obj => {
            obj.quantity = this.getProductQuantityByFrequency(obj, null);
            return obj
        })

        // this.state.selectedOtherTraditionalProducts;
        productsList.salesOrder = this.state.salesOrder;

        return productsList;
    }

}//end
const mapStateToProps = (state) => {
    const roles = state.auth.user.roles;
    return { roles }
};
//   export default connect(mapStateToProps)(ProductSelection)
export default
    connect(mapStateToProps,
        {}
        , null, { forwardRef: true })(ProductSelection);