// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react'
import { Document } from 'react-pdf'
import {
Button, Table
} from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
const FacilityPdfReport = (props) => {
    // ** States
    const [id, setID] = useState(0);
    const [renderPdf, setRenderPdf] = useState(null);

    const [isLoading, setLoading] = useState(true);
    

    useEffect(() => {
        getAllFacilityMsg();
    }, []);
    const getAllFacilityMsg = async () => {
        setLoading(true)
        var { data } = await Api.getRequestQry(Api.URI_GetFacilitesReport,"?facilityId=" + 123)
        debugger
        if (data.success) {
            debugger
            setRenderPdf(data.result)
            setLoading(false)

        } else {
            setLoading(false)
        }

    }
 
    return (
        <Fragment>
            {!isLoading &&
//    <ReactPDF
//   file={{
//     data: renderPdf
//   }}
// />
<Document file={renderPdf} />
}
        </Fragment>
    )
}

export default FacilityPdfReport
