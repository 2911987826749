import React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const SkeletonComponent = ({ count }) => {
    
    
  return (
    <div
      className="w-100"
      style={{ backgroundColor:  "white" }}
    >
      <SkeletonTheme baseColor="#6c7293" highlightColor="#3699FF" >
        <Skeleton count={count || 10} />
      </SkeletonTheme>
    </div>
  );
};
export default SkeletonComponent;