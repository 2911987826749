import React, { useCallback, useEffect, useState } from 'react';
// import $ from 'jquery';
import { Animated } from "react-animated-css";
import { useSelector } from 'react-redux';
//import { useState } from 'react';
import { Alert, Badge, Button, ButtonToolbar, Col, Dropdown, DropdownButton, Modal, Row, Spinner } from "react-bootstrap";
import { showDecisionAlert, showWarningAlert } from "../../../index";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import RoutePaths from '../../constants/routepaths';
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import FacilityDetail from '../modal/facilitymodals/facilitydetail';
import PopupModal from '../modal/popupmodal';
import NewFacilityModal from "../modal/_newfacilitymodal";
import PlaceOrder from "../orders/placeorder";
import EditFacility from './editFacility';
import $ from "jquery";
//import Physician from '../modal/facilitymodals/physician';
import AddFacility from './newFacility';
import ViewFacility from './viewFacility';
import AllFacilityResident from './facilityResident';
import SetBillingDate from './setBillingDate';
import SyncFacility from './syncBrighreeFacility'
import ExcelSheetFacility from './excelSheetFacility'

import AddFacilityUser from '../repfirm/addFacilityUser';
import FacilityHistory from './facilityHistory';

import { CSVLink, CSVDownload } from "react-csv";
import Contactslistmodal from "../modal/facilitymodals/contactslistmodal";

import DataTable from 'react-data-table-component';
import SkeletonComponent from './components/SkeletonComponent';
import { debounce } from 'lodash';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import EmptyComponent from './components/EmptyComponent';

var addFacilityModal = null;
var syncFacilitySheetModal = null;

var addFacility = null;
var editFacilityModal = null;
var editFacility = null;
var viewFacilityModal = null;
var viewFacilityDetail = null;
var facilityUserModal = null;
var facilityHistoryModal = null;
//#region Place Order Modal
var setBillingDateModal = null;
//#endregion
//#region Physician modal
//var physicianModal = null;
//#endregion
export function Facility() {

    useEffect(() => {
        debugger
        getAllFacilities();

        // getAllPartailOrderFacilityList(); 
    }, [getAllFacilities]);

    const [FacilityID, setFacilityID] = useState('');
    const [roleID, setRoleID] = useState('');
    const [billingDateDetail, setBillingDate] = useState('');
    const [isPartail, setIsPartail] = useState(false);

    const { user } = useSelector((state) => state.auth);
    const [tableFacility, setFacility] = useState([]);
    const [partailFacilityUI, setPartailFacility] = useState([]);

    const [Facility, setFacilityForEdit] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showResident, setShowResident] = useState(false);
    const [FacilityObj, setFacilityObj] = useState("");
    const [facilityListToCSV, setFacilityListToCSV] = useState([]);
    const [showModalContacttList, setShowContactList] = useState(false);

    //#region new facility modal
    const [showModalNewFacility, setShowNewFacility] = useState(false);
    const [changeDetect, setChangeDetect] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [faclityList, setFacilityList] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [facilityPrinting, setFacilityPrinting] = useState(false)
    const handleCloseNewFacility = () => setShowNewFacility(false);
    const handleShowNewFacility = () => setShowNewFacility(true);

    //#endregion
    const handleCloseContactList = () => {
        debugger
        if (changeDetect) {
            Common.showConfirmation('Are you sure you want to close without saving the data?', "", closeContactList, null, false)
        } else {
            closeContactList(false)
        }

    };

    var rows = [];
    const afterCreateFacility = () => {
        addFacilityModal.hideModal(true);
        editFacilityModal.hideModal(true);
        setBillingDateModal.hideModal();
        Common.showSuccessAlertAutoClose(
            "Data Saved Successfully",
            "success",
            "Success!",
            3000
        );
        getAllFacilities()
    }
    const handleBillingDate = (facility) => {
        if (facility.zip == '' || facility.zip == null) {
            Common.showSuccessAlert(
                "Facility do not have postal code. Please enter postal code to proceed",
                "warning",
                "Warning!",
                3000
            );
            return false;
        }
        setFacilityID(facility.id);
        var billingDateObj = {};
        if (Common.GetDateString(facility.cycleDate) == null) {
            billingDateObj.billingDate = null;

        } else {
            billingDateObj.billingDate = facility.cycleDate;
        }
        if (Common.GetDateString(facility.secondaryCycleDate) == null) {
            billingDateObj.secondayBillingDate = null;


        } else {
            billingDateObj.secondayBillingDate = facility.secondaryCycleDate;

        }

        if (Common.GetDateString(facility.primaryCycleDate) == null) {
            billingDateObj.primaryCycleDate = null;


        } else {
            billingDateObj.primaryCycleDate = facility.primaryCycleDate;

        }
        billingDateObj.isRequested = false;
        billingDateObj.requestedCycleDate = facility.requestedCycleDate;
        billingDateObj.requestedSecondaryCycleDate = facility.requestedSecondaryCycleDate;
        billingDateObj.isPartial = false;
        billingDateObj.isSecondaryCycle = facility.isSecondaryCycle;

        setBillingDate(billingDateObj);
        setBillingDateModal.showModal()
    }
    const viewFacility = (id) => {

        Api.getRequestById(Api.Uri_GetFacilityByID, id)
            .then((response) => {
                // // debugger
                setFacilityForEdit(response.data.result);
                viewFacilityModal.showModal();
            })
            .catch(function (error) {
                Common.LogError(error, "Facility", "viewFacility");
            });
    }

    const editFacilityByID = (id) => {
        setFacilityID(id);
        getFacilityByID(id);
    }

    const viewFacilityByID = (id) => {
        // setFacilityID(id);
        // getFacilityByID(id);
        viewFacility(id);
    }

    const getFacilityByID = (id) => {
        Api.getRequestById(Api.Uri_GetFacilityByID, id)
            .then((response) => {
                debugger
                setFacilityForEdit(response.data.result);
                editFacilityModal.showModal();
            })
            .catch(function (error) {
                Common.LogError(error, "Facility", "getFacilityByID");

            });
    };
    const refreshFacilityList = () => {

        setShowResident(false);
        getAllFacilities();
        getAllPartailOrderFacilityList();
    }

    const getAllFacility = useCallback(() => {
        Api.getRequest(Api.Uri_GetFacility)
            .then((res) => {
                if (res.data.statusCode == 200) {
                    if (res.data.success) {
                        if (res.data.result != null) {
                            if (res.data.result.length > 0) {
                                // setFacilityListUi(res.data.result);
                                setFacilityListToCSVUI(res.data.result)
                                document.getElementById('csv-link').click();
                                setFacilityPrinting(false)
                            }

                        }
                    }
                }
            })
            .catch(function (error) {
                Common.LogError(error, "Facility", "getAllFacility");

            }).then(() => {

            });
    })
    const getAllFacilities = useCallback((search = '') => {
        setIsLoading(true)
        var parm =
            "?pageIndex=" +
            currentPage +
            "&pageSize=" +
            rowsPerPage +
            "&search=" +
            search
        Api.getRequestQry(Api.Uri_GetFacilities, parm)
            .then((res) => {
                if (res.data.statusCode == 200) {
                    if (res.data.success) {
                        if (res.data.result != null) {
                            if (res.data.result.items.length > 0) {
                                setIsLoading(false)
                                setTotalItems(res.data.result.totalRows)
                                setFacilityList(res.data.result.items);
                                // setFacilityListToCSVUI(res.data.result.items)
                            }

                        }
                    }
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                Common.LogError(error, "Facility", "getAllFacilities");

            }).then(() => {

            });
    })
    const setFacilityListToCSVUI = async (facilityList) => {
        let facilityUI = [];
        facilityList.map((fac, i) => {

            facilityUI.push(
                // { value: user.key, label: user.value }
                {
                    facilityName: fac.facilityName,
                    organizationName: fac.organizationName,
                    chain: fac.chain,
                    active: fac.active ? "Yes" : "No",

                    createdDate: Common.GetDateString(
                        fac.createdDate
                    ),
                    facilityType: fac.facilityType,
                    clinicianName: fac.clinicianName,
                    npi: fac.npi,
                    productLine: fac.productLine,
                    numberofBeds: fac.numberofBeds,
                    addressLine1: fac.addressLine1,
                    addressLine2: fac.addressLine2,
                    city: fac.city,
                    stateName: fac.stateName,
                    zip: fac.zip,
                    phone1: fac.phone1,
                    zip: fac.zip,
                    fax: fac.fax,
                    partBrecPerson: fac.partBrecPerson,
                    partBrecPersonPhone: fac.partBrecPersonPhone,
                    partBrecPersonEmail: fac.partBrecPersonEmail,
                    centralSupplyPerson: fac.centralSupplyPerson,
                    centerSupplyPersonPhone: fac.centerSupplyPersonPhone,
                    centerSupplyPersonEmail: fac.centerSupplyPersonEmail,
                    assignedNurse: fac.assignedNurse,
                    medicarePartBbyGentell: fac.medicarePartBbyGentell ? "Yes" : "No",
                    outProgram: fac.outProgram ? "Yes" : "No",
                    isEcp: fac.isEcp ? "Yes" : "No",
                    medicarePartBbyGentell: fac.medicarePartBbyGentell ? "Yes" : "No",
                    NeedToFind: "NeedToFind"

                }

            );
        });

        setFacilityListToCSV(facilityUI);
    };
    const getAllPartailOrderFacilityList = useCallback(() => {

        //setIsLoading(true);
        Api.getRequestQry(Api.Uri_GetPartailOrderFacilities, "?queueId=" + 0)
            .then((res) => {
                if (res.data.statusCode == 200) {

                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            setPartailFacilityList(res.data.result);
                        }
                    }
                }
            })
            .catch((ex) => {


            })
            .then(() => {
                setIsLoading(false);

            });
    });
    const closeContactList = (close) => {
        setChangeDetect(false)
        setShowContactList(close)
    }
    const handleShowContactList = (_facilityID) => {
        setFacilityID(_facilityID)
        setTimeout(() => {
            setShowContactList(true);
        }, 200)
    }
    const setFacilityListUi = (list) => {
        rows = [];
        list.map((object, i) => {
            rows.push(<tr key={i.toString()}>
                <td align="left" className="customStyleAccorion">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                            setIsPartail(false)
                            setShowResident(true);
                            setFacilityObj(object);
                        }}
                    >
                        {object.facilityName}
                    </button>
                </td>

                <td>{Common.GetDateString(object.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(object.billingDate)}</td>
                <td>{Common.replaceNullOrEmptyWithString(object.organizationName, "")}{object.organizationName == null && object.chain == null ? "N/A" : ""}
                    {object.organizationName != null && object.chain != null ? "/" : ""}
                    {Common.replaceNullOrEmptyWithString(object.chain, "")}</td>
                <td>{Common.replaceNullOrEmptyWithString(object.clinicianName, "N/A")}</td>
                <td align="center">{object.active ? <Badge variant="success">Active</Badge> : <Badge variant="primary">Deactive</Badge>}</td>
                <td>{object.totalResident + "/" + object.totalWound}</td>
                {/* <td>{object.totalPartailWound}</td> */}

                <td align="center">{object.fkProcessStatusId == Common.ProcessStatus.workQueueID ? <Badge variant="success">Work Queue</Badge> : object.fkProcessStatusId == Common.ProcessStatus.billingQueueID ? <Badge variant="primary">Processor Queue</Badge> : <Badge variant="info">Brightree Queue</Badge>}</td>
                <td align="center">
                    <>
                        <ButtonToolbar className="justify-content-center">
                            <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                                {[DropdownButton].map((DropdownType, idx) => (
                                    <DropdownType
                                        size="xs"
                                        variant="primary"

                                        title="Actions"
                                        id={`dropdown-button-drop-${idx}`}
                                        key={idx}
                                    >
                                        <CheckPermission
                                            userRole={[RBAC.Role_Gentell_Administrator,
                                            RBAC.Role_Gentell_SeniorBiller
                                                , RBAC.Role_Gentell_IntakeProcessor]}

                                            yes={() => (
                                                <>
                                                    <Dropdown.Item eventKey="0" onClick={() => handleBillingDate(object)}>Set Ship Date</Dropdown.Item>
                                                </>
                                            )}
                                            no={() => null}
                                        />

                                        <Dropdown.Item eventKey="6" onClick={() => editFacilityByID(object.id)}>Edit Facility</Dropdown.Item>
                                        {/* <Dropdown.Item eventKey="6" onClick={() => viewFacilityByID(object.id)}>View Facility</Dropdown.Item> */}
                                        <Dropdown.Item eventKey="11" onClick={() => handleFacilityReportModel(object, object.fkProcessStatusId, false)}>Print Facility</Dropdown.Item>
                                        <CheckPermission
                                            userRole={[RBAC.Role_Gentell_Administrator, RBAC.Role_Facility_Level1, RBAC.Role_Facility_Level2]}
                                            yes={() => (
                                                <>
                                                    <Dropdown.Item eventKey="0"
                                                        onClick={() => { showAddResidentFacility(object.id) }}

                                                    >Add User</Dropdown.Item>
                                                </>
                                            )}
                                            no={() => null}
                                        />
                                        <Dropdown.Item eventKey="3" onClick={() => { handleShowContactList(object.id) }}>Contacts</Dropdown.Item>
                                        <Dropdown.Divider />
                                        {/* <Dropdown.Item eventKey="7" className="text-danger" onClick={displayWarningAlert}>Authorize</Dropdown.Item> */}
                                        <CheckPermission
                                            userRole={[RBAC.Role_Facility_RegionalClinician, RBAC.Role_Gentell_Administrator
                                            ]}

                                            yes={() => (
                                                <>
                                                    <Dropdown.Item eventKey="8" className="text-danger" onClick={() => handleFacilityStatus(object)}>{object.active == false ? 'Active' : 'Deactivate'}</Dropdown.Item>
                                                </>
                                            )}
                                            no={() => null}
                                        />

                                        <Dropdown.Item eventKey="6" onClick={() => { setFacilityID(object.id); facilityHistoryModal.showModal() }}>Facility History</Dropdown.Item>
                                    </DropdownType>
                                ))}
                            </div>


                        </ButtonToolbar>
                    </>
                </td>
            </tr>);
        })

        setFacility(rows)
        //  Common.LoadDataTable("facilityListTable");
        $("#facilityListTable").DataTable({
            "order": [],
            responsive: true,
            pageLength: 20,
            destroy: true,
            columnDefs: [
                { responsivePriority: 1, targets: 0 }
            ],
            dom: "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            "buttons": [/*
                {
                    extend: 'csvHtml5',
                    text: 'CSV',
                    titleAttr: 'Generate CSV',
                    className: 'btn btn-sm btn-primary mr-2'
                },
                {
                    extend: 'copyHtml5',
                    text: 'Copy',
                    titleAttr: 'Copy to clipboard',
                    className: 'btn btn-sm btn-light'
                }*/
            ]
        });

    }

    const setPartailFacilityList = (list) => {
        rows = [];
        list.map((object, i) => {
            rows.push(<tr key={i.toString()}>
                <td align="left" className="customStyleAccorion">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                            setIsPartail(true)
                            setShowResident(true);
                            setFacilityObj(object);
                        }}
                    >
                        {object.facilityName}
                    </button>
                </td>

                <td>{Common.GetDateString(object.cycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(object.cycleDate)}</td>
                <td>{Common.replaceNullOrEmptyWithString(object.organizationName, "")}{object.organizationName == null && object.chain == null ? "N/A" : ""}
                    {object.organizationName != null && object.chain != null ? "/" : ""}
                    {Common.replaceNullOrEmptyWithString(object.chain, "")}</td>
                <td>{Common.replaceNullOrEmptyWithString(object.clinicianName, "N/A")}</td>
                <td align="center">{object.active ? <Badge variant="success">Active</Badge> : <Badge variant="primary">Deactive</Badge>}</td>
                <td>{object.totalResident + "/" + object.totalWound}</td>
                <td align="center">{object.fkProcessStatusId == Common.ProcessStatus.workQueueID ? <Badge variant="success">Work Queue</Badge> : object.fkProcessStatusId == Common.ProcessStatus.billingQueueID ? <Badge variant="primary">Processor Queue</Badge> : <Badge variant="info">Brightree Queue</Badge>}</td>
                <td align="center">
                    <>
                        <ButtonToolbar className="justify-content-center">
                            <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                                {[DropdownButton].map((DropdownType, idx) => (
                                    <DropdownType
                                        size="xs"
                                        variant="primary"

                                        title="Actions"
                                        id={`dropdown-button-drop-${idx}`}
                                        key={idx}
                                    >
                                        <CheckPermission
                                            // userRole={[RBAC.Role_Facility_RegionalClinician, RBAC.Role_Gentell_Administrator]}
                                            userRole={[RBAC.Role_Gentell_Administrator
                                                , RBAC.Role_Gentell_SeniorBiller
                                                , RBAC.Role_Gentell_IntakeProcessor]}

                                            yes={() => (
                                                <Dropdown.Item eventKey="0" onClick={() => handleBillingDate(object)}>Set Ship Date</Dropdown.Item>
                                            )}
                                            no={() => null}
                                        />

                                        <Dropdown.Item eventKey="6" onClick={() => editFacilityByID(object.id)}>Edit Facility</Dropdown.Item>



                                        <Dropdown.Divider />
                                        {/* <Dropdown.Item eventKey="7" className="text-danger" onClick={displayWarningAlert}>Authorize</Dropdown.Item> */}
                                        <Dropdown.Item eventKey="8" className="text-danger" onClick={() => handleFacilityStatus(object)}>{object.active == false ? 'Active' : 'Deactivate'}</Dropdown.Item>
                                    </DropdownType>
                                ))}
                            </div>
                        </ButtonToolbar>
                    </>
                </td>
            </tr>);
        })

        setPartailFacility(rows)
        Common.LoadDataTable("partailFacilityList");
    }
    const handleFacilityStatus = (obj) => {
        obj.active = obj.active == true ? false : true;
        updateFacilityStatus(obj);
    };
    const updateFacilityStatus = (obj) => {
        var msg = "You want to " + (obj.active ? "Activate" : "Deactivate") + " facility.";
        Common.showConfirmation(msg, "", updateStatus, null, obj)

    };
    const updateStatus = (obj) => {
        if (obj) {
            Api.postRequest(Api.apiUrls.updateFacilityStatus, obj).then((res) => {
                if (res.data.success) {
                    getAllFacilities();
                    Common.showSuccessAlertAutoClose("Facility Status Updated Successfully", "success", "Success!", 3000)
                } else {
                    Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
                }
            });
        }
    }

    const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(ShowAdvancedSearch ? true : false);

    const showAdvanceSearchSection = () => {
        showAdvanceSearchSectionState(!ShowAdvancedSearch);
    };
    const handleFacilityReportModel = async (facility, facilityProcessStatusId, isPartial) => {
        // window.location.assign("https://attachmentsfcprod.blob.core.windows.net/fcfacilityreports/ABERCORNREHABILITATIONCENTER_20220915042053_815427a12-79e5-46e2-b58a-0ee151ea8ea3.pdf")
        // facilityReportModal.showModal()
        var { data } = await Api.getRequestQry(Api.URI_GetFacilitesReport,
            "?facilityId=" + facility.id + "&facilityProcessStatusId=" + facilityProcessStatusId + "&isPartial=" + isPartial + "&facilityStatus=Facility")

        if (data.success) {
            if (data.result != null && data.result != '') {
                window.location.assign(data.result)
            }

        } else {
        }
    }
    //#region Export facility List
    const headers = [
        { label: "FacilityName", key: "facilityName" },
        { label: "Ownership- Headquarter", key: "organizationName" },
        { label: "Purchasing Group- Chain", key: "chain" },
        { label: "Active", key: "active" },
        { label: "Date Added", key: "createdDate" },
        { label: "Facility Type", key: "facilityType" },
        { label: "NPI #", key: "npi" },
        { label: "Product Line", key: "productLine" },
        { label: "Number of Beds", key: "numberofBeds" },
        { label: "Street", key: "addressLine1" },
        { label: "Street 2", key: "addressLine2" },
        { label: "City", key: "city" },
        { label: "State", key: "stateName" },
        { label: "Zip", key: "zip" },
        { label: "Phone 1", key: "phone1" },
        { label: "Fax #", key: "fax" },
        { label: "Part B Receiving Person", key: "partBrecPerson" },
        { label: "Part B Rec. Person #", key: "partBrecPersonPhone" },
        { label: "Part B Rec. Person Email", key: "partBrecPersonEmail" },
        { label: "Central Supply Person", key: "centralSupplyPerson" },
        { label: "Central Supply Person Phone #", key: "centerSupplyPersonPhone" },
        { label: "Central Supply Person Email", key: "centerSupplyPersonEmail" },
        { label: "Last Day of Week", key: "lastname" },
        { label: "Assigned Nurse", key: "clinicianName" },
        { label: "Wound Care Part B", key: "medicarePartBbyGentell" },
        { label: "OUT's Program", key: "outProgram" },
        { label: "Equal Care Program", key: "isEcp" },
        { label: "Equal Care resident count", key: "NeedToFind" },
        { label: "Resident Woundcare cost per day", key: "NeedToFind" },






    ];
    const handleClick = () => {
        setFacilityPrinting(true)
        getAllFacility();
      };

    //#endregion
    const toolbar = (
        <div className="card-toolbar">
            <div className="example-tools">
                {/* <Button variant="warning" className="mr-5" size="sm" onClick={showAdvanceSearchSection}>Advanced Search</Button> */}
                {/* <Button variant="primary" size="sm" onClick={handleShowNewFacility}>New Facility</Button> */}
                <CheckPermission
                    permission={UserActions.Create}
                    module={ModulesName.Module_Facility}
                    yes={() => (
                        <>
                            <Button variant="primary" className="mr-5" size="sm" onClick={() => addFacilityModal.showModal()}>New Facility</Button>
                            {/* <Button variant="success" size="sm" onClick={() => syncFacilityModal.showModal()}>Download Facility</Button> */}
                            <Button variant="success" className="mr-5" size="sm" onClick={() => syncFacilitySheetModal.showModal()}>Download Facility</Button>

                        </>
                    )}
                    no={() => null}
                />

                {/*<CSVLink
id="csv-link"
      style={{ textDecoration: 'none' }}
      filename='Facility List Report'
      data={facilityListToCSV}
      headers={headers}>
     
    </CSVLink>
    <Button
        variant="success"
        className="mr-5"
        size="sm"
        disabled={facilityPrinting}
        onClick={handleClick}>{facilityPrinting==true ? <Spinner color='primary' size="sm">
        Loading...
      </Spinner>: <span>
        {' '}Facility List Report
      </span>}</Button>*/}

            </div>
        </div>
    );

    // Add Facility User
    const showAddResidentFacility = async (facilityId) => {

        await setFacilityID(facilityId);

        await setRoleID(Common.UserRole.LevelII);
        facilityUserModal.showModal();


    }
    useEffect(() => {

        getAllFacilities();
    
        
      }, [currentPage, rowsPerPage]);
    // End Facility User
    const columns = [
        
        {
            name: "Facility Name",
            sortable: false,
            minWidth: "320px",
            sortField: "facilityName",
            selector: (row) => `${row.facilityName}`,
            cell: (row) => (
                <div className="d-flex align-items-center">
                   
                    <button
                        type="button"
                        className="btn btn-link text-left"
                        onClick={() => {
                            setIsPartail(false)
                            setShowResident(true);
                            setFacilityObj(row);
                        }}
                    >
                        {row.facilityName}
                    </button>
                </div>
            ),
        },

        {
            name: "Ship Date",
            sortable: false,
            minWidth: "150px",
            sortField: "billingDate",
            selector: (row) => `${row.billingDate}`,
            cell: (row) => (
                <div className="d-flex justify-content-left align-items-center">
                    <div className="d-flex flex-column">
                        <small className="text-truncate mb-0 font-size-sm">
                            {Common.GetDateString(row.billingDate) == "01-01-1900" ? "N/A" : Common.GetDateString(row.billingDate) + (row.isSecondaryCycle ? " (S)" : "")}
                        </small>
                    </div>
                </div>
            ),
        },
        {
            name: "Ownership-Headquarters Purchasing Group-Chain",
            sortable: false,
            minWidth: "250px",
            sortField: "organizationName",
            selector: (row) => `${row.organizationName}`,
            cell: (row) => (
                <div className="d-flex justify-content-left align-items-center">
                    <div className="d-flex flex-column">
                        <small className="text-truncate text-wrap mb-0 font-size-sm">{Common.replaceNullOrEmptyWithString(row.organizationName, "")}{row.organizationName == null && row.chain == null ? "N/A" : ""}
                            {row.organizationName != null && row.chain != null ? "/" : ""}
                            {Common.replaceNullOrEmptyWithString(row.chain, "")}</small>
                    </div>
                </div>
            ),
        },
        {
            name: "Clinician Name",
            sortable: false,
            minWidth: "145px",
            sortField: "clinicianName",
            selector: (row) => `${row.clinicianName}`,
            cell: (row) => (
                <div className="d-flex justify-content-left align-items-center">
                    <div className="d-flex flex-column font-size-sm">
                        <small className="text-truncate text-wrap mb-0 font-size-sm">{Common.replaceNullOrEmptyWithString(row.clinicianName, "N/A")} </small>
                    </div>
                </div>
            ),
        },
        {
            name: "Status",
            sortable: false,
            minWidth: "100px",
            sortField: "active",
            selector: (row) => `${row.active}`,
            cell: (row) => (
                <div className="d-flex justify-content-left align-items-center">
                    <div className="d-flex flex-column font-size-sm">
                        <small className="text-truncate mb-0 font-size-sm">{row.active == true ? <Badge variant="success">Active</Badge> : <Badge variant="primary">Deactive</Badge>} </small>
                    </div>
                </div>
            ),
        },
        {
            name: "Residents/Wounds",
            sortable: false,
            minWidth: "150px",
            sortField: "totalResident",
            selector: (row) => `${row.totalResident}`,
            cell: (row) => (
                <div className="d-flex justify-content-left align-items-center">
                    <div className="d-flex flex-column font-size-sm">
                        <small className="text-truncate mb-0 font-size-sm">{row.totalResident + "/" + row.totalWound}</small>
                    </div>
                </div>
            ),
        },
        {
            name: "Queue",
            sortable: false,
            minWidth: "150px",
            sortField: "fkProcessStatusId",
            selector: (row) => `${row.fkProcessStatusId}`,
            cell: (row) => (
                <div className="d-flex justify-content-left align-items-center">
                    <div className="d-flex flex-column ">
                        {row.fkProcessStatusId == Common.ProcessStatus.workQueueID ? <Badge variant="success">Work Queue</Badge> : row.fkProcessStatusId == Common.ProcessStatus.billingQueueID ? <Badge variant="primary">Processor Queue</Badge> : <Badge variant="info">Brightree Queue</Badge>}
                    </div>
                </div>
            ),
        },

        {
            name: "Actions",
            allowOverflow: true,
            minWidth: "150px",
            maxWidth: "150px",
            cell: (row) => {
                return (
                    <div className="d-flex" key={row?.id}>
                        {
                            <div>
                                <>
                                    <ButtonToolbar className="justify-content-center">
                                        <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                                            {[DropdownButton].map((DropdownType, idx) => (
                                                <DropdownType
                                                    size="xs"
                                                    variant="primary"

                                                    title="Actions"
                                                    id={`dropdown-button-drop-${idx}`}
                                                    key={idx}
                                                >
                                                    <CheckPermission
                                                        userRole={[RBAC.Role_Gentell_Administrator,
                                                        RBAC.Role_Gentell_SeniorBiller
                                                            , RBAC.Role_Gentell_IntakeProcessor]}

                                                        yes={() => (
                                                            <>
                                                                <Dropdown.Item eventKey="0" onClick={() => handleBillingDate(row)}>Set Ship Date</Dropdown.Item>
                                                            </>
                                                        )}
                                                        no={() => null}
                                                    />

                                                    <Dropdown.Item eventKey="6" onClick={() => editFacilityByID(row.id)}>
                                                      Edit Facility
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item eventKey="6" onClick={() => viewFacilityByID(row.id)}>View Facility</Dropdown.Item> */}
                                                    <Dropdown.Item eventKey="11"
                                                    onClick={() => handleFacilityReportModel(row, row.fkProcessStatusId, false)}
                                                    >Print Facility</Dropdown.Item>
                                                    <CheckPermission
                                                        userRole={[RBAC.Role_Gentell_Administrator, RBAC.Role_Facility_Level1, RBAC.Role_Facility_Level2]}
                                                        yes={() => (
                                                            <>
                                                                <Dropdown.Item eventKey="0"
                                                                onClick={() => { showAddResidentFacility(row.id) }}

                                                                >Add User</Dropdown.Item>
                                                            </>
                                                        )}
                                                        no={() => null}
                                                    />
                                                    <Dropdown.Item eventKey="3"
                                                    onClick={() => { handleShowContactList(row.id) }}
                                                    >Contacts</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    {/* <Dropdown.Item eventKey="7" className="text-danger" onClick={displayWarningAlert}>Authorize</Dropdown.Item> */}
                                                    <CheckPermission
                                                        userRole={[RBAC.Role_Facility_RegionalClinician, RBAC.Role_Gentell_Administrator
                                                        ]}

                                                        yes={() => (
                                                            <>
                                                                <Dropdown.Item eventKey="8" className="text-danger"
                                                                 onClick={() => handleFacilityStatus(row)}
                                                                >{row.active == false ? 'Active' : 'Deactivate'}</Dropdown.Item>
                                                            </>
                                                        )}
                                                        no={() => null}
                                                    />

                                                    <Dropdown.Item eventKey="6"
                                                    onClick={() => { setFacilityID(row.id); facilityHistoryModal.showModal() }}
                                                    >Facility History</Dropdown.Item>
                                                </DropdownType>
                                            ))}
                                        </div>


                                    </ButtonToolbar>
                                </>
                            </div>
                        }
                    </div>
                );
            },
        },
    ];
    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    // ** Function in get data on rows per page
    const handlePerPage = async (e) => {
        setRowsPerPage(e.target.value);
        setCurrentPage(1);
    };
    const handleFilter = debounce((val) => {
        debugger
        if (val != "" && val.length >= 2) {
            setCurrentPage(1);
            getAllFacilities(val);
        }
        if (val == "") {
            setCurrentPage(1);
            getAllFacilities(val);
        }

    }, 1000);
    const CustomPagination = () => {
        // const count = Number(Math.ceil(store.total / rowsPerPage))
        let count = 0
        if (rowsPerPage > 0) count = Number(Math.ceil(totalItems / rowsPerPage))
        else {
          count = 1
        }
        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={count || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        );
    };

    return (
        <>
            {ShowAdvancedSearch && <Animated animationIn="fadeInLeft" animationInDuration={1000}>
                <Card className="">
                    <CardHeader title='Search Facility' />
                    <CardBody>
                        <Alert className="mb-5" variant="secondary">
                            Fill in one or more fields to search facility.
                        </Alert>
                        <form>
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FacilityNamebeginningwith">Facility Name beginning with</label>
                                        <input type="text" id="FacilityNamebeginningwith" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="WoundProductSpecialist">Wound & Product Specialist</label>
                                        <input type="text" id="WoundProductSpecialist" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="OwnershipGroup">Ownership Group</label>
                                        <select className="form-control form-control-sm" id="OwnershipGroup">
                                            <option>This is a Headquarters location</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="State">State</label>
                                        <select className="form-control form-control-sm" id="State">
                                            <option>New York</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group mt-5 mt-md-10 mt-lg-5">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                name="acceptTerms"
                                                className="m-1"
                                            />
                                            <span className="mr-3" />
                                            Facility is active
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </CardBody>
                    <CardFooter className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm">Find</Button>
                        <Button variant="danger" size="sm" onClick={showAdvanceSearchSection}>Cancel</Button>
                    </CardFooter>
                </Card>
            </Animated>}
            {showResident && !isLoading && (
                <Animated
                    animationIn="fadeIn"
                    animationInDuration={1000}
                    animationOut="fadeOut"
                    isVisible={showResident}
                >
                    <AllFacilityResident
                        backPress={() => refreshFacilityList()}
                        facility={FacilityObj}
                        isPartail={isPartail}
                    />
                </Animated>
            )}
            {!showResident &&  (
                <>
                    {
                        (
                            <Card className="example example-compact">
                            <CardHeader title="Facility" toolbar={toolbar} />
                            <CardBody>
                            <div className="react-dataTable">
                                <DataTable
                                    highlightOnHover
                                    striped
                                    noHeader
                                    subHeader
                                    sortServer
                                    pagination
                                    responsive
                                    paginationServer
                                    columns={columns}
                                    progressPending={isLoading}
                                    noDataComponent={<EmptyComponent></EmptyComponent>}
                                    progressComponent={<SkeletonComponent />}
                                    className="react-dataTable"
                                    paginationComponent={CustomPagination}
                                    data={faclityList}
                                    subHeaderComponent={
                                        <div className="w-100 mb-75">
                                            <Row>
                                                <Col
                                                    xl="3"
                                                    lg="3"
                                                    md="3"
                                                    style={{ width: "5rem" }}
                                                    className="d-flex align-items-sm-start justify-content-md-start justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column p-0 mt-md-0 mt-1"
                                                >
                                                    <div className="d-flex align-items-start mb-sm-0 mb-1">
                                                        <label htmlFor="rows-per-page">Facility Name:</label>
                                                        <input
                                                            id="search-invoice"
                                                            className="ms-50 w-100"
                                                            type="text"
                                                            value={searchTerm}
                                                            onChange={(e) => {
                                                                handleFilter(e.target.value);
                                                                setSearchTerm(e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    xl="2"
                                                    lg="2"
                                                    md="2"
                                                    className="d-flex align-items-start p-0 "
                                                >
                                                    <div className="d-flex align-items-start w-100">
                                                        <label htmlFor="rows-per-page">Show Entries</label>
                                                        <select
                                                            className="ms-50 w-30"
                                                            id="rows-per-page"
                                                            value={rowsPerPage}
                                                            onChange={handlePerPage}
                                                            style={{ width: "5rem" }}
                                                        >
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                        {/* <label htmlFor="rows-per-page">Entries</label> */}
                                                    </div>
                                                </Col>




                                            </Row>
                                        </div>
                                    }
                                />
                            </div>
                            </CardBody>
                            </Card>
                        )}
                    {/* <Card className="example example-compact">
                        <CardHeader title='Facility List' toolbar={toolbar} />
                        <CardBody>
                            <div>
                                {isLoading ?
                                    <div className="row d-flex justify-content-center">
                                        <span className="mt-5 spinner spinner-primary"></span>
                                    </div> : (
                                        <table className="table table-striped table-hover table-sm table-bordered"
                                            id="facilityListTable">
                                            <thead>
                                                <tr>
                                                    <th>Facility Name</th>
                                                    <th>Ship Date</th>
                                                    <th>Ownership-Headquarters <br /> Purchasing Group-Chain</th>
                                                    <th>Clinician Name</th>
                                                    <th>Status</th>
                                                    <th>Residents/ Wounds</th>
                                                    

                                                    <th>Queue</th>

                                                    <th width="20%">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableFacility}
                                            </tbody>
                                        </table>
                                    )}
                            </div>

                        </CardBody>
                    </Card> */}
                    {/* <Card className="example example-compact">
                        <CardHeader title='Partail Facility List' toolbar={toolbar} />
                        <CardBody>
                            <div>
                                {isLoading ?
                                    <div className="row d-flex justify-content-center">
                                        <span className="mt-5 spinner spinner-primary"></span>
                                    </div> : (
                                        <table className="table table-striped table-hover table-sm table-bordered" id="partailFacilityList">
                                            <thead>
                                                <tr>
                                                    <th>Facility Name</th>
                                                    <th>Billing Date</th>
                                                    <th>Ownership-Headquarters/Purchasing Group-Chain</th>
                                                    <th>Clinician Name</th>
                                                    <th>Status</th>
                                                    <th>Residents/ Wounds</th>
                                                    <th>Queue</th>

                                                    <th width="20%">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {partailFacilityUI}
                                            </tbody>
                                        </table>
                                    )}
                            </div>

                        </CardBody>
                    </Card> */}
                </>
            )}


            {/** physicianModal */}


            <PopupModal
                dialogClassName="modal-90w"
                title="Add Facility"
                module={ModulesName.Module_Facility}
                action={UserActions.Create}
                buttonPress={() => addFacility.onSubmit()}
                ref={(ref) => {
                    addFacilityModal = ref;
                }}
                onClose={(ref) => {
                    Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
                        () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
                }}
            >
                {" "}
                <AddFacility
                    ref={(ref) => {
                        addFacility = ref;
                    }}
                    onSaveAPI={() => afterCreateFacility()}
                />{" "}
            </PopupModal>
            <PopupModal
                size="lg"
                title="New Ship Date"
                module={ModulesName.Module_Facility}
                action={UserActions.View}
                buttonPress={() => addFacility.onSubmit()}
                ref={(ref) => {
                    setBillingDateModal = ref;
                }}
            >
                {" "}
                <SetBillingDate
                    ref={(ref) => {
                        addFacility = ref;
                    }}
                    onSaveAPI={() => afterCreateFacility()}
                    facilityId={FacilityID}
                    billingDateDetail={billingDateDetail}

                />{" "}
            </PopupModal>
            {/* <PopupModal
                dialogClassName="modal-90w"
                title="Download Facility"
                module={ModulesName.Module_Facility}
                action={UserActions.Create}
                buttonPress={() => addFacility.onSubmit()}
                ref={(ref) => {
                    syncFacilityModal = ref;
                }}
            >
                {" "}
                <SyncFacility
                    ref={(ref) => {
                        addFacility = ref;
                    }}
                    onSaveAPI={() => afterCreateFacility()}


                />{" "}
            </PopupModal> */}
            <PopupModal
                dialogClassName="modal-90w"
                title="Download Facility"
                module={ModulesName.Module_Facility}
                action={UserActions.Create}
                showButton={false}
                ref={(ref) => {
                    syncFacilitySheetModal = ref;
                }}
            >
                {" "}
                <ExcelSheetFacility
                    ref={(ref) => {
                        addFacility = ref;
                    }}
                    onSaveAPI={() => afterCreateFacility()}


                />{" "}
            </PopupModal>
            <PopupModal
                size="xl"
                title="Edit Facility"
                module={ModulesName.Module_Facility}
                action={UserActions.Update}
                buttonPress={() => editFacility.onSubmit()}
                ref={(ref) => {
                    editFacilityModal = ref;
                }}
                onClose={(ref) => {
                    Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
                        () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
                }}
            >
                {" "}
                <EditFacility
                    ref={(ref) => {
                        editFacility = ref;
                    }}
                    FacilityID={FacilityID}
                    Facility={Facility}
                    onSaveAPI={() => afterCreateFacility()}
                />{" "}
            </PopupModal>
            <PopupModal
                size="xl"
                title="Facility Detail"
                module={ModulesName.Module_Facility}
                action={UserActions.View}
                showButton={false}
                ref={(ref) => {
                    viewFacilityModal = ref;
                }}
            >
                {" "}
                <ViewFacility
                    ref={(ref) => {
                        viewFacilityDetail = ref;
                    }}
                    Facility={Facility}

                />{" "}
            </PopupModal>

            <PopupModal
                // title="Sales Order Message"
                title={<div className="card-title">
                    <span>
                        <h3 className="card-label">Facility History</h3>
                    </span>
                </div>}
                showButton={false}
                ref={(ref) => {
                    facilityHistoryModal = ref;
                }}

            >
                {" "}
                <FacilityHistory facilityId={FacilityID} />
                {" "}
            </PopupModal>

            {/* Facility Users */}
            <PopupModal
                size="xl"
                title="Facility Users"
                buttonPress={() => viewFacilityDetail.onSubmit()}
                ref={(ref) => {
                    facilityUserModal = ref;
                }}
            >
                {" "}
                <AddFacilityUser
                    // backPress={() => refreshFacilityList()}
                    ownershipHeadquartersID={""}
                    operatorsPurchasingGroupID={""}
                    facilityID={FacilityID}
                    roleID={roleID}
                    onSaveAPI={() => setShowResident(false)}
                />
                {" "}
            </PopupModal>
            {/* End Facility Users */}
            {/* new facility modal */}
            <Modal
                aria-labelledby="newFacilityModal"
                scrollable backdrop="static"
                dialogClassName="modal-90w"
                keyboard={false}
                show={showModalNewFacility}
                onHide={handleCloseNewFacility}>

                <Modal.Header className="py-3" closeButton>
                    <Modal.Title id="newFacilityModal">
                        New Facility
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewFacilityModal />
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <Button variant="danger" size="sm" onClick={handleCloseNewFacility}>
                        Close
                    </Button>
                    <Button variant="primary" size="sm" onClick={handleCloseNewFacility}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="xl"
                aria-labelledby="showModalContacttList"
                dialogClassName="modal-90w"
                centered
                scrollable
                backdrop="static"
                keyboard={false}
                show={showModalContacttList}
                onHide={handleCloseContactList}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Contacts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Contactslistmodal
                        changeDetect={setChangeDetect}
                        isChangeDetect={changeDetect}
                        FacilityID={FacilityID} />
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <Button variant="danger" size="sm" onClick={handleCloseContactList}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>





        </>
    );
}


export default Facility;