import React, { useState,useEffect } from "react";
import "../app";
import { Button } from "react-bootstrap";

import * as XLSX from "xlsx";
import * as Api from "./api";
import * as Common from "./common";
class ExcelToJson extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            file: "",
            isLoading:false,
        };
    }

    handleClick(e) {
        this.refs.fileUploader.click();
    }

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        console.log(file);
        this.setState({ file });

        console.log(this.state.file);
    }

    readFile() {
        this.setState({ isLoading: true });
        var f = this.state.file;
        var name = f.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            debugger
            /* Update state */
            //   console.log("Data>>>" + data);// shows that excel data is read
            //   console.log(this.convertToJson(data)); // shows data in json format
            var dataTest = this.convertToJson(data);
            // this.fetchAllFacility(dataTest)
             this.props.excelDataRead(dataTest)
             //this.setState({ isLoading: false });
        };
        reader.readAsBinaryString(f);
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading === true && this.props.isLoading === false) {
          this.setState({ isLoading: false });
        }
      }
      
    convertToJson(csv) {
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split(",");
        // for (var i = 1; i < lines.length; i++) {
            var leng=0;
            if(lines.length>2245){
                leng=2245;
            }else{
                leng=lines.length;
            }
        for (var i = 1; i < leng; i++) {

            var obj = {};
            if (lines[i] != "" &&lines[i]!=undefined) 
            {
                var currentline = lines[i].split(",");
                if (currentline.length > 0 && currentline[0] != '') {
                    for (var j = 0; j < headers.length; j++) {
                        obj[headers[j]] = currentline[j];
                    }

                    result.push(obj);
                }
            }
        }
        //return result; //JavaScript object
        debugger
        // return JSON.stringify(result); //JSON
        return result; //JSON

    }

    render() {
        const { isLoading } = this.state;
        return (
            <div className="row mb-5">
                <input
                    type="file"
                    id="file"
                    ref="fileUploader"
                    onChange={this.filePathset.bind(this)}
                />
                <Button variant="primary" size="sm"
                    onClick={() => {
                        this.readFile();
                    }}
                    
                >{isLoading ? (
                    <span className="p-3 spinner spinner-white"></span>
                  ) : (
                    'Read File'
                  )}
                </Button>
            </div>
        );
    }
}

export default ExcelToJson;