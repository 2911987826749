import React from "react";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import RoutePaths from "../../constants/routepaths";
import FacilityAndGentellUsers from "../repfirm/facilityAndGentellUsers";
import Lookup from "./lookup/lookupslist";
import Role from "./roles/role";
import ICDList from "./ICDCode/ICDList";
import Insurance from "./Insurance/insurance";


import AddUpdateForm from "./systemSetting/addUpdateForm";
import VacationList from './vacation/VacationList';
import EMRCrendential from '../facility/emrCredential';
import FacilityUser from "../repfirm/facilityUser";
import NightlySync from "./nightlySync";
import ExcelSheetUser from "../User/excelSheetUser"
import FacilityWoundAndProductSpecialist from "../facility/facilityWoundAndProductSpecialist";





export default function SetupNav() {
  // debugger;
  return (
    <>
      <Switch>

        {/* Rep Firms */}
        {/* <ContentRoute from={RoutePaths.Page_RepFirmList} component={RepFirmList} /> */}
        <ContentRoute from={RoutePaths.Page_Lookuplist} component={Lookup} />
        <ContentRoute from={RoutePaths.Page_Role} component={Role} />
        <ContentRoute from={RoutePaths.Page_SystemSetting} component={AddUpdateForm} />
        <ContentRoute from={RoutePaths.Page_ICDCode} component={ICDList} />
        <ContentRoute from={RoutePaths.Page_Insurance} component={Insurance} />


        <ContentRoute from={RoutePaths.Page_vacation} component={VacationList} />
        <ContentRoute from={RoutePaths.Page_UserList} component={FacilityAndGentellUsers} />
        <ContentRoute from={RoutePaths.Page_EMRCredential} component={EMRCrendential} />
        <ContentRoute from={RoutePaths.Page_FacilityUser} component={FacilityUser} />
        <ContentRoute from={RoutePaths.Page_WoundAndProductSpecialist} component={FacilityWoundAndProductSpecialist} />

        
        <ContentRoute from={RoutePaths.Page_NightlySync} component={NightlySync} />
        <ContentRoute from={RoutePaths.Page_UploadUsers} component={ExcelSheetUser} />


        


        


      </Switch>
    </>
  );
}