import React, { useEffect, useRef, useState } from "react";
import { Animated } from "react-animated-css";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../../_metronic/_partials/controls/Card";
import { apiUrls } from '../../../constants/endpoints';
import * as API from '../../../General/api';
import * as common from "../../../General/common";
import { LoadDataTable } from "../../../General/common";
import AddContactmodal from './addcontactmodal';
import * as Common from "../../../General/common";
export function Contactslistmodal(props) {

    //#region  add person
    const [showModalAddContact, setShowAddContact] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    
    const [st_isActionInProgress, setisActionInProgress] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContacts] = useState({});
    const [rows, setRows] = useState([]);

    // USE EFFECTS 
    useEffect(() => {
        setisLoading(true);
        refreshData();
    }, []);

    const setContactListUI = (list) => {
        let _rows = [];
        // debugger;
        list.forEach(contact => {
            _rows.push(<tr key={contact.facilityContactID.toString()}>
                <td>{contact.name}</td>
                <td>{contact.fPosition}</td>
                <td>{contact.email}</td>
                <td>{common.replaceNullOrEmptyWithString(common.getFormattedPhoneNum(contact.mobile), "")}</td>
                <td>{common.replaceNullOrEmptyWithString(common.getFormattedPhoneNum(contact.fax), "")}</td>
                <td align="center">
                    {st_isActionInProgress ? <span className="ml-3 spinner spinner-white"></span> : <div className="text-center">
                        <DropdownButton
                            drop={"down"}
                            size="xs"
                            disabled={st_isActionInProgress}
                            variant="primary"
                            title={'Actions'}
                            id={`dropdown-button-drop-actions`}
                            key={'down'}>
                            <Dropdown.Item disabled={st_isActionInProgress} eventKey="1" onClick={() => handleShowEditContact(contact)}>Edit Contact</Dropdown.Item>
                            <Dropdown.Item disabled={st_isActionInProgress} onClick={() => { deleteContactHandler(contact) }} eventKey="3">Delete</Dropdown.Item>
                        </DropdownButton>
                    </div>}
                </td>
            </tr>);

        });
        // debugger;
        setRows(_rows);
    }
    const deleteContactHandler = (contact) => {
        if(props.isChangeDetect){
            Common.showConfirmation('Are you sure you want to delete this contact?', "", deleteContact, null, contact)
        }else{
            closeAddContact(false)
        }
       
    };
    const deleteContact = (contact) => {
        try {
            // debugger;
            setisActionInProgress(true);
            setisLoading(true);
            var obj = { ...contact };
            obj.active = false;
            API.postRequest(`${apiUrls.upsertfacilitycontact}`, obj, false).then(raw => {
                const response = raw.data;
                // debugger
                if (response.success) {
                    common.showSuccessAlert(response.messsage, "success", "Success!!");
                    refreshData();
                }
                else {
                    common.showSuccessAlert(response.messsage, "danger", "Error!!");
                }
                setisActionInProgress(false);
                setisLoading(false);
            });
        } catch (ex) {
      common.LogError(ex, "ContactList", "Delete");
            
        }
    }

    // event handlers
    const handleCloseAddContact = () => {
        if(props.isChangeDetect){
            Common.showConfirmation('Are you sure you want to cancel without saving the data?', "", closeAddContact, null, false)
        }else{
            closeAddContact(false)
        }
       
    };

    const closeAddContact =() =>{
        debugger
        props.changeDetect(false)
        setShowAddContact(false)
    }

    const handleShowAddContact = () => {
        setSelectedContacts({});
        setShowAddContact(true);
    }
    const handleShowEditContact = (contact) => {
        setSelectedContacts(contact);
        setShowAddContact(true);
    }

    const refreshData = () => {
        setShowAddContact(false);
        try {
            API.getRequest(`${apiUrls.getallfacilitycontactsbyfacilityid}?id=${props.FacilityID}`, false).then((raw) => {
                const response = raw.data;
                if (response.success) {
                    setContacts(response.result);
                    setContactListUI(response.result);
                    setisLoading(false);
                    // initializeDataTable();
                }
            }).then(() => {
                setisLoading(false);
            });
        }
        catch (ex) {
      common.LogError(ex, "ContactList", "RefreshData");
            
        }
    }

    //#endregion

    const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(ShowAdvancedSearch ? true : false);
    const showAdvanceSearchSection = () => {
        showAdvanceSearchSectionState(!ShowAdvancedSearch);
    };
    const AddContactmodalRef = useRef();
    //initialize datatable 
    const initializeDataTable = () => {
        LoadDataTable("contactsList");
    }
    return (
        <>
            {showModalAddContact && <Animated animationIn="fadeInLeft" animationInDuration={1000}>
                <Card className="">
                    <CardHeader title='New Contact' />
                    <CardBody>
                        <AddContactmodal
                            refresh={refreshData}
                            FacilityID={props.FacilityID}
                            contact={selectedContact}
                            ref={AddContactmodalRef}
                            changeDetect={props.changeDetect}
                            isChangeDetect={props.isChangeDetect}
                            close={() => {
                                // getAllResident();
                                AddContactmodalRef.hideModal();
                            }}
                        />
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end py-3">
                        <Button onClick={() => { AddContactmodalRef.current.triggerSubmitInForm() }} variant="success" className="mr-5 px-10" size="sm">Save</Button>
                        <Button variant="danger" size="sm" onClick={handleCloseAddContact}>Cancel</Button>
                    </CardFooter>
                </Card>
            </Animated>}
            <div className="alert border-primary bg-transparent text-primary fade show" role="alert">
                <div className="d-flex align-items-center">
                    {/* <div className="alert-icon">
                        <i className="fas fa-info-circle mr-4 text-primary"></i>
                    </div> */}
                    <div className="flex-1">
                        {/* <span className="h5 m-0 fw-700">Use buttons to do more actions </span> */}
                    </div>
                    <Button variant="primary" size="sm" disabled={st_isActionInProgress} onClick={handleShowAddContact}>New Contact</Button>
                </div>
            </div>
            <div class='loading-wrapper'>
                {isLoading ?
                    <div className="row d-flex justify-content-center">
                        <span className="mt-5 spinner spinner-primary"></span>
                    </div> : null}
            </div>
            <table className={"table table-striped table-hover table-sm table-bordered " + (isLoading ? "d-none" : "")} id="contactsList">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Email</th>
                        <th>Telephone #</th>
                        <th>Fax</th>
                        <th width="130px">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </>
    );

}

export default Contactslistmodal;

/*eslint-disable */