import $ from "jquery";
import _ from "lodash";
import React from "react";
import swal from "sweetalert";
import Swal2 from "sweetalert2";
import ReactDOMServer from 'react-dom/server';
import {
  apiUrls,
  blobContainerName,
  blobSas,
  blobStorageUrl,
  blobAccount
} from "../constants/endpoints";
import * as Api from "./api";
// import moment from 'moment';
// import 'moment-timezone';
import moment from "moment-timezone"
const { BlobServiceClient } = require("@azure/storage-blob");
export const versionNumber='TR 2.0.6';
export const envinmentName='';

export function updateInputValueInState(
  stateVariable,
  event,
  _that,
  state,
  ReactSelectOBJ
) {
  if (ReactSelectOBJ != null && ReactSelectOBJ != undefined && ReactSelectOBJ) {
    if (event == undefined) {
      stateVariable[ReactSelectOBJ["NameProp"]] = [];
      state[ReactSelectOBJ["ValidationState"]] = false;
    } else {
      stateVariable[ReactSelectOBJ["NameProp"]] = event.map((obj, index) => {
        return obj.value;
      });
      state[ReactSelectOBJ["ValidationState"]] = true;
    }
  } else if (event.target.type == "checkbox") {
    stateVariable[event.target.name] = event.target.checked;
  } else {
    stateVariable[event.target.name] = event.target.value;
  }
  _that.setState(state);
}
export function withOutEventUpdateInputValueInState(
  stateVariable,
  _that,
  state,
  value,
  name,
  ReactSelectOBJ
) {
  if (ReactSelectOBJ != null && ReactSelectOBJ != undefined && ReactSelectOBJ) {

  //   if (event == undefined) {
  //     stateVariable[ReactSelectOBJ["NameProp"]] = [];
  //     state[ReactSelectOBJ["ValidationState"]] = false;
  //   } else {
  //     stateVariable[ReactSelectOBJ["NameProp"]] = event.map((obj, index) => {
  //       return obj.value;
  //     });
  //     state[ReactSelectOBJ["ValidationState"]] = true;
  //   }
  // } 
  // else if (event.target.type == "checkbox") {
  //   stateVariable[event.target.name] = event.target.checked;
  // }
  }
    stateVariable[name] = value;
  
  _that.setState(state);
}
export function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    );
  }
  return "?" + keyValuePairs.join("&");
}

export function isValidZipCode(zipCode) {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}\d{4}$)/.test(zipCode);
}
export function updateInputSelectValueInState(
  stateVariable,
  event,
  controlName,
  _that,
  state,
  ReactSelectOBJ
) {
  if (ReactSelectOBJ != null && ReactSelectOBJ != undefined && ReactSelectOBJ) {
    if (event == undefined) {
      stateVariable[ReactSelectOBJ["NameProp"]] = [];
      state[ReactSelectOBJ["ValidationState"]] = false;
    } else {
      stateVariable[ReactSelectOBJ["NameProp"]] = event.map((obj, index) => {
        return obj.value;
      });
      state[ReactSelectOBJ["ValidationState"]] = true;
    }
  } else {
    stateVariable[controlName] = event.value;
  }
  _that.setState(state);
}

export function updateEmailInputValueInState(
  stateVariable,
  event,
  _that,
  state,
  ReactSelectOBJ
) {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(event.target.value)) {
    UpdateCustomElementValidation(event, true);
  } else {
    UpdateCustomElementValidation(event, false);
  }
  stateVariable[event.target.name] = event.target.value;
  _that.setState(state);
}
export function getFormattedPhoneNum( input ) {
    if(input=="" || input==null){
      return "";
    }
    let output = "(";
    input.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
        {

          if ( g1.length ) {
            output += g1;
            if ( g1.length == 3 ) {
                output += ")";
                if ( g2.length ) {
                    output += " " + g2; 
                    if ( g2.length == 3 ) {
                        output += " - ";
                        if ( g3.length ) {
                            output += g3;
                        }
                    }
                }
             }
          }
        }       
      );        
    return output;
   }
export function showSuccessAlert(msg, type, title) {
  swal({
    title: title,
    text: msg,
    icon: type,
  });
}

export function showSuccessAlertAutoClose(msg, type, title, timer) {
  swal({
    title: title,
    text: msg,
    icon: type,
    timer: timer,
    buttons: false,
  });
}
export const SweetAlertWithValidation = (array) => {
  const messageList = array.map((message, index) => (
    `<li key=${index}>${message}</li>`
  ));

  const messageHtml = `<div><ul>${messageList.join('')}</ul></div>`;

  Swal2.fire({
    title: '',
    html: messageHtml,
    icon: 'error',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: 'OK',
    confirmButtonAriaLabel: 'OK',
  });
};
export function showConfirmation(
  
  msg,
  title,
  confirmCallback,
  cancelCallback = null,
  obj,
  buttonText="OK",
  showDenyButton=false,
  denyCallback=null,
  cancelbuttonText="Cancel"
) {
  Swal2.fire({
    title: title ? title : "Are you sure?",
    text: msg ? msg : "",
    icon: "warning",
    showCancelButton: true,
    showDenyButton:showDenyButton,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: buttonText,
    cancelButtonText:cancelbuttonText
  }).then((result) => {
    if (result.value) {
      if (_.isFunction(confirmCallback)) confirmCallback(obj);
      
    }  
     else {
      if (_.isFunction(cancelCallback)) {
        if (cancelCallback != null) {
          cancelCallback(obj);
        }
      
      }else if(_.isFunction(denyCallback) && result.isDenied){
     
        if (denyCallback != null) {
          denyCallback(obj)
        }
      }
    }
  });
}

export function GetBaseUrl() {
  var getUrl = window.location;
  var baseUrl = getUrl.protocol + "//" + getUrl.host;
  return baseUrl;
}

export function GetDateTimeString(dateString) {
  var date = new Date(dateString);
  return (
   
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    "-" +
    date.getFullYear()  +
    " " +
    date.toLocaleTimeString()
  );
}
export function GetDateString(dateString) {
  var date = new Date(dateString);
  return (
    minTwoDigit(date.getMonth() + 1) +
    "-" +
    minTwoDigit(date.getDate()) +
    "-" +
    minTwoDigit(date.getFullYear()) 
  );
}

function minTwoDigit(input) {
  if ((input + "").length == 1) return "0" + input;
  return input;
}

export const BusEntityDetailType = {
  DescriptionHtml: "DescriptionHtml",
  LogoPath: "LogoPath",
};
export function getCurrentDate(separator = "") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`;
}
export function UpdateCustomElementValidation(event, isValid) {
  if (isValid) {
    event.currentTarget.nextElementSibling.style.display = "";
    event.target.setCustomValidity("");
  } else {
    event.currentTarget.nextElementSibling.style.display = "block";
    event.target.setCustomValidity("Invalid field.");
  }
}

export function prependZeroIfLessThanTen(item) {
  if (item.toString().length === 1) {
    return "0" + item.toString();
  } else {
    return item;
  }
}

export function LoadDataTable(tableId) {
  if ($.fn.dataTable.isDataTable("#" + tableId)) {
    $("#" + tableId).DataTable = $("#" + tableId).DataTable();
  } else {
    $("#" + tableId).DataTable({
      "order": [],
      responsive: true,
      pageLength: 25,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, "All"],
      ],
    });
  }
}
export function LoadDataTableWithButtons(tableId) {
$("#" +tableId).DataTable({
  "order": [],
  responsive: true,
  pageLength: 20,
  destroy: true,
  columnDefs: [
    { responsivePriority: 1, targets: 0 }
  ],
  dom: "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
  "buttons": [
    {
      extend: 'csvHtml5',
      text: 'CSV',
      titleAttr: 'Generate CSV',
      className: 'btn btn-sm btn-primary mr-2'
    },
    {
      extend: 'copyHtml5',
      text: 'Copy',
      titleAttr: 'Copy to clipboard',
      className: 'btn btn-sm btn-light'
    }
  ]
});
}
export function GetCurrentUser() {
  var user = localStorage.getItem("gentell-fastcare-authUser");
  return user;
}

export async function getLookupOptions(fkID) {
  const res = await Api.getRequestById(Api.Uri_GetLookupValuesByFkId, fkID);
  const data = res.data.result;

  let optionTemplate = data.map((v) => (
    <option key={v.key} value={v.key}>
      {v.value}
    </option>
  ));
  return optionTemplate;
}
export async function getLookupKeyValues(fkID) {
  const res = await Api.getRequestById(Api.Uri_GetLookupValuesByFkId, fkID);
  const data = res.data.result;

  let optionTemplate = data.map((v) => (
     {key:v.key,value:v.value}
   
  ));
  return optionTemplate;
}
export async function getAllLookupOptions(url) {
  const res = await Api.getRequest(url);
  const data = res.data.result;

  let optionTemplate = data.map((v) => (
    <option key={v.key} value={v.key}>
      {v.value}
    </option>
  ));
  return optionTemplate;
}
export async function getAllBTLookupOptions(url) {
  const res = await Api.getRequest(url);
  const data = res.data.result;

  let optionTemplate = data.map((v) => (
    <option key={v.id} value={v.id}>
      {v.name}
    </option>
  ));
  return optionTemplate;
}
export const LookupsFK = {
  Fk_FacilityTypeID: 1,
  Fk_ProductLineID: 2,
  OrganizationID: 3,
  Fk_EHRVendorID: 5,
  Fk_WarehouseID: 6,
  Fk_ResidentStatusID: 7,
  Fk_ResidentNoOrderStatus: 1019,
  Fk_WoundInfoChangeReasons: 1024,


  Fk_ProcessorResidentNoOrderStatus: 1021,
  Fk_ClinicianResidentNoOrderStatus: 1022,
  Fk_ECPStatus: 1023,


  Fk_WoundNoOrderStatus:1020,
  Fk_fkPressureLocationID: 1002,
  Fk_fkNonPressureLocationID: 1004,
  Fk_fkNonPressureLocationSurgeryID: 1017,
  Fk_WoundAcquisitionID: 10,
  Fk_WoundTypeID: 11,
  Fk_WoundEdges: 20,
  Fk_WoundBedDescription: 17,
  Fk_WoundExudateColor: 1025,

  Fk_WoundSurroundingSkin: 18,
  Fk_WoundSkinAndUlcerTreatment: 19,
  Fk_fkPressureThiknessID: 12,
  Fk_WoundExudateAmountID: 14,
  Fk_WoundExudateTypeID: 15,
  Fk_WoundDebridementTypeID: 16,
  Fk_WoundDressingChangeFrequencyID: 22,
  Fk_WoundPrimaryDressingID: 23,
  Fk_WoundSecondaryDressingID: 24,
  Fk_fkNonPressureThiknessID: 1005,
  Fk_fkWoundSideID: 1003,
  Fk_InsuranceLevel: 28,
  Fk_WoundSevereTissueTypeID: 13,
  Fk_SignatureCredentials: 1018,

  Fk_RoleClinicianID: 3,
  Fk_IntakeProcessorID: 5,

};
export const UserRole = 
{
  CorporateClinician:7,
  RegionalClinician:8,
  LevelI:9,
  LevelII:10,

  Fk_RoleClinicianID: 3,

}
export const ResidentStatusID = 
{
  open:36,
  closed:37

}
export const ProductCategories = 
{ 
  collagen:1257,
  collagenAG:1318,
  hydrogel:1263,
  calciumAlginate:1255,
  calciumAlginateAG:1317,
  cMCFiber:1259,
  honeyFiber:1261,
  superAbsorbent:1265,
  superAbsorbent:1265,
  superAbsorbentAdherent:1320



}
export const WoundType = 
{ 
  pressure:5,
  Surgical:12,
  tubeSize:15
}
export const debridementType={
  sharp : 1027,
  enzymatic  : 1029,
  chemical:1032
}

export const exudate={
  light : 3,
  moderate : 4,
  heavy:5
}

export const mostSevereTissue={
  Epithelial : 1214,
  Granulation : 1253,
  Slough : 1298,
  Necrosis_Eschar_On_Muscle : 1299,
  Necrosis_Eschar_On_Bone:1300,
  Slough_On_Muscle:1302,
  Slough_On_Bone : 1303,
  Necrosis : 1348,
}

export const ProductLevel = 
{
  primary:1203,
  secondary:1204
}
  export const ProcessStatus = 
  {
    workQueueID:1281,
    billingQueueID:1282,
    brighreeQueueID:1283,

  }
export const WoundOrientation = 
{
  right:1,
  left:2,
  upper:1,
  lower:2,
  inner:1,
  outer:2,
  anterior:1,
  posterior:2,
  medial:1,
  lateral:2,
  dorsal:1,
  distal:2,
  proximal:3

}
export const OtherBodyRegionAndLocation = 
{
 bodyRegion:10011,
 woundLocation:10012,


}
export function replaceNullWithString(value, replaceValue) {
  if (value == null) {
    value = replaceValue != null ? replaceValue : "";
  }
  return value;
}
export function replaceNullOrEmptyWithString(targetString, replaceValue) {
  targetString = targetString?.trim();
  if (targetString == null || targetString == "") {
    targetString = replaceValue;
  }
  return targetString;
}
export function zipCodeToTimeZone (dateTime,zipCode){

  if(dateTime=='' || dateTime==null){
    return "";
  }
  if(zipCode.length>5){
    zipCode=zipCode.slice(0,5)
  }
  var zipcode_to_timezone = require( 'zipcode-to-timezone' );
  var tz = zipcode_to_timezone.lookup( zipCode );
  if(tz==null){
    tz='America/New_York'
  }
  var displayCutoff = moment.tz(dateTime, 'YYYYMMDD HH:mm:ss',tz);
  const month =
  displayCutoff._d.getMonth() + 1 < 10
    ? "0" + (displayCutoff._d.getMonth() + 1)
    : displayCutoff._d.getMonth() + 1;

  const _date = displayCutoff._d.getDate() < 10 ? "0" + displayCutoff._d.getDate() : displayCutoff._d.getDate();
  let dt =   month + "-" + _date + "-" +  displayCutoff._d.getFullYear();
    return dt;
}
export function getInputStringDate(date) {
  //date= new Date();
  let dt = "";
  try {
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const _date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dt = date.getFullYear() + "-" + month + "-" + _date;
  } catch (ex) {
    LogError(ex,"Api Call in common.js file","getInputStringDate");
   }
  return dt;
}
export function getInputStringDateUsingMoment(date) {
  if(date==null || date._isValid==false){
    return null
  }
  //date= new Date();
  let dt = "";
  try {
    const month =
      date._d.getMonth() + 1 < 10
        ? "0" + (date._d.getMonth() + 1)
        : date._d.getMonth() + 1;
    const _date = date._d.getDate() < 10 ? "0" + date._d.getDate() : date._d.getDate();
    dt = date._d.getFullYear() + "-" + month + "-" + _date;
  } catch (ex) {
    LogError(ex,"Api Call in common.js file","getInputStringDate");
   }
  return dt;
}
export function getInputStringDateUsingMoment2(date) {
  //date= new Date();
  let dt = "";
  try {
    const month =
      date._d.getMonth() + 1 < 10
        ? "0" + (date._d.getMonth() + 1)
        : date._d.getMonth() + 1;
    const _date = date._d.getDate() < 10 ? "0" + date._d.getDate() : date._d.getDate();
    dt =   month + "-" + _date + "-" +  date._d.getFullYear();
  } catch (ex) {
    LogError(ex,"Api Call in common.js file","getInputStringDate");
   }
  return dt;
}
export function getInputStringDateFormat(date) {
  
  //date= new Date();
  let dt = "";
  try {
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const _date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dt =   month + "/" + _date + "/" +date.getFullYear();
  } catch (ex) {
    LogError(ex,"Api Call in common.js file","getInputStringDate");
   }
  return dt;
}
export function getInputStringDate2(date) {
  
  date= new Date();
  let dt = "";
  try {
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const _date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dt = date.getFullYear() + "-" + month + "-" + _date;
  } catch (ex) {
    LogError(ex,"Api Call in common.js file","getInputStringDate");
   }
  return dt;
}
export async function uploadImage(file) {
  try {
    const blobServiceClient = new BlobServiceClient(blobStorageUrl + blobSas);
    const containerClient = await blobServiceClient.getContainerClient(
      blobContainerName
    );
    var re = /(?:\.([^.]+))?$/;
    const fileExtesnion = re.exec(file.name)[1];
    const _filename = Date.now() + "." + fileExtesnion;
    let filename = _filename;

    const client = containerClient.getBlockBlobClient(filename);
    const response = await client.uploadBrowserData(file, {
      blockSize: file.size,
      concurrency: 20, // 20 concurrency
      onProgress: (ev) =>
        console.log(((ev.loadedBytes / file.size) * 100).toFixed(2)),
      blobHTTPHeaders: { blobContentType: file.type },
    });
    return response._response.status == 201
      ? `${blobStorageUrl}/${blobContainerName}/${filename}`
      : "e";
  } catch (ex) {
    LogError(ex,"Api Call in common.js file","uploadImage");
    return "e";
  }
}
export async function uploadPdfFile(file) {
  try {
    const blobServiceClient = new BlobServiceClient(blobStorageUrl + blobSas);
    const containerClient = await blobServiceClient.getContainerClient(
      blobContainerName
    );
    const _filename = Date.now() + ".pdf";
    let filename = _filename;

    const client = containerClient.getBlockBlobClient(filename);
    const response = await client.uploadBrowserData(file, {
      blockSize: file.size,
      concurrency: 20, // 20 concurrency
      onProgress: (ev) =>
        console.log(((ev.loadedBytes / file.size) * 100).toFixed(2)),
      blobHTTPHeaders: { blobContentType: file.type },
    });
    if (response._response.status === 201) {
      // Generate pre-signed URL for the uploaded file
      //const sasToken = await generateSasToken(filename);
      //const url = `${blobStorageUrl}/${blobContainerName}/${filename}?${sasToken}`;
      const url = `${blobStorageUrl}/${blobContainerName}/${filename}`;
      console.log("Uploaded PDF URL:", url);
      return url;
    } else {
      console.error("Failed to upload PDF file");
      return "e";
    }
  } catch (ex) {
    LogError(ex,"Api Call in common.js file","uploadPdfFile");
    return "e";
  }
}

export function getDifferenceInDays(date1, date2) {
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  const end = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());

  // so it's safe to divide by 24 hours
  return (start-end) / oneDay;

}
export function calculatePushScore(area, exudateAmount, tissueTypeId) {
  var pushScore = 0;
  if (area <= 0) {

  }
  else if (area < (0.3).toFixed(2)) {
      pushScore = 1;
  }
  else if (area >= (0.3).toFixed(2) && area <= (0.6).toFixed(2)) {
      pushScore = 2;
  }
  else if (area >= (0.7).toFixed(2) && area <= (1.0).toFixed(2)) {
      pushScore = 3;
  }
  else if (area >= (1.1).toFixed(2) && area <= (2.0).toFixed(2)) {
      pushScore = 4;
  }
  else if (area >= (2.1).toFixed(2) && area <= (3.0).toFixed(2)) {
      pushScore = 5;
  }
  else if (area >= (3.1).toFixed(2) && area <= (4.0)) {
      pushScore = 6;
  }
  else if (area >= (4.1).toFixed(2) && area <= (8.0)) {
      pushScore = 7;
  }
  else if (area >= (8.1).toFixed(2) && area <= (12.0)) {
      pushScore = 8;
  }
  else if (area >= (12.1).toFixed(2) && area <= (24.0)) {
      pushScore = 9;
  }
  else if (area > 24) {
      pushScore = 10;
  }
  if (exudateAmount == exudate.light) {
      pushScore = pushScore + 1;
  } else if (exudateAmount == exudate.moderate) {
      pushScore = pushScore + 2;
  }
  else if (exudateAmount == exudate.heavy) {
      pushScore = pushScore + 3;

  }

  if (tissueTypeId == mostSevereTissue.Epithelial) {
      pushScore = pushScore + 1;
  }
  else if (tissueTypeId == mostSevereTissue.Granulation) {
      pushScore = pushScore + 2;
  }
  else if (tissueTypeId == mostSevereTissue.Slough) {
      pushScore = pushScore + 3;
  }
  else if (tissueTypeId == mostSevereTissue.Necrosis) {
      pushScore = pushScore + 4;

  }
   return pushScore;
}
export async function LogError(errorMessage, moduleName, actionName) {
  try {
    Api.postRequest("LogException/Log/", { error:errorMessage, module:moduleName, action: actionName}, true);
  }
  catch (error) {

  }

}
// const { BlobServiceClient } = require("@azure/storage-blob");
// export async function downloadImage() {
//   try {
//   const blobServiceClient = new BlobServiceClient(blobStorageUrl + blobSas);
// const containerClient = blobServiceClient.getContainerClient(blobContainerName);
// const blobClient = containerClient.getBlobClient("ATESTFACILITY1_20220909043910_82ec4069-3b9e-4a3e-b564-7ba2ac5628e6.pdf");

// // Get blob content from position 0 to the end
// // In Node.js, get downloaded data by accessing downloadBlockBlobResponse.readableStreamBody
// const downloadBlockBlobResponse = await blobClient.download();
// // const downloaded = (
// //   await streamToBuffer(downloadBlockBlobResponse.readableStreamBody)
// // ).toString();
// const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

//   window.print();
//       const elem = "printit";
//       var mywindow = window.open("", "PRINT", "height=400,width=600");
//       mywindow.document.write("</head><body >");
//       mywindow.document.write("</body></html>");

//       // mywindow.document.close(); // necessary for IE >= 10
//       mywindow.focus(); // necessary for IE >= 10*/

//       mywindow.print();
//       // mywindow.close();
//  return downloaded
// } catch (ex) {
//   console.log(ex,"Api Call in common.js file","uploadImage");
//   return "e";
// }

// }
//   const blobToString=async(blob) =>{
    
//   const fileReader = new FileReader();
//   return new Promise((resolve, reject) => {
//     fileReader.onloadend = (ev) => {
//       resolve(ev.target.result);
//     };
//     fileReader.onerror = reject;
//     fileReader.readAsText(blob);
//   });
// }