import { AlternateEmail } from "@material-ui/icons";
import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import UserList from "./userlist";


export function FacilityAndGentellUsers() {

    return (
        <>
            <Tabs defaultActiveKey="wounds" id="uncontrolled-tab-example">
                <Tab eventKey="wounds"  title={
                    <div>
                        
                        Gentell Users
                    </div>}>
                   
                    <UserList isForGentell={1}
                        />
                </Tab>
                <Tab eventKey="ostomy"  title={
                    <div>
                       Facility Users
                    </div>
                }>
                        
                        <UserList isForGentell={0}
                    />
                </Tab>

            
            </Tabs>
        </>
    );
}
export default FacilityAndGentellUsers;