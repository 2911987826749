import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Alert,
  Badge,
  Button,
  ButtonToolbar,
  Dropdown,
  DropdownButton,
  Modal
} from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import { defaultUrls } from "../../constants/endpoints";
import { useSelector } from 'react-redux';

import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import FacilityDetail from "../modal/facilitymodals/facilitydetail";
import PopupModal from "../modal/popupmodal";
import EditFacility from '../facility/editFacility';
import NewFacilityModal from "../modal/_newfacilitymodal";
import Physician from "../physician/physician";
import BillingResident from "../resident/billingresident";
import SetBillingDate from '../facility/setBillingDate';
import SetBTCredentials from '../../../app/modules/Auth/pages/setBTCredentials'
import IntakeProcessor from './assignProcessor';
import uuid from 'node-uuid'
var setBillingDateModal, addFacility, editFacility, editFacilityModal, intakeProcessorModal = null;

export function BillingQueue(props) {
  //#region resident for facility
  const { user } = useSelector((state) => state.auth);

  const [showResident, setShowResident] = useState(false);
  const [isPartailOrder, setPartailOrder] = useState(false);
  const [isReleasedFacility, setIsReleasedFacility] = useState(false);
  const [isBTOn, setIsBtOn] = useState(true);
  const [isProcessorFunctionOn, setIsProcessorFunctionOn] = useState(true);
  const [FacilityObj, setFacilityObj] = useState("");
  const [Facility, setFacilityForEdit] = useState({});
  const [refreshworkQueueListUI, setrefreshworkQueueListUI] = useState(false);
  const [billingQueueReleasedFacilityListUI, setBillingQueueReleasedFacilityListUI] = useState([]);
  const [billingDateDetail, setBillingDate] = useState('');
  // USE EFFECTS
  useEffect(() => {
    setIsLoading(true);
    getAllFacility();

  }, [getAllFacility, refreshworkQueueListUI]);
  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(defaultUrls.notificationHub)
      .configureLogging(LogLevel.None)
      .build();

    async function start() {
      try {
        await connection.start();

      } catch (err) {

        setTimeout(start, 5000);
      }
    };

    connection.onclose(start);

    // Start the connection.

    connection.on('RefreshRequested', (data) => {
      // GETS TRIGGERED FROM SIGNALR
      //setIsLoading(false);

      getAllFacility();
    });

    start();
  }, [getAllFacility]);
  //#endregion

  //#endregion
  const [billingFacilityListUI, setBillingQueueFacilityListUI] = useState([]);

  const [processStatusId, setProcessStatusId] = useState(Common.ProcessStatus.billingQueueID);


  const [isLoading, setIsLoading] = useState(false);
  //#region facility orders list modal
  //#endregion
  //#region BT Credential modal
  const [showModalNewBT, setShowHideBTModel] = useState(false);

  const handleCloseBTModal = () => setShowHideBTModel(false);
  const handleShowBTModel = () => setShowHideBTModel(true);
  //#endregion
  //#region new facility modal
  const [showModalNewFacility, setShowNewFacility] = useState(false);

  const handleCloseNewFacility = () => { setShowNewFacility(false) };
  const handleShowNewFacility = () => setShowNewFacility(true);
  //#endregion
  const [isReleasedFacilityShow, setReleasedFacility] = useState(false);

  //#region Consultants list modal
  const [FacilityID, setFacilityID] = useState('');

  //#endregion


  //#endregion

  //#region Set Resident List
  const getAllFacility = useCallback(() => {

    //setIsLoading(true);
    Api.getRequestQry(Api.Uri_GetBillingFacility, "?queueId=" + Common.ProcessStatus.billingQueueID
      + "&serachParm=&orgID=" + 0 + "&isInBillingQueue=" + 1)
      .then((res) => {
        if (res.data.statusCode == 200) {

          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              // setFacilityList(res.data.result);
              getAllPartailOrderFacilityList(res.data.result)
            } else {
              setBillingQueueFacilityListUI([]);
              getAllPartailOrderFacilityList([]);
            }
          }
        }
      })
      .catch((ex) => {


      })
      .then(() => {
        setIsLoading(false);

      });
  });
  const getAllPartailOrderFacilityList = useCallback((regularFacilityList) => {

    //setIsLoading(true);
    Api.getRequestQry(Api.Uri_GetPartailOrderFacilities, "?queueId="
      + Common.ProcessStatus.billingQueueID + "&isInBillingQueue=" + 1)
      .then((res) => {
        if (res.data.statusCode == 200) {

          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setPartailFacilityList(res.data.result, regularFacilityList);
            } else {
              setPartailFacilityList([], regularFacilityList);

            }
          }
        }
      })
      .catch((ex) => {


      })
      .then(() => {
        // setIsLoading(false);

      });
  });

  const editFacilityByID = (id) => {
    setFacilityID(id);
    getFacilityByID(id);
  }
  const getFacilityByID = (id) => {
    Api.getRequestById(Api.Uri_GetFacilityByID, id)
      .then((response) => {
        setFacilityForEdit(response.data.result);
        editFacilityModal.showModal();
      })
      .catch(function (error) {
        Common.LogError(error, "Facility", "getFacilityByID");

      });
  };
  const getAllReleasedFacility = () => {
    Api.getRequestQry(Api.Uri_GetBillingFacility, "?queueId=" + Common.ProcessStatus.brighreeQueueID + "&serachParm=&orgID=" + 0)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setReleasedFacilityList(res.data.result);
            } else {
              setBillingQueueReleasedFacilityListUI([]);
              getAllBTReleasedPartailOrderFacilityList([]);
            }
          }
        }
      })
      .catch((ex) => { })
      .then(() => {
        // setIsLoading(false);
        // Common.LoadDataTable("facilityBillingList");
      });
  };
  const setReleasedFacilityList = (list) => {
    debugger
    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var cycleDate = new Date(obj.cycleDate);
      rows.push(
        <tr key={i.toString()}>
          <td></td>
          <td>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {

                setFacilityObj(obj);
                setIsReleasedFacility(true);

                // setselectedFacilityDate(date);
                handleShowReleasedFacility(false);
                setShowResident(true);
                setFacilityObj(obj);
                setPartailOrder(false);
                setIsReleasedFacility(true);

              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingDate)}</td> */}
          <td>{Common.getInputStringDate(cycleDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td>
          <td>{Common.getInputStringDate(cycleDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td>
          <td>{Common.replaceNullOrEmptyWithString(obj.organizationName, "")}{obj.organizationName == null && obj.chain == null ? "N/A" : ""}
            {obj.organizationName != null && obj.chain != null ? "/" : ""}
            {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>

          <td align="center">{obj.isEcp == true ? <Badge variant="success">Yes</Badge> : <Badge variant="primary">No</Badge>} </td>

          <td>{obj.totalResident + "/" + obj.totalWound}</td>
          {/* <td>

            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">

                  <Button
                    size="xs"
                    variant="success"
                    onClick={() => handleFacilityReportModel(obj, Common.ProcessStatus.brighreeQueueID, false)}
                  >
                    Print Facility
                  </Button>

                </div>
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {obj.isManualProcess == true ? "Manual" : "Brightree Queue"}
                </div>

              </ButtonToolbar>
            </>
          </td> */}
          <td align="center">

            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {obj.isManualProcess == true ? "Manual" : "Brightree Queue"}
                </div>
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title="Actions"
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      <Dropdown.Item eventKey="12" onClick={() => handleFacilityReportModel(obj, Common.ProcessStatus.brighreeQueueID, false)}>Print Facility</Dropdown.Item>

                      <Dropdown.Item eventKey="0" onClick={() => handleBillingDate(obj)}>Set Billing Date</Dropdown.Item>
                      <Dropdown.Divider />

                    </DropdownType>
                  ))}
                </div>
              </ButtonToolbar>
            </>

          </td>
        </tr>
      );
    }
    // setBillingQueueReleasedFacilityListUI(rows);
    getAllBTReleasedPartailOrderFacilityList(rows);


  };
  const getAllBTReleasedPartailOrderFacilityList = useCallback((regularReleasedFacilityList) => {

    //setIsLoading(true);
    Api.getRequestQry(Api.Uri_GetPartailOrderFacilities, "?queueId=" + Common.ProcessStatus.brighreeQueueID)
      .then((res) => {
        if (res.data.statusCode == 200) {

          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setReleasedPartailFacilityList(res.data.result, regularReleasedFacilityList);
            } else {
              setReleasedPartailFacilityList([], regularReleasedFacilityList);

            }
          }
        }
      })
      .catch((ex) => {


      })
      .then(() => {

      });
  });
  const setReleasedPartailFacilityList = (list, regularReleasedFacilityList) => {

    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const OrganizationName = obj.organizationName;
      const facilityID = obj.id;

      const chain = Common.replaceNullWithString(obj.chain);
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var dob = new Date(obj.cycleDate);
      const date =
        dob.getMonth() + 1 + "/" + dob.getDate() + "/" + dob.getFullYear();
      const facilityType = Common.replaceNullWithString(obj.facilityType);
      regularReleasedFacilityList.push(
        <tr key={i.toString()}>
          <td>{obj.totalWound>0 && obj.totalOrderNowWound>0?"P*"
          : obj.totalOrderNowWound>0?"*":"P"}{i.toString()+1}</td>
          <td align="left" className="customStyleAccorion">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setProcessStatusId(Common.ProcessStatus.brighreeQueueID)
                setselectedFacilityDate(date);
                handleShowReleasedFacility(false);
                setShowResident(true);
                setFacilityObj(obj);
                setPartailOrder(true);
                setIsReleasedFacility(true);
              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingDate)}</td> */}
          <td>{Common.GetDateString(obj.cycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.cycleDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td>
          <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td>
          {/* <td>{Common.GetDateString(obj.secondaryCycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.secondaryCycleDate)}</td> */}
          {/* <td>{Common.GetDateString(obj.btqueueReleasedDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.btqueueReleasedDate)}</td> */}
          <td>{Common.replaceNullOrEmptyWithString(obj.organizationName, "")}{obj.organizationName == null && obj.chain == null ? "N/A" : ""}
            {obj.organizationName != null && obj.chain != null ? "/" : ""}
            {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>
          <td align="center">{obj.isEcp == true ? <Badge variant="success">Yes</Badge> : <Badge variant="primary">No</Badge>} </td>
          <td>{obj.totalResident + "/" + (obj.totalWound + obj.totalOrderNowWound)}</td>
          <td>

            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                {obj.isManualProcess == true ? "Manual" : "Brightree Queue"}
                </div>
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title="Actions"
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      <Dropdown.Item eventKey="12"      onClick={() => handleFacilityReportModel(obj, Common.ProcessStatus.brighreeQueueID, true)}>Print Facility</Dropdown.Item>

                      <Dropdown.Divider />

                    </DropdownType>
                  ))}
                </div>
              </ButtonToolbar>
            </>
          </td>
        </tr >
      );
    }
    // setBillingQueueReleasedFacilityListUI([]);
    setBillingQueueReleasedFacilityListUI(regularReleasedFacilityList);

    Common.LoadDataTable("facilityBillingList");
  };
  const handleShowReleasedFacility = (value) => {


    setReleasedFacility(value);
    if (value) {

      getAllReleasedFacility();
      // getAllBTReleasedPartailOrderFacilityList();
    }

  }

  const refreshFacility =()=>{
    getAllFacility()
    if(isReleasedFacilityShow)
    {
      getAllReleasedFacility()
    }
   
  }
  const CloseBtCrendentialsModal = (logedInUser) => {
    debugger
    handleCloseBTModal();
    localStorage.setItem(
      "gentell-fastcare-authUser",
      JSON.stringify(logedInUser)
    );
    localStorage.setItem(
      "gentell-fastcare-authToken",
      JSON.stringify(logedInUser.jwtToken)
    );
    props.login(logedInUser.jwtToken);
    // // debugger;
    //user.roles = roles;
    props.fulfillUser(logedInUser);
  }
  const handleReleaseFacility = (facility) => {
    var msg = "You want to release facility.";
    Common.showConfirmation(msg, "", releaseFacility, null, facility)

  };
  const releaseFacility = (facility) => {

    //setIsLoading(true);
    var obj = {};
    obj.FacilityID = facility.id;
    Api.postRequest(Api.Uri_UpdateBillingFacilityStatus, obj)
      .then((res) => {
        if (res.data.success) {
          if (res.data.statusCode == 400) {
            handleShowBTModel();
          } else {
            Common.showSuccessAlert(
              res.data.message,
              "success",
              "Success!",
              3000
            );
            if (res.data.result != null && res.data.result != "") {
              window.location.assign(res.data.result)
            }
            getAllFacility();
            // getAllReleasedFacility();
          }
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
      })
      .catch((ex) => {

        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      });
  };
  const handleReleasePartailFacility = (facility) => {
    var msg = "Do you want to release partial orders.";
    Common.showConfirmation(msg, "", releasePartailFacility, null, facility)

  };
  const releasePartailFacility = (facility) => {

    //setIsLoading(true);
    Api.getRequestQry(
      Api.apiUrls.releaseFacilityPartailOrders,
      "?facilityId=" + facility.id + "&queueID=" + Common.ProcessStatus.billingQueueID + "&partailOrderDate=" + facility.cycleDate
    )
      .then((res) => {
        if (res.data.success) {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
          if (res.data.result != null && res.data.result != "") {
            window.location.assign(res.data.result)
          }
          getAllFacility()
          // getAllPartailOrderFacilityList();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
      })
      .catch((ex) => {

        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      });
  };
  const refreshFacilityList = () => {

    setShowResident(false);
    // setIsLoading(true);
    getAllFacility();
    // getAllPartailOrderFacilityList();

  }
  const handleFacilityStatus = (obj) => {
    obj.active = obj.active == true ? false : true;
    updateFacilityStatus(obj);
  };
  const updateFacilityStatus = (obj) => {
    var msg = "You want to " + (obj.active ? "Activate" : "Deactivate") + " facility.";
    Common.showConfirmation(msg, "", updateStatus, null, obj)

  };
  const updateStatus = (obj) => {
    if (obj) {
      Api.postRequest(Api.apiUrls.updateFacilityStatus, obj).then((res) => {
        if (res.data.success) {
          getAllFacility();
          Common.showSuccessAlertAutoClose("Facility Status Updated Successfully", "success", "Success!", 3000)
        } else {
          Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
        }
      });
    }
  }
  const handleBillingDate = (facility,isPartial=false) => {
    if(facility.zip=='' || facility.zip==null){
      Common.showSuccessAlert(
        "Facility do not have postal code. Please enter postal code to proceed",
        "warning",
        "Warning!",
        3000
      );
      return false;
    }
    setFacilityID(facility.id);
    var billingDateObj = {};
    if (Common.GetDateString(facility.cycleDate) == null) {
      billingDateObj.billingDate = null;

    } else {
      billingDateObj.billingDate = facility.cycleDate;
    }
    if (Common.GetDateString(facility.secondaryCycleDate) == null) {
      billingDateObj.secondayBillingDate = null;


    } else {
      billingDateObj.secondayBillingDate = facility.secondaryCycleDate;

    }

    if (Common.GetDateString(facility.primaryCycleDate) == null) {
      billingDateObj.primaryCycleDate = null;


    } else {
      billingDateObj.primaryCycleDate = facility.primaryCycleDate;

    }

    billingDateObj.isRequested = false;
    billingDateObj.requestedCycleDate = facility.requestedCycleDate;
    billingDateObj.requestedSecondaryCycleDate = facility.requestedSecondaryCycleDate;
    billingDateObj.isPartial=isPartial;
    billingDateObj.isSecondaryCycle= facility.isSecondaryCycle;
    setBillingDate(billingDateObj);
    setBillingDateModal.showModal()
  }
  const handleFacilityProcessor = (facility, isPartial) => {
    facility.isPartial = isPartial;
    setFacilityObj(facility);
    intakeProcessorModal.showModal()
  }

  const toolbarReleasesFacility = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Button variant="dark" size="sm" className="mr-3" onClick={() => { (handleShowReleasedFacility(false)) }}>
          Hide Released Facilities
        </Button>

      </div>
    </div>
  );
  const [selectedFacilityDate, setselectedFacilityDate] = useState(null);
  const setFacilityList = async (partialFacilityList, list) => {

    // var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var dob = new Date(obj.cycleDate);
      const date =
        dob.getMonth() + 1 + "/" + dob.getDate() + "/" + dob.getFullYear();
      partialFacilityList.push(
        <tr key={i.toString()}>
          <td></td>

          <td align="left" className="customStyleAccorion">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setProcessStatusId(Common.ProcessStatus.billingQueueID)

                setselectedFacilityDate(date);
                handleShowReleasedFacility(false);
                setShowResident(true);
                setFacilityObj(obj);
                setPartailOrder(false)
                setIsBtOn(obj.isBTOn)
                setIsProcessorFunctionOn(obj.isProcessorFunctionOn)


                setIsReleasedFacility(false);

              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.cycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.cycleDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td> */}
          <td>{Common.GetDateString(obj.billingQueueReleaseDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingQueueReleaseDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td>

          <td>{Common.GetDateString(obj.cycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.cycleDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td>


          <td>{Common.replaceNullOrEmptyWithString(obj.organizationName, "")}{obj.organizationName == null && obj.chain == null ? "N/A" : ""}
            {obj.organizationName != null && obj.chain != null ? "/" : ""}
            {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>
          <td align="center">{obj.isEcp == true ? <Badge variant="success">Yes</Badge> : <Badge variant="primary">No</Badge>} </td>
          <td>{obj.totalResident + "/" + obj.totalWound}</td>
          <td>{Common.replaceNullOrEmptyWithString(obj.billerName, "N/A")}</td>


          {/* <td align="center">
            <Badge variant="success">Active</Badge>
          </td> */}

          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  <CheckPermission
                    permission={UserActions.Update}
                    module={ModulesName.Module_BillingQueue}
                    yes={() => (
                      <Button
                        size="xs"
                        variant="success"
                        disabled={!obj.isProcessorFunctionOn}
                        onClick={() => handleReleaseFacility(obj)}
                      >
                        {!obj.isBTOn ? "Print Orders" : "Release To Brightree"}
                      </Button>
                    )}
                    no={() => null}
                  />
                </div>
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title="Actions"
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      <CheckPermission
                        userRole={[RBAC.Role_Facility_RegionalClinician, RBAC.Role_Gentell_Administrator]}

                        yes={() => (
                          <>
                            <Dropdown.Item eventKey="6" onClick={() => editFacilityByID(obj.id)}>Edit Facility</Dropdown.Item>

                          </>
                        )}
                        no={() => null}
                      />
                      <CheckPermission
                        userRole={[RBAC.Role_Gentell_SeniorBiller, RBAC.Role_Gentell_Administrator]}

                        yes={() => (
                          <>
                            <Dropdown.Item eventKey="61" onClick={() => handleFacilityProcessor(obj, false)}>Assign Intake Processor</Dropdown.Item>

                          </>
                        )}
                        no={() => null}
                      />
                      <Dropdown.Item eventKey="0" onClick={() => handleBillingDate(obj)}>Set Billing Date</Dropdown.Item>
                      <Dropdown.Item eventKey="12" onClick={() => handleFacilityReportModel(obj, Common.ProcessStatus.billingQueueID, false)}>Print Facility</Dropdown.Item>

                      <Dropdown.Divider />
                      <CheckPermission
                        userRole={[RBAC.Role_Facility_RegionalClinician, RBAC.Role_Gentell_Administrator]}

                        yes={() => {
                          return (
                            <>
                              {/* <Dropdown.Item eventKey="8" className="text-danger" onClick={displayWarningAlert}>Authorize</Dropdown.Item> */}
                              <Dropdown.Item eventKey="8" className="text-danger" onClick={() => handleFacilityStatus(obj)}>{obj.active == false ? 'Active' : 'Deactivate'}</Dropdown.Item>
                            </>
                          );
                        }}
                        no={() => null}
                      />
                    </DropdownType>
                  ))}
                </div>

              </ButtonToolbar>
            </>
          </td>
        </tr >
      );
    }
    //   setBillingQueueFacilityListUI([]);
    //  await setBillingQueueFacilityListUI(rows);
    //   Common.LoadDataTable("facilityList");
    // getAllPartailOrderFacilityList(rows);
    setBillingQueueFacilityListUI([]);
    setBillingQueueFacilityListUI(partialFacilityList);
  };
  const setPartailFacilityList = (list, regularFacilityList) => {

    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const OrganizationName = obj.organizationName;
      const facilityID = obj.id;

      const chain = Common.replaceNullWithString(obj.chain);
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var dob = new Date(obj.cycleDate);
      const date =
        dob.getMonth() + 1 + "/" + dob.getDate() + "/" + dob.getFullYear();
      const facilityType = Common.replaceNullWithString(obj.facilityType);
      rows.push(
        <tr key={i.toString()}>
          <td>{obj.totalWound>0 && obj.totalOrderNowWound>0?"P*"
          : obj.totalOrderNowWound>0?"*":"P"} </td>
          <td align="left" className="customStyleAccorion">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setProcessStatusId(Common.ProcessStatus.billingQueueID)
                setselectedFacilityDate(date);
                handleShowReleasedFacility(false);
                setShowResident(true);
                setFacilityObj(obj);
                setPartailOrder(true);
                setIsReleasedFacility(false);

              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingDate)}</td> */}
          <td>{Common.GetDateString(obj.cycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.cycleDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td>
          <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td>
          {/* <td>{Common.GetDateString(obj.secondaryCycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.secondaryCycleDate)}</td> */}
          <td>{Common.replaceNullOrEmptyWithString(obj.organizationName, "")}{obj.organizationName == null && obj.chain == null ? "N/A" : ""}
            {obj.organizationName != null && obj.chain != null ? "/" : ""}
            {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>
          <td align="center">{obj.isEcp == true ? <Badge variant="success">Yes</Badge> : <Badge variant="primary">No</Badge>} </td>
          <td>{obj.totalResident + "/" + (obj.totalWound + obj.totalOrderNowWound)}</td>
          <td>{Common.replaceNullOrEmptyWithString(obj.billerName, "N/A")}</td>


          {/* <td align="center">
            <Badge variant="success">Active</Badge>
          </td> */}

          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  <CheckPermission
                    permission={UserActions.Update}
                    module={ModulesName.Module_BillingQueue}
                    yes={() => (
                      <Button
                        size="xs"
                        variant="success"
                        disabled={!obj.isProcessorFunctionOn}
                        onClick={() => handleReleasePartailFacility(obj)}
                      >

                        {!obj.isBTOn ? "Print Orders" : "Release To Brightree"}
                      </Button>
                    )}
                    no={() => null}
                  />
                </div>
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title="Actions"
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      <CheckPermission
                        permission={UserActions.Update}
                        module={ModulesName.Module_Facility}
                        yes={() => (
                          <Dropdown.Item eventKey="6" onClick={() => editFacilityByID(obj.id)}>Edit Facility</Dropdown.Item>
                        )}
                        no={() => null}
                      />
                      
                      <Dropdown.Item eventKey="0" onClick={() => handleBillingDate(obj,true)}>Set Billing Date</Dropdown.Item>
                      <CheckPermission
                        userRole={[RBAC.Role_Gentell_SeniorBiller, RBAC.Role_Gentell_Administrator]}

                        yes={() => (
                          <>
                            <Dropdown.Item eventKey="61" onClick={() => handleFacilityProcessor(obj, true)}>Assign Intake Processor</Dropdown.Item>

                          </>
                        )}
                        no={() => null}
                      />
                      <Dropdown.Item eventKey="11" onClick={() => handleFacilityReportModel(obj, Common.ProcessStatus.billingQueueID, true)}>Print Facility</Dropdown.Item>


                      <Dropdown.Divider />
                      <CheckPermission
                        userRole={[RBAC.Role_Facility_RegionalClinician, RBAC.Role_Gentell_Administrator]}

                        yes={() => {
                          return (
                            <>
                              <Dropdown.Item eventKey="8" className="text-danger" onClick={() => handleFacilityStatus(obj)}>{obj.active == false ? 'Active' : 'Deactivate'}</Dropdown.Item>
                            </>
                          );
                        }}
                        no={() => null}
                      />
                    </DropdownType>
                  ))}
                </div>

              </ButtonToolbar>
            </>
          </td>
        </tr >
      );
    }
    setFacilityList(rows, regularFacilityList)

    Common.LoadDataTable("facilityList");

  };

  const assignNextFacilityToBiller = () => {

    // Api.getRequestQry(Api.Uri_AssignFacilityToBiller)
    Api.getRequest(Api.Uri_AssignFacilityToBiller)

      .then((response) => {
        if (response.data.success) {

          getAllFacility();

          Common.showSuccessAlert(response.data.message, "success", "Success!!");

        }
        else {
          Common.showSuccessAlert(response.data.message, "warning", "Warning");
        }
      }).catch((ex) => {

      }).then();

  }
  const handleFacilityReportModel = async (facility, facilityProcessStatusId, isPartial) => {
    var { data } = await Api.getRequestQry(Api.URI_GetFacilitesReport,
      "?facilityId=" + facility.id + "&facilityProcessStatusId=" + facilityProcessStatusId + "&isPartial=" + isPartial)
    debugger
    if (data.success) {
      debugger
      if (data.result != null && data.result != '') {
        window.location.assign(data.result)
      }

    } else {
    }
  }
  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Button variant="success" size="sm" className="mr-3" onClick={() => { (handleShowReleasedFacility(true)) }}>
          Show Released Facility
        </Button>
        <CheckPermission
          userRole={RBAC.Role_Gentell_IntakeProcessor}
          yes={() => (
            <Button variant="primary" size="sm" onClick={() => assignNextFacilityToBiller()}>
              Request Facility
            </Button>
          )}
          no={() => null}
        />

      </div>
    </div>
  );



  return (
    <>


      {showResident && !isLoading && (
        <Animated
          animationIn="fadeIn"
          animationInDuration={1000}
          animationOut="fadeOut"
          isVisible={showResident}
        >
          <BillingResident
            refresh={getAllFacility}
            backPress={() => refreshFacilityList()}
            facility={FacilityObj}
            selectedFacilityDate={selectedFacilityDate}
            isPartailOrder={isPartailOrder}
            processStatusId={processStatusId}
            isReleasedFacility={isReleasedFacility}
            isBTOn={isBTOn}
            isProcessorFunctionOn={isProcessorFunctionOn}
          />
        </Animated>
      )}

      {!showResident && (
        <Animated animationIn="fadeIn" animationInDuration={1000}>
          <Card className="example example-compact">
            <CardHeader title="Processor Queue" toolbar={toolbar} />
            <CardBody>
              {isLoading ? (
                <div className="row d-flex justify-content-center">
                  <span className="mt-5 spinner spinner-primary"></span>
                </div>
              ) : (
                <table
                  className="table table-hover table-sm table-bordered"
                  id="facilityList"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Facility Name</th>

                      <th>Processing Date</th>
                      <th>Next ship Date</th>
                      {/* <th>Secondary Date</th> */}


                      <th>Ownership-Headquarters <br /> Purchasing Group-Chain</th>
                      <th>Equal Care</th>
                      <th>Residents <br /> Wounds</th>

                      <th>Processor Name</th>

                      <th width="20%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{billingFacilityListUI}</tbody>
                </table>

              )}
            </CardBody>
          </Card>

        </Animated>
      )}
      {isReleasedFacilityShow && (
        <Animated animationIn="fadeIn" animationInDuration={1000} >
          <hr />
          <Card key={uuid()} className="example example-compact">
            <CardHeader title="Released Facilities" toolbar={toolbarReleasesFacility} />
            {/* toolbar={toolbar} */}
            <CardBody>
              {isLoading ? (
                <div className="row d-flex justify-content-center">
                  <span className="mt-5 spinner spinner-primary"></span>
                </div>
              ) : (
                <table
                  className="table table-striped table-hover table-sm table-bordered"
                  id="facilityBillingList"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Facility Name</th>
                      <th>Processing Date</th>
                      <th>Next Ship Date</th>
                      {/* <th>Secondary Date</th> */}

                      <th>Operators-Purchasing Group <br /> Ownership-Headquarters</th>
                      <th>Equal Care</th>
                      <th>Residents/ Wounds</th>
                      <th>Status</th>


                    </tr>
                  </thead>
                  <tbody>{billingQueueReleasedFacilityListUI}</tbody>
                </table>
              )}
            </CardBody>
          </Card>

        </Animated>
      )}
 

    
      <PopupModal

        dialogClassName="modal-90w"
        title="Edit Facility"
        module={ModulesName.Module_Facility}
        action={UserActions.Update}
        buttonPress={() => editFacility.onSubmit()}
        ref={(ref) => {
          editFacilityModal = ref;
        }}
        onClose={(ref) => {
          Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
            () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
        }}
      >
        {" "}
        <EditFacility
          ref={(ref) => {
            editFacility = ref;
          }}
          FacilityID={FacilityID}
          Facility={Facility}
          onSaveAPI={() => { getAllFacility(); editFacilityModal.hideModal(true); }}

        />{" "}
      </PopupModal>
      <PopupModal
        size="lg"
        title="New Ship Date"
        module={ModulesName.Module_BillingQueue}
        action={UserActions.Create}
        buttonPress={() => addFacility.onSubmit()}
        ref={(ref) => {
          setBillingDateModal = ref;
        }}
      >
        {" "}
        <SetBillingDate
          ref={(ref) => {
            addFacility = ref;
          }}
          onSaveAPI={() => { refreshFacility(); setBillingDateModal.hideModal(); }}
          facilityId={FacilityID}
          billingDateDetail={billingDateDetail}

        />{" "}
      </PopupModal>

      <PopupModal
        title="Intake Processor"
        ref={(ref) => {
          intakeProcessorModal = ref;
        }}
      >
        {" "}
        <IntakeProcessor
          refresh={() => { getAllFacility(); intakeProcessorModal.hideModal() }}
          facility={FacilityObj} />
        {" "}

      </PopupModal>
      {/* new facility modal */}
      <Modal
        aria-labelledby="newFacilityModal"
        scrollable
        backdrop="static"
        dialogClassName="modal-90w"
        keyboard={false}
        show={showModalNewFacility}
        onHide={handleCloseNewFacility}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title id="newFacilityModal">New Facility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewFacilityModal />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseNewFacility}>
            Close
          </Button>
          <CheckPermission
            permission={UserActions.Create}
            module={ModulesName.Module_Facility}
            yes={() => (
              <Button
                variant="primary"
                size="sm"
                onClick={handleCloseNewFacility}
              >
                Save Changes
              </Button>
            )}
            no={() => null}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="newFacilityModal"
        scrollable backdrop="static"
        // dialogClassName="modal-90w"
        keyboard={false}
        show={showModalNewBT}
        onHide={handleCloseBTModal}>

        <Modal.Header className="py-3" closeButton>
          <Modal.Title id="newFacilityModal">
            Brightree Credentials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SetBTCredentials user={user} CloseBtCrendentialsModal={CloseBtCrendentialsModal} />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseBTModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BillingQueue;

/*eslint-enable */
