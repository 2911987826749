/*eslint-disable */

import PropTypes from 'prop-types';
import React from "react";
import { Button, Modal } from "react-bootstrap";
import CheckPermission from '../../General/CheckPermission';
import * as Common from "../../General/common";
export default class PopupModal extends React.Component {
    constructor(props, context) {
        super(props);
        this.state = {
            show: false,
        };

    }
handleCloseModal(){
    Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning', () => { this.hideModal() }, () => { }, null);
}
    render() {
        const children = this.props.children;
        return (
            <>
            {this.state.show==true &&
                <Modal
                    size={this.props.size}
                    aria-labelledby="newProductModal"
                    centered scrollable backdrop="static"
                    keyboard={false}
                    show={this.state.show}
                    onHide={this.hideModal}
                    dialogClassName={this.props.dialogClassName}
                    enforceFocus={false}
                >
                    <Modal.Header className="py-3" closeButton>
                        <Modal.Title id="newProductModal">
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {children}
                        </div>
                        {/* <NewProduct ref={instance => { this.newProduct = instance; }} /> */}
                    </Modal.Body>
                    <Modal.Footer className="py-2">
                        <Button disabled={!!this.props?.buttonDisabled ? this.props.buttonDisabled : false} variant="danger" size="sm" onClick={() => this.handleCloseModal()}>
                            Close
                        </Button>
                        {
                            this.props.showButton == true ?
                                <>
                                    {/* {this.props.showButtonPreviosOrder == true ?

                                        <>
                                        {this.props.isPreviosOrderPress &&

                                            <Button variant="info" size="sm" onClick={() => this.props.buttonPreviosOrderClosePress()}>
                                                Hide Previous Order
                                            </Button>
                                           
                                        }
                                              {!this.props.isPreviosOrderPress &&
                                            <Button variant="info" size="sm" onClick={() => this.props.buttonPreviosOrderPress()}>
                                                Previous Order
                                            </Button>
    }
                                        </>
                                        :
                                        null
                                    } */}
                                    <CheckPermission
                                        permission={this.props.action}
                                        module={this.props.module}
                                        yes={() => (
                                            <Button variant="primary" size="sm" disabled={!!this.props?.buttonDisabled ? this.props.buttonDisabled:false} onClick={() => this.onButtonPress()}>
                                                {this.props.buttonText}
                                            </Button>
                                        )}
                                        no={() => null}
                                    />
                                </>
                                :
                                null
                        }
                    </Modal.Footer>
                </Modal>
                }
            </>
        );
    }

    //Functions

    showModal = () => {
        this.setState({ show: true });
    }

    onButtonPress = () => {
        //this.newProduct.onFormSave();
        //this.props.onPress();
        if (this.props.buttonPress != null) {
            this.props.buttonPress();
        }
        //this.setState({ show: false });
    };

    hideModal = (notShowConfirmation) => {
        debugger
        //this.props.onPress();
        if (this.props.onClose) {
            if (notShowConfirmation) {
                this.setState({ show: false });
            } else {

                this.props.onClose(this);
            }

        }
        else {
            this.setState({ show: false });
        }
    };

}//end of class
PopupModal.defaultProps = {
    showButton: true,
    buttonPress: null,
    buttonText: "Save Changes",
    size: "xl",
    dialogClassName: "",
    action: null,
    module: null,
    onClose: null,
    buttonDisabled:false
}

PopupModal.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    showButton: PropTypes.bool,
    buttonPress: PropTypes.func,
    buttonText: PropTypes.string,
    size: PropTypes.string,
    dialogClassName: PropTypes.string,
    buttonDisabled:PropTypes.bool
}


/*eslint-disable */