import React, { Component } from 'react';  
import axios from 'axios';  
import {  Dropdown, DropdownButton,Button } from "react-bootstrap";
import { showDecisionAlert } from "../../../../index";

///const displayWarningAlert  = () => showWarningAlert("Coming Soon!!!", "Alert");
const displayDecisionAlert  = () => showDecisionAlert(function () {}, function () {}, "Are you sure?", "You want to delete this record.");


class RoleTable extends Component {  
  constructor(props) {  
    super(props);  
    }  
    handleEdit = () =>{
      this.props.showEditPopup(this.props.obj);
    }
    handleAssignAction = () =>{
      this.props.showAssignActionPopup(this.props.obj);
    }
     
  render() {  
    return (  
        <tr>
          <td>  
            {this.props.obj.roleName}  
          </td>  
          <td>  
            {this.props.obj.description}  
          </td>  
          <td>  
          {this.props.obj.forGentell==true?<span className="badge badge-info">Gentell</span>:<span className="badge badge-warning">Facility</span>} 
          </td>
          <td>  
          {this.props.obj.status==true?<span className="badge badge-success">Active</span>:<span className="badge badge-danger">In-Active</span>} 
          </td>  
          <td align="center">
                <div className="text-center">
                <Button onClick={ this.handleAssignAction } size="xs"  >Manage Access</Button>
                    {/* <DropdownButton
                        drop={"down"}
                        size="xs"
                        variant="primary"
                        title={'Actions'}
                        id={`dropdown-button-drop-actions`}
                        key={'down'}>
                        <Dropdown.Item onClick={ this.handleEdit }  eventKey="1" >Edit Role</Dropdown.Item>
                        <Dropdown.Item onClick={ this.handleAssignAction } eventKey="2" >Assign Action</Dropdown.Item>
                        </DropdownButton> */}
                </div>
            </td>
        </tr>  
    );  
  }  
}  
  
export default RoleTable;  