import * as React from "react";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Alert,
  Badge,
  Button,
  ButtonToolbar,
  Dropdown,
  DropdownButton,
  Modal
} from "react-bootstrap";
import { showDecisionAlert, showWarningAlert } from "../../../index";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import RoutePaths from "../../constants/routepaths";
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import { ModulesName, UserActions } from "../../General/roleModuleActions";
import Consultantslistmodal from "../modal/facilitymodals/consultantslistmodal";
import Contactslistmodal from "../modal/facilitymodals/contactslistmodal";
import FacilityDetail from "../modal/facilitymodals/facilitydetail";
import Facilityorderslist from "../modal/facilitymodals/facilityorderslist";
import PopupModal from "../modal/popupmodal";
import Qapireport from "../modal/reports/qapireport";
import EvalForms from "../modal/reports/showevals";
import Trendreport from "../modal/reports/trendreport";
import Woundreport from "../modal/reports/woundreport";
import NewFacilityModal from "../modal/_newfacilitymodal";
import PlaceOrder from "../orders/placeorder";
import Physician from "../physician/physician";
import WorkQueueWoundOrders from './workQueueWoundOrders';
import UrologicalResident from "../outs/urologicalResident";
import OstomyResident from "../outs/ostomyresident";
export function UrologicalFacility(props) {
  //#region resident for facility
  const [showResident, setShowResident] = useState(false);
  const [FacilityObj, setFacilityObj] = useState("");
  //#endregion

  //#region facility orders list modal
  var FacilityDetailModal = null;
  //#endregion
  const [workQueueListUI, setWorkQueueListUI] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //#region facility orders list modal
  const [showModalFacilityOrders, setShowFacilityOrders] = useState(false);

  const handleCloseFacilityOrders = () => setShowFacilityOrders(false);
  const handleShowFacilityOrders = () => setShowFacilityOrders(true);
  //#endregion

  //#region new facility modal
  const [showModalNewFacility, setShowNewFacility] = useState(false);

  const handleCloseNewFacility = () => setShowNewFacility(false);
  const handleShowNewFacility = () => setShowNewFacility(true);
  //#endregion

  //#region Consultants list modal
  const [showModalConsultantList, setShowConsultantList] = useState(false);
  const [FacilityID, setFacilityID] = useState('');
  const handleCloseConsultantList = () => setShowConsultantList(false);
  // const handleShowConsultantList = () => setShowConsultantList(true);
  const handleShowConsultantList = (_facilityID) => {

    setFacilityID(_facilityID)
    console.log()
    setTimeout(() => {
      setShowConsultantList(true)
    }, 200)
  };

  //#endregion

  //#region Contacts list modal
  const [showModalContacttList, setShowContactList] = useState(false);

  const handleCloseContactList = () => setShowContactList(false);
  const handleShowContactList = (_facilityID) => {
    setFacilityID(_facilityID)
    console.log()
    setTimeout(() => {
      setShowContactList(true);
    }, 200)
  }
  //#endregion

  //#region Qapi report modal
  const [showModalQapiReport, setShowQapiReport] = useState(false);

  const handleCloseQapiReport = () => setShowQapiReport(false);
  const handleShowQapiReport = () => setShowQapiReport(true);
  //#endregion

  //#region Trend report modal
  const [showModalTrendReport, setShowTrendReport] = useState(false);

  const handleCloseTrendReport = () => setShowTrendReport(false);
  const handleShowTrendReport = () => setShowTrendReport(true);
  //#endregion

  //#region Wound report modal
  const [showModalWoundReport, setShowWoundReport] = useState(false);

  const handleCloseWoundReport = () => setShowWoundReport(false);
  const handleShowWoundReport = () => setShowWoundReport(true);
  //#endregion

  //#region Eval froms report modal
  const [showModalEvalForms, setShowEvalForms] = useState(false);

  const handleCloseEvalForms = () => setShowEvalForms(false);
  const handleShowEvalForms = () => setShowEvalForms(true);
  //#endregion

  //#region Place Order Modal
  var placeOrderModal = null;
  //#endregion

  //#region Physician modal
  var physicianModal = null;
  //#endregion

  const displayWarningAlert = () => showWarningAlert("Coming Soon!!!", "Alert");
  const displayDecisionAlert = () =>
    showDecisionAlert(
      function () { },
      function () { },
      "Are you sure?",
      "You want to deactivate this Facility."
    );
  useEffect(() => {
    getAllFacility();
    //setResidentListTemp();
    //Common.LoadDataTable("facilityList")
  }, []);

  //#region Set Resident List
  const getAllFacility = () => {
    setIsLoading(true);
    Api.getRequestQry(Api.Uri_GetUrologicalFacilityAndResidentByStatusId, "?serachParm=&orgID=" + 0+"&processStatusID=" + props.processStatusID)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setFacilityList(res.data.result);
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Workqueue", "getAllFacility");


      })
      .then(() => {
        setIsLoading(false);
        Common.LoadDataTable("residentsList");
      });
  };
  const refreshFacilityList = () => {

    setShowResident(false);
    getAllFacility();

  }
  const handleReleaseFacility = (facilityID) => {
    setIsLoading(true);
    Api.getRequestQry(
      Api.URI_UpdateUrologicalBillingOUtsOrderStatusByFacilityID,
      "?facilityID=" + facilityID
    )
      .then((res) => {
        if (res.data.success) {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
          getAllFacility();
        } else {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "warning",
            "Failed!",
            3000
          );
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Workqueue", "handleReleaseFacility");

        // // debugger;
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const setFacilityList = (list) => {
    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const OrganizationName = obj.organizationName;
      const facilityID = obj.id;

      const chain = Common.replaceNullWithString(obj.chain);
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var cycleDate = new Date(obj.cycleDate);
      debugger
      const facilityType = Common.replaceNullWithString(obj.facilityType);
      rows.push(
        <tr key={i.toString()}>
          <td>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {

                setShowResident(true);
                debugger
                setFacilityObj(obj);
              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingDate)}</td> */}
          <td>{Common.getInputStringDate(cycleDate)}</td>
          {/* <td>{Common.GetDateString(cycleDate)}</td> */}

          <td>{Common.replaceNullOrEmptyWithString(obj.organizationName, "N/A")}  {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>
          <td>{Common.replaceNullOrEmptyWithString(obj.clinicianName, "N/A")}</td>


          <td align="center">
            <Badge variant="success">Active</Badge>
          </td>

          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                {/* <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  <CheckPermission
                    permission={UserActions.Update}
                    module={ModulesName.Module_Facility}
                    yes={() => (
                      <Button
                        size="xs"
                        variant="success"
                        onClick={() => handleReleaseFacility(facilityID)}
                      >
                        Release
                      </Button>
                    )}
                    no={() => null}
                  />
                </div> */}
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title="Actions"
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      <Dropdown.Item eventKey="1" onClick={() => placeOrderModal.showModal()}>Sample Order</Dropdown.Item>
                      <Dropdown.Item eventKey="2" onClick={() => window.open(RoutePaths.Page_Resident + "?fid=" + facilityID, "_self")}>Resident</Dropdown.Item>
                      <Dropdown.Item eventKey="3" onClick={() => { handleShowContactList(obj.id) }}>Contacts</Dropdown.Item>
                      <Dropdown.Item eventKey="4" onClick={() => { handleShowConsultantList(obj.id) }}>Consultants</Dropdown.Item>
                      <Dropdown.Item eventKey="5" onClick={handleShowFacilityOrders}>Residents Orders</Dropdown.Item>
                      <Dropdown.Item eventKey="6" onClick={handleShowNewFacility} >Edit Facility</Dropdown.Item>
                      <Dropdown.Item eventKey="7" onClick={() => physicianModal.showModal()} >Physicians</Dropdown.Item>
                      <Dropdown.Divider />
                      <CheckPermission
                        permission={UserActions.Update}
                        module={ModulesName.Module_Facility}
                        yes={() => {
                          return (
                            <>
                              <Dropdown.Item eventKey="8" className="text-danger" onClick={displayWarningAlert}>Authorize</Dropdown.Item>
                              <Dropdown.Item eventKey="9" className="text-danger" onClick={displayDecisionAlert}>Deactivate</Dropdown.Item>
                            </>
                          );
                        }}
                        no={() => null}
                      />
                    </DropdownType>
                  ))}
                </div>
                {[DropdownButton].map((DropdownType, idx) => (
                  <DropdownType
                    size="xs"
                    variant="dark"
                    title="Reports"
                    id={`dropdown-button-drop-${idx}`}
                    key={idx}
                  >
                    <Dropdown.Item eventKey="1" onClick={handleShowEvalForms}>
                      Eval Forms
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={handleShowWoundReport}>
                      Wound Report
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={handleShowQapiReport}>
                      QAPI Report
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4" onClick={handleShowTrendReport}>
                      Trend Report
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="5"
                      tag="a"
                      rel="noopener noreferrer"
                      href="https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_1MB.pdf"
                      target="_blank"
                    >
                      WES Fac. Report
                    </Dropdown.Item>
                  </DropdownType>
                ))}
              </ButtonToolbar>
            </>
          </td>
        </tr>
      );
    }
    setWorkQueueListUI(rows);
  };

  const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(
    ShowAdvancedSearch ? true : false
  );
  const showAdvanceSearchSection = () => {
    showAdvanceSearchSectionState(!ShowAdvancedSearch);
  };

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Button
          variant="warning"
          className="mr-5"
          size="sm"
          onClick={showAdvanceSearchSection}
        >
          Advanced Search
        </Button>
        <Button variant="primary" size="sm" onClick={handleShowNewFacility}>
          New Facility
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {ShowAdvancedSearch && !isLoading && (
        <Animated animationIn="fadeInLeft" animationInDuration={1000}>
          <Card className="">
            <CardHeader title="Search Facility" />
            <CardBody>
              <Alert className="mb-5" variant="secondary">
                Fill in one or more fields to search facility.
              </Alert>
              <form>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="FacilityNamebeginningwith"
                      >
                        Facility Name beginning with
                      </label>
                      <input
                        type="text"
                        id="FacilityNamebeginningwith"
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="WoundProductSpecialist"
                      >
                        Wound & Product Specialist
                      </label>
                      <input
                        type="text"
                        id="WoundProductSpecialist"
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="OwnershipGroup">
                        Ownership Group
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="OwnershipGroup"
                      >
                        <option>This is a Headquarters location</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">
                        State
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="State"
                      >
                        <option>New York</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group mt-5 mt-md-10 mt-lg-5">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="acceptTerms"
                          className="m-1"
                        />
                        <span className="mr-3" />
                        Facility is active
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
            <CardFooter className="d-flex justify-content-end py-3">
              <Button variant="success" className="mr-5 px-10" size="sm">
                Find
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={showAdvanceSearchSection}
              >
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Animated>
      )}

      {showResident && !isLoading && (
        <Animated
          animationIn="fadeIn"
          animationInDuration={1000}
          animationOut="fadeOut"
          isVisible={showResident}
        >
          <UrologicalResident
            backPress={() => refreshFacilityList()}
            facility={FacilityObj}
            processStatusID={props.processStatusID}
          />
        </Animated>
      )}

      {!showResident && (
        <Animated animationIn="fadeIn" animationInDuration={1000}>
          <Card className="example example-compact">
            <CardHeader title="My Work Queue" toolbar={toolbar} />
            <CardBody>
              {isLoading ? (
                <div className="row d-flex justify-content-center">
                  <span className="mt-5 spinner spinner-primary"></span>
                </div>
              ) : (
                <table
                  className="table table-striped table-hover table-sm table-bordered"
                  id="facilityList"
                >
                  <thead>
                    <tr>
                      <th>Facility Name</th>
                      <th>Billing Date</th>
                      {/* <th>OUT'S Date</th> */}

                      <th>Mgmt. Group/Chain</th>
                      <th>Clinician Name</th>
                      <th>Status</th>
                      <th width="20%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{workQueueListUI}</tbody>
                </table>
              )}
            </CardBody>
          </Card>
        </Animated>
      )}

      {/* <PopupModal
                title="Weekly Facility Reports - Test 3"
                ref={ref => { woundReportModal = ref; }}
                buttonText="Show"
                buttonPress={()=> window.open("https://gentellfastcare.com/wcp/forms/show_facrep/f/2020-10-29")}
                size="lg">
                <WoundReport />
            </PopupModal> */}

      {/** Place order Modal */}
      <PopupModal
        size="lg"
        title="Pick Order Type"
        ref={(ref) => {
          placeOrderModal = ref;
        }}
      >
        {" "}
        <PlaceOrder />{" "}
      </PopupModal>

      {/** physicianModal */}
      <PopupModal
        title="Physicians"
        showButton={false}
        ref={(ref) => {
          physicianModal = ref;
        }}
      >
        {" "}
        <Physician />{" "}
      </PopupModal>

      {/* facility Detail modal */}

      <PopupModal
        title="Facility Detail"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          FacilityDetailModal = ref;
        }}
      >
        {" "}
        <FacilityDetail />{" "}
      </PopupModal>

      {/* new facility modal */}
      <Modal
        aria-labelledby="newFacilityModal"
        scrollable
        backdrop="static"
        dialogClassName="modal-90w"
        keyboard={false}
        show={showModalNewFacility}
        onHide={handleCloseNewFacility}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title id="newFacilityModal">New Facility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewFacilityModal />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseNewFacility}>
            Close
          </Button>
          <CheckPermission
            permission={UserActions.Create}
            module={ModulesName.Module_Facility}
            yes={() => (
              <Button
                variant="primary"
                size="sm"
                onClick={handleCloseNewFacility}
              >
                Save Changes
              </Button>
            )}
            no={() => null}
          />
        </Modal.Footer>
      </Modal>

      {/* Facility Orders modal */}
      <Modal
        size="xl"
        aria-labelledby="facilityordersModal"
        dialogClassName="modal-90w"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
        show={showModalFacilityOrders}
        onHide={handleCloseFacilityOrders}
      >
        <Modal.Header closeButton>
          <Modal.Title>Facility Orders List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Facilityorderslist />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button
            variant="danger"
            size="sm"
            onClick={handleCloseFacilityOrders}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  Consultants List modal */}
      <Modal
        size="xl"
        aria-labelledby="showModalConsultantList"
        dialogClassName="modal-90w"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
        show={showModalConsultantList}
        onHide={handleCloseConsultantList}
      >
        <Modal.Header closeButton>
          <Modal.Title>Consultants List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Consultantslistmodal FacilityID={FacilityID} />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button
            variant="danger"
            size="sm"
            onClick={handleCloseConsultantList}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Contacts List modal */}
      <Modal
        size="xl"
        aria-labelledby="showModalContacttList"
        dialogClassName="modal-90w"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
        show={showModalContacttList}
        onHide={handleCloseContactList}
      >
        <Modal.Header closeButton>
          <Modal.Title>Contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Contactslistmodal FacilityID={FacilityID} />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseContactList}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Qapi report modal */}
      <Modal
        size="lg"
        aria-labelledby="showModalQapiReport"
        // dialogClassName="modal-90w"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
        show={showModalQapiReport}
        onHide={handleCloseQapiReport}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            QAPI Report - AA Healthcare Management, LLC{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Qapireport />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseQapiReport}>
            Close
          </Button>
          <a
            href="https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_1MB.pdf"
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn-primary btn-sm"
          >
            Show
          </a>
        </Modal.Footer>
      </Modal>

      {/* Trend report modal */}
      <Modal
        size="lg"
        aria-labelledby="showModalTrendReport"
        // dialogClassName="modal-90w"
        centered
        backdrop="static"
        keyboard={false}
        show={showModalTrendReport}
        onHide={handleCloseTrendReport}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Trend Report - AA Healthcare Management, LLC{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trendreport />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseTrendReport}>
            Close
          </Button>
          <a
            href="https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_1MB.pdf"
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn-primary btn-sm"
          >
            Show
          </a>
        </Modal.Footer>
      </Modal>

      {/* Wound report modal */}
      <Modal
        size="lg"
        aria-labelledby="showModalWoundReport"
        // dialogClassName="modal-90w"
        centered
        backdrop="static"
        keyboard={false}
        show={showModalWoundReport}
        onHide={handleCloseWoundReport}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Wound Report - AA Healthcare Management, LLC{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Woundreport />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseWoundReport}>
            Close
          </Button>
          <a
            href="https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_1MB.pdf"
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn-primary btn-sm"
          >
            Show
          </a>
        </Modal.Footer>
      </Modal>

      {/* Eval Froms modal */}
      <Modal
        size="lg"
        aria-labelledby="showModalEvalForms"
        // dialogClassName="modal-90w"
        centered
        backdrop="static"
        keyboard={false}
        show={showModalEvalForms}
        onHide={handleCloseEvalForms}
      >
        <Modal.Header closeButton>
          <Modal.Title>Eval Froms - AA Healthcare Management, LLC </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EvalForms />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseEvalForms}>
            Close
          </Button>
          <a
            href="https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_1MB.pdf"
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn-primary btn-sm"
          >
            Show
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UrologicalFacility;
