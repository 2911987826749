import React from "react";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import RoutePaths from "../../constants/routepaths";
import WoundAreaTrendReport from "./woundAreaTrendReport";
import QAPIReport from "./qapireport";
import WoundEvaluationReport from "./woundevaluationreport";
import facilityReport from "./facilityReport";
import HistoricalReport from "./historicalReport";

export default function AssessmentReportsNav() {
  // debugger;
  return (
    <>
      <Switch>
        <ContentRoute
          from={RoutePaths.Page_WoundReport}
          component={WoundAreaTrendReport}
        />
        <ContentRoute
          from={RoutePaths.Page_QAPIReport}
          component={QAPIReport}
        />
        <ContentRoute
          from={RoutePaths.Page_WoundEvaluationReport}
          component={WoundEvaluationReport}
        />
        <ContentRoute
          from={RoutePaths.Page_FacilityReport}
          component={facilityReport}
        />
        <ContentRoute
          from={RoutePaths.Page_HistoricalReport}
          component={HistoricalReport}
        />
      </Switch>
    </>
  );
}
