import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls/Card";
import Select, { createFilter } from "react-select";
import Loader from "react-loader-spinner";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getRequest, Uri_GetFacility, getRequestQry, Uri_GetFacilityResidentWoundDocumentation } from "../../General/api";
import { GetDateString, calculatePushScore, showSuccessAlertAutoClose, exudate } from "../../General/common";
import moment from "moment";
import { Grid } from "@material-ui/core";
import flatpickr from "flatpickr";

export default function FacilityReport(props) {
  const [facilitySelected, setFacilitySelected] = useState(null);
  const [formValidated, setFormValidated] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [woundEvaluations, setWoundEvaluations] = useState();
  const [datePicker, setDatePicker] = useState(null);

  const successStatusCode = 200;
  const defaultCloseModalTimeout = 3000;

  const acquisitionEnum = {
    1: "Admitted",
    2: "Acquired",
    3: "Closed",
    4: "Reoccurring",
  }

  const datePickerRef = useCallback((node) => {
    if (node !== null) {
      setDatePicker(flatpickr(node, {
        allowInput: true,
        mode: "single",
        altInput: true,
      }));
    }
  }, []);

  useEffect(() => {
    getRequest(`${Uri_GetFacility}?isAssessmentView=1`)
      .then((res) => {
        const data = res.data;
        if (data.statusCode === successStatusCode && data.result != null && data.result.length > 0) {
          setFacilityList(data.result.map(facility => ({ value: facility.id, label: facility.facilityName })).sort((a, b) => a.label.localeCompare(b.label)));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        showSuccessAlertAutoClose('Sorry, an error has occurred', 'warning', 'warning!', defaultCloseModalTimeout)
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const dateFormatted = moment(datePicker.selectedDates[0]).format("YYYY-MM-DD");
    console.log(dateFormatted);
    getRequestQry(Uri_GetFacilityResidentWoundDocumentation, `?facilityId=${facilitySelected.value}&toDate=${dateFormatted}`)
      .then((res) => {
        const data = res.data;
        if (data.statusCode === 200) {
          if (data.result != null) {
            setWoundEvaluations(data.result);
            setShowReport(true);
          } else {
            showSuccessAlertAutoClose('Record not found', 'warning', 'warning!', defaultCloseModalTimeout);
            setShowReport(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        showSuccessAlertAutoClose('Sorry, an error has occurred', 'warning', 'warning!', defaultCloseModalTimeout)
      })
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setFacilitySelected(null);
    datePicker.clear();
    setWoundEvaluations(null);
    setShowReport(false);
    setFormValidated(false);
  }

  const renderWoundForm = () => {
    return (
      <>
        {!IsLoading ? (
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-label" for="FacilityName">
                    Facility Name
                  </label>
                  <Select
                    options={facilityList}
                    id="facilityId"
                    name="facilityId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={e => setFacilitySelected(e)}
                    value={facilitySelected}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-label" forHtml="datePicker">
                    To Date
                  </label>
                  <input type="date" id='datePicker' placeholder='Select...' required className="form-control form-control-md" ref={datePickerRef} />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end py-3">
              <Button
                variant="success"
                className="mr-5 px-10"
                size="sm"
                type="submit"
              >
                Search Report
              </Button>
              <button className="btn btn-danger btn-sm" onClick={(e) => handleClearFilters(e)}>Clear filter</button>
            </div>
          </form>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader type="Puff" color="#00BFFF" height={50} width={50} />
          </div>
        )}
      </>
    );
  }

  function PrintElem() {
    let elementProcessed = 0;
    let heightFromTop = 30;
    const facilityNameHeight = 25;
    const woundTypeHeight = 10;
    const tableHeaderHeight = 35;
    const tableDataHeight = 30;

    const sheetWidth = 785;
    const widthFromLeft = 30;
    const maxElementsPerArray = 3;
    let printElementsReady = [];

    let doc = new jsPDF('landscape', 'pt', 'a4', false);

    const printElements = Array.from(document.getElementById('printit').children);
    const maxLength = Math.ceil(printElements.length / maxElementsPerArray);
    let printElementsGroup = Array.from({ length: maxLength }, () => printElements.splice(0, 3));

    printElementsGroup.forEach((element) => {
      let tableData;
      tableData = element[2].children[0].querySelectorAll('tr');
      printElementsReady.push([element[0], element[1], ...tableData])
    })

    const canvas = document.getElementById('fixedCanvas');
    const ctx = canvas.getContext('2d', { willReadFrequently: true });

    printElementsReady = printElementsReady.flat();

    printElementsReady.forEach((item, index) => {
      html2canvas(item, { quality: 3, scale: 3 }).then(canvasItem => {
        canvas.width = 1246
        canvas.height = 300
        ctx.scale(1, 1);
        ctx.drawImage(canvasItem, 0, 0, 1246, 300);
        const imgData = canvas.toDataURL('image/png');

        if (item.classList.contains('registerTitle') && index !== 0) {
          doc.addPage();
          heightFromTop = 30;
        }

        if (item.classList.contains('registerTitle')) {
          doc.addImage(imgData, 'PNG', 24, heightFromTop, sheetWidth, facilityNameHeight, index, 'NONE');
          heightFromTop += 30;
        } else if (item.classList.contains('woundType')) {
          doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetWidth, woundTypeHeight, index, 'NONE');
          heightFromTop += 25
        } else if (item.classList.contains('tableHeader')) {
          doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetWidth, tableHeaderHeight, index, 'NONE');
          heightFromTop += 35
        } else {
          doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetWidth, tableDataHeight, index, 'NONE');
          heightFromTop += 30
        }

        elementProcessed++;

        if (elementProcessed === printElementsReady.length) {
          const pageCount = doc.internal.getNumberOfPages();
          doc.setFont('Poppins', 'normal', 'bold');
          doc.setFontSize(8);
          doc.setTextColor(112, 108, 108);
          for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text('Page ' + i + ' of ' + pageCount, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 10);
          }
          doc.save(`${facilitySelected.label} - Wound Report.pdf`);
        }
      });
    })
  }

  return (
    <>
      <Card>
        <CardHeader className="Text-center" title="Facility Wound Report">
          <CardHeaderToolbar>
            <div className="example-tools"></div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {renderWoundForm()}
          <hr />
          {showReport && (
            <>
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={PrintElem}
                    className="mr-3 btn btn-success btn-sm float-right"
                  >
                    Print Report
                  </button>
                </div>
              </div>
              <div id="printit" className="printDiv">
                {woundEvaluations?.map(
                  (woundType) => {
                    return (
                      <>
                        <Grid className="registerTitle" container spacing={3}>
                          <Grid item xs={4}>
                            <span className="font-weight-bolder">
                              Facility Name:{" "} {woundType.facilityName}
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <span className="font-weight-bolder">
                              Wound Report:{" "}
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <span className="font-weight-bolder"> Week of: {GetDateString(woundType.toDate)} </span>
                          </Grid>
                        </Grid>

                        <div className="mt-5 mb-7 woundType">
                          <p className="mb-1 fs_1_1">
                            <span className="font-weight-bolder">Wound Type: </span>{" "}
                            {woundType.woundType}
                          </p>
                        </div>
                        <div className="mt-5">
                          <table className="table table-striped table-bordered w-100">
                            <thead className="thead-light">
                              <tr className="tableHeader">
                                <th width="9%"><strong>Name</strong></th>
                                <th width="6%"><strong>Unit/Room</strong></th>
                                <th width="9%"><strong>Date First Observed</strong></th>
                                <th width="30%">
                                  <div className="facility-report_content">
                                    <div className="facility-report_rotate-text"><strong>Admitted</strong></div>
                                    <div className="facility-report_rotate-text"><strong>Acquired</strong></div>
                                    <div className="facility-report_rotate-text"><strong>Closed</strong></div>
                                    <div className="facility-report_rotate-text"><strong>Reoccurring</strong></div>
                                  </div>
                                </th>
                                <th width="9%"><strong>Location</strong></th>
                                <th width="9%"><strong>Type</strong></th>
                                <th width="1%"><strong>Length</strong></th>
                                <th width="1%"><strong>Width</strong></th>
                                <th width="1%"><strong>Depth</strong></th>
                                <th width="1%"><strong>Stage/<br />Thickness</strong></th>
                                <th width="1%"><strong>Exudate</strong></th>
                                <th width="1%"><strong>Push</strong></th>
                              </tr>
                              <tr className="tableHeader">
                                <th colSpan={4}><strong>Wound bed Peri-wound Wound Edge Description</strong></th>
                                <th colSpan={9}><strong>Treatment</strong></th>
                              </tr>
                            </thead>
                            <tbody>
                              {woundType.woundEvaluationDetail?.map((wound, index) => {
                                let exudateId;
                                switch (wound.exudateAmountName) {
                                  case 'Light':
                                    exudateId = exudate.light;
                                    break;
                                  case 'Moderate':
                                    exudateId = exudate.moderate;
                                    break;
                                  case 'Heavy':
                                    exudateId = exudate.heavy;
                                    break;
                                  default:
                                    exudateId = 0;
                                    break;
                                }

                                return (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>{wound.residentName}</td>
                                      <td>{wound.roomUnit ? wound.roomUnit : 'N/A'}</td>
                                      <td>{moment(wound.dateFirstObserved).format("YYYY-MM-DD")}</td>
                                      <td>
                                        <div className="facility-report_count-content">
                                          {wound.acquisition === acquisitionEnum[1] ? <span>1</span> : <span>0</span>}
                                          {wound.acquisition === acquisitionEnum[2] ? <span>1</span> : <span>0</span>}
                                          {wound.acquisition === acquisitionEnum[3] ? <span>1</span> : <span>0</span>}
                                          {wound.acquisition === acquisitionEnum[4] ? <span>1</span> : <span>0</span>}
                                        </div>
                                      </td>
                                      <td>{wound.woundLocation}</td>
                                      <td>{wound.woundType}</td>
                                      <td>{wound.woundLength}</td>
                                      <td>{wound.woundWidth}</td>
                                      <td>{wound.woundDepth}</td>
                                      <td>{wound.woundThicknessName}</td>
                                      <td>{wound.exudateAmountName}</td>
                                      <td>{calculatePushScore((wound.woundLength * wound.woundWidth), exudateId, wound.fkWoundSevereTissueTypeId)}</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td colSpan={3}>
                                        {wound.granulationPerc > 0 && <p className='mb-0'><span>Granulation %:</span>{wound.granulationPerc} </p>}
                                        {wound.hyperGranulationPerc > 0 && <p className='mb-0'><span>HyperGranulation %:</span>{wound.hyperGranulationPerc} </p>}
                                        {wound.sloughPerc > 0 && <p className='mb-0'><span>Slough %:</span>{wound.sloughPerc} </p>}
                                        {wound.necroticPerc > 0 && <p className='mb-0'><span>Necrotic/Eschar %:</span>{wound.necroticPerc} </p>}
                                        {wound.epithelialPerc > 0 && <p className='mb-0'><span>Epithelial %:</span>{wound.epithelialPerc} </p>}
                                        {wound.musclePerc > 0 && <p className='mb-0'><span>Muscle, Tendon or Bone %:</span>{wound.musclePerc} </p>}
                                        {wound.otherPerc > 0 && <p className='mb-0'><span>{wound.otherWoundDesc} %:</span>{wound.otherPerc} </p>}


                                        <p className='mb-0'><span>Surrounding Skin:</span> {wound.wounSurroundingSkinName}</p>
                                        {wound.wounSurroundingSkinName === "Other" &&
                                          <p className='mb-0'><span>Surrounding Skin Other:</span> {wound.otherSurroundingSkin}</p>
                                        }
                                        <p className='mb-0'><span>Wound Edges:</span> {wound.woundEdgesName}</p>
                                      </td>
                                      <td colSpan={8}>
                                        {wound.treatment}
                                      </td>
                                    </tr>
                                    <br />
                                  </React.Fragment>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )
                  }
                )
                }
              </div>
              <canvas id='fixedCanvas' width='100' height='100' style={{ display: 'none' }}></canvas>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
