export default class RBAC {
  //#region module
  // static Module_MyWorkQueue = 'WorkQueu';
  // static Module_BillingQueue = 'BillingQueue'
  // static Module_Facility = 'Facility';
  // static Module_Residents = 'Residents'
  // static Module_Products = 'Products';
  // static Module_Orders = 'Orders'
  // static Module_TabletData = 'TabletData';
  // static Module_Recap = 'Recap';
  // static Module_EHRVendors = 'EHRVendors';
  // static Module_Setup = 'Setup';
  // static Module_Brightree = 'Brightree';



  //#endregion

  //#region Facility Role
  static Role_Facility_CorporateClinician = 'Corporate Clinician';
  static Role_Facility_RegionalClinician = 'Regional Clinician'
  static Role_Facility_Level1 = 'Level I'
  static Role_Facility_Level2 = 'Level II'
  static Role_Facility_MDSCoordinator = 'MDS Coordinator'
  static Role_Facility_Surveyor = 'Surveyor'
  static Role_Facility_Physician = 'Physician'
  static Role_Facility_ReadOnly = 'Read only'
  //#endregion

  //#region Gentell Role
  static Role_Gentell_Administrator = 'Administrator';
  static Role_Gentell_RegionalManager = 'Regional Manager'
  static Role_Gentell_WoundCareSpecialist = 'Wound Care Specialist'
  static Role_Gentell_SeniorBiller = 'Senior Biller'
  static Role_Gentell_IntakeProcessor = 'Intake Processor'
  static Role_Gentell_QualityTeam = 'Quality Team'
  static Role_Gentell_Trainer = 'Trainer'

  //#endregion




}

export const ModulesName = {
  Module_MyWorkQueue: 'WorkQueue',
  Module_BillingQueue: 'BillingQueue',
  Module_Facility: 'Facility',
  Module_Residents: 'Residents',
  Module_Products: 'Products',
  Module_Orders: 'Orders',
  Module_TabletData: 'TabletData',
  Module_Recap: 'Recap',
  Module_EHRVendors: 'EHRVendors',
  Module_Setup: 'Setup',
  Module_Brightree: 'Brightree',
  Module_Facility: 'Facility',
  Module_FacilityQueue: 'FacilityQueue',

  Module_Physician: "Physician",
  Module_ApproveOrder: "ApproveOrder",
  Module_Wound: "Wound",
  Module_Reports: 'Reports',
  Module_AssessmentReports: 'Assessment Reports',


}

export const UserActions = {
  Create: "Create",
  View: "View",
  Update: "Update",
  Delete: "Delete"
}
