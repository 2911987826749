import ICDReport from '../modal/reports/icdreport';
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from "react-animated-css";
import { Badge, ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { Card, CardBody } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import CheckPermission from "../../General/CheckPermission";
import RBAC, { ModulesName } from '../../General/roleModuleActions';
import PopupModal from "../modal/popupmodal";
import WoundProducts from "../products/woundProducts";
import NewWound from './newwound';
import Loader from "react-loader-spinner";

import WoundNote from './woundnote';
import NewWoundAssessment from './newwoundassessment';
import SelectableContext from "react-bootstrap/SelectableContext";
import moment from 'moment';
import WoundInformationChangeReason from './woundInformationChangeReason';
import { FLAGS } from '../../constants/flags';

var woundAssessmentModel, woundInfoModal = null;

var woundDetailsModal = null;
var editWoundmodal = null;
var woundProductModal = null;
var woundDocumentModal = null;
var WoundNoteModal = null;
export function ResidentAsssessmentWounds(props) {
    // const ICDReportModalRef= useRef(null); 
    const [isLoading, setIsLoading] = useState(false);
    const [showWoundInfoChangeModel, setShowWoundInfoChangeModel] = useState(null);
    const [isReorderDocumentation, setIsReorder] = useState(false)
    // const [isProductShow, setIsProductShow] = useState(false);



    const { user } = useSelector((state) => state.auth);

    var _sampleWoundObj = {
        fkResidentId: props.Resident.id,
        residentName: Common.replaceNullWithString(props.Resident.firstName) + " " + Common.replaceNullWithString(props.Resident.lastName),
        facilityName: Common.replaceNullWithString(props.Resident.facilityName),
        roomUnit: Common.replaceNullWithString(props.Resident.room) + "/" + Common.replaceNullWithString(props.Resident.unit),
        evaluatedBy: Common.replaceNullWithString(user.firstName) + " " + Common.replaceNullWithString(user.lastName),
        physicanName: Common.replaceNullWithString(props.Resident.primaryPhysicianName),
        createdBy: user.userID,
        modifiedBy: user.userID,
        btPatientBrightreeID: props.Resident.btPatientBrightreeID,
        isWoundInfoChange: false,
        woundEvalutionId:null,
        wound:
        {
            id: 0,
            // evaluationDate: '',
            dateFirstObserved: '',
            // fkAcquisitionId: null,
            fkWoundLocationId: null,
            woundLocation: "",
            fkWoundTypeId: null,
            woundDescription: '',
            fkWoundThicknessId: null,
            //fkWoundSevereTissueTypeId: null,
            fkWoundLateralityId: null,
            woundLength: '',
            woundWidth: '',
            woundDepth: '',
            isUtd: false,
            isLastUtd: false,
            lastWoundArea: 0,
            tunneling: false,
            tunnelingDepth: null,
            tunnelingClockPosition: '',
            undermining: false,
            underminingDepth: null,
            underminingClockPosition: null,
            toUnderminingClockPosition:null,
            icdcode: "",
            fkExudateAmountId: null,
            fkExudateTypeId: null,
            exudateColor: '',
            fkDebridementTypeId: null,
            debridementDate: null,
            surgicalDate: null,
            odor: false,
            Pain: false,
            painMedicationOrdered: false,
            sloughPercentage: null,
            necroticPercentage: null,
            //FkWoundCleanseId :'',
            cleanseDescription: null,
            fkDressingChangeFrequencyId: null,
            //fkPrimaryDressingId :null,
            //FkAdditionalPrimaryDressingId :'',
            otherAdditionalPrimaryDressing: '',
            //fkSecondaryDressingId :null,
            //FkAdditionalSecondaryDressingId :'',
            otherAdditionalSecondaryDressing: '',
            treatment: '',
            comments: '',
            thicknessIdForBackStagging: null,
            orientationLeftRight: null,
            orientationUpperLower: null,
            orientationInnerOuter: null,
            orientationAnteriorPosterior: null,
            orientationMedialLateral: null,
            orientationDorsalDistalProximal: null,
            isDocumentation: false,
            isPartailOrder: false,
            otherWoundType: '',
            otherBodyRegion: '',
            otherSurroundingSkin: '',
            otherWoundBedDesc: '',
            otherExudateType: '',
            bedDescPercentage:0,
            fkWoundColorId:null
            // FkWoundcareProductTypeId :'',

        }
    };
    const [showAddWoundComponent, setAddWoundComponent] = useState(null);
    const [residentWoundListUI, setResidentWoundListUI] = useState([]);
    const [residentWoundDocumentationList, setResidentWoundDocumentationList] = useState([]);

    const [resident, setResident] = useState([]);
    const [residentWound, setResidentWound] = useState(null);

    useEffect(() => {
        getResidentWound();
        //setResidentListTemp();
        //Common.LoadDataTable("facilityList")
    }, []);

    //#region Set Resident List
    const getResidentWound = () => {
        setIsLoading(false);
        if (props.isPartail) {
            Api.getRequestQry(
                Api.URI_GetResidentWoundByStatusID, "?residentId=" + props.Resident.id + "&processStatusID=" + props.facility.fkProcessStatusId +
                "&isOpen=" + 1 + "&isPartailOrder=" + 1 + "&partailOrderDate=" + props.facility.cycleDate

            )

                .then((res) => {
                    setIsLoading(false);
                    if (res.data.statusCode == 200) {
                        if (res.data.result != null) {
                            if (res.data.result.length > 0) {
                                setResidentWoundList(res.data.result);

                            }
                            setIsLoading(true);
                        }
                    }
                }).catch((ex) => {
                    Common.LogError(ex, "ResidentNote", "Get");

                });

        } else {
            Api.getRequestById(Api.URI_GetResidentWoundByResidentID, props.Resident.id)

                .then((res) => {
                    setIsLoading(false);
                    if (res.data.statusCode == 200) {
                        if (res.data.result != null) {
                            const isUserAdministrator = user.roles.roleList.find(x => x.roleName === RBAC.Role_Gentell_Administrator) !== undefined;
                            const canAddBadge = [Boolean(res.data.result.length) && FLAGS.BADGE_MIGRATION && isUserAdministrator].every((x) => x === true);

                            if (canAddBadge) {
                                const residentWounds = [];
                                const residentWoundList = res?.data?.result;

                                for (const residentWound of residentWoundList) {
                                    const comments = residentWound?.wound?.comments;

                                    const regex = /\[FC1\|WD:(\d+);(\d+)\|R:(\d+);(\d+);INSERTED|MATCHED\]/g;
                                    const isMatched = regex.test(comments);

                                    if (isMatched) {
                                        const regex = /\[FC1\|WD:(\d+);(\d+)\|R:(\d+);(\d+);(INSERTED|MATCHED)\]/g;
                                        const matches = regex.exec(comments);
                                        
                                        const [_, woundDetailFC1, woundDetailFC2, residentFC2, residentFC1, residentType] = matches;

                                        const hasResidentMigration = props.residentMigration.find(x => x.residentIDFC1 === +residentFC1);

                                        residentWounds.push({
                                            ...residentWound,
                                            woundDetailFC1,
                                            woundDetailFC2,
                                            residentFC1,
                                            residentFC2,
                                            residentType,
                                            hasResidentMigration,
                                        });

                                        if (!hasResidentMigration) {
                                            props.setResidentMigration((prevState) => ([...prevState, {
                                                residentIDFC2: +residentFC2,
                                                residentIDFC1: +residentFC1,
                                                type: residentType,
                                            }]));
                                        }
                                    } else {
                                        residentWounds.push({
                                            ...residentWound,
                                        })
                                    }
                                }

                                setResidentWoundList(residentWounds);
                            } else {
                                setResidentWoundList(res.data.result);
                            }
                            setIsLoading(true);
                        }
                    }
                }).catch((ex) => {
                    Common.LogError(ex, "ResidentNote", "Get");

                });
        }
    }
    const showResidentWoundDocument = (obj) => {
        Api.getRequestQry(
            Api.URI_GetWoundHistoryByWoundID,
            "?woundId=" + obj.wound.id + "&isLatest=" + 2
        )
            .then((res) => {
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            debugger
                            setResidentWoundDocumentationList(res.data.result);
                            setResidentWound(res.data.result[0]);
                            woundDocumentModal.showModal()
                        } else {
                            Common.showSuccessAlert("Wound assessment has not been created", "warning", "Warning!", 3000);
                        }
                    }
                }

                //Common.LoadDataTable("woundList"+props.Resident.id)
            }).catch((ex) => {
                Common.LogError(ex, "ResidentWoundHistory", "Get");

            });


    }
    const handleResidentWoundStatus = (obj) => {
        let tempObj = JSON.parse(JSON.stringify(obj));
        tempObj.wound.isOpen = tempObj.wound.isOpen == true ? false : true;
        updateResidentWoundStatus(tempObj);
    };
    const updateResidentWoundStatus = (obj) => {
        var msg = "You want to " + (obj.wound.isOpen ? "Open" : "Close") + " Wound.";
        Common.showConfirmation(msg, "", updateWoundStatus, null, obj)

    };
    const updateWoundStatus = (obj) => {
        if (obj) {
            Api.postRequest(Api.apiUrls.updateResidentWoundStatus, obj).then((res) => {
                if (res.data.success) {
                    getResidentWound();
                    Common.showSuccessAlertAutoClose("Wound Status Updated Successfully", "success", "Success!", 3000)
                } else {
                    Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
                }
            });
        }
    }
    const setResidentWoundList = (list) => {

        let rows = [];
        var isReorder = false;
        try {
            for (var i = 0; i < list.length; i++) {

                const obj = list[i];
                debugger
                if (obj.wound.isReviewOnce && isReorder == false) {
                    isReorder = true;
                }

                const isUserAdministrator = user.roles.roleList.find(x => x.roleName === RBAC.Role_Gentell_Administrator) !== undefined;
                const canAddBadge = [Boolean(obj?.woundDetailFC1) && Boolean(obj.woundDetailFC2) && FLAGS.BADGE_MIGRATION && isUserAdministrator].every((x) => x === true);

                rows.push(
                    <React.Fragment key={i}>
                        <tr>
                            <th>Num</th>
                            <th>Body Region/Wound Location</th>
                            <th>{obj.wound.fkWoundTypeId == Common.WoundType.pressure ? 'Stage/Thickness'
                                : obj.wound.fkWoundTypeId == Common.WoundType.Surgical || obj.wound.fkWoundTypeId == Common.WoundType.tubeSize ? 'Stage/Thickness' : 'Thickness/Most Sever Tissue'}</th>
                       
                            <th>Wound Size</th>
                            <th>Phush Score</th>

                            {/* <th>{obj.wound.isClinicianReviewRequired == true ? "Last Assessment Date" : "Evaluated on"}</th> */}
                            <th>Evaluated On</th>
                            <th>Evaluated By</th>
                            <th>Last Assessment Date</th>
                            <th>Actions</th>
                        </tr>
                        <tr key={i.toString()}>
                            <td className="justify-content-center"> 
                         {canAddBadge ? <Badge className="mr-3" variant="danger">FC 1.0</Badge> : null}
                         {obj.wound.isOpen ? <Badge className="mr-3" variant="success">Open</Badge> : <Badge className="mr-3" variant="danger">Closed</Badge>}
                        <b>{i + 1}</b> </td>
                            <td><button className="btn btn-link" 
                    onClick={() =>
                        handleNewWoundDocumentModal(obj)

                }
                // onClick={() =>
                //     user.roles.roleList.find(x => x.roleName == RBAC.Role_Facility_Level1 || x.roleName == RBAC.Role_Facility_Level2) != undefined ?
                //         handleNewWoundDocumentModal(obj)
                //         :
                //         handleWoundAssessmentModal(obj)

                // }
                >
                                {obj.wound.orientationLeftRightName == null || obj.wound.orientationLeftRightName == "" ? "" : obj.wound.orientationLeftRightName + " - "} {Common.replaceNullWithString(obj.wound.bodyRegion)}{obj.residentWoundLocation != null ? "/" : ""}{Common.replaceNullWithString(obj.residentWoundLocation)}
                                {obj.wound.orientation == null || obj.wound.orientation == "" ? "" : " - " + obj.wound.orientation}
                            </button></td>
                            <td>
                                {Common.replaceNullWithString(obj.wound.woundThicknessName?.replace('IV', '4')?.replace('III', '3')?.replace('II', '2'))}
                                {obj.wound.fkWoundTypeId == Common.WoundType.pressure ||
                                    obj.wound.fkWoundTypeId == Common.WoundType.Surgical
                                    || obj.wound.fkWoundTypeId == Common.WoundType.tubeSize ? ''
                                    : obj.wound.woundThicknessName != null && obj.wound.woundSevereTissueName != null ?
                                        " - " + (Common.replaceNullWithString(obj.wound.woundSevereTissueName)) : ""}



                            </td>
                            <td>{`${obj.wound.woundLength} x ${obj.wound.woundWidth} x ${obj.wound.woundDepth} ${obj.wound.isUtd ? "U" : ""}`}</td>
                            <td>{Common.calculatePushScore(obj.wound.woundLength*obj.wound.woundWidth,obj.wound.fkExudateAmountId,obj.wound.fkWoundSevereTissueTypeId)}</td>
                            <td>{Common.replaceNullWithString(obj.wound.evaluationDate) !== '' ? Common.getInputStringDateUsingMoment2(moment.utc(obj.wound.evaluationDate).local()) : 'N/A'}</td>
                            <td>{Common.replaceNullWithString(obj.evaluatedBy) !== '' && Common.replaceNullWithString(obj.wound.evaluationDate) !== '' ? obj.evaluatedBy : 'N/A'}</td>
                            <td>{obj.wound.isClinicianReviewRequired ? Common.replaceNullWithString(obj.wound.evaluationDate) !== '' ? Common.getInputStringDateUsingMoment2(moment.utc(obj.wound.evaluationDate).local()) : 'N/A' : 'N/A'}</td>

                            <td align="center">
                                <>
                                    {!props.isProcessor &&
                                        <SelectableContext.Provider value={false}>
                                            <ButtonToolbar className="justify-content-center">
                                                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                                                    {[DropdownButton].map((DropdownType, idx) => (
                                                        <DropdownType
                                                            size="xs"
                                                            variant="primary"
                                                            title="Actions"
                                                            id={`dropdown-button-drop-${idx}`}
                                                            key={idx}
                                                        >



                                                            {/* <Dropdown.Item eventKey="2" onClick={() => { this.edtitResidentWound(obj) }}>Edit Wound</Dropdown.Item> */}
                                                            {props.facility.canAddAssessment &&
                                                                <>
                                                                    <Dropdown.Item eventKey="4" onClick={() => { handleNewWoundDocumentModal(obj) }}>Latest Assessment</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="0" onClick={() => handleLatestDocumentModal(obj)}>Create New Assessment</Dropdown.Item>
                                                                    <CheckPermission
                                                                        userRole={[
                                                                            RBAC.Role_Gentell_Administrator]}

                                                                        yes={() => (
                                                                            <>
                                                                               <Dropdown.Item eventKey="8" onClick={() => handleWoundInfoChangeModal(obj)}>Amend Wound Assessment</Dropdown.Item>
                                                                            </>
                                                                        )}
                                                                        no={() => null}
                                                                    />
                                                                  
                                                                </>
                                                            }
                                                            <Dropdown.Item eventKey="8" className="text-danger" onClick={() => handleResidentWoundStatus(obj)}>{obj.wound.isOpen ? 'Close Wound' : 'Open Wound'}</Dropdown.Item>




                                                        </DropdownType>
                                                    ))}
                                                </div>
                                            </ButtonToolbar>
                                        </SelectableContext.Provider>
                                    }

                                </>
                            </td>
                        </tr>
                    </React.Fragment>
                );
            }
            setResidentWoundListUI([]);
            setResidentWoundListUI(rows);
            setIsLoading(true);
            setIsReorder(isReorder);

        } catch (error) {
            Common.LogError(error, "WorkqueueWounds", "SetWoundList");
        }
    }
    const getLastWoundDetailByWoundId = async (obj, isGetInfo = false) => {
        var { data } = await  Api.getRequestQry(
            Api.URI_GetWoundHistoryByWoundID,
            "?woundId=" + obj.wound.id + "&isLatest=" + 1
        )
           
                if (data.statusCode == 200) {
                    if (data.result != null) {
                        debugger
                        var woundDetail;
                        if (data.result.length > 0) {

                            woundDetail = data.result[0];
                            if (isGetInfo) {
                                return woundDetail
                            } else {
                                handleWoundAssessmentModal(woundDetail)
                            }
                        }else {
                            if (isGetInfo) {
                                return null
                            } else {
                                Common.showSuccessAlert("Last Wound evaluation is not available", "warning", "Warning!", 3000);
        
                            }
        
                        }
                      
                     

                    }
                }

    }
    const handleLatestDocumentModal = async (obj) => {
        debugger


        Api.getRequestQry(
            Api.URI_GetWoundHistoryByWoundID,
            "?woundId=" + obj.wound.id + "&isLatest=" + 1
        )
            .then((res) => {
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        debugger
                        var woundDetail;
                        if (res.data.result.length > 0) {

                            woundDetail = res.data.result[0];
                        }
                        let tempObj = JSON.parse(JSON.stringify(_sampleWoundObj));
                        if (woundDetail != undefined) {
                            tempObj.wound.id = obj.wound.id;
                            tempObj.wound.fkWoundTypeId = woundDetail.wound.fkWoundTypeId;
                            tempObj.wound.fkWoundSevereTissueTypeId = woundDetail.wound.fkWoundSevereTissueTypeId;
                            tempObj.wound.fkWoundThicknessId = woundDetail.wound.fkWoundThicknessId;
                            tempObj.wound.thicknessIdForBackStagging = woundDetail.wound.thicknessIdForBackStagging;
                            tempObj.wound.fkBodyRegionId = woundDetail.wound.fkBodyRegionId;
                            tempObj.wound.fkWoundLocationId = woundDetail.wound.fkWoundLocationId;
                            tempObj.wound.dateFirstObserved = woundDetail.wound.dateFirstObserved;
                            tempObj.wound.fkAcquisitionId = woundDetail.wound.fkAcquisitionId;

                            

                            tempObj.wound.orientationLeftRight = woundDetail.wound.orientationLeftRight;
                            tempObj.wound.orientationUpperLower = woundDetail.wound.orientationUpperLower;
                            tempObj.wound.orientationInnerOuter = woundDetail.wound.orientationInnerOuter;
                            tempObj.wound.orientationAnteriorPosterior = woundDetail.wound.orientationAnteriorPosterior;
                            tempObj.wound.orientationMedialLateral = woundDetail.wound.orientationMedialLateral;
                            tempObj.wound.orientationDorsalDistalProximal = woundDetail.wound.orientationDorsalDistalProximal;
                        } else {
                            tempObj.wound.id = obj.wound.id;
                            tempObj.wound.fkWoundTypeId = obj.wound.fkWoundTypeId;
                            tempObj.wound.fkWoundSevereTissueTypeId = obj.wound.fkWoundSevereTissueTypeId;
                            tempObj.wound.fkWoundThicknessId = obj.wound.fkWoundThicknessId;
                            tempObj.wound.thicknessIdForBackStagging = obj.wound.thicknessIdForBackStagging;
                            tempObj.wound.fkBodyRegionId = obj.wound.fkBodyRegionId;
                            tempObj.wound.fkWoundLocationId = obj.wound.fkWoundLocationId;
                            tempObj.wound.dateFirstObserved = obj.wound.dateFirstObserved;
                            tempObj.wound.fkAcquisitionId = obj.wound.fkAcquisitionId;

                            

                            tempObj.wound.orientationLeftRight = obj.wound.orientationLeftRight;
                            tempObj.wound.orientationUpperLower = obj.wound.orientationUpperLower;
                            tempObj.wound.orientationInnerOuter = obj.wound.orientationInnerOuter;
                            tempObj.wound.orientationAnteriorPosterior = obj.wound.orientationAnteriorPosterior;
                            tempObj.wound.orientationMedialLateral = obj.wound.orientationMedialLateral;
                            tempObj.wound.orientationDorsalDistalProximal = obj.wound.orientationDorsalDistalProximal;
                        }
                        tempObj.wound.woundDetailId = 0;
                        tempObj.wound.evaluationDate = new Date();
                        tempObj.wound.isDocumentation = true;
                        tempObj.wound.isLastUtd = tempObj.wound.isUtd;
                        if (tempObj.wound.isUtd) {
                            tempObj.wound.lastWoundArea = tempObj.wound.woundLength * tempObj.wound.woundWidth;
                        } else {
                            tempObj.wound.lastWoundArea = tempObj.wound.woundLength * tempObj.wound.woundWidth * tempObj.wound.woundDepth;
                        }

                        // await setResidentWound(tempObj);
                        setAddWoundComponent(<NewWoundAssessment isEdit={true} canView={true}
                            user={user}
                            isReorder={isReorderDocumentation ? true : obj.wound.isReorder}
                            resident={tempObj} close={handleCloseWoundAssessmentmodalPopup} />)
                        woundAssessmentModel.showModal()

                    }
                }

                //Common.LoadDataTable("woundList"+props.Resident.id)
            }).catch((ex) => {
                Common.LogError(ex, "ResidentWoundHistory", "Get");

            });
    }
    const handleWoundInfoChangeModal = async (obj) => {
        var result = await getLastWoundDetailByWoundId(obj, true)
        if (result != null) {
            await setShowWoundInfoChangeModel(<WoundInformationChangeReason
                isDocumentation={true}
                woundEvalutionId={obj.wound.woundDetailId}
                msg={null} woundInfo={result} close={handleCloseWoundInfoModal}
                 showWound={handleNewWoundDocumentModal} />)
            woundInfoModal.showModal()
        } else {

            Common.showSuccessAlert("Last Wound evaluation is not available", "warning", "Warning!", 3000);

        }

    }
    const handleCloseWoundInfoModal = () => {
        woundInfoModal.hideModal()
    }
    const handleNewWoundDocumentModal = async (obj, isInfoChange = false, isAssessmentView = false,woundEvalutionId=null) => {
        debugger
        let tempObj = JSON.parse(JSON.stringify(_sampleWoundObj));
        if (obj != null) {
            tempObj.wound = obj.wound;
        } else {
            tempObj.wound.isDocumentation = true;
            tempObj.wound.evaluationDate =  new Date();
        }
        if (isInfoChange) {
            tempObj.isWoundInfoChange = true;
            tempObj.wound.isDocumentation = isAssessmentView;

        }
        if(woundEvalutionId>0){
            tempObj.woundEvalutionId= woundEvalutionId;
        }
        await setAddWoundComponent(<NewWoundAssessment isEdit={obj != null ? true : false}
             canView={true}
            user={user}
            isReorder={obj != null ? obj.wound.isReorder : false}
            resident={tempObj} close={handleCloseWoundAssessmentmodalPopup} />)
        woundAssessmentModel.showModal()

    }

    const handleNewWoundModal = async (obj) => {
        if (props.Resident.fkResidentStatusId == Common.ResidentStatusID.open) {
            if (obj != null) {
                await setAddWoundComponent(<NewWound refresh={props.refresh} isEdit={true} isReorder={false}
                    showProduct={false}
                    isReadOnly={false}
                    displayProduct={false}
                    user={user} resident={obj} close={handleCloseWoundmodalPopup} />)
            }
            else {
                await setAddWoundComponent(<NewWound refresh={props.refresh} isEdit={false} isReorder={false}
                    showProduct={false}
                    isReadOnly={false}
                    displayProduct={true}

                    user={user} resident={_sampleWoundObj} close={handleCloseWoundmodalPopup} />)
            }
            editWoundmodal.showModal()
        } else {
            Common.showSuccessAlert("Resident is not open please update resident status to add/update new wound", "warning", "Warning!", 3000);

        }
    }
    const handleWoundAssessmentModal = async (obj) => {
        if (props.Resident.fkResidentStatusId == Common.ResidentStatusID.open) {

            let tempObj = JSON.parse(JSON.stringify(_sampleWoundObj));
            tempObj.wound = obj.wound;
            await setAddWoundComponent(<NewWound refresh={props.refresh} isEdit={true}
                isReorder={obj.wound.isReorder}
                showProduct={false}
                isReadOnly={props.isProcessor ? true : false}
                displayProduct={false}
                user={user} resident={tempObj} close={handleCloseWoundmodalPopup} />)
            editWoundmodal.showModal()
        } else {
            Common.showSuccessAlert("Resident is not open please update resident status to add new wound evaluation", "warning", "Warning!", 3000);

        }
    }

    const handleCloseWoundmodalPopup = () => {
        try {
            editWoundmodal.hideModal();
        } catch (error) {
            Common.LogError(error, "WorkqueueWounds", "handleNewWoundModal");
        }
    }
    const handleCloseWoundAssessmentmodalPopup = () => {
        try {
            woundAssessmentModel.hideModal();
        } catch (error) {
            Common.LogError(error, "WorkqueueWounds", "handleNewWoundAsessmentModal");
        }
    }
    return (
        <>


            {/* <PopupModal title="Wound Products (Resident: Jon white, Facility: Abrazo Central Campus)" size="xl" showButton={false} ref={ref => { woundProductModal = ref; }} > <WoundProducts />  </PopupModal> */}
            <PopupModal onClose={(ref) => {
                Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning', () => { ref.setState({ show: false }); }, () => { }, null);

            }}
                // title="Wound Evaluation" 
                title={<div className="card-title">
                    <span>
                        <h3 className="card-label">{user.roles.roleList.find(x => x.roleName == RBAC.Role_Facility_Level1 || x.roleName == RBAC.Role_Facility_Level2) != undefined ? "Add/Edit Wound" : "Wound Evaluation "} - First Name:{" "} {Common.replaceNullWithString(props.Resident.firstName)},{" "}Last Name: {Common.replaceNullWithString(props.Resident.lastName)}</h3>

                    </span>
                </div>}
                dialogClassName="modal-95w" ref={ref => { editWoundmodal = ref; }}> {showAddWoundComponent} </PopupModal>
            {/** Wound Note Modal */}
            <PopupModal title="Add Wound Note" size="lg" showButton={true} ref={ref => { WoundNoteModal = ref; }}> <WoundNote /> </PopupModal>
            <PopupModal 
            title={<div className="card-title">

            <span>
                <h3 className="card-label">Wound Assessment - First Name:{" "}
                    {Common.replaceNullWithString(props.Resident.firstName)},{" "}Last Name: {Common.replaceNullWithString(props.Resident.lastName)}</h3>

            </span>
        </div>}
            dialogClassName="modal-95w" ref={ref => { woundAssessmentModel = ref; }}> {showAddWoundComponent} </PopupModal>
            <PopupModal
                title="Amend Wound Information Reason"
                showButton={false}
                ref={(ref) => {
                    woundInfoModal = ref;
                }}
            >
                {" "}

                {showWoundInfoChangeModel}
                {" "}
            </PopupModal>
            {/** Wound Detail Modal */}
            <PopupModal
                title={<div className="card-titlec" dialogClassName="modal-95w">
                    <span>
                        <h3 className="card-label">Resident Wound Assessment</h3>
                        <small className="">
                            <b>Resident:</b>{" "} {resident.residentName},{" "}
                            <b>Facility:</b> {resident.facilityName},{" "}
                            <b>Chain:</b>{" "} {resident.chain},{" "}
                            <b>Group:</b>{" "} {resident.organizationName},{" "}

                        </small>
                    </span>
                </div>}
                showButton={false} ref={ref => { woundDocumentModal = ref; }}>
                <ICDReport user={props.user} residentWound={residentWound} residentWoundDocumentationList={residentWoundDocumentationList} isReorder={isReorderDocumentation} /> </PopupModal>
            <Animated animationIn="fadeIn" animationInDuration={2000} >
                <div className="d-flex justify-content-end">
                    {/* <button className="btn btn-success  btn-xs mr-5 mb-3" size="sm" onClick={() => handleNewWoundModal(null)}>Add New Wound Assessment</button> */}

                    {(!props.isPartail && !props.isProcessor) &&
                        <CheckPermission
                            userRole={[RBAC.Role_Facility_Level1, RBAC.Role_Facility_Level2]}

                            yes={() => (
                                <button className="btn btn-primary btn-xs mr-5 mb-3" size="sm" onClick={() => handleNewWoundDocumentModal(null)}>Add New  Wound</button>
                            )}
                            no={() => <button className="btn btn-primary btn-xs mr-5 mb-3" size="sm" onClick={() => handleNewWoundModal(null)}>Add New Wound</button>}
                        />


                    }
                    {/* <button className="btn btn-info btn-xs mr-5 mb-3" size="sm" onClick={() =>{ getResidentWound(true)}}>Show Product</button> */}

                </div>
                <table className="table table-striped table-hover table-sm table-bordered" id={"woundList" + props.Resident.id}>
                    {/* <thead>
                        <tr>
                            <th>Wound#</th>
                            <th>Body Region/Wound Location</th>
                            <th>Wound Type/Stage</th>
                            <th>ICD10 Code</th>
                            <th>Status</th>
                            <th>Evaluated on</th>
                            <th>Evaluated By</th>
                            <th>Actions</th>
                        </tr>
                
                    </thead> */}
                    <tbody>


                        {(isLoading == true) ? residentWoundListUI :
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td> <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                /></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                        {/* {rows} */}

                    </tbody>
                </table>

            </Animated>

        </>
    );
}


export default ResidentAsssessmentWounds;