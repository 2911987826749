import { extend } from 'jquery';
import React, { useCallback, useEffect, useState } from 'react';
import { Animated } from "react-animated-css";
import { Button, Badge, ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import AddTracheostomy from './addTracheostomy';
import PopupModal from "../modal/popupmodal";
var addNewTracheostomyModal = null;
export default class TracheostomyList extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      addEditTracheostomyUI:'',
      tracheostomy:
      {
        tracheostomyId: 0,
        fkResidentId: props.resident.id,
        diagnosisCode: null,
        surgeryDate: null,
        isNewTracheostomy: false,
        isEstablishedTracheostomy: false,
        isCuffed: false,
        isFrenestrated: false,
        isTracheostomyEstablishedTrach31mo: false,
        isTracheostomycollar30mo: false,
        itemNumber: null,
        trachTubeSize: null,
        trachTubeItemNumber: null,
        innerCannulasSize: null,
        innerCannulasItemNumber: null,
        tracheostomyType: ''
      },
      rows: [],
      diagCodes: ["Colostomy", "Urostomy", "lleostomy"]

    }
    
  }
  componentDidMount() {

    this.GetResidentTracheostomy();
  }
  GetResidentTracheostomy = () => {
    debugger

    // Api.getRequestById(Api.URI_GetTracheostomySaleOrderByResidentId, this.props.resident.id)
    Api.getRequestQry(Api.URI_GetTracheostomySaleOrderByResidentId, "?residentId=" + this.props.resident.id + "&processStatusID=" + this.props.processStatusID)
      .then((res) => {
        debugger
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setTracheostomyLstUI(res.data.result);
            }
          }
        }

        Common.LoadDataTable("woundList")
      }).catch((ex) => {
        // Common.LogError(error, "Wound", "GetResidentWound");
      });
  }
  processSaleOrder = (saleOrderID) => {
    debugger
    // Api.getRequestById(Api.URI_ProcessTracheostomySaleOrderById, saleOrderID)
    Api.getRequestQry(Api.URI_ProcessTracheostomySaleOrderById, "?saleOrderID=" + saleOrderID + "&processStatusID=" + this.props.processStatusID)
      .then((res) => {

        if (res.data.statusCode == 200) {
          if (res.data.result > 0) {
            Common.showSuccessAlertAutoClose("Tracheostomy Order processed Successfully", "success", "Success!", 3000);
          }
          this.GetResidentTracheostomy();
        }

        Common.LoadDataTable("woundList")
      }).catch((ex) => {
        // Common.LogError(error, "Wound", "GetResidentWound");
      });
  }
  setTracheostomyLstUI = (list) => {
    let rows = [];

    for (var i = 0; i < list.length; i++) {
      const obj = list[i];
      if (obj.brightreeKey == null) {
        rows.push(
          <tr key={i.toString()}>
            <td className="justify-content-center"> <b>{i + 1}</b> </td>
            <td>{obj.isCuffed == true ? <Badge variant="info">Cuffed</Badge> : <Badge variant="info">Un-Cuffed</Badge>} </td>
            <td>{obj.diagnosisCode}</td>
            <td>{obj.trachTubeSize}</td>
            <td>{Common.replaceNullWithString(obj.surgeryDate) != '' ? new Date(obj.surgeryDate).toDateString() : 'N/A'}</td>
            <td align="center">
              <>
                <ButtonToolbar className="justify-content-center">
                {this.props.processStatusID ==Common.ProcessStatus.workQueueID &&
                  <div className="mr-md-4 mr-0 mb-1 mb-md-0">

                    <Button
                      size="xs"
                      variant="success"
                      onClick={() => this.processSaleOrder(obj.tracheostomyId)}
                    >
                      Release
                    </Button>

                  </div>
      }
                  <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                    {[DropdownButton].map((DropdownType, idx) => (
                      <DropdownType
                        size="xs"
                        variant="primary"
                        title="Actions"
                        id={`dropdown-button-drop-${idx}`}
                        key={idx}
                      >
                        <Dropdown.Item onClick={() => { this.handleShowEditOstomyModal(obj) }} eventKey="1" >Edit Tracheostomy</Dropdown.Item>
                      </DropdownType>
                    ))}
                  </div>
                </ButtonToolbar>
              </>
            </td>
          </tr>
        );
      }
    }
    this.setState({ rows: rows });
  }



  handleCloseAdd = () => {
    addNewTracheostomyModal.hideModal();
    this.GetResidentTracheostomy();

  };

  // handleShowEditOstomyModal = () => {
  //   //addNewOstomyModal.showModal();
  // }
  // handleShowAddNewOstomyModal = (resName) => {
  //   // setResidentName(resName);
  //   this.addNewOstomyModal.showModal();
  // }
  handleShowEditOstomyModal = (obj) => {
    
    this.setState({addEditTracheostomyUI:<AddTracheostomy tracheostomy={obj} residentID={this.props.resident.id} close={this.handleCloseAdd} />})
    addNewTracheostomyModal.showModal();
  }
  handleShowAddNewOstomyModal = () => {

    this.setState({addEditTracheostomyUI:<AddTracheostomy tracheostomy={this.state.tracheostomy} residentID={this.props.resident.id} close={this.handleCloseAdd} />})
    addNewTracheostomyModal.showModal();
  }
  render() {
    return (
      <>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary btn-xs mr-5 mb-3" size="sm" onClick={() => { this.handleShowAddNewOstomyModal() }} >Add New Tracheostomy</button>
        </div>
        <Animated animationIn="fadeIn" animationInDuration={2000} >
          {/* <div className="d-flex justify-content-end">
          <button className="btn btn-primary btn-xs mr-5 mb-3" size="sm" onClick={handleShowAddNewOstomyModal} >Add New Tracheostomy</button>
        </div> */}
          <table className="table table-striped table-hover table-sm table-bordered" id={"tracheostomyList"}>
            <thead>
              <tr>
                <th>Tracheostomy#</th>
                <th>Is Cuffed</th>
                <th>Diagnosis Code (ICD10)</th>
                <th>Trach Tube Size</th>
                <th>Surgery Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.rows}
            </tbody>
          </table>
        </Animated>
        {
          <PopupModal title={
            <div className="">
              <span>
                <h3 className="card-label">Tracheostomy Intake</h3>
                <small className="">
                  {/* <b>Facility:</b> {props.facility.facilityName},{" "}
                <b>Chain:</b>{" "} {props.facility.chain},{" "}
                <b>Group:</b>{" "} {props.facility.organizationName},{" "}
                <b>Resident:</b>{" "} {residentName} */}
                </small>
              </span>
            </div>
          } dialogClassName="modal-80w" ref={ref => { addNewTracheostomyModal = ref; }}>
            <Animated animationIn="fadeIn" animationInDuration={2000} >
              {this.state.addEditTracheostomyUI}
            </Animated>
          </PopupModal>
        }
      </>

    );
  }
}
