import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import EditDropdownValueForm from './editDropdownValueForm';
import PopupModal from '../../modal/popupmodal';
import * as Common from "../../../General/common";

var editDropdownModal = null;
var addDropdown = null;
class DropdownValueTable extends Component {
    constructor(props) {
        super(props);

    }
    handleEdit = () =>{
        this.props.showEditPopup(this.props.obj);
      }
    render() {
        return (
            <>
                <tr>
                    <td>
                        {this.props.obj.valueText}
                    </td>
                    <td>
                        {this.props.obj.valueAlias}
                    </td>
                    <td>
                        {this.props.obj.active == true ? <span className='badge badge-success'>Active</span> : <span className='badge badge-danger'>In-Active</span>}
                    </td>
                    <td align="center">
                    {/* onClick={() => this.openModel(this.props.obj)} */}
                        <Button variant="primary" size="sm"   onClick={ this.handleEdit }>
                            Edit
                                </Button>
                    </td>
                </tr>
                {/* <PopupModal size="lg" title="Dropdown Value" buttonPress={() => addDropdown.onSubmit()} ref={ref => { editDropdownModal = ref; }}> <EditDropdownValueForm dropdownValueObject={this.state.dropdownValueObject} ref={ref => { addDropdown = ref; }} onUpdateAPI={()=>this.onDropDownFormUpdate()}  /> </PopupModal> */}
                {/* <PopupModal size="lg" title="Dropdown Value" buttonPress={() => addDropdown.onSubmit()} ref={ref => { addDropdownModal = ref; }}> <AddDropdownValueForm headerID={this.props.headerID} ref={ref => { addDropdown = ref; }} onSaveAPI={()=>this.onDropDownFormSubmit()} /> </PopupModal> */}
            </>
        );
    }

}

export default DropdownValueTable;  