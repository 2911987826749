import React  from "react";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";

import Resident from "./Resident";
import SaleOrder from "./SaleOrder";
import Note from "./Note";
import Insurance from "./Insurance";
import physician from "./physician";

import RoutePaths from "../../constants/routepaths";

export default function SetupBrightree() {
  return (
    <Switch>
        
        <ContentRoute from={RoutePaths.Page_ResidentBrigthree} component={Resident} />
        <ContentRoute from={RoutePaths.Page_SaleOrder} component={SaleOrder} />
        <ContentRoute from={RoutePaths.Page_Note} component={Note} />
        <ContentRoute from={RoutePaths.Page_Insurance} component={Insurance} />
        <ContentRoute from={RoutePaths.Page_Physician} component={physician} />

   
       
    </Switch>
  );
}