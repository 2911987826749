export const FACILITIES = [
  {
    id: 1750,
    name: 'Tomball Rehab & Nursing (TX) **AWS**'
  },
  {
    id: 2151,
    name: 'Heritage House At Paris (TX) **AWS**'
  },
  {
    id: 2136,
    name: 'Greenville Health & Rehab (TX) **AWS**'
  },
  {
    id: 1830,
    name: 'Henderson Health & Rehab Center (TX) **AWS**'
  },
  {
    id: 2165,
    name: 'Colonial Manor (Pharr)'
  },
  {
    id: 1838,
    name: 'Winfield Healthcare (TX) **AWS**'
  },
  {
    id: 1963,
    name: 'Heritage House Keller (TX) **AWS**'
  },
  {
    id: 1945,
    name: 'Prairie House Living Center (TX) **AWS**'
  },
  {
    id: 1801,
    name: 'Wedgewood Nursing Home (TX) **AWS**'
  },
  {
    id: 1800,
    name: 'White Settlement Nursing Center (TX) **AWS**'
  },
  {
    id: 2203,
    name: 'Seymour Rehab & Healthcare (TX) **AWS**'
  },
  {
    id: 1933,
    name: 'Park View Care Center (TX) **AWS**'
  },
  {
    id: 2140,
    name: 'Balch Springs Nursing Home (TX) **AWS**'
  },
  {
    id: 2152,
    name: 'Clarksville Nursing Center (TX)'
  },
  {
    id: 1930,
    name: 'Clyde Nursing Home (TX) **AWS**'
  },
  {
    id: 1686,
    name: 'Whispering Oaks Rehab & Nursing (TX) **AWS**'
  },
  {
    id: 1978,
    name: 'Crowell Nursing Center (TX) **AWS**'
  },
  {
    id: 3413,
    name: 'Obion County Nursing Home'
  },
  {
    id: 1835,
    name: 'Advanced Rehab and Nursing of Athens (TX)'
  },
  {
    id: 1839,
    name: 'Whitehall Nursing Center (TX) **AWS**'
  },
  {
    id: 2204,
    name: 'Palo Pinto Nursing Center (TX) **AWS**'
  },
  {
    id: 1977,
    name: 'Advanced Rehab & HC of Bowie (TX) **AWS**'
  },
  {
    id: 3281,
    name: 'Resthave Care and Rehab (IL)'
  },
  {
    id: 1958,
    name: 'Southeast Nursing & Rehab (TX) **AWS**'
  },
  {
    id: 1931,
    name: 'Windcrest Health & Rehabilitation'
  },
  {
    id: 1960,
    name: 'Brady West R&N (TX) **AWS**'
  },
  {
    id: 2021,
    name: 'Granbury Nursing & Rehab (TX) **AWS**'
  },
  {
    id: 1954,
    name: 'Colonial Manor - Cleburne (TX) **AWS**'
  },
  {
    id: 1956,
    name: 'Advanced Rehab & HC of Live Oak (TX) **AWS**'
  },
  {
    id: 3452,
    name: 'Aspire at Bradenton (FL) (old Bay Vue)'
  },
  {
    id: 2759,
    name: 'Edwardsville Nursing & Rehabilitation Center (IL)'
  },
  {
    id: 2168,
    name: 'Renaissance at Kessler Park (TX) **AWS**'
  },
  {
    id: 3905,
    name: 'Advanced Healthcare of Burleson'
  },
  {
    id: 2141,
    name: 'Mesquite Tree Nursing Center (TX) **AWS**'
  },
  {
    id: 471,
    name: 'Sharon Health Care Elms'
  },
  {
    id: 2195,
    name: 'Santa Fe Health & Rehab (TX) **AWS**'
  },
  {
    id: 2205,
    name: 'Advanced Rehab & HC of Vernon (TX) **AWS**'
  },
  {
    id: 5938,
    name: 'Cherokee Trails Rehab & Healthcare (TX)'
  },
  {
    id: 2207,
    name: 'Advanced H&R of Wichita Falls (TX) **AWS**'
  },
  {
    id: 2134,
    name: 'Willows of Kilgore'
  },
  {
    id: 6124,
    name: 'Willow Springs Health & Rehab (TX)'
  },
  {
    id: 2137,
    name: 'Sulphur Springs Health & Rehab (TX) **AWS**'
  },
  {
    id: 2827,
    name: 'Bender Terrace'
  },
  {
    id: 1748,
    name: 'Lakes at Texas City (TX) **AWS**'
  },
  {
    id: 2721,
    name: 'University Care Center (IL)'
  },
  {
    id: 1746,
    name: 'Rosenberg Health & Rehab Center (TX) **AWS**'
  },
  {
    id: 2153,
    name: 'Advanced Rehab of Garland (TX) **AWS**'
  }
]