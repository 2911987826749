import React from "react";
import { Fragment, useState, useEffect } from "react";
import * as Api from "../../General/api";
import {
  Form,
  Modal,
  Button
} from "react-bootstrap";

import * as Common from "../../General/common";
//formik
import * as Yup from "yup";
import { useFormik } from "formik";

const AddEditPhysician = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isChangeDetect, setChangeDetect] = useState(false);

  const Schema = Yup.object().shape({
    firstName: Yup.string()
    .max(50, "Maximum limit (50 characters)")
    .typeError("Invalid Value")
    .matches(/^[A-Za-z ]*$/, 'Please enter valid first name')
    .required("First Name is required"),
    lastName: Yup.string()
    .max(50, "Maximum limit (50 characters)")
    .typeError("Invalid Value")
    .matches(/^[A-Za-z ]*$/, 'Please enter valid last name')
    .required("Last Name is required"),
    npi: Yup.string()
    .min(10, "Minimum limit 10 numbers")
    .max(10, "Maximum limit 10 numbers")
    .typeError("Invalid Value")
    .required("NPI is required"),
  });

  const formik = useFormik({
    initialValues: {
      physicianId: 0,
      firstName: "",
      lastName: "",
      npi: "",
    },
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleValidSubmit(values);
      setSubmitting(false);
    },
  });

  const handleValidSubmit = async (values) => {
    debugger
    setLoading(true);
    values.npi= values.npi.toString()
    Api.postRequest(Api.URI_UpsertPhysician, values)    
      .then((res) => {
        debugger
        if (res.data.success) {
            Common.showSuccessAlert(
              "Physician created successfully",
              "success",
              "Success!",
              3000
            );
            
           props.onSaveAPI({physicianId:res.data.result,npi:values.npi,
             lastName:values.lastName, firstName:values.firstName});
           props.toggleModal()
        } else {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleCloseModal =()=>{
    if(isChangeDetect){
      Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
              () => props.toggleModal(), () => { }, null, "Exit Without Saving");
    }else{
      props.toggleModal()
    }

  }
  const handleChangeDetect=()=>{
    setChangeDetect(true)
  }

  return (
    <Fragment>
      <Modal

        size="lg"
        aria-labelledby="showModalContacttList"
        dialogClassName="modal-90w"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
        show={props.open}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <span className="align-middle">New Physician</span>
        </Modal.Header>
        <Modal.Body className="py-2">
          <Form>
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <label className="mb-50" htmlFor="urn-type">
                  Npi<span className="text-danger ms-50">*</span>
                </label>
                <input
                  type="number"
                  id="npi"
                  name="npi"
                  onChange={e=> {formik.handleChange(e); handleChangeDetect()}}
                  onBlur={formik.handleBlur}
                  value={formik.values.npi}
                  className="form-control form-control-sm"
                />
                  
                {formik.touched.npi && formik.errors.npi ? (
                  <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.npi}
                  </div>
                ) : null}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <label className="mb-50" htmlFor="urn-stle">
                  First Name<span className="text-danger ms-50">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  onChange={e=> {formik.handleChange(e); handleChangeDetect()}}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  className="form-control form-control-sm"
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </div>

              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <label className="mb-50" htmlFor="price_single">
                Last Name<span className="text-danger ms-50">*</span>
                </label>
                <input
                  type="text"
                  id="price_single"
                  placeholder=""
                  name="lastName"
                  onChange={e=> {formik.handleChange(e); handleChangeDetect()}}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  className="form-control form-control-sm"
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </div>
        
  
            </div>
          </Form>
          </Modal.Body>
        <Modal.Footer>
          <Button
            color="danger"
            className="btn-sm"
            onClick={() => {
              handleCloseModal();
            }}
          >
            <span className="align-middle">Close</span>
          </Button>
          {!isLoading ? (
            <Button
              type="button"
              color="primary"
              className="btn-sm"
              onClick={formik.handleSubmit}
            >
              <span className="align-middle">Save</span>
            </Button>
          ) : (
            <button
              data-v-aa799a9e=""
              type="button"
              disabled="disabled"
              className="btn btn-outline-primary disabled"
            >
              <span
                data-v-aa799a9e=""
                aria-hidden="true"
                className="spinner-border spinner-border-sm"
              ></span>{" "}
              Loading...{" "}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default AddEditPhysician;
