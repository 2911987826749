import React, { Component } from "react";
import * as Common from "../../General/common";
import * as Api from "../../General/api";
import { apiUrls } from "../../constants/endpoints";

export default class ViewFacility extends Component {
  constructor(props) {
    super(props);
    Common.LoadDataTable("facilityDetail");
  }

  componentDidMount() {
    this.updateFacility().catch(console.log);
  }

  async updateFacility() {
    const clinicianName = await this.getClinicianNameById(this.props.Facility.fkClinicianId);
    const facilityTypeName = await this.getFacilityType(this.props.Facility.fkFacilityTypeId);
    const vendorName = await this.getEhrVendor(this.props.Facility.fkEhrvendorId);
    const gpoName = await this.getGroupPurchasingOrg(this.props.Facility.fkGroupPurchasingOrg);
    const facilityContacts = await this.getFacilityContacts(this.props.Facility.id);
    const orgName = await this.getOrgTypeName(this.props.Facility.fkOrgId);
    const patients = await this.getPatientsByFacilityId(this.props.Facility.id);

    this.setState({
      clinicianName,
      facilityTypeName,
      vendorName,
      gpoName,
      facilityContacts,
      orgName,
      patients
    });
  }

  async getClinicianNameById (clinicianId) {
    const responseOfClinicianUsersGotten = await Api.getRequestById(Api.Uri_GetUserByRoleID, Common.LookupsFK.Fk_RoleClinicianID).catch(() => undefined);
    const clinicians = responseOfClinicianUsersGotten?.data?.result;
    const clinicianOfFacility = clinicians?.find(clinician => clinician.key === clinicianId);
    return String(clinicianOfFacility?.value || "Not assigned");
  }

  async getFacilityType (facilityTypeId) {
    const facilityTypes = await Common.getLookupOptions(Common.LookupsFK.Fk_FacilityTypeID).catch(() => undefined);
    const facilityType = facilityTypes?.find((facility) => facility?.props?.value === facilityTypeId);
    return String(facilityType?.props?.children || "Not assigned");
  }

  async getEhrVendor (ehrVendorId) {
    const ehrVendors = await Common.getLookupOptions(Common.LookupsFK.Fk_EHRVendorID).catch(() => undefined);
    const ehrVendor = ehrVendors?.find((vendor) => vendor?.props?.value === ehrVendorId);
    return String(ehrVendor?.props?.children || "Not connected to EHR");
  }

  async getGroupPurchasingOrg (gpoId) {
    const allEmrResponse = await Api.getRequestQry(Api.apiUrls.getAllEMR, "?isActive=" + 1).catch(() => undefined);
    const allEmr = allEmrResponse?.data?.result;
    const gpo = allEmr?.find((emr) => emr?.id === gpoId);
    return String(gpo?.name || "Not a member of any GPO");
  }

  async getFacilityContacts(facilityId) {
    const facilityContactsResponse = await Api.getRequest(`${apiUrls.getallfacilitycontactsbyfacilityid}?id=${facilityId}`, false).catch(() => undefined);
    const facilityContacts = facilityContactsResponse?.data?.result;
    return facilityContacts;
  }

  async getPatientsByFacilityId(facilityId) {
    const patientsResponse = await Api.getRequestQry(Api.URI_GetAllResidentByFacilityID, "?facilityID=" + facilityId);
    const patients = patientsResponse?.data?.result;
    console.log(patients);
    return patients;
  }

  async getOrgTypeName (orgTypeId) {
    const orgTypesResponse = await Api.getRequestById(Api.Uri_GetChainByOrgID, orgTypeId).catch(() => undefined);
    const orgTypes = orgTypesResponse?.data?.result;
    const orgType = orgTypes?.find((orgType) => orgType?.key === orgTypeId);
    return String(orgType?.value || "Not assigned");
  }

  render() {
    return (
      <>
        <h3 className="border-bottom border-dark text-primary mb-0">
          Facility Information:{" "}
          <span className="h6 text-danger">
            {this.props.Facility.facilityName}
          </span>
        </h3>
        <table
          className="table table-striped table-sm w-100"
          id="facilityDetail"
        >
          <tbody>
            <tr>
              <td width="20%" className="font-weight-bolder">
                Orgtype
              </td>
              <td width="30%" className="border-right border-dark border-top-0">
                Patient Facility
                {/* {this.state?.organizationName || "N/A"} */}
              </td>
              <td width="20%" className="font-weight-bolder">
                Facility type
              </td>
              <td width="30%">{ this.state?.facilityTypeName }</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Headquarter</td>
              <td className="border-right border-dark border-top-0">
                {this.props.Facility.organizationName}
              </td>
              {/* <td className="font-weight-bolder">Main Office</td>
              <td>
                {" "}
                <button className="btn btn-link">
                  Kane Financial Services
                </button>{" "}
                facility
              </td> */}
            </tr>
            <tr>
              <td className="font-weight-bolder">EHR Vendor</td>
              <td className="border-right border-dark border-top-0">
                {this.state?.vendorName}
              </td>
              <td className="font-weight-bolder">EHR Vendor Id</td>
              <td>{this.props.Facility.ehrVendorId || "Unassigned"}</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">GPO</td>
              <td className="border-right border-dark border-top-0">
                {this.state?.gpoName}
              </td>
              <td className="font-weight-bolder"> NPI #</td>
              <td>{this.props.Facility.npi}</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Date Added</td>
              <td className="border-right border-dark border-top-0">
                {new Date(this.props.Facility.createdDate).toLocaleDateString()}
              </td>
              <td className="font-weight-bolder">Web Site</td>
              <td>
                <a
                  href={this.props.Facility.websiteAddress}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.props.Facility.facilityName}
                </a>
              </td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Product Line </td>
              <td className="border-right border-dark border-top-0">{this.props.Facility.productLine}</td>
              <td className="font-weight-bolder">Number of Beds</td>
              <td>{this.props.Facility.numberofBeds}</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Part B Receiving Person</td>
              <td className="border-right border-dark border-top-0">{this.props.Facility.partBrecPerson}</td>
              <td className="font-weight-bolder"> Email (Part B)</td>
              <td>{this.props.Facility.partBrecPersonEmail}</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Central Supply Person</td>
              <td className="border-right border-dark border-top-0">{this.props.Facility.centralSupplyPerson}</td>
              <td className="font-weight-bolder">Email (Central)</td>
              <td>{this.props.Facility.centerSupplyPersonEmail}</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Address</td>
              <td className="border-right border-dark border-top-0">
                {this.props.Facility.address.addressLine1 + ", " + this.props.Facility.address.city + (this.props.Facility.address.stateId > 0 ? ", " + this.props.Facility.address.stateId : "") + " " + this.props.Facility.address.zip}
              </td>
              <td className="font-weight-bolder">Phone</td>
              <td>{this.props.Facility.address.phone1}</td>
            </tr>
            <tr>
              <td className="font-weight-bolder"></td>
              <td className="border-right border-dark border-top-0"> </td>
              <td className="font-weight-bolder">Fax</td>
              <td>{this.props.Facility.address.fax}</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Ship To</td>
              <td className="border-right border-dark border-top-0">
                {this.props.Facility.address.addressLine1 + ", " + this.props.Facility.address.city + (this.props.Facility.address.stateId > 0 ? ", " + this.props.Facility.address.stateId : "") + " " + this.props.Facility.address.zip}
              </td>
              <td className="font-weight-bolder">Phone (Ship To)</td>
              <td>
                {this.props.Facility.address.phone1} {this.props.Facility.address.phone2 ? ", " + this.props.Facility.address.phone2 : ""}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Bill To</td>
              <td className="border-right border-dark border-top-0">
                {this.props.Facility.address.addressLine1 + ", " + this.props.Facility.address.city + (this.props.Facility.address.stateId > 0 ? ", " + this.props.Facility.address.stateId : "") + " " + this.props.Facility.address.zip}
              </td>
              <td className="font-weight-bolder">Phone (Bill To)</td>
              <td>
                {this.props.Facility.address.phone1} {this.props.Facility.address.phone2 ? ", " + this.props.Facility.address.phone2 : ""}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bolder">End of Week Day</td>
              <td className="border-right border-dark border-top-0">Sunday</td>
              <td className="font-weight-bolder">Week is from</td>
              <td>Monday to Sunday</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Time Zone</td>
              <td className="border-right border-dark border-top-0">Eastern</td>
              <td className="font-weight-bolder"></td>
              <td></td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Wound Education Specialist</td>
              <td className="border-right border-dark border-top-0">
                {/* Bezdek, Deborah */}
                {this.state?.clinicianName}
              </td>
              <td className="font-weight-bolder">Facility In Use</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Company</td>
              <td className="border-right border-dark border-top-0">
                {this.props.Facility.facilityName}
              </td>
              {/* <td className="font-weight-bolder">Rep</td>
              <td>Arnie Andersen</td> */}
            </tr>
            <tr>
              <td className="font-weight-bolder">Address</td>
              <td className="border-right border-dark border-top-0">
                {this.props.Facility.address.addressLine1 + ", " + this.props.Facility.address.city + (this.props.Facility.address.stateId > 0 ? ", " + this.props.Facility.address.stateId : "") + " " + this.props.Facility.address.zip}
              </td>
              <td className="font-weight-bolder">Phone</td>
              <td>
                {this.props.Facility.address.phone1}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bolder">Memo</td>
              <td align="left" colSpan="3">
                {" "}
                {this.props.Facility.memo}
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="border-bottom border-dark text-primary mb-0">
          Contacts
        </h3>
        <table
          className="table table-striped table-sm table-bordered"
          id="facilityContactDetail"
        >
          <thead>
            <tr>
              <th width="15%">Name</th>
              <th width="15%">Position</th>
              <th width="30%">Email</th>
              <th width="15%"> Phone 1</th>
              <th width="15%">Phone 2</th>
              <th width="10%">Main</th>
            </tr>
          </thead>
          <tbody>
            {this.state?.facilityContacts?.map((contact, index) => (
              <tr key={index}>
                <td>{contact?.name}</td>
                <td>{contact?.fPosition}</td>
                <td className="text-break">{contact?.email}</td>
                <td>{contact?.mobile}</td>
                <td>{contact?.fax}</td>
                <td>{contact?.fPosition === 'DON' ? 'Main' : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <h3 className="border-bottom border-dark text-primary mb-0">
              Facility Contracted Info
            </h3>
            <table
              className="table table-striped table-sm border-0 w-100"
              id="facilityContractedInfo"
            >
              <tbody>
                <tr>
                  <td width="30%" align="left" className="font-weight-bolder">
                    Gentell handles Medicare part B?
                  </td>
                  <td width="20%"> {this.props.Facility.medicarePartBbyGentell ? "Yes" : "No"}</td>
                  <td width="30%" align="left" className="font-weight-bolder">
                    OUT`s Program
                  </td>
                  <td width="20%">{this.props.Facility.outProgram ? "Yes" : "No"} </td>
                </tr>
                <tr>
                  <td align="left" className="font-weight-bolder">
                    Woundcare Capitation Contract
                  </td>
                  <td>{this.props.Facility.woundcareCapitationContract ? "Yes" : "No"}</td>
                  <td align="left" className="font-weight-bolder">
                    Num patients woundcare cap.
                  </td>
                  <td>
                    {this.state?.patients?.length || 0}
                  </td>
                </tr>
                {/* <tr>
                  <td align="left" className="font-weight-bolder">
                    WC cost per patient per day
                  </td>
                  <td>$0.93</td>
                  <td align="left" className="font-weight-bolder">
                    Min order for free shipping
                  </td>
                  <td>$400.0</td>
                </tr>
                <tr>
                  <td align="left" className="font-weight-bolder">
                    Discount
                  </td>
                  <td> 10.0%</td>
                  <td align="left" className="font-weight-bolder">
                    Min order for high discount
                  </td>
                  <td>$10000.0</td>
                </tr>
                <tr>
                  <td align="left" className="font-weight-bolder">
                    High level discount
                  </td>
                  <td>12.0%</td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <h3 className="border-bottom border-dark text-primary mb-0">
              Shipping Info
            </h3>
            <table
              className="table table-striped table-sm border-0 w-100"
              id="facilityDetail"
            >
              <tbody>
                <tr>
                  <td align="left" className="font-weight-bolder">
                    28-Day Schedule
                  </td>
                  <td align="right">
                    {this.props.Facility.isThisFacilityFollowingTheCycle ? "Yes" : "No"}
                  </td>1
                </tr>
                <tr>
                  <td align="left" className="font-weight-bolder">
                    Ships from this warehouse
                  </td>
                  <td align="right">{this.props.Facility.warehouseName}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
