const HttpStatus = {
  OK: 200,
};

export default class HttpResponse {
  /**
   * Handle response from API
   * @template T
   * @param {AxiosResponse<any>} response
   * @returns {T}
   */
  static handle(response) {
    if (response.status !== HttpStatus.OK) {
      return HttpResponse.error(response);
    }

    if (response.data.statusCode !== HttpStatus.OK) {
      return HttpResponse.error(response);
    }

    return HttpResponse.ok(response);
  }

  static ok(response) {
    return {
      ...response.data.result,
      ok: true,
    }
  }

  /**
   * Handle error response from API
   * @template T
   * @param {AxiosResponse<any>} response
   * @returns {T} 
   */
  static error(response) {
    return {
      ...response.data,
      ok: false,
    }
  }

  /**
   * Check if response is ok
   * @param {AxiosResponse<any>} response
   * @returns {boolean} 
   */
  static isOk(response) {
    return response.ok;
  }

  /**
   * Get data from response
   * @template T
   * @param {AxiosResponse<any>} response
   * @returns {T}
   */
  static getData(response) {
    const { ok: _, ...data } = response;
    return data;
  }
}
