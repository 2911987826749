import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import RoutePaths from "./constants/routepaths";
import { default as Resident } from "./pages//resident/resident";
import BillingQueueOrders from './pages/billing/billingQueueOrders';
import SetupBrightree from "./pages/Brightree/SetupBrightree";
import { BuilderPage } from "./pages/builderpage";
import { DashboardPage } from "./pages/dashboardpage";
import EHRVendors from './pages/ehrvendors/ehrvendors';
import { default as Facility } from "./pages/facility/facility";
import { default as FacilityQueue } from "./pages/facilityQueue/facilityQueue";
import PostVisitEmail from './pages/postVisitEmail/postVisitEmail';



import { MyPage } from "./pages/mypage";
import Physician from "./pages/physician/physician";
import SetupNav from "./pages/Setups/setupNav";
import ReportNav from "./pages/reports/reportsNav";
import AssessmentReportNav from "./pages/reports/assessmentReportsNav";

// import WorkQueue from './pages/workqueue/workqueue';
import WorkQueueWoundOrders from './pages/workqueue/workQueueWoundOrders';
import { useSelector } from "react-redux";
import RBAC, { ModulesName } from "./General/roleModuleActions";


const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

const ProductNavPage = lazy(()=>
  import('./pages/products/productnav')
);

const OrdersPage = lazy(() =>
  import("./pages/orders/orderspage")
);

const RepFirmNavPage = lazy(()=>
  import('./pages/repfirm/repfirmnav')
);

const TabletDataListPage = lazy(()=>
  import('./pages/tabletdata/tabletlist')
);

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);

 
    // useEffect(() => {
      
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
 const setRoutePage= () =>{
  //ali
   console.log(user.roles)
  var currentPage = RoutePaths.Page_Facility;
  user.roles.roleList.map((role)=>{
    if(role.roleName ===RBAC.Role_Gentell_Administrator){
      currentPage = RoutePaths.Page_Facility 
  }else if(role.roleName ===RBAC.Role_Gentell_RegionalManager){
  currentPage = RoutePaths.Page_WorkQueue 
  }else if(role.roleName ===RBAC.Role_Gentell_WoundCareSpecialist){
  currentPage = RoutePaths.Page_WorkQueue 
  } else if(role.roleName ===RBAC.Role_Gentell_SeniorBiller){
  currentPage = RoutePaths.Page_Billing 
  } 
  else if(role.roleName ===RBAC.Role_Gentell_IntakeProcessor){
  currentPage = RoutePaths.Page_Billing 
  }else if(role.roleName ===RBAC.Role_Facility_Level1){
    currentPage = RoutePaths.Page_FacilityQueue 
    } 
    else if(role.roleName ===RBAC.Role_Facility_Level2){
    currentPage = RoutePaths.Page_FacilityQueue 
    }
  })
  
console.log(currentPage)
return currentPage;
 }    
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                  
                    /* Redirect from root URL to /dashboard. */
                    
                   
                }
                  <Route exact path="/" render={()=>(
                     <Redirect exact to={setRoutePage()}/>
                  )}/>
                <ContentRoute path={RoutePaths.Page_Dashboard} component={DashboardPage}/>
                <ContentRoute path={RoutePaths.Page_Builder} component={BuilderPage}/>
                <ContentRoute path={RoutePaths.Page_MyPage} component={MyPage}/>
                <ContentRoute path={RoutePaths.Page_WorkQueue} component={WorkQueueWoundOrders} />
                <ContentRoute path={RoutePaths.Page_Billing} component={BillingQueueOrders} />
                <ContentRoute path={RoutePaths.Page_Facility} component={Facility} />
                <ContentRoute path={RoutePaths.Page_FacilityQueue} component={FacilityQueue} />

                <ContentRoute path={RoutePaths.Page_Resident} component={Resident} />
                <ContentRoute path={RoutePaths.Page_Physician} component={Physician} />
                <ContentRoute path={RoutePaths.Page_ProductsNav} component={ProductNavPage} />
                <ContentRoute path={RoutePaths.Page_RepFirmNav} component={RepFirmNavPage} />
                <ContentRoute path={RoutePaths.Page_TabletData} component={TabletDataListPage} />
                <ContentRoute path={RoutePaths.Page_EHRVendors} component={EHRVendors} />
                <ContentRoute path={RoutePaths.Page_SetupNav} component={SetupNav} />
                <ContentRoute path={RoutePaths.Page_ReportNav} component={ReportNav} />
                <ContentRoute path={RoutePaths.Page_PostVisitEmail} component={PostVisitEmail}/>
                <ContentRoute path={RoutePaths.Page_AssessmentReportNav} component={AssessmentReportNav} />


                <ContentRoute path={RoutePaths.Page_SetupBrightree} component={SetupBrightree} />
                <Route path={RoutePaths.Page_OrderNav} component={OrdersPage} />
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>

                

            </Switch>
        </Suspense>
    );
}
