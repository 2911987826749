export const ApiBaseUrl = "https://fastcareapi.rqapps.com/"; // Local FastCare API URL
// export const ApiBaseUrl = "https://localhost:44306/"; // Local FastCare API URL
// export const ApiBaseUrl = "https://fastcareapi.gentell.com/"; // Live Prodcution FastCare API URL
// export const ApiBaseUrl = "https://uat-fastcareapi.gentell.com/"; // Live UAT FastCare API URL
// export const ApiBaseUrl = "https://dev-fastcareapi.gentell.com/"; // Live UAT FastCare API URL



//#region BlobStorage
// export const blobAccount = "gentellfastcarestorage";
export const blobAccount = "attachmentsfcprod";

// Current sas
export const blobSas =
  "?sv=2020-10-02&st=2022-03-16T08%3A22%3A25Z&se=2025-12-31T09%3A22%3A00Z&sr=c&sp=racwl&sig=3OPzHkBLCzSli8g5Q9eUXa%2FStlwq4qMXFCr21ynHZkE%3D";

// facilityreports
// export const blobSas =
//    "?sv=2020-10-02&st=2022-07-19T11%3A16%3A01Z&se=2025-07-20T11%3A16%3A00Z&sr=c&sp=racwl&sig=fyE3LLP%2F4qRN%2B9KV9QqEEhDR8bgUgsxqYtQVzogm6H4%3D";

export const blobContainerName = "fcproduction";
// export const blobContainerName = "fcfacilityreports";

// export const blobStorageUrl = `https://${blobAccount}.blob.core.windows.net`;
 export const blobStorageUrl = `https://${blobAccount}.blob.core.windows.net/fcproduction`;

//#endregion

export const defaultUrls = {
  defaultResidentPicture: "/template/media/images/nopatientpic.jpg",
  notificationHub: ApiBaseUrl + "NotificationHub",
};

export const apiUrls = {
  upsertResidentImage: ApiBaseUrl + "Resident/UpsertResidentImage",
  getallfacilitycontactsbyfacilityid:
    ApiBaseUrl + "FacilityContact/getallfacilitycontactsbyfacilityid",
    upsertfacilitycontact:
    ApiBaseUrl + "FacilityContact/upsertfacilitycontact",
  getvacationbyid: ApiBaseUrl + "Vacation/getvacationbyid",
  getallvacations: ApiBaseUrl + "Vacation/getallvacations",
  upsertvacation: ApiBaseUrl + "Vacation/upsertvacation",
  searcDoctors: "Physician/SearchDoctors",
  doctorsSearch: "Physician/DoctorsSearch",

  SearchInsurance: ApiBaseUrl + "Resident/SearchInsurance",
  //User paths
  getAllUsers: "User/GetAllUser",
  getAllEMR: "Facility/GetEMR",
  upsertEMR: "Facility/UpsertEMR",
  fetchFacilityGroups: "Facility/FetchFacilityGroups",
  updateFacilityStatus: "Facility/UpdateFacilityStatus",
  updateResidentStatus: "Resident/UpdateResidentStatus",
  updateResidentWoundStatus: "Wound/UpdateResidentWoundStatus",
  updateResidentWoundPartailStatus: "Process/UpdateResidentWoundPartailStatus",
  updateResidentWoundOrderNowStatus:
    "Process/UpdateResidentWoundOrderNowStatus",

  updateResidentWoundNoOrderStatus: "Process/UpdateResidentWoundNoOrderStatus",
  updateFacilityProcessor: "Process/UpdateFacilityProcessor",

  updateResidentNoOrderStatus: "resident/UpdateResidentNoOrderStatus",
  updateResidentECP: "resident/updateResidentECP",
  createWoundInfoChangeDetail: "wound/createWoundInfoChangeDetail",



  releaseFacilityPartailOrders: "Process/ReleaseFacilityPartailOrders",
  updateSkipOrHoldFacilityStatus: "Process/UpdateSkipOrHoldFacilityStatus",


  upsertFacilityUser: "Facility/UpsertFacilityUser",
  upsertFacilityClinician: "Facility/UpsertFacilityClinician",

  getFacilityUsers: "Facility/GetFacilityUsers",
  upsertResidentAOB: ApiBaseUrl + "Resident/UpsertAobForm",
};
