/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import {Spinner,} from "react-bootstrap";
const ModalLoading=({height, color})=>{
    const fixedHieght = height ? height : 600
    function caliculateMargin(fixedHeight){
        return Math.round((fixedHeight / 2) - 15);
    }
    return(
        <div className="d-flex justify-content-center" style={{height: fixedHieght}}>
  <span className="pr-4">
                  <Spinner animation="border"  variant="primary" />
                </span>
        </div>
    )
}

export default ModalLoading