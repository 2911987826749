import React,{useEffect,useState }  from 'react';
import { Button,Badge } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import uuid from 'node-uuid'
import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";

export function NightlySync() {

    const [logDetail, setNightlySyncDetail] = useState([]);
    const [isJobCompleted, setJobIsCompletd] = useState(true);

    

    useEffect(() => { getSyncDetail();},[])


    const getSyncDetail = () => {
        Api.getRequest(Api.Uri_getNightlySyncLog)
            .then((res) => {
                if (res.data.statusCode == 200) {
                    if (res.data.success) {
                        if (res.data.result != null) {
                            if (res.data.result.length > 0) {
                                var findCategory = res.data.result.find(x => x.isCompleted == false )
                            if(findCategory!=undefined)
                            {
                                setJobIsCompletd(false)
                            }else{
                                setJobIsCompletd(true)
                            }
                                setNightlySyncDetail(res.data.result);
                                
                            }

                        }
                    }
                }
            })
            .catch(function (error) {
                Common.LogError(error, "Nightly", "getSyncDetail");

            }).then(() => {

            });
    }
    const handleRunJob = () => {
        var msg = "You want to run this job.It is long process and may slow the system performance.If Possible please perform this activity in off hours";
        Common.showConfirmation(msg, "", saveParsedData, null, null)

    };
   const saveParsedData=()=>{
       debugger
        setJobIsCompletd(false)
   
        Api.getRequest(Api.Uri_processJob)
        .then((res) => {
            if (res.data.success) {
                // this.props.onSaveAPI();
               
                Common.showSuccessAlertAutoClose(
                    res.data.message,
                    "success",
                    "Success!",
                    3000
                );
                getSyncDetail();
            } else {
                Common.showSuccessAlert(
                    res.data.message,
                    "warning",
                    "Error!",
                    3000
                );
            }

        });

   }



  

    
    return (
      <React.Fragment>




                    {/* <div className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm"  onClick={saveParsedData}>Run Job</Button>
           </div> */}
           <Card className="example example-compact">
            <CardHeader  >
              <div className="card-title">
                <span>
                  <h3 className="card-label">Nightly Sync Detail</h3>

                </span>
              </div>


              <div className="card-toolbar">
                <div className="example-tools">
              
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-5"
                        disabled={isJobCompleted?false:true}
                        onClick={()=>handleRunJob()}
                      >
                       Run Job
                      </Button>
              

                </div>
              </div>

            </CardHeader>
           <CardBody>
              {/* {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : */}
              {/* ( */}
              <table
                className="table table-striped table-hover table-sm table-bordered"
                id="nightlySyncList"
              >
                <thead>
                  <tr>
                    <th>Total Facilities</th>
                    <th>Synced Facilities</th>
                    <th>Synced Resident</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Success</th>
                    <th >Is Completed</th>
                  </tr>
                </thead>
                <tbody>
                {logDetail?.map((log) => {
                    debugger
                    // if(log.isCompleted==false)
                    // {
                    // setJobIsCompletd(false)   
                    // }
                    return (
                     
                    <tr key={uuid()}>
                      <td>{log.totalFacilityCount}</td>
                      <td>{log.syncFacilityCount}</td>
                      <td>{log.syncResidentCount}</td>
                      <td>{Common.replaceNullWithString(log.startTime) != '' ? moment(log.startTime).format("YYYY-MM-DD HH:mm:ss") : 'N/A'}</td>
                      <td>{Common.replaceNullWithString(log.endTime) != '' ? moment(log.endTime).format("YYYY-MM-DD HH:mm:ss") : 'N/A'}</td>
                      <td align="center">{log.isSuccess==true ? <Badge variant="success">Success</Badge> 
                      : log.isCompleted!=true? <Badge variant="primary">In Process</Badge>: <Badge variant="primary">Failed</Badge>}</td>

                      <td align="center">{log.isCompleted==true ? <Badge variant="success">Completed</Badge> : <Badge variant="primary">In Completed</Badge>}</td>

                     
                    </tr>
                    );
                  })
                
                  }
                    </tbody>
              </table>
              {/* )} */}
            </CardBody>
          </Card>
          </React.Fragment>

    );
}

export default NightlySync;