import { Switch, Typography } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle, Button, Dropdown, DropdownButton, ButtonToolbar
} from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import SelectableContext from "react-bootstrap/SelectableContext";
import { useSelector } from 'react-redux';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import FacilityResidentHistory from './facilityResidentHistory'
import Loader from "react-loader-spinner";
// import ResidentHistoryWounds from "./residentWoundHistory"
export function FacilityHistory(props) {
  //#region Resident modal
  const [facilityHistoryListUI, setFacilityHistoryListUI] = useState([])
  //#endregion
  const [showResident, setShowResident] = useState(false);
  const [FacilityObj, setFacilityObj] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getFacilityHistory();
    //setResidentListTemp();
    //Common.LoadDataTable("facilityList")
  }, []);

  const getFacilityHistory = () => {
    debugger
    setIsLoading(true);
    Api.getRequestQry(
      Api.URI_GetFacilityHistoryById,
      "?id=" + props.facilityId
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setFacilityList(res.data.result);

            }
          }
        }

      })
      .catch((ex) => { })
      .then(() => {
        setIsLoading(false);

      });

  };

  const setFacilityList = (list) => {
    debugger
    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var cycleDate = new Date(obj.cycleDate);
      rows.push(
        <tr key={i.toString()}>
          <td>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {


                setShowResident(true)
                setFacilityObj(obj)
              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingDate)}</td> */}
          <td>{!obj.isPartial ? Common.getInputStringDate(cycleDate) : null}</td>
          <td>{obj.isPartial ? Common.getInputStringDate(cycleDate) : null}</td>

          <td>

            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">

                  <Button
                    size="xs"
                    variant="success"
                    onClick={() => { handleFacilityReport(obj)}}
                  >
                    Print Facility
                  </Button>

                </div>


              </ButtonToolbar>
            </>
          </td>
          {/* <td align="center">
            <Badge variant="success">Active</Badge>
          </td> */}


        </tr>
      );
    }
    setFacilityHistoryListUI(rows);

    Common.LoadDataTable("facilityBillingList");
  };


  const handleFacilityReport = async (facility) => {
    if(facility.printedPdfUrl!='' && facility.printedPdfUrl!=null){
      window.open(facility.printedPdfUrl, '_blank', 'noopener,noreferrer')
    }else{
      setIsLoading(true);
      var { data } = await Api.getRequestQry(Api.URI_GetResidentHistoryReport,
        "?facilityId=" + facility.id + "&processQueueId=" + facility.processQueueID +
        "&residentId=" + 0+ "&isPartial=" + facility.isPartial + "&partialOrderDate=" + facility.cycleDate)
      debugger
      if (data.success) {
        debugger
        setIsLoading(false);
        if (data.result != null && data.result != '') {
          window.location.assign(data.result)
        }
  
      } else {
        setIsLoading(false);
      }
    }
   

  }



  return (
<>
    {(isLoading == false) ?
    <>
      {showResident && !isLoading && (
        <Animated
          animationIn="fadeIn"
          animationInDuration={1000}
          animationOut="fadeOut"
          isVisible={showResident}
        >
          <FacilityResidentHistory
            backPress={() => { setShowResident(false) }}
            facility={FacilityObj}
          />
        </Animated>
      )}
      {!showResident && !isLoading && (
        <Card className="example example-compact">
          <CardHeader>


          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div className="row d-flex justify-content-center">
                <span className="mt-5 spinner spinner-primary"></span>
              </div>
            ) : (
              <table
                className="table table-striped table-hover table-sm table-bordered"
                id="facilityBillingList"
              >
                <thead>
                  <tr>
                    <th>Facility Name</th>
                    <th>Ship Date</th>
                    <th>Reorder Date</th>
                    <th>Action</th>


                  </tr>
                </thead>
                <tbody>{facilityHistoryListUI}</tbody>
              </table>
            )}
          </CardBody>
        </Card>
      )}
    </>
    :
    <div className="d-flex justify-content-center">
      <Loader
        type="Puff"
        color="#00BFFF"
        height={50}
        width={50}
      />
    </div>
  }
  </>
  );
}

export default FacilityHistory;
