import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Card, CardBody } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";


var prods = {};
const basicAccessories= [{ id: 1, productName: "1,000ml Leg bag w/tubing & strap" ,buttonLabel:"1,000ml Leg bag w/tubing & strap",sku:"1,000ml Leg bag w/tubing & strap"}, { id: 2, productName: "2,000ml Bedside drainage Bags",buttonLabel:"2,000ml Bedside drainage Bags",sku:"2,000ml Bedside drainage Bags" }];
const trays = [{ id: "4", productName: "Insertion Tray" ,buttonLabel:"Insertion Tray" ,sku:"Insertion Tray" }, { id: "5", productName: "Irrigation Tray for intermittent irrigation" ,buttonLabel: "Irrigation Tray for intermittent irrigation",sku: "Irrigation Tray for intermittent irrigation"}, { id: "6", productName: "Irrigation Tray for continuous irrigation " ,buttonLabel:"Irrigation Tray for continuous irrigation ",sku:"Irrigation Tray for continuous irrigation "}];
const others= [{ id: 7, productName: "Cather/tube anchoring device" ,buttonLabel:"Cather/tube anchoring device",sku:"Cather/tube anchoring device"}, { id: 8, productName: "Cleaner/Decrystalizer ",buttonLabel:"Cleaner/Decrystalizer ",sku:"Cleaner/Decrystalizer " }];

export default class UrologicalAccessoriesSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryList: [],
            secondaryList: [],
            selectedProducts: [],

            orderProducts: [],
       
        }
    }

    componentDidMount() {
        prods = {};
        if (this.props.orderProducts != null) {
            debugger
            this.setState({ orderProducts: this.props.orderProducts });
        }
        //this.setProductData(data);
        this.GetAllProductsApiCall();
    }

     handleChangeOtherPrimaryProducts = selectedOtherPrimaryProducts => {
        this.setState({ selectedOtherPrimaryProducts });
      };

      handleChangeOtherSecodaryProducts = selectedSecondaryProducts => {
        this.setState({ selectedSecondaryProducts });
      };
    render() {
        return (
            <>
                <div className="row mt-2" id="changed">

                    <div className="col-md-6" >
                        <h4>Drainage Bags</h4>
                        {this.renderProductUI(basicAccessories)}

                    </div>

                    <div className="col-md-6" >
                        <h4>Trays</h4>
                        {this.renderProductUI(trays)}

                    </div>
                    <div className="col-md-6" >
                        <h4>Others</h4>
                        {this.renderProductUI(others)}

                    </div>

                </div>


         
            </>

        );
    }



    setOrderProducts() {
        // // debugger
        this.state.orderProducts.map((product) => {
            if (!product.withOtherButtons) {
            let e = { target: { value: product.quantity } }
            this.setSelectedProducts(product);
            this.onchangeQuantity(e, product.id)
            }
        })
    }

    setSelectedProducts(product) {
        if (prods[product.id]) {
            prods[product.id] = false;
            const _selectedprod = this.state.selectedProducts.filter(obj => {
                return obj.id != product.id
            })

            this.setState({ selectedProducts: _selectedprod });
        }
        else {
            prods[product.id] = true;

            let _selectedProd = JSON.parse(JSON.stringify(this.state.selectedProducts));
            _selectedProd.push({ id: product.id, quantity: 1, saleOrderProductId: product.saleOrderProductId });
            this.setState({ selectedProducts: _selectedProd })
        }

    }

    onchangeQuantity(event, id) {
        // // debugger;
        const _selectedprod = this.state.selectedProducts.map(obj => {
            if (obj.id == id) {
                obj.quantity = event.target.value;
            }
            return obj
        })

        this.setState({ selectedProducts: _selectedprod });

    }

    getSelectedProductQuantity(id) {
        let quantity = 1;
        this.state.selectedProducts.map(obj => {
            if (obj.id == id) {
                quantity = obj.quantity;
            }
        })

        return quantity;
    }

    renderProductUI(list) {
        const _list = list;
        let productUIArray = [];
debugger
        //  Object.entries(_list).forEach(([key, value]) => {


             productUIArray.push(
                
                <div className="row mt-5" >
                  
                    <div className="col-md-8">

                        {
                            list.map((product) => {
                             debugger
                                const btnColor = prods[product.id] ? "btn-success" : "btn-outline-info";
                                return product.sku != null ?
                                    prods[product.id] ?

                                        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={
                                            <Popover id="popover-basic"  >
                                                <div>
                                                    <Card>
                                                        <CardBody>
                                                            <label className="form-label text text-bold">{product.productName}</label>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label" htmlFor="DebridementType">Quantity</label>
                                                                        <input type="number" id="quantity"
                                                                            name="quantity"
                                                                            onChange={(e) => this.onchangeQuantity(e, product.id)}
                                                                            defaultValue={this.getSelectedProductQuantity(product.id)}
                                                                            className="form-control form-control-sm" />

</div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label" htmlFor="DebridementType">Remaining Quantity</label>
                                                                        <input type="number" id="RemainingQuantity"
                                                                            name="RemainingQuantity"
                                                                            // onChange={(e) => this.onchangeQuantity(e, product.id)}
                                                                            //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                                            // defaultValue={this.getSelectedProductQuantity(product)}
                                                                            className="form-control form-control-sm" />

                                                                        <button type="button" className="btn btn-danger mt-5" size="sm" onClick={() => this.setSelectedProducts(product)}>Remove Product</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>

                                                    </Card>
                                                </div>
                                            </Popover>
                                        }>
                                            <button type="button" title={product.productName} className={`mr-4 mt-2 btn position-relative  ${btnColor}`}
                                                id={product.sku}
                                            //onClick={() => this.setSelectedProducts(product)}
                                            >
                                                {Common.replaceNullWithString(product.buttonLabel)}
                                                <span className="badge rounded-pill badge-notification position-absolute top-0 left-100 ml-1 translate-middle bg-warning">{this.getSelectedProductQuantity(product.id)}</span>
                                            </button>
                                        </OverlayTrigger>


                                        :
                                        <button type="button" title={product.productName} className={`mr-2 mt-2 btn  ${btnColor}`}
                                            id={product.sku}
                                            onClick={() => this.setSelectedProducts(product)}
                                        >
                                            {Common.replaceNullWithString(product.buttonLabel)}
                                        </button>

                                    :
                                    <label className="form-label" htmlFor="Status">{Common.replaceNullWithString(product.buttonLabel)}</label>
                             })
                        }

                    </div>
                </div>
            );
        //  })

        return productUIArray;


    }
  



    //#region Get/init Product

    groupBy(objectArray, property, subProperty) {
        return objectArray.reduce((acc, obj) => {
            var key = obj[property];
            var subkey = obj[subProperty];

            if (!acc[key]) {
                acc[key] = [];
            }

            let tempAcc = acc[key];

            if (!tempAcc[subkey]) {
                tempAcc[subkey] = { name: subkey, description: obj["productCategoryDescription"], list: [] };
            }

            tempAcc[subkey].list.push(obj);

            return acc;
        }, {});
    }

    setProductData(data) {
        const productListByLevel = this.groupBy(data, "productLevelName", "productCategoryName");

        if (productListByLevel != null) {

     
            this.setState({
                primaryList: productListByLevel["Primary"] != null ? productListByLevel["Primary"] : [],
                secondaryList: productListByLevel["Secondary"] != null ? productListByLevel["Secondary"] : [],
            })
        }
    }



  async  GetAllProductsApiCall() {
        var WoundDressingChangeFrequency = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundDressingChangeFrequencyID)
        this.setState({ WoundDressingChangeFrequency: WoundDressingChangeFrequency })
        Api.getRequest(Api.URI_GetAllProducts).then((res) => {
            // // debugger
            if (res.data.success) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        this.setProductData(res.data.result);
                        if (this.props.orderProducts != null) {
                            this.setOrderProducts();
                        }
                    }

                }
                else {
                    Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {

            // // debugger
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }

    //#endregion

    getSelectedProductList = () => {
        // debugger
                var productsList = {}
                productsList.products = [];
                productsList.products = this.state.selectedProducts;
        
        
                return productsList;
            }

}//end