import React, { useCallback, useEffect, useState } from 'react';
//import { useState } from 'react';
import { Animated } from "react-animated-css";
import { Alert, Badge, Button, ButtonToolbar, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import ExcelToJson from "../../General/excelToJson";
import uuid from 'node-uuid'
export function ExcelSheetUser() {

    const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(false);

    const [fileUsers, setFileUser] = useState([]);
    const [isFileSave, setFileSave] = useState(false);
    const [pasredDataList, setPasredDataList] = useState([]);


    const [isLoading, setIsLoading] = useState(false);




   const saveParsedData=()=>{
       debugger
       if(pasredDataList.length>0){
        Api.postRequest(Api.Uri_saveParseUserList, pasredDataList)
        .then((res) => {
            debugger
            setFileSave(true)
            if (res.data.success) {
                // this.props.onSaveAPI();
                debugger
                setDataUi(res.data.result,true)
                Common.showSuccessAlertAutoClose(
                    res.data.message,
                    "success",
                    "Success!",
                    3000
                );
            } else {
                Common.showSuccessAlert(
                    res.data.message,
                    "warning",
                    "Error!",
                    3000
                );
            }

        });
       }else{
        Common.showSuccessAlert(
            "Data is not available in list",
            "warning",
            "warning!",
            3000
        );
       }
   }

   const excelDataRead = (list) => {
    setFileSave(false)
    setPasredDataList(list);
    setDataUi(list,false)
   }

    const setDataUi = (list,isSaved) => {
     
        debugger
        var rows = [];
        list.map((object, i) => {
            rows.push(<tr key={i.toString()}>
                <td >

                    {object.firstName}
                </td>
                <td>{Common.replaceNullOrEmptyWithString(object.lastName)}</td>
                <td>{object.userName} </td>
                <td>{Common.replaceNullOrEmptyWithString(object.email, "")}</td>
                <td>{Common.replaceNullOrEmptyWithString(object.roleName, "")}</td>
                <td>{Common.replaceNullOrEmptyWithString(object.phone, "")}</td>
                <td>{Common.replaceNullOrEmptyWithString(object.managerUserName, "")}</td>

                {isSaved && (<th>{object.message}</th>)}

            </tr>);
        })
        debugger
        setFileUser(rows)
        Common.LoadDataTable("facilitySheetList");
    }



  
   
    return (
        <>

                <Card className="">
                    <CardHeader title='User List' />
                    <CardBody>




                        <ExcelToJson excelDataRead={excelDataRead} />


                        <div>
                            {isLoading ?
                                <div className="row d-flex justify-content-center">
                                    <span className="mt-5 spinner spinner-primary"></span>
                                </div> : (
                                    <table className="table table-striped table-hover table-sm table-bordered" id="facilitySheetList">
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>

                                                <th>User Name</th>
                                                <th>Email</th>

                                                <th>Role Name</th>
                                                <th>Phone</th>
                                                <th>Manager</th>
                                               {isFileSave&& <th>Response</th>}


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fileUsers}
                                        </tbody>
                                    </table>
                                )}
                        </div>


                    </CardBody>
                    <CardFooter className="d-flex justify-content-end py-3">
                        {fileUsers.length>0 && !isFileSave &&(
                        <Button variant="success" className="mr-5 px-10" size="sm"  onClick={saveParsedData}>Save Data</Button>
                        )}
                        <Button variant="danger" size="sm" onClick={() => showAdvanceSearchSectionState(false)}>Cancel</Button>
                    </CardFooter>
                </Card>
       
        </>

    );
}

export default ExcelSheetUser;