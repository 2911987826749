import { useFormik } from "formik";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { login } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
import { Button, Modal } from "react-bootstrap";

import SetBTCredentials from "./setBTCredentials";

var authorizeModal=null;

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "admin@gentellfastcare.com",
  password: "demo",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const [showModalNewBT, setShowHideBTModel] = useState(false);

  const handleCloseBTModal = () => setShowHideBTModel(false);
  const handleShowBTModel = () => setShowHideBTModel(true);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const roles = {
    "roleName": ["admin","clinician"],
    "moduleList": [
      {
        "moduleID": 1,
        "moduleName": "Facility",
        "actionList": [
          {
            "actionNames": ["create", "update", "read", "delete"]
          }
        ]
      },
      {
        "moduleID": 1,
        "moduleName": "Setup",
        "actionList": [
          {
            "actionNames": ["read"]
          }
        ]
      },
    ]
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        // var Username='admin';
        //  var Password ='Admin1234';
        //  const body = { Username, Password };
        var Username = `${email}`; //values.email;
        var Password = `${password}`; // values.password;
        // // debugger;
        // const body = { Username, Password };
        //  axios.post('https://localhost:44306/api/Account/authenticate',body )

        //var Username='admin';
        // var Password ='Admin1234';
        // const body = { Username, Password };
        //  // // debugger
        // axios.post('https://localhost:44306/api/Account/authenticate', body);
        //axios.post('https://localhost:44306/api/Account/authenticate',body )

        login(Username, Password)
          //login(Username, Password)

          // .then((accessToken)=> {
          //   disableLoading();

          //   console.log(accessToken)
          //   // // debugger
          //   alert("coming")
          //   //return <Redirect to='/dashboard'/>
          //  //return  <Redirect  to="/dashboard" />
          //  // return <Redirect from="/auth" to="/"/>
          //  // props.login(accessToken);
          // })
          // .then(({ data: { accessToken, user } }) => {
          .then((res) => {

             debugger;
             setSubmitting(false)
             disableLoading()
            if (!res.data.success) {
              // setSubmitting(false);
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            } else {
              debugger
              if(res.data.statusCode==400)
              {
              setUser(res.data.result);
              handleShowBTModel();
             
              }else{
              localStorage.setItem(
                "gentell-fastcare-authUser",
                JSON.stringify(res.data.result)
              );
              localStorage.setItem(
                "gentell-fastcare-authToken",
                JSON.stringify(res.data.result.jwtToken)
              );

              props.login(res.data.result.jwtToken);
              // // debugger;
              //user.roles = roles;
              props.fulfillUser(res.data.result);
              }
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 100);
    },
  });
const CloseBtCrendentialsModal =(logedInUser)=>{
  debugger
  handleCloseBTModal();
localStorage.setItem(
  "gentell-fastcare-authUser",
  JSON.stringify(logedInUser)
);
localStorage.setItem(
  "gentell-fastcare-authToken",
  JSON.stringify(logedInUser.jwtToken)
);

props.login(logedInUser.jwtToken);
// // debugger;
//user.roles = roles;
props.fulfillUser(logedInUser);
}
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (evt) => {
    // // debugger;
    evt.preventDefault();
    alert(`${email}  ${password}`);
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        //onSubmit={handleSubmit}
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
            <></>
          )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "userName"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input type="checkbox" name="acceptTerms" className="m-1" />
            <span className="mr-3" />
            Remember me on this computer
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}

      <p>
        By logging in as a Gentell Fastcare user, I acknowledge that my work
        will involve access to Protected Health Information (PHI) as defined in
        HIPAA (The Health Insurance Portability and Accountability Act) for the
        purpose of providing or arranging treatment, payment or other health
        care operations. I also acknowledge that I am engaged by a covered
        entity. I further acknowledge my responsibility to protect the privacy
        of and to guard against inappropriate use or disclosure of this PHI by
        logging in as a Gentell Fastcare user.
      </p>
      <small>
        This is in compliance with "The Health Insurance Portability and
        Accountability Act (HIPAA) of 1996" and its implementation regulations.
        For more information, please see:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.hhs.gov/ocr/hipaa"
        >
          {" "}
          http://www.hhs.gov/ocr/hipaa{" "}
        </a>
        or
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.gentell.com/wp-content/uploads/2015/05/GentellHIPAApolicy.pdf"
        >
          {" "}
          Gentell's HIPAA Policy Statement
        </a>
      </small>
      <Modal
                 size="lg"
                aria-labelledby="newFacilityModal"
                scrollable backdrop="static"
                // dialogClassName="modal-90w"
                keyboard={false}
                show={showModalNewBT}
                onHide={handleCloseBTModal}>

                <Modal.Header className="py-3" closeButton>
                    <Modal.Title id="newFacilityModal">
                    Brightree Credentials
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SetBTCredentials user={user} CloseBtCrendentialsModal={CloseBtCrendentialsModal}/>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <Button variant="danger" size="sm" onClick={handleCloseBTModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
