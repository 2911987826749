import React from "react";
import { Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import Loader from "react-loader-spinner";
import moment from 'moment';
export default class SetBillingDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsLoading: true,
      isRemovePrimaryAndSecondary:false,
      facility:
      {
        facilityId: props.facilityId,
        // Current cycle date use to pass backend. Current billing date has format issue
        // currentCycleDate: props.billingDateDetail.billingDate,
        currentBillingDate: moment(props.billingDateDetail.primaryCycleDate).format('MM/DD/YYYY'),

         currentSecondaryBillingDate: moment(props.billingDateDetail.secondayBillingDate).format('MM/DD/YYYY'),

        newBillingDate: Common.replaceNullOrEmptyWithString(props.billingDateDetail.requestedCycleDate, ''),
        isRequested: props.billingDateDetail.isRequested,
        isPartial: props.billingDateDetail.isPartial,
        isSecondaryCycle: props.billingDateDetail.isSecondaryCycle,
        isRemoveSecondaryDate: false


        // newSecondayBillingDate: Common.replaceNullOrEmptyWithString(props.billingDateDetail.requestedSecondaryCycleDate, ''),
      }
    }
  }
  onSubmit() {
    debugger
    if (this.form.checkValidity() === false) {
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      const obj = this.state.facility;
      var dateDif = 0;
      // if (new Date(obj.newBillingDate) < new Date(obj.newSecondayBillingDate)) {
      //   dateDif = Common.getDifferenceInDays(new Date(obj.newBillingDate), new Date(obj.newSecondayBillingDate));
      // } else {
      //   dateDif = Common.getDifferenceInDays(new Date(obj.newSecondayBillingDate), new Date(obj.newBillingDate));

      // }
      debugger
      // if (dateDif < 8 ) {
      //   Common.showSuccessAlert(
      //     "Date should be 8 days or greater",
      //     "warning",
      //     "Warning!",
      //     3000
      //   );
      //   return false
      // }
      this.setState({ IsLoading: false });
      debugger

      Api.postRequest(Api.Uri_SetBillingDate, obj).then((res) => {
        if (res.data.success) {
          this.props.onSaveAPI();

          Common.showSuccessAlertAutoClose(
            "Code Verified Successfully",
            "success",
            "Success!",
            3000
          );
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Error!",
            3000
          );
        }
        this.setState({ IsLoading: true });
      });
    }
  }
  onChangeRemovePrimaryAndSecondary =(event)=>{
    debugger

    if(event.target.checked){
    
      // Common.withOutEventUpdateInputValueInState
      // (this.state.Resident.wound, this, this.state, 5, "fkWoundTypeId")
      // obj.newSecondayBillingDate=null;
      // obj.newBillingDate=null;
      var shipdDateDetail = this.state.facility;
      shipdDateDetail.newBillingDate=null;
      shipdDateDetail.newSecondayBillingDate=null;

      this.setState({isRemovePrimaryAndSecondary:event.target.checked, facility:shipdDateDetail})
    }else{
      this.setState({isRemovePrimaryAndSecondary:event.target.checked})  
    }

  }
  render() {
    return (
      <>  {
        (this.state.IsLoading == true) ?
          <div>
            <Form
              ref={(ref) => {
                this.form = ref;
              }}
              validated={this.state.formValidated}
              onSubmit={(e) => this.onSubmit(e)}
            >
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="doB">
                      Current Primary Ship Date {this.props.billingDateDetail.isSecondaryCycle==true?"(S)":""}
                    </label>
                    <input
                      type="date"
                      id="currentBillingDate"
                      disabled
                      className="form-control form-control-sm"
                      name="currentBillingDate"
                      defaultValue={Common.getInputStringDate(
                        new Date(this.state.facility.currentBillingDate)
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.facility,
                          e,
                          this,
                          this.state
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="doB">
                      Current Secondary Ship Date
                    </label>
                    <input
                      type="date"
                      id="currentSecondaryBillingDate"
                      disabled
                      className="form-control form-control-sm"
                      name="currentSecondaryBillingDate"
                      defaultValue={Common.getInputStringDate(
                        new Date(this.state.facility.currentSecondaryBillingDate)
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.facility,
                          e,
                          this,
                          this.state
                        )
                      }
                    />
                  </div>
                </div>
                {/* </div>
                <div className="row"> */}
               

              </div>
              <div className="row">
             

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="doB">
                      {this.props.billingDateDetail.isRequested ? "Request " : ""}
                      {"New Primary Ship Date" + (this.props.billingDateDetail.requestedCycleDate != null ? "(Requested Date)" : "")}
                    </label>
                    <input
                      type="date"
                      id="newBillingDate"
                      className="form-control form-control-sm"
                      max={Common.getInputStringDateUsingMoment(moment().add(3, 'M'))}
                      min={Common.getInputStringDateUsingMoment(moment().add(-1, 'M'))}
                     disabled={this.state.isRemovePrimaryAndSecondary}
                      name="newBillingDate"
                      required={!this.state.isRemovePrimaryAndSecondary}
                      value={ this.state.facility.newBillingDate==null ||
                          this.state.facility.newBillingDate==''?'':this.state.facility.newBillingDate}
                      // value={this.state.facility.newBillingDate==null ||
                      //   this.state.facility.newBillingDate==''?'': Common.getInputStringDate(
                      //   new Date(this.state.facility.newBillingDate)
                      // )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.facility,
                          e,
                          this,
                          this.state
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="doB">
                      {this.props.billingDateDetail.isRequested ? "Request " : ""}
                      {"New Secondary Ship Date" + (this.props.billingDateDetail.requestedCycleDate != null ? "(Requested Date)" : "")}
                    </label>
                    <input
                      type="date"
                      id="newSecondayBillingDate"
                      className="form-control form-control-sm"
                      max={Common.getInputStringDateUsingMoment(moment().add(3, 'M'))}
                      min={Common.getInputStringDateUsingMoment(moment().add(-1, 'M'))}
                      disabled={this.state.isRemovePrimaryAndSecondary}
                      name="newSecondayBillingDate"
                      required={!this.state.facility.isRemoveSecondaryDate || !this.state.isRemovePrimaryAndSecondary}
                      // value={this.state.facility.newSecondayBillingDate==null ||
                      //   this.state.facility.newSecondayBillingDate==''?'': Common.getInputStringDate(
                      //   new Date(this.state.facility.newSecondayBillingDate)
                      // )}
                      value={ this.state.facility.newSecondayBillingDate==null ||
                        this.state.facility.newSecondayBillingDate==''?'':this.state.facility.newSecondayBillingDate}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.facility,
                          e,
                          this,
                          this.state
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                { (!this. props.billingDateDetail.isRequested
        && !this. props.billingDateDetail.isPartial) &&(
                    <div className="form-group mt-2 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="isRemovePrimaryAndSecondary"
                          name="isRemovePrimaryAndSecondary"
                          // disabled={}
                          defaultChecked={Common.replaceNullWithString(
                            this.state.isRemovePrimaryAndSecondary,
                            false
                          )}
                          onChange={(e) =>    this.onChangeRemovePrimaryAndSecondary(e)}
                        />
                        <span className="mr-3" />
                        Remove Primary and Secondary
                      </label>
                    </div>
                  ) }
                  </div>
                { (!this. props.billingDateDetail.isRequested
        && !this. props.billingDateDetail.isPartial) &&(
                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <div className="form-group mt-2 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="isRemoveSecondaryDate"
                          name="isRemoveSecondaryDate"
                          // disabled={}
                          defaultChecked={Common.replaceNullWithString(
                            this.state.facility.isRemoveSecondaryDate,
                            false
                          )}
                          onChange={(e) =>    Common.updateInputValueInState(
                            this.state.facility,
                            e,
                            this,
                            this.state
                          )}
                        />
                        <span className="mr-3" />
                        Remove Secondary Ship Date
                      </label>
                    </div>
                  </div>
                  ) }
              </div>
            </Form>
          </div> :
          <div className="d-flex justify-content-center">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
      }</>


    );
  }
}

