/*eslint-disable */

import React from "react";
import AsyncSelect from "react-select/async";
import { Animated } from "react-animated-css";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";

export default class FacilityContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValidated: false,
            isEdit: false,
            insurancesList: [],
            insuranceSelect: [],
            insuranceLevels: [],
            showModalAddInsurance: false,
            AddPhysicianComponent: '',
            residentInsuranceListUI: [],
            residentInsuranceList: props.residentInsuranceList,
            primaryPhysiciansInputValue: '',
            primaryPhysician: null,
            ResidentInsurance:
            {
                idR: 0,
                fkResidentId: props.residentID,
                fkInsuranceIdR: '',
                fkInsuranceLevelIdR: '',
                active: true,
                startDate: '',
                endDate: '',
                policyNoR: '',
                payPct: '',
                insuranceName: '',
                insuranceLevel: '',
                isAddEdit: false,



            }

        };

    }
    componentDidMount() {
        debugger

        this.setResidentInsuranceList(this.state.residentInsuranceList);

        // this.getAllDropdowns();
    }

    //#region  add person

    getInsuranceList = () => {
        return this.state.residentInsuranceList;
    }
    handleCloseAdd = () => {

        this.setState({ showModalAddInsurance: false })
        // if(this.props.residentID!=null){
        // this.GetResidentInsurance();
        // }

    };

    handleShowAddInsurance = () => {
        var Insurance =
        {
            idR: 0,
            fkResidentId: this.props.residentID,
            fkInsuranceIdR: '',
            fkInsuranceLevelIdR: '',
            active: true,
            startDate: '',
            endDate: '',
            policyNoR: '',
            payPct: '',
            insuranceName: '',
            insuranceLevel: '',
            isAddEdit: false,



        }
        this.setState({ ResidentInsurance: Insurance })

        //  this.setState({ AddPhysicianComponent: <AddInsurancmodal insurance={this.state.Insurance} close={this.handleCloseAdd} /> })
        this.setState({ showModalAddInsurance: true, isEdit: false })

    };



    //#region loop to  print the physician firm list

    // GetResidentInsurance = () => {
    //     debugger
    //     Api.getRequestById(Api.URI_GetAllResidentInsurance, this.props.residentID).then((res) => {

    //         if (res.data.statusCode == 200) {
    //             if (res.data.result != null) {
    //                 if (res.data.result.length > 0) {
    //         this.setState({ residentInsuranceList: res.data.result });

    //                     this.setResidentInsuranceList(res.data.result);
    //                 }
    //             }
    //         }

    //         Common.LoadDataTable("physicianList")
    //     }).catch((ex) => {
    //     });
    // }
    updateResidentActiveInActive = (obj) => {
        var msg = "You want to remove insurance";
        Common.showConfirmation(msg, "", this.removeInsurance, null, obj)

    };
    removeInsurance = (insurance) => {
        if (insurance) {
            let savedInsurance = JSON.parse(JSON.stringify(this.state.residentInsuranceList.filter(obj => {
                return obj.fkInsuranceLevelId != insurance.fkInsuranceLevelId
            })));
            this.setState({ residentInsuranceList: savedInsurance });
            this.setResidentInsuranceList(savedInsurance);
            Common.showSuccessAlertAutoClose("Resident insurance Updated Successfully", "success", "Success!", 3000)

        };

    }
    setResidentInsuranceList = (list) => {
        debugger
        // // debugger
        let rows = [];
        try {
            for (var i = 0; i < list.length; i++) {
                // // debugger
                const obj = list[i];

                rows.push(<tr key={i.toString()}>
                    <td>{Common.replaceNullWithString(obj.insuranceName)}</td>
                    <td>{obj.fkInsuranceLevelId == 1 ? "Primary"
                        : obj.fkInsuranceLevelId == 2 ? "Secondary" : obj.fkInsuranceLevelId == 3 ? "Tertiary"
                            : "N/A"}</td>
                    {/* <td>{(obj.startDate)}</td>
                    <td>{(obj.endDate)}</td> */}
                    <td>{Common.replaceNullWithString(obj.policyNo) != '' ? obj.policyNo : 'N/A'}</td>
                    <td align="center">
                        <div className="text-center">

                            <DropdownButton
                                drop={"down"}
                                size="xs"
                                variant="primary"
                                title={'Actions'}
                                id={`dropdown-button-drop-actions`}
                                key={'down'}>

                                {/* <Dropdown.Item eventKey={"1" + i.toString()} onClick={() => handleShowAdd(obj)}>Edit</Dropdown.Item> */}
                                <Dropdown.Item eventKey={"2" + i.toString()} onClick={() => this.updateInsurance(obj)}>Edit</Dropdown.Item>
                                <Dropdown.Item eventKey={"2" + i.toString()} onClick={() => this.updateResidentActiveInActive(obj)}>Remove</Dropdown.Item>


                            </DropdownButton>
                        </div>
                    </td>
                </tr>);
            }
            this.setState({ residentInsuranceListUI: rows });
            // // debugger
        } catch (error) {
        }
    }

    //#endregion



    updateInsurance = (obj) => {

        // var insurance =
        // {
        //     insuranceName: obj.insuranceName,
        //     insuranceLevel: obj.insuranceLevel,
        //     fkInsuranceLevelIdR: obj.fkInsuranceLevelId,
        //     fkInsuranceIdR: obj.fkInsuranceId,
        //     policyNoR: obj.policyNo,
        //     idR: obj.id,
        //     isAddEdit: true
        // }
        // this.setState({ ResidentInsurance: insurance })
        this.setState({ showModalAddInsurance: true, isEdit: true })

    }


    // async getAllDropdowns() {
    //     Api.getRequest(Api.Uri_GetInsurances).then((res) => {

    //         if (res.data.statusCode == 200) {
    //             if (res.data.result != null) {
    //                 if (res.data.result.length > 0) {
    //                     this.setState({ insurancesList: res.data.result })
    //                    var insuranceSelect= res.data.result.map(insurance => (
    //                         <option
    //                             key={insurance.key}
    //                             value={insurance.key}
    //                         >
    //                             {insurance.value}
    //                         </option>
    //                     ))
    //                     this.setState({insuranceSelect:insuranceSelect})
    //                 }
    //             }
    //         }

    //     }).catch((ex) => {
    //     });
    // }

    // setAllinsurances(insurances) {
    //     return 
    // }
    //#endregion

    handleSubmit = (event) => {
        debugger
        const form = event.currentTarget.form;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {
            this.URI_UpsertResidentInsurance();
        }
    }
  
    URI_UpsertResidentInsurance() {
        debugger
        // fkInsuranceId: '',
        //         fkInsuranceLevelId
        // insuranceName:'',
        // InsuranceLevel:''
        let obj = this.state.ResidentInsurance;
        //    this.state.insurancesList.map(ins => {
        //         if (ins.key == obj.fkInsuranceIdR) {
        //             obj.insuranceName = ins.value;
        //         }

        //     })

        if (obj.fkInsuranceLevelIdR == 1) {
            obj.insuranceLevel = "Primary";
        }
        else if (obj.fkInsuranceLevelIdR == 2) {
            obj.insuranceLevel = "Secondary";
        }
        else if (obj.fkInsuranceLevelIdR == 3) {
            obj.insuranceLevel = "Tertiary";
        }
        else {
            obj.insuranceLevel = "";
        }
        //   var isNew=true;
        let savedInsurance = JSON.parse(JSON.stringify(this.state.residentInsuranceList.map(ins => {
            if (obj.isAddEdit && obj.idR == ins.id && obj.fkInsuranceLevelIdR == ins.fkInsuranceLevelId) {
                ins.fkInsuranceLevelId = obj.fkInsuranceLevelIdR;
                ins.insuranceLevel = obj.insuranceLevel;
                ins.insuranceName = obj.insuranceName;
                ins.fkInsuranceId = obj.fkInsuranceIdR;

                ins.policyNo = obj.policyNoR;



            }
            return ins
        })));
        if (!obj.isAddEdit) {
            savedInsurance.push({

                insuranceName: obj.insuranceName,
                insuranceLevel: obj.insuranceLevel,
                fkInsuranceLevelId: obj.fkInsuranceLevelIdR,
                fkInsuranceId: obj.fkInsuranceIdR,
                policyNo: obj.policyNoR,
                id: 0,
                isAddEdit: true,
                fkResidentId: this.props.residentID

            });
        }
        var count = 0;
        savedInsurance.map(ins => {
            if (ins.fkInsuranceLevelId == obj.fkInsuranceLevelIdR) {
                debugger
                count++
            }

        })
        if (count > 1) {
            Common.showSuccessAlert(obj.insuranceLevel + " level is already assigned", "warning", "warning!", 3000);
            return false;
        }
        this.setState({ residentInsuranceList: savedInsurance });
        this.setResidentInsuranceList(savedInsurance);
        this.props.selectInsurance(savedInsurance);
        Common.showSuccessAlertAutoClose("Insurance Created Successfully", "success", "Success!", 3000);
        this.setState({ showModalAddInsurance: false })
    }



    render() {
        return (


            <>
                {this.state.showModalAddInsurance && <Animated animationIn="fadeInLeft" animationInDuration={1000}>

                    {/* {this.state.AddPhysicianComponent} */}
                    <Form
                        validated={this.state.formValidated}
                    >

                        <>

                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="State">Name</label>
                                 
                                        <input
                            type="text"
                            id="FacilityName"
                            name="facilityName"
                            required
                            // defaultValue={this.state.Facilty.facilityName}

                            // onChange={(e) =>
                            //   Common.updateInputValueInState(
                            //     this.state.Facilty,
                            //     e,
                            //     this,
                            //     this.state
                            //   )
                            // }
                            className="form-control form-control-sm"
                          />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="State">Position</label>
                                        <input
                                type="text"
                                id="partBrecPersonEmail"
                                name="partBrecPersonEmail"
                                // defaultValue={this.state.Facilty.partBrecPersonEmail}
                                // onChange={(e) =>
                                //   Common.updateInputValueInState(
                                //     this.state.Facilty,
                                //     e,
                                //     this,
                                //     this.state
                                //   )
                                // }
                                className="form-control form-control-sm"
                              />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="State">Email</label>
                                        <input
                                type="email"
                                id="partBrecPersonEmail"
                                name="partBrecPersonEmail"
                                // defaultValue={this.state.Facilty.partBrecPersonEmail}
                                // onChange={(e) =>
                                //   Common.updateInputValueInState(
                                //     this.state.Facilty,
                                //     e,
                                //     this,
                                //     this.state
                                //   )
                                // }
                                className="form-control form-control-sm"
                              />
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Phone1">
                            Phone
                          </label>
                          <input
                            type="phone"
                            id="Phone1"
                            name="phone1"
                            // value={Common.getFormattedPhoneNum(this.state.Facilty.address.phone1)}
                            // onChange={(e) =>
                            //   Common.updateInputValueInState(
                            //     this.state.Facilty.address,
                            //     e,
                            //     this,
                            //     this.state
                            //   )
                            // }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Phone1">
                            Fax
                          </label>
                          <input
                            type="text"
                            id="Fax"
                            name="fax"
                            // value={Common.getFormattedPhoneNum(this.state.Facilty.address.fax)}
                            // onChange={(e) =>
                            //   Common.updateInputValueInState(
                            //     this.state.Facilty.address,
                            //     e,
                            //     this,
                            //     this.state
                            //   )
                            // }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                            </div>
                        </>

                        <div className="d-flex justify-content-end py-3">
                            <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Save</Button>
                            <Button variant="danger" size="sm" onClick={() => this.handleCloseAdd()}>Cancel</Button>
                        </div>

                    </Form>

                </Animated>}

             
                {/* <div className="alert border-primary bg-transparent text-primary fade show" role="alert"> */}
                <div className="d-flex align-items-center">
                  
                    <div className="flex-1">
                        {/* <span className="h5 m-0 fw-700">Use buttons to do more actions </span> */}
                    </div>
                    <Button variant="primary" className="mr-5" size="sm" onClick={this.handleShowAddInsurance}>Add New Contact</Button>

                </div>
                <table className="table table-striped table-hover table-sm table-bordered" id="physicianList">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Fax</th>
                            <th width="130px">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                            <td>Test Name</td>
                            <td>Test Position</td>
                            <td>test@gmail.com</td>
                            <td>3007215757</td>
                            <td>3007215757</td>
                            <td align="center">
                        <div className="text-center">

                            <DropdownButton
                                drop={"down"}
                                size="xs"
                                variant="primary"
                                title={'Actions'}
                                id={`dropdown-button-drop-actions`}
                                key={'down'}>

                                {/* <Dropdown.Item eventKey={"1" + i.toString()} onClick={() => handleShowAdd(obj)}>Edit</Dropdown.Item> */}
                                <Dropdown.Item eventKey={"2"} onClick={() => this.updateInsurance()}>Edit</Dropdown.Item>


                            </DropdownButton>
                        </div>
                    </td>
                        </tr>
                        {/* {this.state.residentInsuranceListUI} */}
                    </tbody>
                </table>


            </>
        );
    }
}
