import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select';
import { Card } from '../../../_metronic/_partials/controls/Card';
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Paper
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
  },
  button: {
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    'Facility',
    'Counts',
    'Residents',
    'Recommendation',
    'Points for Consideration',
    'Additional Information',
    'Finish'];
}

export default function PostVisitEmail() {
  const [facilityText, setFacilityText] = useState('');
  const [woundProvider, setWoundProvider] = useState(false);
  const [remoteVisit, setRemoteVisit] = useState(false);
  const [residentCount, setResidentCount] = useState(0);
  const [woundCount, setWoundCount] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [programType, setProgramType] = useState('');
  const [residents, setResidents] = useState([]);
  const [residentsSupplies, setResidentsSupplies] = useState([]);
  const [noSuppliesDueTo, setNoSuppliesDueTo] = useState([]);
  const [exitedWith, setExitedWith] = useState([]);
  const [noSuppliesGroup, setNoSuppliesGroup] = useState([]);
  const [residentRecommendationGroup, setResidentRecommendationGroup] = useState([]);
  const [residentDocumentationGroup, setResidentDocumentationGroup] = useState([]);
  const [residentWoundClosedGroup, setResidentWoundClosedGroup] = useState([]);
  const [residentTreatmentChangedGroup, setResidentTreatmentChangedGroup] = useState([]);
  const [residentCMSComplianceGroup, setResidentCMSComplianceGroup] = useState([]);
  const [residentPressureRelievingDeviceGroup, setResidentPressureRelievingDeviceGroup] = useState([]);
  const [positiveObservationGroup, setPositiveObservationGroup] = useState([]);
  const [educationTopicGroup, setEducationTopicGroup] = useState([]);
  const [upcomingVisitEducationTopicGroup, setUpcomingVisitEducationTopicGroup] = useState([]);
  const [whatDidYouDoGroup, setWhatDidYouDoGroup] = useState([]);
  const [datePickerVisitedDisplay, setDatePickerVisitedDisplay] = useState('');
  const [datePickerVisited, setDatePickerVisited] = useState(null);
  const [topic, setTopic] = useState('');
  const [additionalInformationTopic, setAdditionalInformationTopic] = useState('');
  const [noSuppliesText, setNoSuppliesText] = useState('');
  const [woundTeamOrPersonText, setWoundTeamOrPersonText] = useState('');
  const [residentRecommendation, setResidentRecommendation] = useState('');
  const [residentDocumentation, setResidentDocumentation] = useState('');
  const [residentWoundClosed, setResidentWoundClosed] = useState('');
  const [residentTreatmentChanged, setResidentTreatmentChanged] = useState('');
  const [woundLocationCMSCompliance, setWoundLocationCMSCompliance] = useState('');
  const [positiveObservation, setPositiveObservation] = useState('');
  const [educationTopic, setEducationTopic] = useState('');
  const [upcomingVisitEducationTopic, setUpcomingVisitEducationTopic] = useState('');
  const datePicker = useRef(null);
  const educationScheduleDatePicker = useRef(null);
  const nextVisitDatePicker = useRef(null);
  const exitedDate = useRef(null);
  const residentToAdd = useRef(null);
  const noSuppliesResident = useRef(null);
  const residentNameRecommendation = useRef(null);
  const residentNameDocumentation = useRef(null);
  const residentNameWoundClosed = useRef(null);
  const residentNameTreatmentChanged = useRef(null);
  const residentNameCMSCompliance = useRef(null);
  const residentNamePressureRelieving = useRef(null);
  const pressureRelievingDevice = useRef(null);
  const whatDidYouDo = useRef(null);

  const onSiteVisitOptions = [
    { value: 1, label: 'Rounded with' },
    { value: 2, label: 'Educated' },
    { value: 3, label: 'Met with' },
  ];

  const programsTypesOptions = [
    { value: 1, label: 'Part B' },
    { value: 2, label: 'Equal Care' },
    { value: 3, label: 'Part B and Equal Care' },
  ];

  const noSuppliesDueToOptions = [
    { value: 1, label: 'Primary insurance will not cover products - Medicare A/Skilled/Straight Medicaid.' },
    { value: 2, label: 'Current treatment is not covered under coverage guidelines.' },
    { value: 3, label: 'Resident already received products (during this cycle).' },
    { value: 4, label: 'Other Reason: ' },
  ];

  const pressureRelievingDevicesOptions = [
    { value: 1, label: 'Over inflated' },
    { value: 2, label: 'Under inflated' },
    { value: 3, label: 'Not present' },
  ]

  const pointsForConsiderationOptions = [
    { value: 1, label: 'Missing Documentation' },
    { value: 2, label: 'Wound Closed' },
    { value: 3, label: 'Treatment Changed' },
    { value: 4, label: 'Assessment Is Out Of CMS Compliance' },
  ]

  const additionalInformationOptions = [
    { value: 1, label: 'Pressure relieving device' },
    { value: 2, label: 'Positive observation' },
    { value: 3, label: 'Wound and product education' },
    { value: 4, label: 'Upcoming visit' },
  ]

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleReset() {
    setActiveStep(0);
  }

  const getTemplateEmailElement = () => {
    return document.getElementById('emailTextPlace');
  }

  useEffect(() => {
    getTemplateEmailElement().innerHTML = facilityText ? `Hello: <strong>${facilityText}</strong>,<br>` : '';
    getTemplateEmailElement().innerHTML += datePickerVisitedDisplay ? `Thank you for the opportunity to visit your facility on: <strong>${datePickerVisitedDisplay}</strong> <br>` : '';
    getTemplateEmailElement().innerHTML += remoteVisit ? 'This was a remote visit. <br>' : '';
    getTemplateEmailElement().innerHTML += !remoteVisit && whatDidYouDoGroup.length > 0 ? `During today's visit, I <br> <strong>${whatDidYouDoGroup.map((value, index) => { return '&nbsp; &nbsp; &nbsp;' + (index + 1) + '. ' + value.dropdownValue + ': ' + value.text + ' <br>' }).join('')}</strong><br>` : '';
    getTemplateEmailElement().innerHTML += woundProvider ? 'The Wound Provider joined rounds today.' : '';
    getTemplateEmailElement().innerHTML += residentCount ? `During wound rounds, we reviewed a total of <strong>${residentCount}</strong> residents,` : '';
    getTemplateEmailElement().innerHTML += woundCount ? ` with a total of <strong>${woundCount}</strong> wounds. <br>` : '';
    getTemplateEmailElement().innerHTML += productsCount ? `Gentell will be able to provide wound products for <strong>${productsCount}</strong> residents. <br>` : '';
    getTemplateEmailElement().innerHTML += programType ? `The following residents will receive supplies through the <strong>${programType.label}</strong> programs: <br>` : '';
    getTemplateEmailElement().innerHTML += residentsSupplies.length > 0 ? `<strong>${residentsSupplies.map((resident, index) => { return '&nbsp; &nbsp; &nbsp;' + (index + 1) + '. ' + resident.label + ' <br>' }).join('')}</strong><br>` : '';
    getTemplateEmailElement().innerHTML += noSuppliesGroup.length > 0 ? `The following residents were reviewed, but will not receive wound products: <br> <strong>${noSuppliesGroup.map((value, index) => { return '&nbsp; &nbsp; &nbsp;' + (index + 1) + '. ' + value.resident + ' - ' + value.dropdownValue + value.text + ' <br>' }).join('')}</strong><br>` : '';
    getTemplateEmailElement().innerHTML += residentRecommendationGroup.length > 0 ? 'The product(s) ordered cannot be supplied. Based upon the current wound assessment, please consider the following dressing recommendations so products can be provided under the coverage guidelines and Part B program: <br>' : '';
    getTemplateEmailElement().innerHTML += residentRecommendationGroup.length > 0 ? `<strong>${residentRecommendationGroup.map((value, index) => { return '&nbsp; &nbsp; &nbsp;' + (index + 1) + '. ' + value.resident + ' - Recommendation: ' + value.text + ' <br>' }).join('')}</strong>` : '';
    getTemplateEmailElement().innerHTML += residentRecommendationGroup.length > 0 ? 'Please reach out to your Gentell Wound & Product Specialist ASAP if the treatment is changed so that we may provide the resident with their needed wound products. <br>' : '';
    getTemplateEmailElement().innerHTML += (residentDocumentationGroup.length > 0
      || residentWoundClosedGroup.length) ? 'Our review revealed the following <br>' : '';
    getTemplateEmailElement().innerHTML += residentDocumentationGroup.length > 0 ? `&nbsp; &nbsp; &nbsp; Missing or incomplete documentation on wound assessments or TAR: <br> <strong>${residentDocumentationGroup.map((value, index) => { return '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;' + (index + 1) + '. ' + value.resident + ': ' + value.text + '<br>' }).join('')}</strong>` : '';
    getTemplateEmailElement().innerHTML += residentWoundClosedGroup.length > 0 ? `&nbsp; &nbsp; &nbsp; Wound is currently closed - treatment should be dc'd'` : '';
  }, [
    facilityText,
    datePickerVisitedDisplay,
    remoteVisit,
    whatDidYouDoGroup,
    woundProvider,
    residentCount,
    woundCount,
    productsCount,
    programType,
    residentsSupplies,
    noSuppliesGroup,
    residentRecommendationGroup,
    residentDocumentationGroup,
    residentWoundClosedGroup
  ]);

  const handleDatePickerChange = (selectedDates, dateStr, instance) => {
    if (selectedDates) {
      setDatePickerVisitedDisplay(selectedDates[0].toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }));
      setDatePickerVisited(dateStr)
    }
  }

  const datePickerRef = useCallback((node) => {
    if (node !== null) {
      datePicker.current = flatpickr(node, {
        mode: 'single',
        onChange: handleDatePickerChange,
      })
    }
  }, []);


  const nextVisitDatePickerRef = useCallback((node) => {
    if (node !== null) {
      nextVisitDatePicker.current = flatpickr(node, {
        mode: 'single',
        onChange: handleDatePickerChange,
      })
    }
  }, []);

  const exitedDatePickerRef = useCallback((node) => {
    if (node !== null) {
      exitedDate.current = flatpickr(node, {
        mode: 'single',
        onChange: handleDatePickerChange,
      })
    }
  }, []);

  const educationScheduleDatePickerRef = useCallback((node) => {
    if (node !== null) {
      educationScheduleDatePicker.current = flatpickr(node, {
        mode: 'single',
        onChange: handleDatePickerChange,
      })
    }
  }, []);

  const handleResidentToAdd = () => {
    if (residentToAdd.current) {
      setResidents([...residents, { value: residentToAdd.current, label: residentToAdd.current }])
    }
  }

  const handleAddToGroup = (resident, text, setTextFunction, group, setGroupFunction, dropdownValue) => {
    setGroupFunction([...group, { resident: resident?.current?.label, dropdownValue: (dropdownValue?.label || dropdownValue?.current?.label), text: (text || '') }]);
    if (text)
      setTextFunction('');
  }

  const handleDeleteFromGroup = (index, group, setGroupFunction) => {
    const newGroup = group.filter((item, i) => i !== index);
    setGroupFunction(newGroup);
  }

  const createDisplayGroup = (item, index, group, setGroupFunction) => {
    return (
      <li key={index}>
        <span>{item.resident}</span>
        <span>{item.dropdownValue}</span>
        <span>{item.dueTo}</span>
        <span>{item.text}</span>
        <button className='btn btn-danger' onClick={() => handleDeleteFromGroup(index, group, setGroupFunction)}>Delete</button>
      </li>)
  }

  const checkNextButtonDisabledCondition = () => {
    if (activeStep === steps.indexOf(steps[0])) {
      return !facilityText || !datePickerVisited || remoteVisit ? !remoteVisit : whatDidYouDoGroup.length === 0;
    } else {
      return false;
    }
  }

  return (
    <div className='col-12'>
      <div className='row'>
        <div className='col-12'>
          <Card className='align-items-center'>
            <div className='col-12'>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                </Paper>
              )}
            </div>
          </Card>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <Card className='p-5'>
            <div className='row'>
              <div className='col-12'>
                {activeStep === steps.indexOf(steps[0]) &&
                  <>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='facilityName'>Facility name</label>
                          <input type='text'
                            id='facilityName'
                            name='facilityName'
                            className='form-control form-control-md'
                            placeholder='Facility name'
                            onChange={(e) => { setFacilityText(e.target.value) }}
                            value={facilityText} />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className="form-label" htmlFor="datePicker">Facility visited on</label>
                          <input type="date" id='datePicker' placeholder='Select...' className="form-control form-control-md" ref={datePickerRef} defaultValue={datePickerVisited} />
                        </div>
                      </div>
                      <div className={!remoteVisit ? 'col-12 border rounded' : 'col-12'}>
                        <div className='form-group'>
                          <FormControlLabel
                            className='m-0'
                            control={
                              <Checkbox
                                color='secondary'
                                checked={remoteVisit}
                                value={remoteVisit}
                                onChange={(e) => setRemoteVisit(e.target.checked)}
                              />}
                            label='This was a remote visit?'
                            labelPlacement='start'
                          />
                          {
                            !remoteVisit &&
                            <div className='row'>
                              <div className='col-12 mb-4'>
                                <label className='form-label' htmlFor='roleSelect'>What did you do</label>
                                <Select
                                  options={onSiteVisitOptions}
                                  id='roleSelect'
                                  name='roleSelect'
                                  isClearable
                                  onChange={(e) => { whatDidYouDo.current = e }}
                                  useRef={whatDidYouDo}
                                />
                              </div>
                              <div className='col-12'>
                                <label className='form-label' htmlFor='woundTeamOrPerson'>Wound team or person</label>
                                <input type='text'
                                  id='woundTeamOrPerson'
                                  name='woundTeamOrPerson'
                                  className='form-control form-control-md'
                                  placeholder='Wound team or person'
                                  onChange={(e) => { setWoundTeamOrPersonText(e.target.value) }}
                                  value={woundTeamOrPersonText} />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <FormControlLabel
                            className='m-0'
                            control={
                              <Checkbox
                                color='secondary'
                                checked={woundProvider}
                                value={woundProvider}
                                onChange={(e) => setWoundProvider(e.target.checked)}
                              />}
                            label='Did the wound provider joined round'
                            labelPlacement='start'
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <button className='btn btn-primary' onClick={() => handleAddToGroup(null,
                            woundTeamOrPersonText, setWoundTeamOrPersonText, whatDidYouDoGroup, setWhatDidYouDoGroup, whatDidYouDo)}>Add</button>
                        </div>
                      </div>
                      {
                        whatDidYouDoGroup.length > 0 && (
                          <div className='col-12'>
                            <div className='form-group'>
                              <label className='form-label'>What did i do group</label>
                              <ul>
                                {whatDidYouDoGroup.map((item, index) => (createDisplayGroup(item, index, whatDidYouDoGroup, setWhatDidYouDoGroup)))}
                              </ul>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </>
                }
                {activeStep === steps.indexOf(steps[1]) &&
                  <>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='residentCount'>Total of residents reviewed</label>
                          <TextField
                            id='residentCount'
                            className='form-control form-control-md'
                            color='secondary'
                            value={residentCount}
                            onChange={(e) => { setResidentCount(e.target.value) }}
                            type='number'
                            variant='outlined'
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='Wounds'>Total of wounds</label>
                          <TextField
                            id='woundsCount'
                            className='form-control form-control-md'
                            color='secondary'
                            value={woundCount}
                            onChange={(e) => { setWoundCount(e.target.value) }}
                            type='number'
                            variant='outlined'
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='Wounds'>Gentell will be able to provide wound products for</label>
                          <TextField
                            id='productsCount'
                            className='form-control form-control-md'
                            color='secondary'
                            value={productsCount}
                            onChange={(e) => { setProductsCount(e.target.value) }}
                            type='number'
                            variant='outlined'
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
                {activeStep === steps.indexOf(steps[2]) &&
                  <>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='addResident'>Add resident</label>
                          <div className="input-group">
                            <input type='text' onChange={(e) => residentToAdd.current = e.target.value} className='form-control' placeholder='resident name...' />
                            <div className='input-group-append'>
                              <button className='btn btn-primary' type='button' onClick={handleResidentToAdd}>Add</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className='mb-2'> The following residents will receive supplies</span>
                      <div className='col-12 border rounded mb-8'>
                        <div className='col-12'>
                          <div className='form-group'>
                            <label className='form-label' htmlFor='programType'>Program type for supplies</label>
                            <Select
                              options={programsTypesOptions}
                              id='programType'
                              name='programType'
                              isClearable
                              onChange={(e) => { setProgramType(e) }}
                              value={programType}
                            />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='form-group'>
                            <label className='form-label' htmlFor='residentsSupplies'>Residents</label>
                            <Select
                              options={residents}
                              id='residentsSupplies'
                              name='residentsSupplies'
                              isClearable
                              isMulti
                              onChange={(e) => { setResidentsSupplies(e) }}
                              value={residentsSupplies}
                            />
                          </div>
                        </div>
                      </div>
                      <span className='mb-2'>The following residents were reviewed, but will not receive wound products</span>
                      <div className='col-12 border rounded'>

                        <div className='col-12'>
                          <div className='form-group'>
                            <label className='form-label' htmlFor='residentName'>Resident Name</label>
                            <Select
                              options={residents}
                              id='residentName'
                              name='residentName'
                              isClearable
                              onChange={(e) => noSuppliesResident.current = e}
                              useRef={noSuppliesResident}
                            />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='form-group'>
                            <label className='form-label' htmlFor='suppliesType'>No supplies due to</label>
                            <Select
                              options={noSuppliesDueToOptions}
                              id='suppliesDue'
                              name='suppliesDue'
                              isClearable
                              onChange={(e) => setNoSuppliesDueTo(e)}
                              value={noSuppliesDueTo}
                            />
                          </div>
                        </div>
                        {
                          noSuppliesDueTo && noSuppliesDueTo.value === 4 && (
                            <div className='col-12'>
                              <div className='form-group'>
                                <label className='form-label' htmlFor='noSuppliesText'>Other Reason</label>
                                <TextField
                                  id='noSuppliesText'
                                  name='noSuppliesText'
                                  multiline
                                  row='4'
                                  color='secondary'
                                  className='form-control form-control-md'
                                  variant="outlined"
                                  onChange={(e) => { setNoSuppliesText(e.target.value) }}
                                  value={noSuppliesText}
                                />
                              </div>
                            </div>
                          )
                        }
                        <div className='col-12'>
                          <div className='form-group'>
                            <button className='btn btn-primary' onClick={() => handleAddToGroup(noSuppliesResident,
                              noSuppliesText, setNoSuppliesText, noSuppliesGroup, setNoSuppliesGroup, noSuppliesDueTo)}>Add</button>
                          </div>
                        </div>
                        {
                          noSuppliesGroup.length > 0 && (
                            <div className='col-12'>
                              <div className='form-group'>
                                <label className='form-label'>No supplies group</label>
                                <ul>
                                  {noSuppliesGroup.map((item, index) => (createDisplayGroup(item, index, noSuppliesGroup, setNoSuppliesGroup)))}
                                </ul>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </>
                }
                {activeStep === steps.indexOf(steps[3]) &&
                  <>
                    <div className='row'>
                      <span className='mb-2'>The product(s) ordered cannot be supplied. Based upon the current wound assessment, please consider the following dressing recommendations so products can be provided under the coverage guidelines and Part B program:</span>
                      <div className='border rounded'>
                        <div className='col-12'>
                          <div className='form-group'>
                            <label className='form-label' htmlFor='residentNameRecommendation'>Resident Name</label>
                            <Select
                              options={residents}
                              id='residentNameRecommendation'
                              name='residentNameRecommendation'
                              isClearable
                              onChange={(e) => { residentNameRecommendation.current = e }}
                              useRef={residentNameRecommendation}
                            />
                          </div>
                        </div>
                        <div className='col-12 mb-8'>
                          <div className='form-group'>
                            <label className='form-label' htmlFor='residentRecommendation'>Recommendation</label>
                            <TextField
                              id='residentRecommendation'
                              name='residentRecommendation'
                              multiline
                              row='4'
                              color='secondary'
                              className='form-control form-control-md'
                              variant="outlined"
                              value={residentRecommendation}
                              onChange={(e) => { setResidentRecommendation(e.target.value) }}
                            />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='form-group'>
                            <button className='btn btn-primary' onClick={() => handleAddToGroup(
                              residentNameRecommendation, residentRecommendation, setResidentRecommendation, residentRecommendationGroup, setResidentRecommendationGroup)}>Add</button>
                          </div>
                        </div>
                        {
                          residentRecommendationGroup.length > 0 && (
                            <div className='col-12'>
                              <div className='form-group'>
                                <label className='form-label'>Recommendation group</label>
                                <ul>
                                  {residentRecommendationGroup.map((item, index) => (createDisplayGroup(item, index, residentRecommendationGroup, setResidentRecommendationGroup)))}
                                </ul>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </>
                }
                {activeStep === steps.indexOf(steps[4]) &&
                  <>
                    <div className='row'>
                      <span className='mb-2'>Our review revealed the following</span>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='topics'>Topic</label>
                          <Select
                            options={pointsForConsiderationOptions}
                            id='topics'
                            name='topics'
                            isClearable
                            onChange={(e) => { setTopic(e) }}
                            value={topic}
                          />
                        </div>
                      </div>
                      {
                        topic && topic.value === 1 && (
                          <div className='border rounded'>
                            <div className='col-12'>
                              <div className='form-group'>
                                <label className='form-label' htmlFor='residentNameDocumentation'>Resident</label>
                                <Select
                                  options={residents}
                                  id='residentNameDocumentation'
                                  name='residentNameDocumentation'
                                  isClearable
                                  onChange={(e) => { residentNameDocumentation.current = e }}
                                  useRef={residentNameDocumentation}
                                />
                              </div>
                            </div>
                            <div className='col-12'>
                              <div className='form-group'>
                                <label className='form-label' htmlFor='residentDocumentationText'>Missing Documentation</label>
                                <TextField
                                  id='residentDocumentationText'
                                  name='residentDocumentationText'
                                  multiline
                                  row='4'
                                  color='secondary'
                                  className='form-control form-control-md'
                                  variant="outlined"
                                  onChange={(e) => { setResidentDocumentation(e.target.value) }}
                                  value={residentDocumentation}
                                />
                              </div>
                            </div>
                            <div className='col-12'>
                              <div className='form-group'>
                                <button className='btn btn-primary' onClick={() => handleAddToGroup(
                                  residentNameDocumentation, residentDocumentation, setResidentDocumentation, residentDocumentationGroup, setResidentDocumentationGroup
                                )}>Add</button>
                              </div>
                            </div>
                            {
                              residentDocumentationGroup.length > 0 && (
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label className='form-label'>Documentation group</label>
                                    <ul>
                                      {residentDocumentationGroup.map((item, index) => (createDisplayGroup(item, index, residentDocumentationGroup, setResidentDocumentationGroup)))}
                                    </ul>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )
                      }
                      {
                        topic && topic.value === 2 && (
                          <div className='row'>
                            <span className='mb-2'>Wound is currently closed - treatment should be dc'd'</span>
                            <div className='border rounded'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='residentWoundClosed'>Resident</label>
                                  <Select
                                    options={residents}
                                    id='residentWoundClosed'
                                    name='residentWoundClosed'
                                    isClearable
                                    onChange={(e) => { residentNameWoundClosed.current = e }}
                                    useRef={residentNameWoundClosed}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='residentWoundClosedDocumentation'>Missing Documentation</label>
                                  <TextField
                                    id='residentWoundClosedDocumentation'
                                    name='residentWoundClosedDocumentation'
                                    multiline
                                    row='4'
                                    color='secondary'
                                    className='form-control form-control-md'
                                    variant="outlined"
                                    onChange={(e) => { setResidentWoundClosed(e.target.value) }}
                                    value={residentWoundClosed}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <button className='btn btn-primary' onClick={() => handleAddToGroup(
                                    residentNameWoundClosed, residentWoundClosed, setResidentWoundClosed, residentWoundClosedGroup, setResidentWoundClosedGroup
                                  )}>Add</button>
                                </div>
                              </div>
                              {
                                residentWoundClosedGroup.length > 0 && (
                                  <div className='col-12'>
                                    <div className='form-group'>
                                      <label className='form-label'>Closed wound group</label>
                                      <ul>
                                        {residentWoundClosedGroup.map((item, index) => (createDisplayGroup(item, index, residentWoundClosedGroup, setResidentWoundClosedGroup)))}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                      {
                        topic && topic.value === 3 && (
                          <div className='row'>
                            <span className='mb-2'>Wound treatment is changed to another treatment</span>
                            <div className='border rounded'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='residentNameTreatment'>Resident</label>
                                  <Select
                                    options={residents}
                                    id='residentNameTreatment'
                                    name='residentNameTreatment'
                                    isClearable
                                    onChange={(e) => { residentNameTreatmentChanged.current = e }}
                                    useRef={residentNameTreatmentChanged}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='residentTreatment'>Wound location & treatment</label>
                                  <TextField
                                    id='residentTreatment'
                                    name='residentTreatment'
                                    multiline
                                    row='4'
                                    color='secondary'
                                    className='form-control form-control-md'
                                    variant="outlined"
                                    onChange={(e) => { setResidentTreatmentChanged(e.target.value) }}
                                    value={residentTreatmentChanged}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <button className='btn btn-primary' onClick={() => handleAddToGroup(
                                    residentNameTreatmentChanged, residentTreatmentChanged, setResidentTreatmentChanged, residentTreatmentChangedGroup, setResidentTreatmentChangedGroup
                                  )}>Add</button>
                                </div>
                              </div>
                              {
                                residentTreatmentChangedGroup.length > 0 && (
                                  <div className='col-12'>
                                    <div className='form-group'>
                                      <label className='form-label'>Treatment group</label>
                                      <ul>
                                        {residentTreatmentChangedGroup.map((item, index) => (createDisplayGroup(item, index, residentTreatmentChangedGroup, setResidentTreatmentChangedGroup)))}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                      {
                        topic && topic.value === 4 && (
                          <div className='row'>
                            <span className='mb-2'>Updated assessment due for</span>
                            <div className='border rounded'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='residentNameCMSCompliance'>Resident</label>
                                  <Select
                                    options={residents}
                                    id='residentNameCMSCompliance'
                                    name='residentNameCMSCompliance'
                                    isClearable
                                    onChange={(e) => { residentNameCMSCompliance.current = e }}
                                    useRef={residentNameCMSCompliance}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='woundLocationCMSCompliance'>Wound location</label>
                                  <TextField
                                    id='woundLocationCMSCompliance'
                                    name='woundLocationCMSCompliance'
                                    multiline
                                    row='4'
                                    color='secondary'
                                    className='form-control form-control-md'
                                    variant="outlined"
                                    onChange={(e) => { setWoundLocationCMSCompliance(e.target.value) }}
                                    value={woundLocationCMSCompliance}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <button className='btn btn-primary' onClick={() => handleAddToGroup(
                                    residentNameCMSCompliance, woundLocationCMSCompliance, setWoundLocationCMSCompliance, residentCMSComplianceGroup, setResidentCMSComplianceGroup
                                  )}>Add</button>
                                </div>
                              </div>
                              {
                                residentCMSComplianceGroup.length > 0 && (
                                  <div className='col-12'>
                                    <div className='form-group'>
                                      <label className='form-label'>CMS Compliance group</label>
                                      <ul>
                                        {residentCMSComplianceGroup.map((item, index) => (createDisplayGroup(item, index, residentCMSComplianceGroup, setResidentCMSComplianceGroup)))}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </>
                }
                {activeStep === steps.indexOf(steps[5]) &&
                  <>
                    <div className='row'>
                      <span className='mb-2'>Consider reviewing pressure relieving devices for</span>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='additionalInformation'>Topics</label>
                          <Select
                            options={additionalInformationOptions}
                            id='additionalInformation'
                            name='additionalInformation'
                            isClearable
                            onChange={(e) => { setAdditionalInformationTopic(e) }}
                            value={additionalInformationTopic}
                          />
                        </div>
                      </div>
                      {
                        additionalInformationTopic && additionalInformationTopic.value === 1 && (
                          <div className='col-12 border rounded'>
                            <div className='col-12'>
                              <div className='form-group'>
                                <label className='form-label' htmlFor='residentNamePressureRelieving'>Resident</label>
                                <Select
                                  options={residents}
                                  id='residentNamePressureRelieving'
                                  name='residentNamePressureRelieving'
                                  isClearable
                                  onChange={(e) => { residentNamePressureRelieving.current = e }}
                                  useRef={residentNamePressureRelieving}
                                />
                              </div>
                            </div>
                            <div className='col-12'>
                              <div className='form-group'>
                                <label className='form-label' htmlFor='pressureRelievingDevice'>Pressure relieving device</label>
                                <Select
                                  options={pressureRelievingDevicesOptions}
                                  id='pressureRelievingDevice'
                                  name='pressureRelievingDevice'
                                  isClearable
                                  onChange={(e) => { pressureRelievingDevice.current = e }}
                                  useRef={pressureRelievingDevice}
                                />
                              </div>
                            </div>
                            <div className='col-12'>
                              <div className='form-group'>
                                <button className='btn btn-primary' onClick={() => handleAddToGroup(
                                  residentNamePressureRelieving, null, residentPressureRelievingDeviceGroup, setResidentPressureRelievingDeviceGroup, pressureRelievingDevice
                                )}>Add</button>
                              </div>
                            </div>
                            {
                              residentPressureRelievingDeviceGroup.length > 0 && (
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label className='form-label'>Pressure relieving device group</label>
                                    <ul>
                                      {residentPressureRelievingDeviceGroup.map((item, index) => (createDisplayGroup(item, index, residentPressureRelievingDeviceGroup, setResidentPressureRelievingDeviceGroup)))}
                                    </ul>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )
                      }
                      {
                        additionalInformationTopic && additionalInformationTopic.value === 2 && (
                          <div className='row'>
                            <span className='mb-2'>The following positive observations were found during today's rounds</span>
                            <div className='col-12 border rounded'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='positiveObservation'>Observation</label>
                                  <TextField
                                    id='positiveObservation'
                                    name='positiveObservation'
                                    multiline
                                    row='4'
                                    color='secondary'
                                    className='form-control form-control-md'
                                    variant="outlined"
                                    onChange={(e) => { setPositiveObservation(e.target.value) }}
                                    value={positiveObservation}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <button className='btn btn-primary' onClick={() => handleAddToGroup(
                                    null, positiveObservation, setPositiveObservation, positiveObservationGroup, setPositiveObservationGroup, null
                                  )}>Add</button>
                                </div>
                              </div>
                              {
                                positiveObservationGroup.length > 0 && (
                                  <div className='col-12'>
                                    <div className='form-group'>
                                      <label className='form-label'>Positive Observation group</label>
                                      <ul>
                                        {positiveObservationGroup.map((item, index) => (createDisplayGroup(item, index, positiveObservationGroup, setPositiveObservationGroup)))}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                      {
                        additionalInformationTopic && additionalInformationTopic.value === 3 && (
                          <div className='row'>
                            <span className='mb-2'>Wound and Product education was provided to clinical staff today on the following topic(s):</span>
                            <div className='col-12 border rounded'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='educationTopic'>Education Topic</label>
                                  <TextField
                                    id='educationTopic'
                                    name='educationTopic'
                                    multiline
                                    row='4'
                                    color='secondary'
                                    className='form-control form-control-md'
                                    variant="outlined"
                                    onChange={(e) => { setEducationTopic(e.target.value) }}
                                    value={educationTopic}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <button className='btn btn-primary' onClick={() => handleAddToGroup(
                                    null, educationTopic, educationTopicGroup, setEducationTopicGroup, null
                                  )}>Add</button>
                                </div>
                              </div>
                              {
                                educationTopicGroup.length > 0 && (
                                  <div className='col-12'>
                                    <div className='form-group'>
                                      <label className='form-label'>Education Topic group</label>
                                      <ul>
                                        {educationTopicGroup.map((item, index) => (createDisplayGroup(item, index, educationTopicGroup, setEducationTopicGroup)))}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                      {
                        additionalInformationTopic && additionalInformationTopic.value === 4 && (
                          <div className='row'>
                            <span className='mb-2'>On the upcoming visit, I will provide in-person education for the following subject(s) based on identified needs during this visit</span>
                            <div className='col-12 border rounded'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='upcomingVisitEducationTopic'>Education Topic</label>
                                  <TextField
                                    id='upcomingVisitEducationTopic'
                                    name='upcomingVisitEducationTopic'
                                    multiline
                                    row='4'
                                    color='secondary'
                                    className='form-control form-control-md'
                                    variant="outlined"
                                    onChange={(e) => { setUpcomingVisitEducationTopic(e.target.value) }}
                                    value={upcomingVisitEducationTopic}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-label' htmlFor='upcomingVisitEducationTopic'>The identified education presentation is scheduled for</label>
                                  <input type="date" id='nextVisitSchedule' placeholder='Select...' className="form-control form-control-md" ref={educationScheduleDatePickerRef} />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <button className='btn btn-primary' onClick={() => handleAddToGroup(
                                    null, upcomingVisitEducationTopic, setUpcomingVisitEducationTopic, upcomingVisitEducationTopicGroup, setUpcomingVisitEducationTopicGroup, null
                                  )}>Add</button>
                                </div>
                              </div>
                              {
                                upcomingVisitEducationTopicGroup.length > 0 && (
                                  <div className='col-12'>
                                    <div className='form-group'>
                                      <label className='form-label'>Education Topic group</label>
                                      <ul>
                                        {upcomingVisitEducationTopicGroup.map((item, index) => (createDisplayGroup(item, index, upcomingVisitEducationTopicGroup, setUpcomingVisitEducationTopicGroup)))}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </>
                }
                {activeStep === steps.indexOf(steps[6]) &&
                  <>
                    <div className='row'>
                      <span className='mb-2'></span>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='nextVisitSchedule'>Next Visit Schedule</label>
                          <input type="date" id='nextVisitSchedule' placeholder='Select...' className="form-control form-control-md" ref={nextVisitDatePickerRef} />
                        </div>
                      </div>
                      <div className='col-12'>
                        <span>I look forward to seeing your team again on my next visit!</span>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='exitedWith'>Exited with</label>
                          <TextField
                            id='exitedWith'
                            name='exitedWith'
                            color='secondary'
                            className='form-control form-control-md'
                            variant="outlined"
                            onChange={(e) => { setExitedWith(e.target.value) }}
                            value={exitedWith}
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <label className='form-label' htmlFor='exitedDate'>Date</label>
                        <input type="date" id='exitedDate' placeholder='Select...' className="form-control form-control-md" ref={exitedDatePickerRef} />
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
            <div className='row justify-content-end'>
              <div className='col-auto'>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  disabled={checkNextButtonDisabledCondition()}
                  variant='contained'
                  color='secondary'
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-8'>
          <Card>
            <div className='row'>
              <div className='col-12'>
                <div className='form-group'>
                  <p id='emailTextPlace' className='lead m-0 p-5 lh-10'>
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}