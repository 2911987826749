import React from "react";

export function Newfacilitymodal() {

    return (
        <>
            <form>
                <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                    <legend className="text-primary fw-500">Basic Information:</legend>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="ThisFacilityBelongsTo">This facility belongs to</label>
                                <select className="form-control form-control-sm" 
                                id="ThisFacilityBelongsTo"
                                required>
                                    <option>This is a Headquarters location</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="FacilityName">Facility Name</label>
                                <input type="text" id="FacilityName" className="form-control form-control-sm text-uppercase" required />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="FacilityType">Facility Type</label>
                                <select className="form-control form-control-sm" id="FacilityType" required>
                                    <option>Nursing Home</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="NPINumber">NPI #:</label>
                                <input type="text" id="NPINumber" className="form-control form-control-sm" required/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="FacilityType">Product Line</label>
                                <select className="form-control form-control-sm" id="FacilityType" required>
                                    <option>Gentell</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="NumberofBeds">Number of Beds</label>
                                <input type="text" id="NumberofBeds" className="form-control form-control-sm" required/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="MemberofGroupPurchasingOrg.">Member of Group Purchasing Org.</label>
                                <select className="form-control form-control-sm" id="MemberofGroupPurchasingOrg" required>
                                    <option>Not a member of GPO</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="WebSiteAddress">Web Site Address</label>
                                <input type="text" id="WebSiteAddress" className="form-control form-control-sm" required/>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                    <legend className="text-primary fw-500">Address Information:</legend>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Street">Street</label>
                                <input type="text" id="Street" className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Stree2:">Street 2</label>
                                <input type="text" id="Stree2" className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="City">City</label>
                                <input type="text" id="City" className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="State">State</label>
                                <select className="form-control form-control-sm" id="State">
                                    <option>New York</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Zip">Zip</label>
                                <input type="text" id="Zip" className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Country">Country</label>
                                <input type="text" id="Country" className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Phone1">Phone 1</label>
                                <input type="text" id="Phone1" className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Fax#">Fax #</label>
                                <input type="text" id="Fax" className="form-control form-control-sm" />
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                    <legend className="text-primary fw-500">Other Information</legend>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4">
                            <ul className="list-unstyled my-5 border-dark border-md-0 border-right-lg">
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="acceptTerms"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                                    Medicare Part B?
                                            </label>
                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="acceptTerms"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                                    OUT`s Program
                                            </label>

                                </li> 
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="acceptTerms"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                                    Is this facility following the 28-day billing cycle?
                                            </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8">
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="PartBReceivingPerson">Part B Receiving Person</label>
                                        <input type="text" id="PartBReceivingPerson" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="Central Supply Person">Central Supply Person</label>
                                        <input type="text" id="Stree2:" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="AssignedNurse">Assigned Nurse</label>
                                        <select className="form-control form-control-sm" id="AssignedNurse">
                                            <option>Test Test</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="EMRSystem">EMR System </label>
                                        <select className="form-control form-control-sm" id="EMRSystem">
                                            <option>Test Test</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="Shipsfromthiswarehouse:">Ships from this warehouse</label>
                                        <select className="form-control form-control-sm" id="Shipsfromthiswarehouse">
                                            <option>Test Test</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="Logo">Logo</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input cursor-pointer" id="Logo" aria-describedby="Logo" />
                                                <label className="custom-file-label" htmlFor="inputGroupFile04">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Memo">Memo</label>
                                <textarea className="form-control" id="Memo" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </fieldset>


            </form>
        </>
    );
}

export default Newfacilitymodal;