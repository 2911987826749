/*eslint-disable */

import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/Card";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import AddInsurancemodal from './addInsurance';
import NewBrightreeInsurance from './newbrightreeInsurance';

export function Insurance() {
    const { user } = useSelector((state) => state.auth);
    //#region  add person
    const [showModalAddInsurance, setShowAddInsurance] = useState(false);

    const [AddInsuranceComponent, setAddInsuranceComponent] = useState(null);


    const handleCloseAdd = () => setShowAddInsurance(false);
    const handleShowAddInsurance = (obj) => {
        setInsurance(obj);
        setAddInsuranceComponent(<AddInsurancemodal userID={user.userID} Insurance={Insurance} close={handleCloseAdd} />)
        setShowAddInsurance(true);
    };

    const handleShowAddBrightreeInsurance = (obj) => {
        setInsurance(obj);
        setAddInsuranceComponent(<NewBrightreeInsurance userID={user.userID} Insurance={Insurance} close={handleCloseAdd} />)
        setShowAddInsurance(true);
    };


    //#endregion

    //#region Insurance list
    const [Insurance, setInsurance] = useState(null);
    const [InsuranceListUI, setInsuranceListUI] = useState([]);
    //#endregion

    useEffect(() => {
        GetInsuranceList();
    });

    
    




    //#region loop to  print the Insurance firm list

    const GetInsuranceList = () => {
        Api.getRequest(Api.Uri_GetAllInsurance).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        setInsuranceList(res.data.result);
                    }
                }
            }

            Common.LoadDataTable("InsuranceList")
        }).catch((ex) => {
        Common.LogError(error, "Insurance", "LoadDataTable");
            
        });
    }

    const setInsuranceList = (list) => {
        let rows = [];
        try {
            for (var i = 0; i < list.length; i++) {
                const obj = list[i];
                rows.push(<tr key={i.toString()}>
                    <td>{Common.replaceNullWithString(obj.insuranceName) }</td>
                    <td>{Common.replaceNullWithString(obj.contactFirstName) ? obj.contactFirstName : "N/A"}</td>
                    <td>{Common.replaceNullWithString(obj.payPercentage) != '' ? obj.payPercentage : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.grpName) != '' ? obj.grpName : 'N/A'}</td>
                    {/* <td>{Common.replaceNullWithString(obj.npi) != '' ? obj.npi : 'N/A'} </td> */}
                    <td align="center">
                        <div className="text-center">

                            <DropdownButton
                                drop={"down"}
                                size="xs"
                                variant="primary"
                                title={'Actions'}
                                id={`dropdown-button-drop-actions`}
                                key={'down'}>

                                {/* <Dropdown.Item eventKey={"1" + i.toString()} onClick={() => handleShowAdd(obj)}>Edit</Dropdown.Item> */}
                                <Dropdown.Item eventKey={"2" + i.toString()} onClick={() => ConfirmDeleteInsurance(obj.InsuranceId)}>Delete</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </td>
                </tr>);
            }
            setInsuranceListUI(rows);
        } catch (error) {
        Common.LogError(error, "Insurance", "setInsuranceList");

        }
    }

    //#endregion

    //#region Delete Insurance 

    const ConfirmDeleteInsurance = (id) => {

        Common.showConfirmation("Are you sure! You want to delete this Insurance.", "Delete Insurance", DeleteInsurance, null, id)

    }

    const DeleteInsurance = (id) => {
        Api.postRequest(Api.URI_DeletePhysician+"?InsuranceID="+id).then((res) => {
            // // debugger
            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    Common.showSuccessAlertAutoClose("Insurance deleted successfully", "success", "Success!", 3000);
                    GetInsuranceList();
                } 
                else {
                    Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        Common.LogError(ex, "Insurance", "DeleteInsurance");
        });
    }

    //#endregion

    const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(ShowAdvancedSearch ? true : false);
    const showAdvanceSearchSection = () => {
        showAdvanceSearchSectionState(!ShowAdvancedSearch);
    };

    return (
        <>
            {showModalAddInsurance && <Animated animationIn="fadeInLeft" animationInDuration={1000}>
                
                {AddInsuranceComponent}
                {/* <Card className="">
                <CardHeader title='New Insurance' />
                <CardBody>
                    
                        <AddInsurancemodal />
                    
                </CardBody>
                <CardFooter className="d-flex justify-content-end py-3">
                    <Button variant="success" className="mr-5 px-10" size="sm">Save</Button>
                    <Button variant="danger" size="sm" onClick={handleCloseAdd}>Cancel</Button>
                </CardFooter>
            </Card> */}
            </Animated>}

            {!showModalAddInsurance && <Animated animationIn="fadeIn" animationInDuration={1000}>
            <Card className="example example-compact">
                <CardHeader title='Insurance List' />
                <CardBody>
                <div className="alert border-primary bg-transparent text-primary fade show" role="alert">
                    <div className="d-flex align-items-center">
                        <div className="alert-icon">
                            <i className="fas fa-info-circle mr-4 text-primary"></i>
                        </div>
                        <div className="flex-1">
                            <span className="h5 m-0 fw-700">Use buttons to do more actions </span>
                        </div>
                        <Button variant="primary" className="mr-5" size="sm" onClick={handleShowAddInsurance}>Add New Insurance</Button>
                        <Button variant="primary"  size="sm" onClick={handleShowAddBrightreeInsurance}>Add Brightree Insurance</Button>
                    </div>
                </div>
                <table className="table table-striped table-hover table-sm table-bordered" id="InsuranceList">
                    <thead>
                        <tr>
                            <th>Insurance Name</th>
                            <th>ContactFirstName</th>
                            <th>PayPercentage </th>
                            <th>GrpName</th>
                            {/* <th>NPI #</th> */}
                            <th width="130px">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {InsuranceListUI}
                    </tbody>
                </table>
                </CardBody>
            </Card>
                

            </Animated>}
        </>
    );

}

export default Insurance;

/*eslint-disable */