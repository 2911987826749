import { isThisISOWeek } from 'date-fns';
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import React from 'react';
import { Alert, Button, Form, Dropdown, DropdownButton } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../../_metronic/_partials/controls/Card";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";

export default class NewBrightreeInsurance extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formValidated: false,
            findFormValidated: false,
            InsuranceListUI:[],
            Insurance:
            {
                InsuranceId: 0,
                Title: '',
                insuranceName: '',
                MiddleName: '',
                LastName: '',
                Suffix: '',
                InsuranceNote: '',
                Npi: '',
                StateMedicaidId: '',
                LicenseNo: '',
                TaxonomyCode: '',
                DoctorGroupId: 0,
                CreatedDate: new Date(),
                CreatedBy: this.props.userID,
                ModifiedBy: this.props.userID,
                ModifiedDate: new Date(),

          

            }

        }
        this.findForm = React.createRef();
    }

    componentDidMount() {
        $('#Phone1').mask('000-000-0000');
        $('#Mobile1').mask('000-000-0000');
        $('#Fax').mask('000-000-0000');

        $("#Phone1").change((e) => {
            Common.updateInputValueInState(this.state.Insurance.address, e, this, this.state)
        });
        $('#Mobile1').change((e) => {
            Common.updateInputValueInState(this.state.Insurance.address, e, this, this.state)
        });
        $("#Fax").change((e) => {
            Common.updateInputValueInState(this.state.Insurance.address, e, this, this.state)
        });

    }
    handleFindSubmit = (e) => {
        if (this.findForm.current.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
          let _state = this.state;
          _state.findFormValidated = true;
          this.setState(_state);
        } else {
          // if successfull validation
        //   const {
        //     firstName: FirstName,
        //     lastName: LastName,
        //     npi: Npi,
        //   } = this.state.PhysicianFind;
          Api.getRequest(
            `${Api.apiUrls.SearchInsurance}?insuranceName=${this.state.Insurance.insuranceName}`,
            false
          )
            .then((res) => {
                debugger
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            this.setInsuranceList(res.data.result);
                        }
                    }
                }
              // // debugger
              if (res.data.success) {
                if (res.data.result != null) {
                  Common.showSuccessAlertAutoClose(
                    "Insurance find Successfully",
                    "success",
                    "Success!",
                    3000
                  );
                  debugger;
              
                  // this.props.close();
                } else {
                  Common.showSuccessAlertAutoClose(
                    res.data.message,
                    "warning",
                    "Failed!",
                    3000
                  );
                }
              } else {
                Common.showSuccessAlertAutoClose(
                  "No data found against given criteria",
                  "warning",
                  "Failed!",
                  3000
                );
              }
            })
            .catch((ex) => {
              // // debugger
              Common.showSuccessAlertAutoClose(
                "some error occured while processing your request",
                "warning",
                "Failed!",
                3000
              );
        Common.LogError(ex, "Brightree", "Add");

              // api call
              // on response
            });
        }
        // prepare payload
      };
       GetInsuranceList = () => {
        Api.getRequest(Api.Uri_GetAllInsurance).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        this.setInsuranceList(res.data.result);
                    }
                }
            }

            Common.LoadDataTable("InsuranceList")
        }).catch((ex) => {
        Common.LogError(ex, "Brightree", "GetInsuranceList");

        });
    }

     setInsuranceList = (list) => {
        let rows = [];
        try {
            for (var i = 0; i < list.length; i++) {
                const obj = list[i];
                rows.push(<tr key={i.toString()}>
                    <td>{Common.replaceNullWithString(obj.value) }</td>
                    {/* <td>{Common.replaceNullWithString(obj.contactFirstName) ? obj.contactFirstName : "N/A"}</td>
                    <td>{Common.replaceNullWithString(obj.payPercentage) != '' ? obj.payPercentage : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.grpName) != '' ? obj.grpName : 'N/A'}</td> */}
                    {/* <td>{Common.replaceNullWithString(obj.npi) != '' ? obj.npi : 'N/A'} </td> */}
                    <td align="center">
                        <div className="text-center">

                            <DropdownButton
                                drop={"down"}
                                size="xs"
                                variant="primary"
                                title={'Actions'}
                                id={`dropdown-button-drop-actions`}
                                key={'down'}>

                                {/* <Dropdown.Item eventKey={"1" + i.toString()} onClick={() => handleShowAdd(obj)}>Edit</Dropdown.Item> */}
                                <Dropdown.Item eventKey={"2" + i.toString()} onClick={() =>this.UpsertInsurance(obj)}>Sync</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </td>
                </tr>);
            }
            this.setState({InsuranceListUI:rows});
        } catch (error) {
        Common.LogError(error, "Brightree", "setInsuranceList");

        }
    }
UpsertInsurance(obj) {
        // let obj = this.state.Physician;
        // if (obj.address != null) {
        //   obj.address.phone1 =
        //     Common.replaceNullWithString(obj.address.phone1) != ""
        //       ? obj.address.phone1.replace(/\D/g, "")
        //       : null;
        //   obj.address.mobile1 =
        //     Common.replaceNullWithString(obj.address.mobile1) != ""
        //       ? obj.address.mobile1.replace(/\D/g, "")
        //       : null;
        //   obj.address.fax =
        //     Common.replaceNullWithString(obj.address.fax) != ""
        //       ? obj.address.fax.replace(/\D/g, "")
        //       : null;
        // }
    
        debugger
        Api.postRequest(Api.URI_UpsertInsurance, obj)
          .then((res) => {
            debugger;
            // // debugger
            if (res.data.success) {
              if (res.data.result != null) {
                Common.showSuccessAlertAutoClose(
                  "Sync Incurance Successfully",
                  "success",
                  "Success!",
                  3000
                );
                this.props.close();
              } else {
                Common.showSuccessAlertAutoClose(
                  res.data.message,
                  "warning",
                  "Failed!",
                  3000
                );
              }
            } else {
              Common.showSuccessAlertAutoClose(
                res.data.message,
                "warning",
                "Failed!",
                3000
              );
            }
          })
          .catch((ex) => {
            // // debugger
            Common.showSuccessAlertAutoClose(
              "some error occured while processing your request",
              "warning",
              "Failed!",
              3000
            );
        Common.LogError(ex, "Brightree", "UpsertInsurance");

          });
      }
    render() {
        return (
            <>

<Form ref={this.findForm} validated={this.state.findFormValidated}>
                    <Card className="">
                        <CardHeader title='Search Insurance' />
                        <CardBody>
                            <Alert className="mb-5" variant="secondary">
                                Fill in one or more fields to search Insurance.
                            </Alert>

                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="LastName">Insurance Name</label>
                                        <input type="text" id="InsuranceName"
                                            name="insuranceName"
                                            required
                                            onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                            className="form-control form-control-sm" />
                                        <div className="invalid-feedback">Insurance Name Is Required</div>
                                    </div>
                                </div>
                                {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FirstName">First Name</label>
                                        <input type="text" id="FirstName"
                                            name="FirstName"
                                            onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="NPI">NPI</label>
                                        <input type="text" id="NPI"
                                            name="Npi"
                                            onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div> */}
                            </div>


                        </CardBody>
                        <CardFooter className="d-flex justify-content-end py-3">
                        <Button
                variant="success"
                className="mr-5 px-10"
                size="sm"
                onClick={(e) => this.handleFindSubmit(e)}
              >
                Find
              </Button>
                            <Button variant="danger" size="sm" onClick={() => this.props.close()}>Cancel</Button>
                        </CardFooter>
                    </Card>
                </Form>


                <Card className="example example-compact">
                    <CardHeader title='Insurance List' />
                    <CardBody>
                    <table className="table table-striped table-hover table-sm table-bordered" id="InsuranceList">
                    <thead>
                        <tr>
                            <th>Insurance Name</th>
                            <th>ContactFirstName</th>
                            <th>PayPercentage </th>
                            <th>GrpName</th>
                            {/* <th>NPI #</th> */}
                            <th width="130px">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.InsuranceListUI}
                    </tbody>
                </table>
                    </CardBody>
                </Card>
            </>
        );
    }

    //Functions

    //#region Get States
    getAllState() {
        Api.getRequest(Api.Uri_GetState).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        this.setState({ states: res.data.result })
                    }
                }
            }

        }).catch((ex) => {
        Common.LogError(ex, "Brightree", "getAllState");

        });
    }

    setAllState(states) {
        return states.map(state => (
            <option
                key={state.key}
                value={state.key}
            >
                {state.value}
            </option>
        ))
    }
    //#endregion

    handleSubmit = (event) => {
        // // debugger
        const form = event.currentTarget.form;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {
            //this.URI_UpsertInsurance();
        }
    }

    URI_UpsertInsurance() {
        let obj = this.state.Insurance;
        if (obj.address != null) {
            obj.address.Phone1 = Common.replaceNullWithString(obj.address.Phone1) != '' ? obj.address.Phone1.replace(/\D/g, '') : null;
            obj.address.Mobile1 = Common.replaceNullWithString(obj.address.Mobile1) != '' ? obj.address.Mobile1.replace(/\D/g, '') : null;
            obj.address.Fax = Common.replaceNullWithString(obj.address.Fax) != '' ? obj.address.Fax.replace(/\D/g, '') : null;
        }

        // // debugger
        Api.postRequest(Api.URI_UpsertPhysician, obj).then((res) => {
            // // debugger
            if (res.data.success) {
                if (res.data.result != null) {
                    Common.showSuccessAlertAutoClose("Insurance Created Successfully", "success", "Success!", 3000);
                    this.props.close();
                }
                else {
                    Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {
            
            // // debugger
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        Common.LogError(ex, "Brightree", "URI_UpsertInsurance");

        });
    }
}//end of class
