import React, { useCallback, useEffect, useState } from 'react';
import { Animated } from "react-animated-css";
import { Button, ButtonToolbar, Dropdown, DropdownButton, Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import Select from 'react-select';
import PopupModal from "../modal/popupmodal";
import AddOstomy from "./addOstomy";
import { Card, CardBody } from "../../../_metronic/_partials/controls/Card";
var addNewOstomyModal = null;
const procedures = ["Colostomy", "Urostomy", "lleostomy"];

const diagCodes = ["Colostomy Z93.3", "Urostomy Z93.6", "lleostomy Z93.2", 'Encounter for Colostomy Z43.3', 'Encounter for Urostomy Z43.6', 'Encounter for lleostomy Z43.2'];

export default class OstomyList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addEditOstomyUI: '',
      ostomy: {
        ostomyId: 0,
        fkResidentId: props.resident.id,
        fkProcedureId: null,
        diagnosisCode: null,
        pouches: null,
        pouchesQuantity: null,
        pouchesRemainingQuantity: null,
        wafersRemainingQuantity: null,
        wafers: null,
        wafersQuantity: null,
        stomaSize: null,
        avgWearTimeInDays: null,
        surgeryDate: null,
        saleOrderViewModels: []
      },
      rows: [],
      ostomyProductListUI: [],

    }
  }

  componentDidMount() {

    this.GetResidentOstomy();
  }


  GetResidentOstomy = () => {

debugger
    // Api.getRequestById(Api.URI_GetOstomySaleOrderByResidentId, this.props.resident.id)
    Api.getRequestQry(Api.URI_GetOstomySaleOrderByResidentId, "?residentId="+this.props.resident.id+"&processStatusID=" + this.props.processStatusID)
    .then((res) => {

      if (res.data.statusCode == 200) {
        if (res.data.result != null) {
          if (res.data.result.length > 0) {
            this.setOstomyLstUI(res.data.result);
          }
        }
      }

      Common.LoadDataTable("woundList")
    }).catch((ex) => {
      // Common.LogError(error, "Wound", "GetResidentWound");
    });
  }
  processSaleOrder = (saleOrderID) => {

    // Api.getRequestById(Api.URI_ProcessOstomySaleOrderById, saleOrderID)
      Api.getRequestQry(Api.URI_ProcessOstomySaleOrderById, "?saleOrderID="+saleOrderID+"&processStatusID=" + this.props.processStatusID)
      .then((res) => {
      if (res.data.statusCode == 200) {
        if (res.data.result > 0) {
          Common.showSuccessAlertAutoClose("Ostomy Order processed Successfully", "success", "Success!", 3000);
        }
        this.GetResidentOstomy();
      }

      Common.LoadDataTable("woundList")
    }).catch((ex) => {
      // Common.LogError(error, "Wound", "GetResidentWound");
    });
  }
  handleCloseAdd = () => {
    addNewOstomyModal.hideModal();
    this.GetResidentOstomy();

  };
  handleShowEditOstomyModal = (obj) => {

    this.setState({addEditOstomyUI:<AddOstomy ostomy={obj} residentID={this.props.resident.id} close={this.handleCloseAdd} />})
    addNewOstomyModal.showModal();
  }
  handleShowAddNewOstomyModal = () => {

    this.setState({addEditOstomyUI:<AddOstomy ostomy={this.state.ostomy} residentID={this.props.resident.id} close={this.handleCloseAdd} />})
    addNewOstomyModal.showModal();
  }
  setOstomyLstUI = (list) => {
    debugger
    let rows = [];
    for (var i = 0; i < list.length; i++) {

      const obj = list[i];
      if (obj.brightreeKey == null) {
        rows.push(
          <>
            <tr key={i.toString()}>
              <td className="justify-content-center"> <b>{i + 1}</b> </td>
              <td>{obj.fkProcedureId == 1 ? "Colostomy" : obj.fkProcedureId == 2 ? "Urostomy" : obj.fkProcedureId == 3 ? "lleostomy" : ""}</td>
              <td>{obj.diagnosisCode}</td>
              <td>{obj.avgWearTimeInDays}</td>
              {/* <td>{new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString()}</td> */}
              <td>{Common.replaceNullWithString(obj.surgeryDate) != '' ? new Date(obj.surgeryDate).toDateString() : 'N/A'}</td>
              <td align="center">
                <>
                  <ButtonToolbar className="justify-content-center">
                    {this.props.processStatusID ==Common.ProcessStatus.workQueueID &&
                    <div className="mr-md-4 mr-0 mb-1 mb-md-0">

                      <Button
                        size="xs"
                        variant="success"
                        onClick={() => this.processSaleOrder(obj.ostomyId)}
                      >
                        Release
                      </Button>
                    
                    </div>

      }
                    <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                      {[DropdownButton].map((DropdownType, idx) => (
                        <DropdownType
                          size="xs"
                          variant="primary"
                          title="Actions"
                          id={`dropdown-button-drop-${idx}`}
                          key={idx}
                        >
                          <Dropdown.Item eventKey="1"   onClick={() => this.handleShowEditOstomyModal(obj)}>Edit Ostomy</Dropdown.Item>
                          {/* <Dropdown.Item eventKey="1" onClick={() => { this.processSaleOrder(obj.ostomyId); }}>Process Ostomy Order</Dropdown.Item> */}

                        </DropdownType>
                      ))}
                    </div>
                  </ButtonToolbar>
                </>
              </td>
            </tr>
            <tr>
              <td colSpan="9">
                <Card className="example example-compact">
                  <CardBody>
                    <Animated animationIn="fadeIn" animationInDuration={2000} >

                      <table className="table table-striped table-hover table-sm table-bordered" id="woundProductList" >
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Remaining Day(s)</th>
                            {/* <th>Type</th>
                          <th>Category</th> */}
                            {/* <th>SKU</th> */}
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.getProductUi(obj)}
                        </tbody>
                      </table>
                    </Animated>
                  </CardBody>
                </Card>
              </td>
            </tr>
          </>
        );
      }

    }
    this.setState({ rows: rows });
  }

  randomNumber(min, max) {
    var num = Math.random() * (max - min) + min;
    return Math.round(num);
  }
  getProductUi = (saleOrder) => {

    let productrows = [];
    let products = saleOrder.saleOrderViewModels;

    for (var i = 0; i < products.length; i++) {
      const obj = products[i];

      productrows.push(<tr key={i.toString()}>
        <td><button className="btn btn-link" >{obj.productName}</button></td>
        <td>{this.randomNumber(1, 7)}</td>

        {/* <td>{obj.productLevelName}</td>
      <td>{obj.productCategoryDescription}</td> */}
        {/* <td>{obj.sku}</td> */}
        <td >
          {obj.quantity}
        </td>
      </tr>);
    }
    return productrows;

  }


  render() {
    return (
      <>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary btn-xs mr-5 mb-3" size="sm" onClick={() => { this.handleShowAddNewOstomyModal() }} >Add New Ostomy</button>
        </div>
        <Animated animationIn="fadeIn" animationInDuration={2000} >

          <table className="table table-striped table-hover table-sm table-bordered" id="ostomyList" >
            <thead>
              <tr>
                <th>Ostomy#</th>
                <th>Type Of Procedure</th>
                <th>Diagnosis Code (ICD10)</th>
                <th>Avg Wear Time</th>
                <th>Surgery Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.rows}
            </tbody>
          </table>
        </Animated>

        {
          <PopupModal dialogClassName="modal-95w" title={
            <div className="">
              <span>
                <h3 className="card-label">Ostomy Intake</h3>
                {/* <small className="">
              <b>Resident:</b>{" "} {residentName}
                <b>Facility:</b> {props.facility.facilityName},{" "}
                <b>Chain:</b>{" "} {props.facility.chain},{" "}
                <b>Group:</b>{" "} {props.facility.organizationName},{" "}
              </small> */}
              </span>
            </div>
          }  ref={ref => { addNewOstomyModal = ref; }}>
            {/* <Animated animationIn="fadeIn" animationInDuration={2000} > */}
           {this.state.addEditOstomyUI}
            {/* </Animated> */}
          </PopupModal>
        }
      </>
    )
  }
}
