/*eslint-disable */
import { FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from "react";
import { Button, Form } from "react-bootstrap";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import {
    Card,
    CardBody,
    CardHeader
} from "../../../../_metronic/_partials/controls/Card";
export default class NewICDCode extends React.Component {

    // eslint-disable-next-line no-use-before-define
    constructor(props) {
        super(props);
        this.state = {
            WoundType: 'Pressure',
            formValidated: false,
            woundTypeSelect: [],
            pressureLocation: [],
            nonPressureLocation: [],
            thicknessStage: [],
            nonThicknessStage: [],
            WoundSide: [],
            woundBodyRegionSelect: [],
            woundBodyRegionList: [],
            WoundSevereTissue: [],
            WoundTypeList: [],

            ICDCode: JSON.parse(JSON.stringify(props.icd)),

        }
        this.productSelection = null;
    }
    componentDidMount() {
        this.getAllWoundType();
        this.getAllLookupsValues();
        this.getAllBodyRegion();


        // this.setState({Resident: JSON.parse(JSON.stringify(this.props.resident))})
    }
    getAllLookupsValues = async () => {

        var WoundSide = await Common.getLookupOptions(Common.LookupsFK.Fk_fkWoundSideID)
        this.setState({ WoundSide: WoundSide })
        var woundIssue = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundSevereTissueTypeID)
        this.setState({ WoundSevereTissue: woundIssue })
    }
    getAllWoundType = () => {
        Api.getRequest(Api.Uri_GetWoundType).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        let optionTemplate = res.data.result.map(v => (
                            <option key={v.id} value={v.id}>{v.name}</option>
                        ));
                        this.setState({ woundTypeSelect: optionTemplate })
                        this.setState({ WoundTypeList: res.data.result })
                    }
                }
            }


        }).catch((ex) => {
        });
    }
    getAllBodyRegion = () => {
        Api.getRequest(Api.Uri_GetBodyRegion).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        let optionTemplate = res.data.result.map(v => (
                            <option key={v.id} value={v.id}>{v.name}</option>
                        ));
                        this.setState({ woundBodyRegionSelect: optionTemplate })
                        this.setState({ woundBodyRegionList: res.data.result })
                    }
                }
            }

            var woundType = this.props.icd.fkWoundTypeId;
            if (woundType > 0) {
                this.checkWoundType();
            }

        }).catch((ex) => {
        });
    }
    onWoundTypeChange = (e) => {

        var optionTemplate = [];
        this.state.WoundTypeList.map(function (wound) {

            if (wound.id == e.target.value) {
                optionTemplate = wound.woundTypeQualifiers.map(v => (
                    <option key={v.id} value={v.id}>{v.name}</option>
                ));

            }
        })
        Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)
        this.setState({ thicknessStage: optionTemplate })
    }
    onBodyRegionChange = (e) => {

        var optionTemplate = [];
        this.state.woundBodyRegionList.map(function (wound) {

            if (wound.id == e.target.value) {
                optionTemplate = wound.bodyLocations.map(v => (
                    <option key={v.id} value={v.id}>{v.name}</option>
                ));
            }
        })
        Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)
        this.setState({ pressureLocation: optionTemplate })
    }
    checkWoundType() {

        var bodyLocationsTemplate = [];
        var regionID = this.state.ICDCode.fkBodyRegionId
        this.state.woundBodyRegionList.map(function (wound) {

            if (wound.id == regionID) {
                bodyLocationsTemplate = wound.bodyLocations.map(v => (
                    <option key={v.id} value={v.id}>{v.name}</option>
                ));
            }
        })

        this.setState({ pressureLocation: bodyLocationsTemplate })

        var optionTemplate = [];
        var woundTypeId = this.state.ICDCode.fkWoundTypeId;
        this.state.WoundTypeList.map(function (wound) {

            if (wound.id == woundTypeId) {
                optionTemplate = wound.woundTypeQualifiers.map(v => (
                    <option key={v.id} value={v.id}>{v.name}</option>
                ));

            }
        })
        this.setState({ thicknessStage: optionTemplate })

    }
    handleSubmit = (event) => {
        const form = event.currentTarget.form;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {

            this.URI_UpsertResidentWound();


        }
    }

    URI_UpsertResidentWound() {
        let obj = this.state.ICDCode;
debugger
        Api.postRequest(Api.URI_UpsertWoundToIcd, obj).then((res) => {

            if (res.data.success) {
                if (res.data.result != null) {
            //    let icdCodeObj=    
            //    {
            //        woundToIcdid: 0,
            //        fkWoundTypeId: null,
            //        fkWoundLocationId: null,
            //        fkNonPrUthicknessId:null,
            //        fkThicknessStageId: null,
            //        fkWoundLateralityId: null,
            //        iCDCode: "",
            //        isSlough: false,
            //        isNecrotic: false,
            //        isPressure :false,
            //        description :'',
            //        fkBodyRegionId:null,
            //        orientationLeftRight:null,
            //        orientationUpperLower:null,
            //        orientationInnerOuter:null,
            //        orientationAnteriorPosterior:null,
            //        orientationMedialLateral:null,
            //        orientationDorsalDistalProximal:null,
            //        fkWoundSevereTissueTypeId:null
            //    }
            //         this.setState({ICDCode:icdCodeObj})
                    Common.showSuccessAlertAutoClose("ICD Code Create/Updated Successfully", "success", "Success!", 3000);


                    //this.props.close();
                }
                else {
                    Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        Common.LogError(ex, "ICD", "Upsert");

        });
    }






    // Render Methods

    renderWoundForm() {
        return (
            <>


                <Card className="example example-compact">
                    <CardHeader title="ICD Code" />
                    <CardBody>
                        <div className="row">
                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="woundType">Type of Wound</label>
                                    <select className="form-control form-control-sm"
                                        id="woundType"
                                        name="fkWoundTypeId"
                                        required
                                        onChange={e => this.onWoundTypeChange(e)}
                                        value={Common.replaceNullWithString(this.state.ICDCode.fkWoundTypeId)}
                                    >
                                        <option></option>
                                        {this.state.woundTypeSelect}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Country">Body Region</label>
                                    <select className="form-control form-control-sm"
                                        name="fkBodyRegionId"
                                        onChange={e => this.onBodyRegionChange(e)}
                                        value={Common.replaceNullWithString(this.state.ICDCode.fkBodyRegionId)}
                                        id="fkBodyRegionId">
                                        <option ></option>
                                        {this.state.woundBodyRegionSelect}
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="heel">Wound Location</label>
                                    <select className="form-control form-control-sm" id="fk_PressureLocationID"
                                        name='fkWoundLocationId'
                                        // required
                                        onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                        value={Common.replaceNullWithString(this.state.ICDCode.fkWoundLocationId)}
                                    >
                                        <option></option>
                                        {this.state.pressureLocation}
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="thicknessStage">Thickness/Stage</label>
                                    <select className="form-control form-control-sm" id="fkWoundThicknessId"
                                        name="fkThicknessStageId"
                                        onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                        value={Common.replaceNullWithString(this.state.ICDCode.fkThicknessStageId)}
                                    >
                                        <option></option>
                                        {this.state.thicknessStage}
                                    </select>
                                </div>
                            </div>

                            {/* {this.state.WoundType == 'NonPressure' &&
                            <>
                              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="heel">Non Pressure Location</label>
                                        <select className="form-control form-control-sm" id="fk_NonPressureLocationID"
                                            name='fkWoundLocationId'
                                            required
                                            onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                            value={Common.replaceNullWithString(this.state.ICDCode.fkWoundLocationId)}
                                        >
                                            <option></option>
                                            {this.state.nonPressureLocation}
                                        </select>
                                    </div>
                                </div>
                         
                                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FacilityName">Non-PrU Thickness</label>
                                        <select className="form-control form-control-sm" id="fk_NonThicknessStageID"
                                            name='fkNonPrUthicknessId'
                                            onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                            value={Common.replaceNullWithString(this.state.ICDCode.fkNonPrUthicknessId)}
                                        >
                                            <option></option>
                                            {this.state.nonThicknessStage}
                                        </select>
                                    </div>
                                </div>
                            </>
                        } */}
                            {/* <div className="col-sm-6 col-md-4 col-lg-2 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="WoundLaterality">Orientation</label>
                                    <select className="form-control form-control-sm" id="fk_WoundSideID"
                                        name='fkWoundLateralityId'
                                        required
                                        onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                        value={Common.replaceNullWithString(this.state.ICDCode.fkWoundLateralityId)}>


                                        <option></option>
                                        {this.state.WoundSide}
                                    </select>
                                </div>
                            </div> */}
                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                            <label className="form-label">Orientation</label>
                                            <div className="form-group ">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationLeftRight" id="inlineRadio1"
                                                     onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                     value="1"
                                                     
                                                     />
                                                    <label className="form-check-label" htmlFor="inlineRadio1">Right</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationLeftRight" id="inlineRadio2"
                                                       onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                       value="2"
                                                     />
                                                    <label className="form-check-label" htmlFor="inlineRadio2">Left</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationLeftRight" id="inlineRadio3" 
                                                     onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                     value="3"
                                                    />
                                                    <label className="form-check-label" htmlFor="inlineRadio3">Bilateral </label>
                                                </div>
                                                <fieldset id="group1">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationUpperLower" id="Upper" 
                                                        onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                        value="1"
                                                         />
                                                        <label className="form-check-label" htmlFor="Upper">Upper</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationUpperLower" id="Lower" 
                                                        onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                        value="2"
                                                         />
                                                        <label className="form-check-label" htmlFor="Lower">Lower</label>
                                                    </div>
                                                </fieldset>
                                                <fieldset id="group2">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationInnerOuter" id="Inner" 
                                                            onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                            value="1"
                                                        />
                                                        <label className="form-check-label" htmlFor="Inner">Inner</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationInnerOuter" id="Outer"
                                                           onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                           value="2"
                                                          />
                                                        <label className="form-check-label" htmlFor="Outer">Outer</label>
                                                    </div>
                                                </fieldset>
                                                <fieldset id="group3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationAnteriorPosterior" id="Anterior" 
                                                          onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                          value="1"
                                                        />
                                                        <label className="form-check-label" htmlFor="Anterior">Anterior</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationAnteriorPosterior" id="Posterior" 
                                                        onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                        value="2"
                                                         />
                                                        <label className="form-check-label" htmlFor="Posterior">Posterior</label>
                                                    </div>
                                                </fieldset>
                                                <fieldset id="group4">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationMedialLateral" id="Medial"
                                                       onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                       value="1"
                                                          />
                                                        <label className="form-check-label" htmlFor="Medial">Medial</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationMedialLateral" id="Lateral" 
                                                          onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                          value="2"
                                                         />
                                                        <label className="form-check-label" htmlFor="Lateral">Lateral</label>
                                                    </div>
                                                </fieldset>
                                                <fieldset id="group5">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Dorsal"
                                                          onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                          value="1"
                                                         />
                                                        <label className="form-check-label" htmlFor="Dorsal">Dorsal</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Distal" 
                                                          onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                          value="2"
                                                         />
                                                        <label className="form-check-label" htmlFor="Distal">Distal</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Proximal" 
                                                          onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                          value="3"
                                                        />
                                                        <label className="form-check-label " htmlFor="Proximal"><small>Proximal</small></label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="IssueType">Most Severe Tissue Type </label>
                                                <select className="form-control form-control-sm"
                                                    id="IssueType"
                                                    name="fkWoundSevereTissueTypeId"
                                                    
                                                   // onChange={this.onTissueValueChange}
                                                     onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                                    value={Common.replaceNullWithString(this.state.ICDCode.fkWoundSevereTissueTypeId)}
                                                >
                                                    <option ></option>
                                                    {this.state.WoundSevereTissue}
                                                </select>
                                            </div>
                                        </div>
                            <div className="col-sm-6 col-md-4 col-lg-2 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="icdcode">ICD Code </label>
                                    <input type="text" id="icdcode"
                                        name="iCDCode"
                                        onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                        value={this.state.ICDCode.iCDCode} className="form-control form-control-sm text-primary" />
                                </div>
                            </div>

                            {/* </div>
                 

                    <div className="row"> */}



                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div className="form-group mt-6">
                                    <FormControlLabel
                                        control={<Switch name="isSlough" checked={this.state.ICDCode.isSlough} onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)} value="gilad" />}
                                        label="isSlough"
                                    />
                                </div>

                            </div>


                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div className="form-group mt-6">
                                    <FormControlLabel
                                        control={<Switch name="isNecrotic" checked={this.state.ICDCode.isNecrotic} onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)} value="gilad" />}
                                        label="isNecrotic"
                                    />
                                </div>

                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div className="form-group mt-6">
                                    <FormControlLabel
                                        control={<Switch name="isPressure" checked={this.state.ICDCode.isPressure} onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)} value="gilad" />}
                                        label="isPressure"
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Memo">Description</label>
                                    <textarea className="form-control"
                                        value={this.state.ICDCode.description}
                                        name="description"
                                        onChange={e => Common.updateInputValueInState(this.state.ICDCode, e, this, this.state)}
                                        id="description" rows="3"></textarea>
                                </div>
                            </div>
                        </div>


                    </CardBody>
                </Card>


            </>
        );
    }



    render() {

        return (

            <>
                <Form

                    validated={this.state.formValidated}
                >
                    {this.renderWoundForm()}




                    <div className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Submit</Button>
                        <Button type="button" variant="dark" size="sm" onClick={() => this.props.close()}>Back</Button>
                    </div>




                </Form>
            </>
            // </CardBody>
            // </Form>



            // </Card>
        )
    }

    onFormSave() {

    }

}// end of class

/*eslint-enable */