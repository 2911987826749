import React, { useState } from "react";
import DatePicker from "react-datepicker";

export function Woundreport() {

    const [fromDate, setfromDate] = useState(new Date());
    const [toDate, settoDate] = useState(new Date());

    return (
        <form>

            <div className="alert border-primary bg-transparent text-primary fade show mb-5" role="alert">
                <div className="d-flex align-items-center">
                    <div className="alert-icon">
                        <i className="fas fa-info-circle mr-4 text-primary"></i>
                    </div>
                    <div className="flex-1">
                        <span className="h5 m-0 fw-700">Enter date (use mm/dd/yyyy format): </span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                        <label className="form-label d-block" htmlFor="FromDate">Date From</label>
                        <DatePicker className="form-control" selected={fromDate} onChange={date => setfromDate(date)} />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                        <label className="form-label d-block" htmlFor="ToDate">Date To</label>
                        <DatePicker className="form-control" selected={toDate} onChange={date => settoDate(date)} />
                    </div>
                </div>

            </div>
        </form>
    );
}

export default Woundreport;