import React, { useEffect, useRef, useState, useCallback } from 'react';
import Select, { createFilter } from "react-select";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Button } from "react-bootstrap";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { getRequestQry, getRequest, Uri_GetQAPIReport, Uri_GetFacility } from '../../General/api';
import { showSuccessAlertAutoClose } from '../../General/common'
import flatpickr from "flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';

export default function QAPIReport() {
  const [facilitySelected, setFacilitySelected] = useState(null);
  const [searchResultData, setSearchResultData] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [scheduleType, setScheduleType] = useState();
  const [yearlyOptions, setYearlyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [facilitySelectedNameDisplay, setFacilitySelectedNameDisplay] = useState(null);
  const [scheduleTypeSelectedNameDisplay, setScheduleTypeSelectedNameDisplay] = useState(null);
  const monthPicker = useRef(null);
  const quarterlyPicker = useRef(null);
  const yearlyPicker = useRef(null);
  const defaultCloseModalTimeout = 3000;
  const successStatusCode = 200;
  const idForYearToDate = 0;

  const scheduleTypeEnum = {
    1: 'Monthly',
    2: 'Quarterly',
    3: 'Yearly',
    4: 'Year to Date',
  }

  const quarterlyEnum = {
    1: 'Q1 (Jan, Feb, Mar)',
    2: 'Q2 (Apr, May, Jun)',
    3: 'Q3 (Jul, Aug, Sep)',
    4: 'Q4 (Oct, Nov, Dec)',
  }

  const reportColumnsEnum = {
    1: 'Count',
    2: 'Admitted',
    3: 'Acquired',
    4: 'Reoccurring',
    5: 'Improved',
    6: 'Unchanged',
    7: 'Declined',
    8: 'Closed',
  }

  const barsColors = {
    1: '#578EBE',
    2: '#1BBC9B',
    3: '#2AB4C0',
    4: '#E7505A',
    5: '#E87E04',
    6: '#F3C200',
    7: '#8E44AD',
    8: '#3598DC',
    9: '#36D7B7',
    10: '#E08283',
    11: '#C49F47',
    12: '#b572c2'
  }

  useEffect(() => {
    getRequest(`${Uri_GetFacility}?isAssessmentView=1`)
      .then(response => {
        const responseData = response.data;
        if (responseData.statusCode !== successStatusCode || !responseData.result) {
          showSuccessAlertAutoClose('Record not found', 'warning', 'warning!', defaultCloseModalTimeout)
        }

        setFacilityList(responseData.result.map(facility => ({ value: facility.id, label: facility.facilityName })).sort((a, b) => a.label.localeCompare(b.label)));
        setIsLoading(false);

      }).catch(error => {
        console.log(error)
        showSuccessAlertAutoClose('Sorry, an error has occurred', 'warning', 'warning!', defaultCloseModalTimeout)
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const minYear = 2020;
    const currentYear = moment().year();
    const options = [];
    for (let i = minYear; i <= currentYear; i++) {
      options.push({ value: i, label: i.toString() });
    }

    options.sort((a, b) => b.value - a.value)

    setYearlyOptions(options);
  }, [])

  const monthPickerRef = useCallback((node) => {
    if (node !== null) {
      monthPicker.current = flatpickr(node, {
        allowInput: true,
        mode: "single",
        altInput: true,
        plugins: [monthSelectPlugin({
          shorthand: true,
          altFormat: "F",
        })],
        onReady(a, v, flatpickrInstance) {
          flatpickrInstance.calendarContainer.classList.add('flatpickr-disable-year-select')
        }
      });
    }
  }, []);

  function PrintReport() {
    setIsPrinting(true);
    let elementProcessed = 0;
    let heightFromTop = 30;
    let elementsHeight = 360;
    let titleHeight = 70;
    let sheetHeight = 506;
    let widthFromLeft = 50;
    let doc = new jsPDF('p', 'pt', 'a4', false);
    const data = document.querySelectorAll("div[id^='tableForPrint'], #titleForPrint");

    const canvas = document.getElementById('c');
    const ctx = canvas.getContext('2d');

    Array.from(data).forEach((item, index) => {
      html2canvas(item, { quality: 3, scale: 3 }).then(canvasItem => {

        canvas.width = 1236
        canvas.height = 900
        ctx.scale(1, 1);
        ctx.drawImage(canvasItem, 0, 0, 1236, 900);

        if (index % 2 === 0 && index !== 0) {
          doc.addPage();
          heightFromTop = 30;
        }

        const imgData = canvas.toDataURL('image/png');
        if (index === 0)
          doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetHeight, titleHeight, index, 'NONE');
        else
          doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetHeight, elementsHeight, index, 'NONE');

        elementProcessed++;

        if (index === 0)
          heightFromTop += (canvas.height - 800);
        else
          heightFromTop += (canvas.height - 520)

        if (elementProcessed === data.length) {
          const pageCount = doc.internal.getNumberOfPages();
          doc.setFont('Poppins', 'normal', 'bold');
          doc.setFontSize(8);
          doc.setTextColor(112, 108, 108);
          for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text('Page ' + i + ' of ' + pageCount, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 20);
          }
          doc.save("QAPI.pdf");
          setIsPrinting(false);
        }

      });
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();

    setIsSearching(true);
    setFacilitySelectedNameDisplay(facilitySelected.label);

    const facilityIdForSearch = facilitySelected.value;
    let valueForSearch = null;

    switch (scheduleType.label) {
      case scheduleTypeEnum[1]:
        valueForSearch = Number(monthPicker.current.currentMonth) + 1;
        setScheduleTypeSelectedNameDisplay(monthPicker.current?.selectedDates[0].toLocaleString('en-us', { month: 'long' }));
        break;
      case scheduleTypeEnum[2]:
        valueForSearch = quarterlyPicker.current.state.selectValue[0].value;
        setScheduleTypeSelectedNameDisplay(quarterlyPicker.current.state.selectValue[0].label);
        break;
      case scheduleTypeEnum[3]:
        valueForSearch = yearlyPicker.current.state.selectValue[0].value;
        setScheduleTypeSelectedNameDisplay(yearlyPicker.current.state.selectValue[0].label);
        break;
      case scheduleTypeEnum[4]:
        valueForSearch = idForYearToDate;
        setScheduleTypeSelectedNameDisplay('Year to Date');
        break;
      default:
        break;
    }

    getRequestQry(Uri_GetQAPIReport, `?facilityId=${facilityIdForSearch}&scheduleType=${scheduleType.value}&scheduleId=${valueForSearch}`)
      .then(response => {
        const responseData = response.data;
        if (responseData.statusCode !== successStatusCode || !responseData.result || responseData.result?.length === 0) {
          showSuccessAlertAutoClose('Record not found', 'warning', 'warning!', defaultCloseModalTimeout)
        }

        setSearchResultData(responseData.result);
        setIsSearching(false);
        setSearched(true);

      }).catch(error => {
        console.log(error)
        showSuccessAlertAutoClose('Sorry, an error has occurred.', 'warning', 'warning!', defaultCloseModalTimeout)
      })
  }

  const mapColumnValue = (reportCount, reportColumn, colorColumn) => {
    switch (reportColumn) {
      case reportColumnsEnum[1]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.count}</td>;
      case reportColumnsEnum[2]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Admitted || 0}</td>;
      case reportColumnsEnum[3]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Acquired || 0}</td>;
      case reportColumnsEnum[4]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Reoccurring || 0}</td>;
      case reportColumnsEnum[5]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Improved || 0}</td>;
      case reportColumnsEnum[6]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Unchanged || 0}</td>;
      case reportColumnsEnum[7]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Declined || 0}</td>;
      case reportColumnsEnum[8]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Closed || 0}</td>;
      default:
        break;
    }
  }

  const handleDataBars = (qapiReportCounts) => {
    const labels = Object.keys(reportColumnsEnum).map(key => reportColumnsEnum[key]);
    const height = 50;
    const options = { maintainAspectRatio: false }

    const datasets = qapiReportCounts.map((report, index) => {
      return {
        label: `${report.month}/${report.year}`,
        borderWidth: 1,
        data: [report.count,
        report.qapiWoundStatus.Admitted || '',
        report.qapiWoundStatus.Acquired || '',
        report.qapiWoundStatus.Reoccurring || '',
        report.qapiWoundStatus.Improved || '',
        report.qapiWoundStatus.Unchanged || '',
        report.qapiWoundStatus.Declined || '',
        report.qapiWoundStatus.Closed || ''],
        backgroundColor: barsColors[index + 1],
      }
    });
    return { datasets, labels, height, options };
  }

  const createResultDateTable = (searchData, index) => {
    const elementId = `tableForPrint-${index}`;
    return (
      <div id={elementId}>
        <h3 className='my-8 text-primary'>Aggregate Information for {searchData.woundType || 'Wound Type'}</h3>
        {searchData.qapiReportCounts && searchData.qapiReportCounts.length > 0 &&
          <div className='row'>
            <div className='col-lg-12'>
              <table className='table table-bordered table-sm w-100'>
                <thead>
                  <tr>
                    <th></th>
                    {searchData.qapiReportCounts.map(header => <th key={header.month}>{`${header.month}/${header.year}`}</th>)}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: '#FFCB66' }} className='font-weight-bolder'>Count</td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[1], '#FFCB66'))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#76DCD7' }} className='font-weight-bolder'>Admitted</td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[2], '#76DCD7'))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#76DCD7' }} className='font-weight-bolder'>Acquired</td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[3], '#76DCD7'))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#76DCD7' }} className='font-weight-bolder'>Reoccurring</td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[4], '#76DCD7'))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#86C2FF' }} className='font-weight-bolder'>Improved</td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[5], '#86C2FF'))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#86C2FF' }} className='font-weight-bolder'>Unchanged</td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[6], '#86C2FF'))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#86C2FF' }} className='font-weight-bolder'>Declined</td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[7], '#86C2FF'))}
                  </tr>
                  <tr>
                    <td className='font-weight-bolder'>Closed</td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[8]))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-lg-12'>
              <div>
                <Bar data={handleDataBars(searchData.qapiReportCounts)}
                  options={{
                    maintainAspectRatio: true,
                    responsive: true,
                    scales: {
                      yAxes: [{
                        afterBuildTicks: function (scale) {
                          scale.end = scale.ticks[0] + 10;
                          scale.ticks.unshift(scale.ticks[0] + 10);
                          return;
                        },
                        ticks: {
                          beginAtZero: true,
                          suggestedMin: 0,
                          suggestedMax: 0,
                          stepSize: 10,
                        },
                      }],
                      xAxes: [{
                        barThickness: 11.5,
                      }]
                    },
                    plugins: {
                      datalabels: {
                        color: '#000',
                        align: 'top',
                        anchor: 'end',
                      },
                    }
                  }}
                  plugins={[ChartDataLabels]} />
              </div>
            </div>
          </div>
        }
      </div>)
  }

  const handleClearFilters = (e) => {
    setFacilitySelected(null);
    setScheduleType(null);
    setFacilitySelectedNameDisplay(null);
    setScheduleTypeSelectedNameDisplay(null);
    setSearchResultData([]);
    setSearched(false);
    if (monthPicker.current)
      monthPicker.current.clear();
    if (quarterlyPicker.current)
      quarterlyPicker.current.select.clearValue();
    if (yearlyPicker.current)
      yearlyPicker.current.select.clearValue();
  }

  return (
    <>
      <Card>
        <CardHeader title='QAPI (Quality Assurance Performance Improvement) Report'></CardHeader>
        <CardBody>
          {
            isLoading ? (
              <div className="row d-flex justify-content-center">
                <span className="mt-5 spinner spinner-primary"></span>
              </div>
            ) : (
              <>
                <form onSubmit={(e) => handleSearch(e)}>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className="form-group">
                        <label className="form-label" htmlFor="FacilityName">Facility Name</label>
                        <Select
                          options={facilityList}
                          id="facilityId"
                          name="facilityId"
                          required
                          isSearchable
                          isClearable
                          filterOption={createFilter({ matchFrom: 'start' })}
                          onChange={e => {
                            e ? setFacilitySelected({ value: e?.value, label: e?.label }) : setFacilitySelected(null);
                            setScheduleType(null);
                          }}
                          value={facilitySelected}
                        />
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className="form-group">
                        <label className="form-label" htmlFor="scheduleType">Schedule Type</label>
                        <Select
                          options={Object.keys(scheduleTypeEnum).map(key => ({ value: key, label: scheduleTypeEnum[key] }))}
                          id="scheduleType"
                          name="scheduleType"
                          required
                          isClearable
                          onChange={e => e ? setScheduleType({ value: e.value, label: e.label }) : setScheduleType(null)}
                          value={scheduleType}
                        />
                      </div>
                    </div>
                    {
                      scheduleType && scheduleType.label === scheduleTypeEnum[1] &&
                      <div className='col-lg-4'>
                        <div className='form-group'>
                          <label className="form-label" htmlFor="monthPicker">Month</label>
                          <input type="date" id='monthPicker' placeholder='Select...' required className="form-control form-control-md" ref={monthPickerRef} />
                        </div>
                      </div>
                    }
                    {
                      scheduleType && scheduleType.label === scheduleTypeEnum[2] &&
                      <div className='col-lg-4'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='quarterlyPicker'>Quarterly</label>
                          <Select
                            options={Object.keys(quarterlyEnum).map(key => ({ value: key, label: quarterlyEnum[key] }))}
                            id='quarterlyPicker'
                            name='quarterlyPicker'
                            required
                            ref={quarterlyPicker}
                          />
                        </div>
                      </div>
                    }
                    {
                      scheduleType && scheduleType.label === scheduleTypeEnum[3] &&
                      <div className='col-lg-4'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='yearlyPicker'>Yearly</label>
                          <Select
                            options={yearlyOptions}
                            id='yearlyPicker'
                            name='yearlyPicker'
                            required
                            ref={yearlyPicker}
                          />
                        </div>
                      </div>
                    }
                  </div>
                  <div className='d-flex justify-content-end py-3'>
                    <div className='form-group mt-6'>
                      <Button type='submit' variant="success" className="mr-5 px-10" size="sm">Search Report</Button>
                      <button className='btn btn-danger btn-sm' onClick={e => handleClearFilters(e)}>Clear filter</button>
                    </div>
                  </div>
                </form>
                <hr />
                {searched && searchResultData && searchResultData.length === 0 && (<div className='text-center mt-5'>No data found!</div>)}
                {searched && !isSearching && searchResultData && searchResultData.length > 0 && (
                  <>
                    <div className='row'>
                      <div className='col-12'>
                        <button type="button" onClick={PrintReport} disabled={isPrinting} className="mr-3 btn btn-success btn-sm float-right">{!isPrinting ? ('Print Report') : ('Loading...')}</button>
                      </div>
                    </div>
                    <div className='qapi-report'>
                      <div className='qapi-report_content'>
                        <div id='titleForPrint'>
                          <h2 className='text-center mb-10 mt-5 text-primary'>QAPI (Quality Assurance Performance Improvement) Report</h2>
                          <div className='d-flex align-items-center flex-column'>
                            <div className='mb-3 h4'>
                              <span className='font-weight-bolder mr-3'>Facility Name:</span>
                              <span>{facilitySelectedNameDisplay}</span>
                            </div>
                            <div className='h4'>
                              <span className='font-weight-bolder mr-3'>Summary Report For: </span>
                              <span>{scheduleTypeSelectedNameDisplay}</span>
                            </div>
                          </div>
                        </div>
                        {
                          searchResultData?.length > 0 && searchResultData.map((searchData, index) => createResultDateTable(searchData, index))
                        }
                      </div>
                    </div>
                  </>
                )}
                {
                  isSearching && (
                    <div className="row d-flex justify-content-center">
                      <span className="mt-5 spinner spinner-primary"></span>
                    </div>
                  )

                }
                <canvas id='c' width='100' height='100' style={{ display: 'none' }}></canvas>
              </>
            )
          }
        </CardBody>
      </Card>
    </>
  );
}