/*eslint-disable */
import { FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Loader from "react-loader-spinner";
import React from "react";
import { Button, Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import RBAC from "../../General/roleModuleActions";
import ProductSelection from '../products/productselection';
import { common } from '@material-ui/core/colors';
import moment from 'moment';

export default class ViewWound extends React.Component {

    // eslint-disable-next-line no-use-before-define
    constructor(props) {
        super(props);
        this.state = {
            formValidated: false,
            IsLoading: false,
            GetCodeProcess: "Calculate",
            woundTypeSelect: [],
            allWoundTypeSelect: [],
            isFacilityStaff: props.user.roles.roleList.find(x => x.roleName == RBAC.Role_Facility_Level1 || x.roleName == RBAC.Role_Facility_Level2) != undefined ? true : false,

            selectedWoundTypeSelect: [],
            pressureLocation: [],
            nonPressureLocation: [],
            nonPressureLocationSurgery: [],
            thicknessStage: [],
            nonThicknessStage: [],
            WoundSide: [],
            WoundSevereTissue: [],
            WoundAcquisition: [],
            WoundExudateAmount: [],
            WoundExudateType: [],
            WoundDebridementType: [],
            woundBodyRegionSelect: [],
            filterdWoundBodyRegionSelect: [],
            woundICDCodeSheet: [],
            WoundPrimaryDressing: [],
            WoundSecondaryDressing: [],
            WoundType: 'Pressure',
            WoundTypeSelected: '',
            IsThicknessStageTwo: false,
            IsPartialThickness: false,
            IsSloughRequired: false,
            IsNecroticRequired: false,
            ICD10Code: '',
            isReorderAndTissueTypeNotChange: props.isReorder && props?.resident?.isWoundInfoChange !=true ? true : false,
            ReoderAndIsDisableOrientation: false,
            Resident: JSON.parse(JSON.stringify(props.resident)),
            showProduct: props.showProduct,
            orderNote: "",
            orientationLeft: null,
            orientationRight: null,

            orientationUpper: null,
            orientationLower: null,

            orientationInner: null,
            orientationOuter: null,

            orientationAnterior: null,
            orientationPosterior: null,

            orientationMedial: null,
            orientationLateral: null,

            orientationDorsal: null,
            orientationDistal: null,
            orientationProximal: null
            // orientationLeftRight: null,
            // orientationUpperLower: null,
            // orientationInnerOuter: null,
            // orientationAnteriorPosterior: null,
            // orientationMedialLateral: null,
            // orientationDorsalDistalProximal: null

        }
        this.productSelection = null;
        // this.onValueChange = this.onValueChange.bind(this);
  

    }
    componentDidMount() {
        this.getAllLookupsValues();
        this.getAllWoundType();

    }
    getAllLookupsValues = async () => {

        // var woundType = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundTypeID)
        // this.setState({ woundTypeSelect: woundType })

        var woundIssue = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundSevereTissueTypeID)
        this.setState({ WoundSevereTissue: woundIssue })
        // var PressureLocation = await Common.getLookupOptions(Common.LookupsFK.Fk_fkPressureLocationID)
        // this.setState({ pressureLocation: PressureLocation })
        // var NonPressureLocation = await Common.getLookupOptions(Common.LookupsFK.Fk_fkNonPressureLocationID)
        // this.setState({ nonPressureLocation: NonPressureLocation })
        var NonPressureLocationSurgery = await Common.getLookupOptions(Common.LookupsFK.Fk_fkNonPressureLocationSurgeryID)
        this.setState({ nonPressureLocationSurgery: NonPressureLocationSurgery })
        // var PressureThikness = await Common.getLookupOptions(Common.LookupsFK.Fk_fkPressureThiknessID)
        // this.setState({ thicknessStage: PressureThikness })
        var NonPressureThikness = await Common.getLookupOptions(Common.LookupsFK.Fk_fkNonPressureThiknessID)
        this.setState({ nonThicknessStage: NonPressureThikness })

        var WoundSide = await Common.getLookupOptions(Common.LookupsFK.Fk_fkWoundSideID)
        this.setState({ WoundSide: WoundSide })

        var WoundAcquisition = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundAcquisitionID)
        this.setState({ WoundAcquisition: WoundAcquisition })

        // var WoundExudateAmount = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundExudateAmountID)
        // this.setState({ WoundExudateAmount: WoundExudateAmount })

        var WoundExudateType = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundExudateTypeID)
        this.setState({ WoundExudateType: WoundExudateType })

        var WoundDebridementType = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundDebridementTypeID)
        this.setState({ WoundDebridementType: WoundDebridementType })

        var WoundPrimaryDressing = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundPrimaryDressingID)
        this.setState({ WoundPrimaryDressing: WoundPrimaryDressing })

        var WoundSecondaryDressing = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundSecondaryDressingID)
        this.setState({ WoundSecondaryDressing: WoundSecondaryDressing })
    }
    getAllWoundType() {
        debugger
        Api.getRequest(Api.Uri_GetAllWoundType).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    this.setState({ allWoundTypeSelect: res.data.result })
                }
            }
        }).catch((ex) => {
        });
        Api.getRequest(Api.Uri_GetAllICDDropDownList).then((res) => {
            debugger
            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    this.setState({ woundICDCodeSheet: res.data.result.woundToICDList })
                    var woundType = this.props.resident.wound.fkWoundTypeId;
                    var bodyRegion = this.props.resident.wound.fkBodyRegionId;
                    var fkWoundSevereTissueTypeId = this.props.resident.wound.fkWoundSevereTissueTypeId;
                    if (woundType > 0) {
                        this.checkWoundType(woundType);
                        this.onBodyRegionChangeSetValues(bodyRegion);
                        // Diable orientation if orientation in availble reorder
                        debugger
                        if (this.state.Resident.wound.orientationLeftRight != null
                            || this.state.Resident.wound.orientationInnerOuter != null
                            || this.state.Resident.wound.orientationMedialLateral != null
                            || this.state.Resident.wound.orientationUpperLower != null
                            || this.state.Resident.wound.OrientationAnteriorPosterior != null
                            || this.state.Resident.wound.OrientationDorsalDistalProximal != null
                        ) {
                            this.setState({ ReoderAndIsDisableOrientation: true })
                        }

                        this.setOrientationValues(this.props.resident.wound.fkWoundLocationId, false);
                        var icdCode = this.props.resident.wound.icdcode;

                        this.setState({
                            ICD10Code: icdCode
                        })
                    }
                    debugger
                    this.setState({ IsLoading: true })
                }
            }


        }).catch((ex) => {
        });
    }
    onBodyRegionChangeSetValues = (bodyRegion) => {
        var optionTemplate = [];
        if (this.state.WoundTypeSelected == 'NonPressure') {
            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                bodyRegion == menu.fkBodyRegionId && menu.fkThicknessStageId == this.state.Resident.wound.fkWoundThicknessId).map(v => (
                    <option key={v.fkWoundLocationId} value={v.fkWoundLocationId}>
                        {v.woundLocationName}
                    </option>
                ));
        } else {
            var woundTypeId = 0;
            if (this.state.WoundTypeSelected == 'Pressure') {
                woundTypeId = Common.WoundType.pressure;
            }
            else {
                woundTypeId = this.state.Resident.wound.fkWoundTypeId;
            }

            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                bodyRegion == menu.fkBodyRegionId
                && menu.fkThicknessStageId == this.state.Resident.wound.fkWoundThicknessId
                && menu.fkWoundTypeId == woundTypeId).map(v => (
                    <option key={v.fkWoundLocationId} value={v.fkWoundLocationId}>
                        {v.woundLocationName}
                    </option>
                ));
        }
        const uniqueTags = [];
        optionTemplate.map(item => {
            var findItem = uniqueTags.find(x => x.key === item.key);
            if (!findItem)
                uniqueTags.push(item);
        });
        this.setState({ pressureLocation: uniqueTags })
    }
    setOrientationValues = (woundLocation, setOrientation) => {
        debugger
        var icdCodes = this.state.woundICDCodeSheet.filter(x => x.fkWoundSevereTissueTypeId == this.state.Resident.wound.fkWoundSevereTissueTypeId
            && x.fkThicknessStageId == this.state.Resident.wound.fkWoundThicknessId &&
            x.fkBodyRegionId == this.state.Resident.wound.fkBodyRegionId
            && x.fkWoundLocationId == woundLocation)
        this.showHideOrientation(null, null, null, null, null, null, null, null, null, null, null, null, null);

        if (icdCodes) {

            for (var i = 0; i < icdCodes.length; i++) {
                let item = JSON.parse(JSON.stringify(icdCodes[i]));

                // item.orientationLeftRight, item.orientationUpperLower,
                // item.orientationInnerOuter, item.orientationAnteriorPosterior,

                if (item.orientationLeftRight == Common.WoundOrientation.right) {
                    this.setState({ orientationRight: item.orientationLeftRight })
                } else if (item.orientationLeftRight == Common.WoundOrientation.left) {
                    this.setState({ orientationLeft: item.orientationLeftRight })
                }
                if (item.orientationUpperLower == Common.WoundOrientation.upper) {
                    this.setState({ orientationUpper: item.orientationUpperLower })
                } else if (item.orientationUpperLower == Common.WoundOrientation.lower) {
                    this.setState({ orientationLower: item.orientationUpperLower })

                } if (item.orientationInnerOuter == Common.WoundOrientation.inner) {
                    this.setState({ orientationInner: item.orientationInnerOuter })
                }
                else if (item.orientationInnerOuter == Common.WoundOrientation.outer) {
                    this.setState({ orientationOuter: item.orientationInnerOuter })
                }
                if (item.orientationAnteriorPosterior == Common.WoundOrientation.anterior) {
                    this.setState({ orientationAnterior: item.orientationAnteriorPosterior })
                }
                else if (item.orientationAnteriorPosterior == Common.WoundOrientation.posterior) {
                    this.setState({ orientationPosterior: item.orientationAnteriorPosterior })
                }
                if (item.orientationMedialLateral == Common.WoundOrientation.medial) {
                    this.setState({ orientationMedial: item.orientationMedialLateral })
                }
                else if (item.orientationMedialLateral == Common.WoundOrientation.lateral) {
                    this.setState({ orientationLateral: item.orientationMedialLateral })
                }
                if (item.orientationDorsalDistalProximal == Common.WoundOrientation.dorsal) {
                    this.setState({ orientationDorsal: item.orientationDorsalDistalProximal })
                }
                else if (item.orientationDorsalDistalProximal == Common.WoundOrientation.distal) {
                    this.setState({ orientationDistal: item.orientationDorsalDistalProximal })
                }
                else if (item.orientationDorsalDistalProximal == Common.WoundOrientation.proximal) {
                    this.setState({ orientationProximal: item.orientationDorsalDistalProximal })
                }

            }
        }
        // else {
        //     this.showHideOrientation(null, null, null, null, null, null,null, null, null, null, null, null,null);

        // }
        if (setOrientation) {
            this.resetOrientation(this.state.Resident);

        }
    }

    checkWoundType(value) {

        if (value == '5' || value == '23' || value == '24' || value == '25') {
            // this.setState({ WoundType: 'Pressure' })

            this.selectWoundType('pressure');
        } else if (value == '12' || value == '15') {

            this.selectWoundType('surgical');

        }
        else {

            this.selectWoundType('nonpressure');


        }
    }

    getICD10APICall = (event) => {

        const form = event.currentTarget.form;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {
            let obj = this.state.Resident.wound;
            var searchParm = {};
            var tissueTypeId = null;
            debugger
            if (this.state.WoundTypeSelected == 'NonPressure') {
                searchParm.tissueTypeId = obj.fkWoundSevereTissueTypeId;
            }
            searchParm.Fk_WoundLocationID = obj.fkWoundLocationId;
            // if (obj.fkWoundTypeId == '1017') {
            searchParm.Fk_Thickness_StageID = obj.fkWoundThicknessId;
            // searchParm.Fk_NonPrUThicknessID = "";
            // } else {
            //     searchParm.fk_Thickness_StageID = "";
            //     searchParm.fk_NonPrUThicknessID = obj.fkWoundThicknessId;
            // }

            // searchParm.fk_WoundLateralityID = obj.fkWoundLateralityId;
            if (this.state.orientationLeft == null && this.state.orientationRight == null) {

            } else {
                searchParm.OrientationLeftRight = Common.replaceNullWithString(obj.orientationLeftRight);
            }
            if (this.state.orientationUpper == null && this.state.orientationLower == null) {

            } else {
                searchParm.OrientationUpperLower = Common.replaceNullWithString(obj.orientationUpperLower);

            }
            if (this.state.orientationInner == null && this.state.orientationOuter == null) {

            } else {
                searchParm.OrientationInnerOuter = Common.replaceNullWithString(obj.orientationInnerOuter);

            }
            if (this.state.orientationAnterior == null && this.state.orientationPosterior == null) {

            } else {
                searchParm.OrientationAnteriorPosterior = Common.replaceNullWithString(obj.orientationAnteriorPosterior);

            }
            if (this.state.orientationMedial == null && this.state.orientationLateral == null) {

            } else {
                searchParm.OrientationMedialLateral = Common.replaceNullWithString(obj.orientationMedialLateral);

            }
            if (this.state.orientationDorsal == null && this.state.orientationDistal == null && this.state.orientationProximal == null) {

            } else {
                searchParm.OrientationDorsalDistalProximal = Common.replaceNullWithString(obj.orientationDorsalDistalProximal);

            }




            var queryParm = Common.objToQueryString(searchParm);
            Api.getRequestQry(Api.URI_GetICDCode, queryParm)
                .then((response) => {


                    if (response.data.result != null) {
                        if (response.data.result.length > 0) {
                            var code = response.data.result[0].icdCode
                            // setICD10Code(code)
                            this.state.Resident.wound.icdcode = code
                            this.setState({
                                ICD10Code: code,
                                showProduct: true,
                                GetCodeProcess: "Calculated"
                            })
                        }
                        else {
                            Common.showSuccessAlertAutoClose("ICD10 Code Not Found", "warning", "Failed!", 3000);
                        }
                    }
                    else {
                        Common.showSuccessAlertAutoClose("ICD10 Code Not Found", "warning", "Failed!", 3000);
                    }

                })
                .catch(function (error) {

                });
        }
    }

    setProductSelectionDetailToSend(result) {

        let selectedProducts = this.productSelection.getSelectedProductList();
        if (selectedProducts.products != null) {
            if (selectedProducts.products.length > 0) {
                let proudctObjToSend = {
                    "orderNote": this.state.orderNote,
                    "saleOrderId": selectedProducts.salesOrder.saleOrderId,
                    "isWoundInfoChange":this.state.Resident.isWoundInfoChange,
                    // "createdBy": this.props.user.userID,
                    // "modifiedBy": this.props.user.userID,
                    "fkResidentWoundId": result,
                    "FkDressingChangeFrequencyId": selectedProducts.salesOrder.fkDressingChangeFrequencyId,
                    "OtherAdditionalSecondaryDressing": selectedProducts.salesOrder.otherAdditionalSecondaryDressing,
                    "OtherAdditionalPrimaryDressing": selectedProducts.salesOrder.otherAdditionalPrimaryDressing,
                    "OrderNote": selectedProducts.salesOrder.orderNote,
                    "saleOrderViewModels": [],
                    "otherPrimaryProducts": selectedProducts.otherPrimaryProducts,
                    "otherSecodaryProducts": selectedProducts.selectedSecondaryProducts,
                    "otherFillerProducts": selectedProducts.selectedOtherFillerProducts,
                    "otherTraditionalProducts": selectedProducts.selectedOtherTraditionalProducts,
                    "fkLastWoundDetailId":this.props.resident.fkLastWoundDetailId,
                    "isReorder": this.props.isReorder
                };

                selectedProducts.products.forEach((value) => {
                    let obj = {
                        "fkProductId": value.id,
                        "saleOrderProductId": value.saleOrderProductId,
                        "quantity": value.quantity,
                        "remainingQuantity": value.remainingQuantity,
                        "productLevel": value.productLevel,
                        "fkProductCategoryId":value.fkProductCategoryId
                        // "createdBy": this.props.user.userID,
                        // "modifiedBy": this.props.user.userID,
                    }
                    proudctObjToSend.saleOrderViewModels.push(obj);

                });

                this.UpsertSaleOrderApiCall(proudctObjToSend);
            }
        }
    }//
    UpsertSaleOrderApiCall(productObject) {
        let obj = productObject;
        Api.postRequest(Api.URI_UpsertSaleOrder, obj).then((res) => {

            if (res.data.success) {
                if (res.data.result != null) {
                    Common.showSuccessAlertAutoClose("Sales Order Created Successfully", "success", "Success!", 3000);
                    this.props.refresh && this.props.refresh();
                    this.props.close(true);
                }
                else {
                    Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {

            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }
    onWoundTypeValueChange(event) {
        let selectedValue = event.target.value;
        if (this.state.selectedOption != undefined) {

            Common.showConfirmation('Changing Wound Type will reset all values.  Do you want to proceed?', 'Confirmation', () => {
                this.resetICDDropdowns();
                this.selectWoundType(selectedValue);
            }, () => { }, null);
        } else {
            this.selectWoundType(selectedValue);
        }
    }
    selectWoundType = (selectedValue) => {

        let type = selectedValue.toLowerCase();

        let _woundType = [];
        let BodyRegionOption = [];
        let bodyRegion = this.state.woundBodyRegionSelect;
        switch (type) {
            case "pressure":
                _woundType = this.state.allWoundTypeSelect.filter(i => i.type == 'pressure');
                // BodyRegionOption = bodyRegion.map(v => (
                //     <option key={v.key} value={v.key}>
                //         {v.value}
                //     </option>
                // ));
                // this.setState({ filterdWoundBodyRegionSelect: BodyRegionOption, WoundTypeSelected: 'Pressure' })
                Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, null, "fkWoundSevereTissueTypeId");
                Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 5, "fkWoundTypeId")
                // this.resetICDDropdowns();
                this.onTissueValueChangeSetDropdownValues(null, 'Pressure');
                this.setState({ WoundTypeSelected: 'Pressure' })

                //this.onWoundTypeChangeSetDropdownValues(5);

                break;
            case "nonpressure":
                _woundType = this.state.allWoundTypeSelect.filter(i => i.type == 'non-pressure');
                BodyRegionOption = bodyRegion.map(v => (
                    <option key={v.key} value={v.key}>
                        {v.value}
                    </option>
                ));
                // Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 2, "fkWoundTypeId")

                // this.resetICDDropdowns();
                this.onTissueValueChangeSetDropdownValues(this.state.Resident.wound.fkWoundSevereTissueTypeId, 'NonPressure');
                this.setState({ filterdWoundBodyRegionSelect: BodyRegionOption, WoundTypeSelected: 'NonPressure' })
                break;
            case "surgical":
                _woundType = this.state.allWoundTypeSelect.filter(i => i.type == 'surgical');
                Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 12, "fkWoundTypeId");
                Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, null, "fkWoundSevereTissueTypeId");

                this.onTissueValueChangeSetDropdownValues(null, 'Surgical');
                this.setState({ WoundTypeSelected: 'Surgical' });
                // this.onWoundTypeChangeSetDropdownValues(12);

                break;
        }
        let optionTemplate = _woundType.map((v) => (
            <option key={v.id} value={v.id}>
                {v.name}
            </option>
        ));
        debugger
        this.setState({ selectedWoundTypeSelect: optionTemplate })

        this.setState({
            selectedOption: selectedValue
        });


    }


    onTissueValueChangeSetDropdownValues = (tissueTypeId, woundType) => {

        //  var woundTypeId=this.state.Resident.wound.fkWoundTypeId;

        if (woundType === 'NonPressure') {
            if (tissueTypeId == '1214') {
                Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 10, "fkWoundThicknessId")
                this.onThicknessChangeSetDropdownValues(10);

            } else {
                Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 11, "fkWoundThicknessId")
                this.onThicknessChangeSetDropdownValues(11);
            }
            var optionTemplate = [];
            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                tissueTypeId == menu.fkWoundSevereTissueTypeId).map(v => (
                    <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
                        {v.woundThicknessName}
                    </option>
                ));
            const uniqueTags = [];
            optionTemplate.map(item => {
                if (item.key != "null") {

                    var findItem = uniqueTags.find(x => x.key === item.key);
                    if (!findItem)
                        uniqueTags.push(item);

                }
            });

            this.setState({ thicknessStage: uniqueTags })

            //Body Region
            var bodyRegionOptionTemplate = [];
            bodyRegionOptionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                tissueTypeId == menu.fkWoundSevereTissueTypeId).map(v => (
                    <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
                        {v.woundBodyRegionName}
                    </option>
                ));
            const bodyRegionUniqueTags = [];
            bodyRegionOptionTemplate.map(item => {
                var findItem = bodyRegionUniqueTags.find(x => x.key === item.key);
                if (item.key != "null") {
                    if (!findItem)
                        bodyRegionUniqueTags.push(item);
                }
            });
            this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags })

            // if (uniqueTags.length > 0) {

            // }
        }
        else if (woundType === 'Surgical') {
            Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 11, "fkWoundThicknessId")
            var woundTypeId = this.state.Resident.wound.fkWoundTypeId;
            var optionTemplate = [];
            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                woundTypeId == menu.fkWoundTypeId).map(v => (
                    <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
                        {v.woundThicknessName}
                    </option>
                ));
            const uniqueTags = [];
            optionTemplate.map(item => {
                var findItem = uniqueTags.find(x => x.key === item.key);
                if (item.key != "null") {
                    if (!findItem)
                        uniqueTags.push(item);
                }
            });

            this.setState({ thicknessStage: uniqueTags })

            //Body Region
            var bodyRegionOptionTemplate = [];
            bodyRegionOptionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                woundTypeId == menu.fkWoundTypeId).map(v => (
                    <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
                        {v.woundBodyRegionName}
                    </option>
                ));
            const bodyRegionUniqueTags = [];
            bodyRegionOptionTemplate.map(item => {
                var findItem = bodyRegionUniqueTags.find(x => x.key === item.key);
                if (item.key != "null") {
                    if (!findItem)
                        bodyRegionUniqueTags.push(item);
                }
            });
            this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags })


        }
        else {
            var woundTypeId = this.state.Resident.wound.fkWoundTypeId;
            var optionTemplate = [];
            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                woundTypeId == menu.fkWoundTypeId).map(v => (
                    <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
                        {v.woundThicknessName}
                    </option>
                ));
            const uniqueTags = [];
            optionTemplate.map(item => {
                var findItem = uniqueTags.find(x => x.key === item.key);
                if (item.key != "null" && item.key != 10 && item.key != 11) {
                    if (!findItem)
                        uniqueTags.push(item);
                }
            });

            this.setState({ thicknessStage: uniqueTags })

            //Body Region
            var bodyRegionOptionTemplate = [];
            bodyRegionOptionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                woundTypeId == menu.fkWoundTypeId).map(v => (
                    <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
                        {v.woundBodyRegionName}
                    </option>
                ));
            const bodyRegionUniqueTags = [];
            bodyRegionOptionTemplate.map(item => {
                var findItem = bodyRegionUniqueTags.find(x => x.key === item.key);
                if (item.key != "null") {
                    if (!findItem)
                        bodyRegionUniqueTags.push(item);
                }
            });
            this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags })




            this.showHideOrientation(null, null, null, null, null, null, null, null, null, null, null, null, null);
            if (tissueTypeId == 1298 || tissueTypeId == 1303 || tissueTypeId == 1302) {
                this.setState({ IsSloughRequired: true, IsNecroticRequired: false })
            } else if (tissueTypeId == 1299 || tissueTypeId == 1300) {
                this.setState({ IsNecroticRequired: true, IsSloughRequired: false })
            } else {
                this.setState({ IsSloughRequired: false })
                this.setState({ IsNecroticRequired: false })
            }
            //End
        }
    }
    onThicknessChangeSetDropdownValues = (thicknessId) => {
        var tissueTypeId = null;
        if (this.state.WoundTypeSelected == 'NonPressure') {
            tissueTypeId = this.state.Resident.wound.fkWoundSevereTissueTypeId;
        }
        var bodyRegionOptionTemplate = [];
        bodyRegionOptionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
            thicknessId == menu.fkThicknessStageId && tissueTypeId == menu.fkWoundSevereTissueTypeId).map(v => (
                <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
                    {v.woundBodyRegionName}
                </option>
            ));
        const bodyRegionUniqueTags = [];
        bodyRegionOptionTemplate.map(item => {
            var findItem = bodyRegionUniqueTags.find(x => x.key === item.key);
            if (!findItem)
                bodyRegionUniqueTags.push(item);
        });
        this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags })
        //End
    }
    showHideOrientation = (orientationLeft, orientationRight, orientationUpper, orientationLower,
        orientationInner, orientationOuter, orientationAnterior, orientationPosterior,
        orientationMedial, orientationLateral, orientationDorsal, orientationDistal, orientationProximal) => {
        this.setState({
            orientationLeft: orientationLeft,
            orientationRight: orientationRight,
            orientationUpper: orientationUpper,
            orientationLower: orientationLower,

            orientationInner: orientationInner,
            orientationOuter: orientationOuter,

            orientationAnterior: orientationAnterior,
            orientationPosterior: orientationPosterior,

            orientationMedial: orientationMedial,
            orientationLateral: orientationLateral,

            orientationDorsal: orientationDorsal,
            orientationDistal: orientationDistal,
            orientationProximal: orientationProximal

        })
    }
    renderWoundForm() {
        return (
            <>{
                (this.state.IsLoading == true) ?
                    <div >
                        <fieldset className="p-2 mb-2 border rounded">
                            <legend className="text-primary fw-500">Basic Information </legend>
                            <div className="row">

                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold"
                                            htmlFor="ResidentName">Resident Name</label>
                                        <input type="text" id="ResidentName"
                                            name="residentName"
                                            disabled
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                            defaultValue={Common.replaceNullWithString(this.state.Resident.residentName)}
                                            className="form-control form-control-sm" />
                                        <div className="invalid-feedback">Resident Name Is Required</div>
                                    </div>
                                </div>

                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold"

                                            htmlFor="FacilityName">Facility Name</label>
                                        <input type="text" id="FacilityName"
                                            name="facilityName"
                                            disabled
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                            defaultValue={Common.replaceNullWithString(this.state.Resident.facilityName)}
                                            className="form-control form-control-sm" />
                                        <div className="invalid-feedback">Facility Name Is Required</div>
                                    </div>
                                </div>

                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="FirstName">Room / Unit</label>
                                        <input type="text" id="RoomUnit"
                                            name="roomUnit"
                                            disabled
                                            defaultValue={Common.replaceNullWithString(this.state.Resident.wound.roomUnit)}
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                            // defaultValue={Common.replaceNullWithString(this.state.Resident.roomUnit)}

                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="EvaluatorName">Evaluator Name</label>
                                        <input type="text" id="evaluatedBy"
                                            name="evaluatedBy"
                                            defaultValue={Common.replaceNullWithString(this.state.Resident.evaluatedBy)}
                                            disabled
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="PhysicanName">Physican Name</label>
                                        <input type="text" id="PhysicanName"
                                            disabled
                                            name="physicanName"
                                            defaultValue={Common.replaceNullWithString(this.state.Resident.physicanName)}
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                            className="form-control form-control-sm" />
                                        <div className="invalid-feedback">Physican Name</div>
                                    </div>
                                </div>


                            </div>
                        </fieldset>
                        <fieldset disabled={this.state.showProduct == true ? true : false} className="p-2 mb-2 border rounded">
                            <legend className="text-primary fw-500">
                                Information </legend>

                            <label className="form-label font-weight-bold" htmlFor="woundType">Wound Type </label>
                            <div className="row" >
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" id="defaultUnchecked1"
                                            value="Pressure"
                                            checked={this.state.WoundTypeSelected == "Pressure" ? true : false}
                                            //onChange={e => this.onWoundTypeValueChange(e)}
                                            disabled={this.state.isReorderAndTissueTypeNotChange}
                                        />
                                        <label className="custom-control-label font-weight-bold" htmlFor="defaultUnchecked1" >Pressure</label>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" id="defaultUnchecked2" value="NonPressure"
                                            checked={this.state.WoundTypeSelected == "NonPressure" ? true : false}
                                            //onChange={e => this.onWoundTypeValueChange(e)}
                                            disabled={this.state.isReorderAndTissueTypeNotChange}
                                        />
                                        <label className="custom-control-label font-weight-bold" htmlFor="defaultUnchecked2"> Non Pressure</label>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" id="defaultUnchecked3"
                                            value="Surgical"
                                            checked={this.state.WoundTypeSelected == "Surgical" ? true : false}
                                            //onChange={e => this.onWoundTypeValueChange(e)}
                                            disabled={this.state.isReorderAndTissueTypeNotChange}
                                        />
                                        <label className="custom-control-label font-weight-bold" htmlFor="defaultUnchecked3">Surgical</label>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                </div>
                                {/* <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 justify-content-end">
                                    <label className="form-label font-weight-bold" htmlFor="icdcode">ICD Code - <span className={this.state.GetCodeProcess == "Calculate" ? "text-danger" : "text-success"}>{this.state.GetCodeProcess}</span></label>
                                    <div className="input-group mb-3">
                                        <input type="text" id="icdcode"
                                            name="icdcode"
                                            aria-describedby="basic-addon2"
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            value={this.state.ICD10Code} className="form-control form-control-sm" disabled />
                                        <div className="input-group-append">
                                            {this.state.showProduct == false ?
                                            
                                                <button type="button" className="btn btn-success btn-xs" onClick={e => this.getICD10APICall(e)}>GET CODE</button>
                                                :
                                                <div></div>
                                                // <button type="button" className="btn btn-danger btn-xs" onClick={e => this.setState({ showProduct: false })}>Edit</button>
                                            }
                                        </div>

                                    </div>
                                </div> */}
                            </div>


                            <hr></hr>
                            <div className="row">
                                {/* {this.state.WoundTypeSelected != 'Pressure' && */}
                                <>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label font-weight-bold" htmlFor="woundType">
                                                {this.state.WoundTypeSelected == 'NonPressure' ? "Non Pressure Type" :
                                                    this.state.WoundTypeSelected != 'Pressure' ? "Surgical Type" : "Pressure Type"}
                                            </label>
                                            <select className="form-control form-control-sm"
                                                id="woundType"
                                                name="fkWoundTypeId"
                                                required
                                                disabled={this.state.isReorderAndTissueTypeNotChange}
                                                //onChange={e => this.onWoundTypeChange(e)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundTypeId)}
                                            >
                                                <option></option>
                                                {this.state.selectedWoundTypeSelect}
                                            </select>
                                        </div>
                                    </div>
                                </>
                                {/* } */}
                                {this.state.WoundTypeSelected == 'NonPressure' &&

                                    <>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label font-weight-bold" htmlFor="IssueType">Most Severe Tissue Type </label>
                                                <select className="form-control form-control-sm"
                                                    id="IssueType"
                                                    name="fkWoundSevereTissueTypeId"
                                                    required
                                                    //onChange={this.onTissueValueChange}
                                                    // //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                    value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundSevereTissueTypeId)}
                                                >
                                                    <option ></option>
                                                    {this.state.WoundSevereTissue}
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }
                                {this.state.WoundTypeSelected == 'Pressure' &&
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2" >
                                        <div className={`form-group`}>
                                            <label className="form-label font-weight-bold" htmlFor="thicknessStage">
                                                {this.state.WoundTypeSelected == 'Pressure' ? "Stage" : "Thickness"}</label>
                                            <select className="form-control form-control-sm" id="fkWoundThicknessId"
                                                name="fkWoundThicknessId"
                                                required
                                                disabled={this.state.isReorderAndTissueTypeNotChange
                                                    && this.state.WoundTypeSelected != 'Pressure' ? true : false}
                                                //onChange={e => this.onThicknessStageChange(e)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundThicknessId)}
                                            >
                                                <option></option>
                                                {this.state.thicknessStage}
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="Country">Body Region</label>
                                        <select className="form-control form-control-sm"
                                            name="fkBodyRegionId"
                                            required
                                            //onChange={e => this.onBodyRegi//onChange(e)}
                                            // //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            value={Common.replaceNullWithString(this.state.Resident.wound.fkBodyRegionId)}
                                            id="fkBodyRegionId"
                                            disabled={this.state.isReorderAndTissueTypeNotChange}
                                        >
                                            {/* {this.state.filterdWoundBodyRegionSelect} */}
                                            <option></option>
                                            {this.state.woundBodyRegionSelect}
                                        </select>
                                    </div>
                                </div>
                                {this.state.Resident.wound.fkWoundLocationId == 10011 &&
                                    <>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="EvaluatorName">Other Body Region</label>
                                                <input type="text" id="otherBodyRegion"
                                                    name="otherBodyRegion"
                                                    disabled
                                                    //onChange={e => { Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state), this.setState({ isLocationChange: true }) }}
                                                    defaultValue={Common.replaceNullWithString(this.state.Resident.wound.otherBodyRegion)}
                                                    className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* {this.state.WoundType == 'Pressure' &&
                                    <> */}
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="heel">Wound Location</label>
                                        <select className="form-control form-control-sm" id="fk_PressureLocationID"
                                            name='fkWoundLocationId'
                                            required
                                            // disabled={this.state.isReorderAndTissueTypeNotChange}
                                            // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            //onChange={e => this.onWoundLocationChange(e)}

                                            value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundLocationId)}

                                        >
                                            <option></option>
                                            {this.state.pressureLocation}
                                        </select>
                                    </div>
                                </div>

                                {this.state.Resident.wound.fkWoundLocationId == 10012 &&
                                    <>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="EvaluatorName">Other Wound location</label>
                                                <input type="text" id="residentWoundLocation"
                                                    name="residentWoundLocation"
                                                    disabled
                                                    //onChange={e => { Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state), this.setState({ isLocationChange: true }) }}
                                                    defaultValue={Common.replaceNullWithString(this.state.Resident.wound.residentWoundLocation)}
                                                    className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <label className="form-label font-weight-bold">Orientation</label>
                                    <div className="form-group">



                                        {/* {this.state.orientationLeftRight != null &&
                                            <> */}
                                        <div className="row">
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">

                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationLeftRight" id="inlineRadio1"

                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.right}
                                                        required={this.state.orientationLeft == Common.WoundOrientation.right
                                                            || this.state.orientationRight == Common.WoundOrientation.right && true}
                                                        checked={this.state.Resident.wound.orientationLeftRight == Common.WoundOrientation.right ? true : false}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                    />
                                                    <label className={this.state.orientationRight == Common.WoundOrientation.right ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="inlineRadio1"><small>Right</small>{this.state.orientationRight == Common.WoundOrientation.right && "*"}</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">

                                                <div className="form-check form-check-inline ml-5">
                                                    <input className="form-check-input" type="radio" name="orientationLeftRight" id="inlineRadio2"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        checked={this.state.Resident.wound.orientationLeftRight == Common.WoundOrientation.left ? true : false}
                                                        value={Common.WoundOrientation.left}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                    />
                                                    <label className={this.state.orientationLeft == Common.WoundOrientation.left ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="inlineRadio2"><small>Left</small>{this.state.orientationLeft == Common.WoundOrientation.left && "*"}</label>
                                                </div>
                                            </div>
                                        </div>



                                        {/* </>
                                        }
                                        {this.state.orientationUpperLower != null &&
                                            <> */}
                                        <div className="row">
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationUpperLower" id="Upper"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        checked={this.state.Resident.wound.orientationUpperLower == Common.WoundOrientation.upper ? true : false}
                                                        required={this.state.orientationUpper == Common.WoundOrientation.upper
                                                            || this.state.orientationLower == Common.WoundOrientation.lower && true}
                                                        value={Common.WoundOrientation.upper}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                    />
                                                    <label className={this.state.orientationUpper == Common.WoundOrientation.upper ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Upper"><small>Upper</small>{this.state.orientationUpper == Common.WoundOrientation.upper && "*"}</label>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline ml-5">
                                                    <input className="form-check-input" type="radio" name="orientationUpperLower" id="Lower"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}

                                                        checked={this.state.Resident.wound.orientationUpperLower == Common.WoundOrientation.lower ? true : false}
                                                        value={Common.WoundOrientation.lower}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                    />
                                                    <label className={this.state.orientationUpperLower == Common.WoundOrientation.lower ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Lower"><small>Lower</small>{this.state.orientationLower == Common.WoundOrientation.lower && "*"}</label>
                                                </div>
                                            </div>
                                        </div>



                                        {/* </>
                                        }
                                        {this.state.orientationInnerOuter != null &&
                                            <> */}
                                        <div className="row">
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationInnerOuter" id="Inner"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.inner}
                                                        checked={this.state.Resident.wound.orientationInnerOuter == Common.WoundOrientation.inner ? true : false}
                                                        required={this.state.orientationInner == Common.WoundOrientation.inner ||
                                                            this.state.orientationOuter == Common.WoundOrientation.outer && true}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                    />
                                                    <label className={this.state.orientationInner == Common.WoundOrientation.inner ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Inner"><small>Inner</small>{this.state.orientationInner == Common.WoundOrientation.inner && "*"}</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline ml-5">
                                                    <input className="form-check-input" type="radio" name="orientationInnerOuter" id="Outer"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.outer}
                                                        checked={this.state.Resident.wound.orientationInnerOuter == Common.WoundOrientation.outer ? true : false}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                    />
                                                    <label className={this.state.orientationOuter == Common.WoundOrientation.outer ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Outer"><small>Outer</small>{this.state.orientationOuter == Common.WoundOrientation.outer && "*"}</label>
                                                </div>
                                            </div>
                                        </div>

                                        {/* </>
                                        }
                                        {this.state.orientationAnteriorPosterior != null &&
                                            <> */}
                                        <div className="row">
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationAnteriorPosterior" id="Anterior"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.anterior}
                                                        required={this.state.orientationAnterior == Common.WoundOrientation.anterior
                                                            || this.state.orientationPosterior == Common.WoundOrientation.posterior && true}
                                                        checked={this.state.Resident.wound.orientationAnteriorPosterior == Common.WoundOrientation.anterior ? true : false}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                    />
                                                    <label className={this.state.orientationAnterior == Common.WoundOrientation.anterior ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Anterior"><small>Anterior</small>{this.state.orientationAnterior == Common.WoundOrientation.anterior && "*"}</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline ml-5">
                                                    <input className="form-check-input" type="radio" name="orientationAnteriorPosterior" id="Posterior"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        checked={this.state.Resident.wound.orientationAnteriorPosterior == Common.WoundOrientation.posterior ? true : false}
                                                        value={Common.WoundOrientation.posterior}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                    />
                                                    <label className={this.state.orientationPosterior == Common.WoundOrientation.posterior ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Posterior"><small>Posterior</small>{this.state.orientationPosterior == Common.WoundOrientation.posterior && "*"}</label>
                                                </div>
                                            </div>


                                        </div>
                                        {/* </>
                                        }
                                        {this.state.orientationMedialLateral != null &&
                                            <> */}


                                        <div className="row">
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationMedialLateral" id="Medial"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.medial}
                                                        checked={this.state.Resident.wound.orientationMedialLateral == Common.WoundOrientation.medial ? true : false}
                                                        required={this.state.orientationMedial == Common.WoundOrientation.medial
                                                            || this.state.orientationLateral == Common.WoundOrientation.lateral && true}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                    />
                                                    <label className={this.state.orientationMedial == Common.WoundOrientation.medial ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Medial"><small>Medial</small>{this.state.orientationMedial == Common.WoundOrientation.medial && "*"}</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline ml-5">
                                                    <input className="form-check-input" type="radio" name="orientationMedialLateral" id="Lateral"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.lateral}
                                                        checked={this.state.Resident.wound.orientationMedialLateral == Common.WoundOrientation.lateral ? true : false}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                    />
                                                    <label className={this.state.orientationLateral == Common.WoundOrientation.lateral ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Lateral"><small>Lateral</small>{this.state.orientationLateral == Common.WoundOrientation.lateral && "*"}</label>

                                                </div>
                                            </div>
                                        </div>

                                        {/* </>
                                        }
                                        {this.state.orientationDorsalDistalProximal != null &&
                                            <> */}
                                        <div className="row">
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Dorsal"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.dorsal}
                                                        checked={this.state.Resident.wound.orientationDorsalDistalProximal == Common.WoundOrientation.dorsal ? true : false}
                                                        required={this.state.orientationDorsal == Common.WoundOrientation.dorsal
                                                            || this.state.orientationDistal == Common.WoundOrientation.distal
                                                            || this.state.orientationProximal == Common.WoundOrientation.proximal && true}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                    />
                                                    <label className={this.state.orientationDorsal == Common.WoundOrientation.dorsal ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Dorsal"><small>Dorsal</small>{this.state.orientationDorsal == Common.WoundOrientation.dorsal && "*"}</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline ml-5">
                                                    <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Distal"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.distal}
                                                        checked={this.state.Resident.wound.orientationDorsalDistalProximal == Common.WoundOrientation.distal ? true : false}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                    />
                                                    <label className={this.state.orientationDistal == Common.WoundOrientation.distal ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Distal"><small>Distal</small>{this.state.orientationDistal == Common.WoundOrientation.distal && "*"}</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                <div className="form-check form-check-inline ml-5">
                                                    <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Proximal"
                                                        //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.WoundOrientation.proximal}
                                                        checked={this.state.Resident.wound.orientationDorsalDistalProximal == Common.WoundOrientation.proximal ? true : false}
                                                        disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                    />
                                                    <label className={this.state.orientationProximal == Common.WoundOrientation.proximal ? "form-check-label font-weight-bold" : "form-check-label"}
                                                        htmlFor="Proximal"><small>Proximal</small>{this.state.orientationProximal == Common.WoundOrientation.proximal && "*"}</label>
                                                </div>
                                            </div>

                                        </div>
                                        {/* </>
                                        } */}
                                        {(!this.state.isReorderAndTissueTypeNotChange || !this.state.ReoderAndIsDisableOrientation) &&
                                            <button type="button" onClick={e => this.resetOrientation(this.state.Resident)} className="btn btn-danger btn-xs">Reset Orientation</button>
                                        }
                                    </div>
                                </div>



                            </div>
                            <div className="row" >

                                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="Length">Length (cm)</label>
                                        <input type="number" id="Length" min="0" step="0.01"
                                            name="woundLength"
                                            required
                                            // //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}

                                            //onChange={e => this.allowTwoDigitAfterDecimal(e)}
                                            value={Common.replaceNullWithString(this.state.Resident.wound.woundLength)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="Width">Width (cm)</label>
                                        <input type="number" id="Width" min="0" step="0.01"
                                            name="woundWidth"
                                            required
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            // //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            //onChange={e => this.allowTwoDigitAfterDecimal(e)}
                                            value={Common.replaceNullWithString(this.state.Resident.wound.woundWidth)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="Depth">Depth (cm)</label>
                                        <input type="number" id="Depth" min="0" step="0.01"
                                            name="woundDepth"
                                            required
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            // //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            //onChange={e => this.allowTwoDigitAfterDecimal(e)}
                                            value={Common.replaceNullWithString(this.state.Resident.wound.woundDepth)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-4 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="fkExudateAmountId">Exudate Amount</label>
                                        <select className="form-control form-control-sm"
                                            id="fkExudateAmountId"
                                            name="fkExudateAmountId"
                                            required
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            value={Common.replaceNullWithString(this.state.Resident.wound.fkExudateAmountId)}

                                        >

                                            <option value=""></option>
                                            <option value="1">None</option>
                                            <option value="3">Light</option>
                                            <option value="4">Moderate</option>
                                            <option value="5">Heavy</option>

                                            {/* {this.state.WoundExudateAmount} */}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-4 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="sloughPercentage">Slough %</label>
                                        <input type="number" min="1" id="sloughPercentage" className="form-control form-control-sm"
                                            name='sloughPercentage'
                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            value={Common.replaceNullWithString(this.state.Resident.wound.sloughPercentage)}
                                            required={this.state.Resident.wound.fkWoundThicknessId == 5
                                                && this.state.Resident.wound.necroticPercentage > 0 ? false : this.state.IsSloughRequired}
                                            disabled={this.state.IsThicknessStageTwo}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-4 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label className="form-label font-weight-bold" htmlFor="necroticPercentage">Necrotic %</label>
                                        <input type="number" min="1" id="necroticPercentage" className="form-control form-control-sm"
                                            name='necroticPercentage'
                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            value={Common.replaceNullWithString(this.state.Resident.wound.necroticPercentage)}
                                            required={this.state.Resident.wound.fkWoundThicknessId == 5
                                                && this.state.Resident.wound.sloughPercentage > 0 ? false :
                                                this.state.IsNecroticRequired}
                                            disabled={this.state.IsThicknessStageTwo}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row" >









                            </div>

                            <div className="row">
                                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={<Switch name="tunneling" checked={this.state.Resident.wound.tunneling} onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)} value="gilad" />}
                                            label="Tunneling"
                                            className="font-weight-bold"
                                        />
                                    </div>

                                </div>
                                {this.state.Resident.wound.tunneling
                                    &&
                                    <>
                                        <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label font-weight-bold" htmlFor="tunnelingDepth">T Depth (cm)</label>
                                                <input type="number" id="tunnelingDepth" min="0" step="0.01"
                                                    name="tunnelingDepth"
                                                    required

                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                    // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                    //onChange={e => this.allowTwoDigitAfterDecimal(e)}
                                                    value={Common.replaceNullWithString(this.state.Resident.wound.tunnelingDepth)}
                                                    className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label font-weight-bold" htmlFor="underminingDepth font-weight-bold">T Clock Position</label>
                                                <input type="number" id="TunnelingClockPosition" min="0" max="12"
                                                    name="tunnelingClockPosition"
                                                    required
                                                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                    onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                    value={Common.replaceNullWithString(this.state.Resident.wound.tunnelingClockPosition)}

                                                    className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={<Switch name="undermining" checked={this.state.Resident.wound.undermining} 
                                            // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)} 
                                            value="gilad" />}
                                            label="Undermining"
                                            className="font-weight-bold"
                                        />
                                    </div>

                                </div>
                                {this.state.Resident.wound.undermining &&
                                    <>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label font-weight-bold" htmlFor="underminingDepth font-weight-bold">U Depth (cm)</label>
                                                <input type="number" id="underminingDepth" min="0" step="0.01"
                                                    name="underminingDepth"
                                                    required
                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                    // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                    //onChange={e => this.allowTwoDigitAfterDecimal(e)}
                                                    value={Common.replaceNullWithString(this.state.Resident.wound.underminingDepth)}

                                                    className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </>
                                }




                                {/* </div>
                            <div className="row"> */}
                                {this.state.WoundTypeSelected == 'Surgical' &&
                                    <>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label font-weight-bold" htmlFor="surgicalDate">Surgical Date</label>
                                                <input type="Date" id="surgicalDate"
                                                    name="surgicalDate"
                                                    required
                                                    min='1900-01-01'
                                                    max="3999-12-31"
                                                    //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                    // defaultValue={Common.getInputStringDate( moment())}
                                                    value={Common.getInputStringDateUsingMoment(this.state.Resident.wound.surgicalDate != null ? moment(this.state.Resident.wound.surgicalDate) : "")}
                                                    className="form-control form-control-sm"
                                                // disabled={this.state.IsPartialThickness}
                                                //disabled={this.state.IsThicknessStageTwo}

                                                />
                                            </div>
                                        </div>

                                    </>
                                }
                            </div>

                            <div className="row">
                                {this.state.WoundTypeSelected != 'Surgical' &&
                                    <>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label font-weight-bold" htmlFor="DebridementDate">Debridement Date</label>
                                                <input type="Date" id="DebridementDate"
                                                    name="debridementDate"
                                                    required={this.state.isFacilityStaff ? false : true}
                                                    min='1900-01-01'
                                                    max={Common.getInputStringDateUsingMoment(moment())}
                                                    //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                    // value={Common.getInputStringDateUsingMoment(this.state.Resident.wound.debridementDate != null ? moment(this.state.Resident.wound.debridementDate) : moment())}
                                                    value={Common.getInputStringDateUsingMoment(moment(this.state.Resident.wound.debridementDate))}

                                                    className="form-control form-control-sm"

                                                // disabled={this.state.IsPartialThickness}
                                                //disabled={this.state.IsThicknessStageTwo}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label font-weight-bold" htmlFor="fkDebridementTypeId">Debridement Type</label>
                                                <select className="form-control form-control-sm"
                                                    id="fkDebridementTypeId"
                                                    name="fkDebridementTypeId"
                                                    required={this.state.isFacilityStaff ? false : true}
                                                    //onChange={e => this.onDebridementTypeChange(e)}
                                                    value={Common.replaceNullWithString(this.state.Resident.wound.fkDebridementTypeId)}
                                                    disabled={this.props.isReorder && this.props.resident.wound.fkDebridementTypeId == 1027 ?
                                                        true : this.state.IsPartialThickness}
                                                >
                                                    <option value=""></option>
                                                    {

                                                        this.state.WoundDebridementType}
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">


                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 justify-content-end">
                                    <label className="form-label font-weight-bold" htmlFor="icdcode">ICD Code - <span className={this.state.GetCodeProcess == "Calculate" ? "text-danger" : "text-success"}>{this.state.GetCodeProcess}</span></label>
                                    <div className="input-group mb-3">
                                        <input type="text" id="icdcode"
                                            name="icdcode"
                                            aria-describedby="basic-addon2"
                                            //onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            value={this.state.ICD10Code} className="form-control form-control-sm" disabled />
                                        <div className="input-group-append">
                                            {this.state.showProduct == false ?

                                                <button type="button" className="btn btn-success btn-xs" onClick={e => this.getICD10APICall(e)}>GET CODE</button>
                                                :
                                                <div></div>
                                                // <button type="button" className="btn btn-danger btn-xs" onClick={e => this.setState({ showProduct: false })}>Edit</button>
                                            }
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </fieldset>
                        {/* {
                            (this.state.showProduct == false) ?
                                <div>
                                </div>
                                : */}
                        <div className="d-flex justify-content-end py-3">
                            {this.state.showProduct == false ?
                                <div></div>
                                // <button type="button" className="btn btn-success btn-sm" onClick={e => this.getICD10APICall(e)}>GET CODE</button>
                                :

                                <button type="button"
                                    disabled={this.props.isReadOnly ? true : false}
                                    className="btn btn-danger btn-sm"
                                    onClick={e => this.setState({
                                        showProduct: false,
                                        ICD10Code: "", GetCodeProcess: "Calculate"
                                    })}>Edit Assessment</button>
                            }

                        </div>

                        {/* } */}
                    </div> :
                    <div className="d-flex justify-content-center">
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={50}
                            width={50}
                        />
                    </div>
            }
            </>
        );
    }

    renderProductSelectionForm() {
        return (
            <>
                <ProductSelection ref={(ref) => this.productSelection = ref}
                    primaryDressing=''
                    secondaryDressing=''
                    saleNote=''
                    // isWoundInfoChange={this.state.Resident.isWoundInfoChange}
                    frequencyId={8}
                    isReorder={this.props.isReorder}
                    isReadOnly={this.props.isReadOnly}
                    woundDetailId={this.props.resident.wound.woundDetailId}
                />



            </>

        )
    }

    render() {

        return (
            // <Card>
            // <Form
            //validated={this.state.formValidated}
            // >
            //<CardHeader title='Resident' />
            // <CardBody>
            <>
                <Form

                    validated={this.state.formValidated}
                >
                    {this.renderWoundForm()}

                    {
                        this.state.showProduct && this.props.displayProduct
                            ?
                            this.renderProductSelectionForm()
                            :
                            null
                    }

                    {

                        (this.state.showProduct || this.props.isEdit) && !this.props.isReadOnly ?

                            <div className="d-flex justify-content-end py-3">
                                <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Save</Button>
                            </div>
                            :
                            null
                    }
                </Form>
            </>
            // </CardBody>
            // </Form>



            // </Card>
        )
    }

    onFormSave() {

    }

}// end of class

/*eslint-enable */