// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react'
import {
Button, Table
} from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
const ViewWoundInformationChangeReason = (props) => {
    // ** States
    const [changeReasonsList, setChangeReasonsList] = useState([]);
    const [isLoading, setLoading] = useState(false);


 
    useEffect(() => {
        getWoundInfoChangeReasonDetails();
    }, []);
    const getWoundInfoChangeReasonDetails = async () => {
        debugger
        setLoading(true)
        var { data } = await Api.getRequestQry(Api.URI_GetWoundInfoChangeDetailByWoundId,
            "?residentId=" + props.residentId+ "&woundId=" + props.woundId )
        if (data.success) {
            setChangeReasonsList(data.result)
            setLoading(false)

        } else {
            setLoading(false)
        }

    }
    return (
        <Fragment>
            <Card>
                <CardHeader className='border-bottom py-1'>
                    <div className="action-icons">
                        
                    </div>
                </CardHeader>
                <CardBody className='pt-2'>
                    <Table responsive striped hover bordered size='sm' className='w-100'>
                        <thead>
                            <tr>
                                <th>Sr #</th>
                                <th>Reason</th>
                                <th>Notes</th>
                                <th>Created By</th>
                                {/* <th>Modified By</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {changeReasonsList?.map((ms, i) => {
                                return (
                                    <tr key={i.toString()}>
                                <td>{i+1}</td>
                                <td>{ms.changeReason}</td>
                                <td>{ms.description}</td>
                                <td>{ms.createdByName}</td>
                            
                            </tr>
                                 );
                                })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default ViewWoundInformationChangeReason
