// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react'
import {
Button, Table
} from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { NullLogger } from "@microsoft/signalr";
const SkipResidentMsg = (props) => {
    // ** States
    const [id, setID] = useState(0);
    const [msg, setMsg] = useState('');
    const [fkNoOrderStatusId, setFkNoOrderStatusId] = useState(0);

    const [otherStatus, setOtherStatus] = useState('');

    const [isLoading, setLoading] = useState(false);
    const [isNoOrder, setIsNoOrder] = useState(props.isNoOrder);

    const [msgList, setFacilityMsgList] = useState([])
    const [residentNoOrderStatus, setResidentNoOrderStatus] = useState([])


    
    useEffect(() => {
        formik.setValues({
            fkNoOrderStatusId,
            msg,
            isNoOrder
        });
    }, [fkNoOrderStatusId,msg,isNoOrder]);
    const Schema = Yup.object().shape({

        fkNoOrderStatusId: 
        Yup.number().when('isNoOrder',{
            is:true,
            then:
            Yup.number().required("Status is required").typeError("Status is required").min(1, "Status is required"),
        }),
        msg: Yup.string().when("fkNoOrderStatusId", {
            is: (fkNoOrderStatusId) => fkNoOrderStatusId == 1361 || fkNoOrderStatusId == 0,
            then: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("Msg is required")
        }),
    });
    const formik = useFormik({
        initialValues: {
            fkNoOrderStatus:0,
            msg, 
            isNoOrder
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            handleValidSubmit();
            setSubmitting(false);
        },
    });
    useEffect(() => {
        if (props.msg != null) {
            setMsg(props.msg.msg);
            formik.setValues((old) => {
                return {
                    ...old,
                    msg: props.msg.msg,
                }
            })
        } else if (props.msg == null) {
            setMsg('');
            formik.resetForm();
        }
    }, [props.msg]);
    //  Get All organization Type 
    useEffect(() => {
        getResidentStatus()
        getAllFacilityMsg();
    }, []);
    const getAllFacilityMsg = async () => {
        setLoading(true)
        var { data } = await Api.getRequestQry(Api.URI_GetSkipResidentMsgsByResidentId,
            "?residentId=" + props.fkResidentId +"&isProcessorNoOrder="+ props.isProcessorNoOrder)
        if (data.success) {
            setFacilityMsgList(data.result)
            setLoading(false)

        } else {
            setLoading(false)
        }

    }
    const getResidentStatus = () => {
        debugger
        var residentNoOrderStatusID=Common.LookupsFK.Fk_ResidentNoOrderStatus;
        if(props.isProcessorNoOrder){
            residentNoOrderStatusID=Common.LookupsFK.Fk_ProcessorResidentNoOrderStatus
        }
            Api.getRequestById(Api.Uri_GetLookupValuesByFkId, residentNoOrderStatusID)
                .then((res) => {
                    debugger
                    if (res.data.statusCode == 200) {
                        if (res.data.result != null) {
                            if (res.data.result.length > 0) {
                                setResidentNoOrderStatus(res.data.result);
                            }
                        }
                    }
                })
                .catch((ex) => {
    
                    Common.LogError(ex, "Resident", "getDropDownValueApiCall");
    
                });
        }
    const handleValidSubmit = () => {
        debugger
        Api.postRequest(Api.apiUrls.updateResidentNoOrderStatus, 
            {msg:msg,fkResidentId:props.fkResidentId,isNoOrder:props.isNoOrder,isProcessorNoOrder:props.isProcessorNoOrder
            ,fkNoOrderStatusId:fkNoOrderStatusId,otherStatus:otherStatus}).then((res) => {
            if (res.data.success) {
                // props.onSaveAPI();
                Common.showSuccessAlertAutoClose(
                    "Data Saved Successfully",
                    "success",
                    "Success!",
                    3000
                );
                setMsg('')
                props.onSave();
                getAllFacilityMsg();
            } else {
                Common.showSuccessAlert(
                    res.data.message,
                    "error",
                    "Error!",
                    3000
                );
            }
        });
    }
  const  setOptionsUI = (options) => {
        return options.map((option) => (
            <option key={option.key} value={option.key}>
                {option.value}
            </option>
        ));
    }
    return (
        <Fragment>
            {props.isNoOrder&&
                   <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                            No Order Status
                        </label>
                        <select
                        className="form-control form-control-sm"
                        id="fkNoOrderStatusId"
                        name="fkNoOrderStatusId"
                        value={Common.replaceNullWithString(
                          fkNoOrderStatusId
                        )}
                        onChange={e => setFkNoOrderStatusId(e.target.value)}
                      >
                        <option></option>
                            {setOptionsUI(residentNoOrderStatus)}
                        </select>
                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                                    {formik.errors.fkNoOrderStatusId}
                                </div>
                    </div>

                </div>
                {/* {fkNoOrderStatusId==1361&&
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                        <label className="form-label font-weight-bold" htmlFor="Length">Other Status</label>
                        <textarea id="msg"
                            rows="3"
                            className="form-control"
                            name="msg"
                            value={otherStatus}
                            onChange={e => setOtherStatus(e.target.value)}
                        />
                            <div style={{ fontSize: ".8rem" }} className="text-danger">
                                    {formik.errors.msg}
                                </div>

                    </div>
                </div>
} */}
            </div>
}
            <div className="row" >
                <div className="col-12">
                    <div className="form-group">
                        <label className="form-label font-weight-bold" htmlFor="Length">New Message</label>
                        <textarea id="msg"
                            rows="3"
                            className="form-control"
                            name="msg"
                            value={msg}
                            onChange={e => setMsg(e.target.value)}
                        />
                            <div style={{ fontSize: ".8rem" }} className="text-danger">
                                    {formik.errors.msg}
                                </div>

                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end py-3">
                <Button variant="success" className="mr-5 px-10" size="sm" onClick={formik.handleSubmit}>Save</Button>
            </div>
            <Card>
                <CardHeader className='border-bottom py-1'>
                    <div className="action-icons">
                        
                    </div>
                </CardHeader>
                <CardBody className='pt-2'>
                    <Table responsive striped hover bordered size='sm' className='w-100'>
                        <thead>
                            <tr>
                                <th>Sr #</th>
                                <th>Message</th>
                                <th>Created By</th>
                                {/* <th>Modified By</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {msgList?.map((ms, i) => {
                                return (
                                    <tr key={i.toString()}>
                                <td>{i+1}</td>
                                <td>{ms.fkNoOrderStatusId==1361 || ms.fkNoOrderStatusId==null? ms.msg:ms.residentStatus}</td>
                                <td>{ms.createdByName}</td>

                                {/* <td>{ms.modifiedByName}</td> */}
                            
                            </tr>
                                 );
                                })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default SkipResidentMsg
