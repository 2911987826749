// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react'
import {
Button, Table
} from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { NullLogger } from "@microsoft/signalr";
const SkipWoundMsg = (props) => {
    // ** States
  

    const [isLoading, setLoading] = useState(false);

    const [msgList, setWoundMsgList] = useState([])


    

   
    //  Get All organization Type 
    useEffect(() => {
        getAllWoundMsg();
    }, []);
    const getAllWoundMsg = async () => {
        setLoading(true)
        var { data } = await Api.getRequestQry(Api.URI_GetSkipWoundMsgsByResidentId,
            "?residentId=" + props.fkResidentId )
            debugger
        if (data.success) {
            setWoundMsgList(data.result)
            setLoading(false)

        } else {
            setLoading(false)
        }

    }
  
 
    return (
        <Fragment>
    
            <Card>
                <CardHeader className='border-bottom py-1'>
                    <div className="action-icons">
                        
                    </div>
                </CardHeader>
                <CardBody className='pt-2'>
                    <Table responsive striped hover bordered size='sm' className='w-100'>
                        <thead>
                            <tr>
                                <th>Sr #</th>
                                <th>Status</th>
                                <th>Resident Name</th>
                                {/* <th>Modified By</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {msgList?.map((ms, i) => {
                                return (
                                    <tr key={i.toString()}>
                                <td>{i+1}</td>
                                <td>{ ms.clinicianMsg =='' || ms.clinicianMsg == null? ms.processorMsg: ms.clinicianMsg }</td>
                                <td>{ms.residentName}</td>

                                {/* <td>{ms.modifiedByName}</td> */}
                            
                            </tr>
                                 );
                                })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default SkipWoundMsg
