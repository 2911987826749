/*eslint-disable */
import { FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "react-loader-spinner";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import ProductSelection from '../products/productselection';
import moment from 'moment';
import WoundBedDescriptionInput from './customComponents/WoundBedDescriptionInput';
import { event } from 'jquery';
export default class NewWoundAssessment extends React.Component {

    // eslint-disable-next-line no-use-before-define
    constructor(props) {
        super(props);
        this.state = {
            formValidated: false,
            IsLoading: false,
            isLocationChange: false,
            woundTypeSelect: [],
            allWoundTypeSelect: [],
            // allWoundTypeSelect: [

            //     {
            //         "id": 2,
            //         "type": "non-pressure",
            //         "name": "Arterial",
            //         "woundTypeQualifiers": [
            //             {
            //                 "id": 11,
            //                 "name": "Full Thickness"
            //             }
            //         ],
            //         "bodyLocations": null
            //     },

            //     {
            //         "id": 5,
            //         "type": "pressure",
            //         "name": "Pressure",
            //         "woundTypeQualifiers": [

            //             {
            //                 "id": 2,
            //                 "name": "Stage II"
            //             },
            //             {
            //                 "id": 3,
            //                 "name": "Stage III"
            //             },
            //             {
            //                 "id": 4,
            //                 "name": "Stage IV"
            //             },
            //             {
            //                 "id": 5,
            //                 "name": "Unstageable"
            //             },

            //         ],
            //         "bodyLocations": null
            //     },
            //     {
            //         "id": 6,
            //         "name": "Diabetic",
            //         "type": "non-pressure",
            //         "woundTypeQualifiers": [
            //             {
            //                 "id": 12,
            //                 "name": "Grade 0"
            //             },
            //             {
            //                 "id": 13,
            //                 "name": "Grade 1"
            //             },
            //             {
            //                 "id": 14,
            //                 "name": "Grade 2"
            //             },
            //             {
            //                 "id": 15,
            //                 "name": "Grade 3"
            //             },
            //             {
            //                 "id": 16,
            //                 "name": "Grade 4"
            //             },
            //             {
            //                 "id": 17,
            //                 "name": "Grade 5"
            //             },
            //             {
            //                 "id": 10,
            //                 "name": "Partial Thickness"
            //             },
            //             {
            //                 "id": 11,
            //                 "name": "Full Thickness"
            //             }
            //         ],
            //         "bodyLocations": null
            //     },
            //     {
            //         "id": 18,
            //         "name": "Fistula",
            //         "type": "non-pressure",
            //         "woundTypeQualifiers": [

            //             {
            //                 "id": 11,
            //                 "name": "Full Thickness"
            //             }
            //         ],
            //         "bodyLocations": null
            //     },

            //     {
            //         "id": 17,
            //         "name": "Sking Injury",
            //         "type": "non-pressure",
            //         "woundTypeQualifiers": [
            //             {
            //                 "id": 10,
            //                 "name": "Partial Thickness"
            //             },
            //             {
            //                 "id": 11,
            //                 "name": "Full Thickness"
            //             }
            //         ],
            //         "bodyLocations": null
            //     },


            //     {
            //         "id": 12,
            //         "name": "Surgical",
            //         "type": "surgical",
            //         "woundTypeQualifiers": [

            //             {
            //                 "id": 11,
            //                 "name": "Full Thickness"
            //             }
            //         ],
            //         "bodyLocations": null
            //     },
            //     {
            //         "id": 15,
            //         "name": "Tube Site",
            //         "type": "surgical",
            //         "woundTypeQualifiers": [

            //             {
            //                 "id": 11,
            //                 "name": "Full Thickness"
            //             }
            //         ],
            //         "bodyLocations": null
            //     },
            //     {
            //         "id": 13,
            //         "name": "Venous",
            //         "type": "non-pressure",
            //         "woundTypeQualifiers": [
            //             {
            //                 "id": 10,
            //                 "name": "Partial Thickness"
            //             },
            //             {
            //                 "id": 11,
            //                 "name": "Full Thickness"
            //             }
            //         ],
            //         "bodyLocations": null
            //     }
            // ],
            selectedWoundTypeSelect: [],
            pressureLocation: [],
            nonPressureLocation: [],
            nonPressureLocationSurgery: [],
            thicknessStage: [],
            nonThicknessStage: [],
            WoundSide: [],
            WoundSevereTissue: [],
            WoundAcquisition: [],
            WoundExudateAmount: [],
            WoundExudateType: [],
            WoundDebridementType: [],
            woundBodyRegionSelect: [],
            woundICDCodeSheet: [],
            WoundPrimaryDressing: [],
            WoundSecondaryDressing: [],
            WoundBedDescription: [],
            WoundExudateColor: [],

            WoundEdges: [],
            SurroundingSkin: [],
            SkinAndUlcerTreatment: [],
            WoundDressingChangeFrequency: [],
            WoundType: 'Pressure',
            WoundTypeSelected: '',
            IsThicknessStageTwo: false,
            IsPartialThickness: false,
            IsSloughRequired: false,
            IsNecroticRequired: false,
            ICD10Code: '',
            isReorderAndTissueTypeNotChange: props.isReorder ? true : false,
            ReoderAndIsDisableOrientation: false,
            Resident: JSON.parse(JSON.stringify(props.resident)),
            showProduct: false,
            orderNote: "",
            orientationLeft: null,
            orientationRight: null,

            orientationUpper: null,
            orientationLower: null,

            orientationInner: null,
            orientationOuter: null,

            orientationAnterior: null,
            orientationPosterior: null,

            orientationMedial: null,
            orientationLateral: null,

            orientationDorsal: null,
            orientationDistal: null,
            orientationProximal: null

        }
        this.productSelection = null;
    }
    componentDidMount() {

        this.getAllLookupsValues();
        this.getAllWoundType();
        // var woundType = this.props.resident.wound.fkWoundTypeId;
        // if (woundType > 0) {
        //     this.checkWoundType(woundType);
        // }
    }
    onWoundTypeChange = (e) => {
        debugger
        if (e.target.value == 30) {

        } else {
            Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, '', "otherWoundType")
        }
        Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
        this.checkWoundType(e.target.value);
    }

    onExudateTypeChange = (e) => {
        // // // debugger
        if (e.target.value == 1192) {

        } else {
            Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, '', "otherExudateType")
        }
        Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
    }
    onBedDescriptionTypeChange = (e) => {
        // // // debugger
        if (e.target.value == 1049) {

        } else {
            Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, '', "otherWoundBedDesc")
        }
        Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
    }

    onExudateColorChange = (e) => {
        // // // debugger
        if (e.target.value == 1393) {

        } else {
            Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, '', "exudateColor")
        }
        Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
    }
    onSurroundingSkinTypeChange = (e) => {
        // // // debugger
        if (e.target.value == 1056) {

        } else {
            Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, '', "otherSurroundingSkin")
        }
        Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
    }
    getAllLookupsValues = async () => {

        // var woundType = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundTypeID)
        // this.setState({ woundTypeSelect: woundType })
        var woundIssue = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundSevereTissueTypeID)
        this.setState({ WoundSevereTissue: woundIssue })
        // var PressureLocation = await Common.getLookupOptions(Common.LookupsFK.Fk_fkPressureLocationID)
        // this.setState({ pressureLocation: PressureLocation })
        // var NonPressureLocation = await Common.getLookupOptions(Common.LookupsFK.Fk_fkNonPressureLocationID)
        // this.setState({ nonPressureLocation: NonPressureLocation })
        var NonPressureLocationSurgery = await Common.getLookupOptions(Common.LookupsFK.Fk_fkNonPressureLocationSurgeryID)
        this.setState({ nonPressureLocationSurgery: NonPressureLocationSurgery })
        // var PressureThikness = await Common.getLookupOptions(Common.LookupsFK.Fk_fkPressureThiknessID)
        // this.setState({ thicknessStage: PressureThikness })
        var NonPressureThikness = await Common.getLookupOptions(Common.LookupsFK.Fk_fkNonPressureThiknessID)
        this.setState({ nonThicknessStage: NonPressureThikness })
        var WoundSide = await Common.getLookupOptions(Common.LookupsFK.Fk_fkWoundSideID)
        this.setState({ WoundSide: WoundSide })
        var WoundAcquisition = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundAcquisitionID)
        this.setState({ WoundAcquisition: WoundAcquisition })
        var WoundExudateAmount = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundExudateAmountID)
        this.setState({ WoundExudateAmount: WoundExudateAmount })
        var WoundExudateType = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundExudateTypeID)
        this.setState({ WoundExudateType: WoundExudateType })
        var WoundDebridementType = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundDebridementTypeID)
        this.setState({ WoundDebridementType: WoundDebridementType })

        var WoundPrimaryDressing = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundPrimaryDressingID)
        this.setState({ WoundPrimaryDressing: WoundPrimaryDressing })

        var WoundSecondaryDressing = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundSecondaryDressingID)
        this.setState({ WoundSecondaryDressing: WoundSecondaryDressing })

        var surroundingSkin = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundSurroundingSkin)
        this.setState({ SurroundingSkin: surroundingSkin })

        var woundBedDescription = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundBedDescription)
        this.setState({ WoundBedDescription: woundBedDescription })

        var woundExudateColor = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundExudateColor)
        this.setState({ WoundExudateColor: woundExudateColor })
        

        var WoundEdges = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundEdges)
        this.setState({ WoundEdges: WoundEdges })

        var skinAndUlcerTreatment = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundSkinAndUlcerTreatment)
        this.setState({ SkinAndUlcerTreatment: skinAndUlcerTreatment })

        // var WoundDressingChangeFrequency = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundDressingChangeFrequencyID)
        var WoundDressingChangeFrequency = await Common.getAllBTLookupOptions(Api.Uri_GetAllItemfrequencies)
        this.setState({ WoundDressingChangeFrequency: WoundDressingChangeFrequency });

        let optionTemplate = this.state.allWoundTypeSelect.map((v) => (
            <option key={v.id} value={v.id}>
                {v.name}
            </option>
        ));
        // this.setState({ selectedWoundTypeSelect: optionTemplate })


    }
    getAllWoundType = () => {
        Api.getRequest(Api.Uri_GetAllWoundType).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {


                    let wountTypes = res.data.result.map(v => (
                        <option key={v.id} value={v.id}>
                            {v.name}
                        </option>
                    ));
                    this.setState({ allWoundTypeSelect: res.data.result })
                    this.setState({ selectedWoundTypeSelect: wountTypes })
                }
            }
        }).catch((ex) => {
        });
        Api.getRequest(Api.Uri_GetAllICDDropDownList).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {

                    let optionTemplate = res.data.result.woundType.map((v) => (
                        <option key={v.key} value={v.key}>
                            {v.value}
                        </option>
                    ));
                    this.setState({ woundTypeSelect: optionTemplate })

                    let optionBodyRegion = res.data.result.bodyRegion.map(v => (
                        <option key={v.key} value={v.key}>
                            {v.value}
                        </option>
                    ));
                    this.setState({ woundBodyRegionSelect: optionBodyRegion })
                    this.setState({ woundICDCodeSheet: res.data.result.woundToICDList })

                    var woundType = this.props.resident.wound.fkWoundTypeId;
                    var bodyRegion = this.props.resident.wound.fkBodyRegionId;

                    if (woundType > 0) {
                        this.checkWoundType(woundType);
                        this.onBodyRegionChangeSetValues(bodyRegion);
                        if (this.state.Resident.wound.orientationLeftRight != null
                            || this.state.Resident.wound.orientationInnerOuter != null
                            || this.state.Resident.wound.orientationMedialLateral != null
                            || this.state.Resident.wound.orientationUpperLower != null
                            || this.state.Resident.wound.OrientationAnteriorPosterior != null
                            || this.state.Resident.wound.OrientationDorsalDistalProximal != null
                        ) {
                            this.setState({ ReoderAndIsDisableOrientation: true })
                        }

                    }
                    this.setState({ IsLoading: true })

                }
            }


        }).catch((ex) => {
        });
    }
    onThicknessStageChange = (e) => {
        var  valueToCompare= e.target.value;
        debugger
        if(valueToCompare==6){
            valueToCompare=1
        }
        if (this.state.Resident.wound.thicknessIdForBackStagging <= valueToCompare
            || this.state.Resident.wound.thicknessIdForBackStagging == null
            || (this.state.Resident.wound.thicknessIdForBackStagging == 5 && valueToCompare != 2
                )
        ) {

            Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)

            if (e.target.value == "2")//check Thickness stage two to disable slough
            {
                var ICDCodeObj = {};
                ICDCodeObj.sloughPercentage = 0.00;
                ICDCodeObj.necroticPercentage = 0.00;

                this.setState(
                    {
                        IsThicknessStageTwo: true,
                        ICDCode: ICDCodeObj
                    })

            }
            else if (e.target.value == "5") { this.setState({ IsSloughRequired: true, IsNecroticRequired: true }) }
            else {
                this.setState({ IsThicknessStageTwo: false })
            }
        } else {
            Common.showSuccessAlert("back stagging is not allow", "warning", "Warnng!", 3000);
        }
    }
    onNonThicknessChange = (e) => {
        if (this.state.Resident.wound.thicknessIdForBackStagging <= e.target.value || this.state.Resident.wound.thicknessIdForBackStagging == null) {
            Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)

            if (e.target.value == '1246' || e.target.value == '1247') {
                this.setState({ IsNecroticRequired: true })
            } else {
                this.setState({ IsNecroticRequired: false })

            }
        } else {
            Common.showSuccessAlert("back stagging is not allow", "warning", "Warnng!", 3000);
        }
        if (e.target.value == "1244") {
            var resident = this.state.Resident;
            resident.wound.fkDebridementTypeId = '1027';
            let newDate = "2021-02-12T00:00:00";//Common.getCurrentDate('-');

            resident.wound.debridementDate = newDate;
            this.setState(
                {
                    IsPartialThickness: true,
                    Resident: resident

                })
        } else {
            this.setState(
                {
                    IsPartialThickness: false,
                })
        }
    }
    selectWoundType = (selectedValue) => {

        let type = selectedValue.toLowerCase();

        let _woundType = [];
        let BodyRegionOption = [];
        let bodyRegion = this.state.woundBodyRegionSelect;
        switch (type) {
            case "pressure":
                // _woundType = this.state.allWoundTypeSelect.filter(i => i.type == 'pressure');
            
                Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, null, "fkWoundSevereTissueTypeId");
            
                this.onTissueValueChangeSetDropdownValues(null, 'Pressure');
                this.setState({ WoundTypeSelected: 'Pressure' })


                break;
             case "other":
                    // _woundType = this.state.allWoundTypeSelect.filter(i => i.type == '');
                
                    Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, null, "fkWoundSevereTissueTypeId");
                
                    this.onTissueValueChangeSetDropdownValues(null, 'other');
                    this.setState({ WoundTypeSelected: 'other' })
    
                    //this.onWoundTypeChangeSetDropdownValues(5);
    
                    break;
            case "nonpressure":
                // _woundType = this.state.allWoundTypeSelect.filter(i => i.type == 'non-pressure');
                BodyRegionOption = bodyRegion.map(v => (
                    <option key={v.key} value={v.key}>
                        {v.value}
                    </option>
                ));
                // Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 2, "fkWoundTypeId")

                // this.resetICDDropdowns();
                this.onTissueValueChangeSetDropdownValues(this.state.Resident.wound.fkWoundSevereTissueTypeId, 'NonPressure');
                this.setState({ filterdWoundBodyRegionSelect: BodyRegionOption, WoundTypeSelected: 'NonPressure' })
                break;
            case "surgical":
                // _woundType = this.state.allWoundTypeSelect.filter(i => i.type == 'surgical');
                // Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 12, "fkWoundTypeId");
                Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, null, "fkWoundSevereTissueTypeId");

                this.onTissueValueChangeSetDropdownValues(null, 'Surgical');
                this.setState({ WoundTypeSelected: 'Surgical' });
                // this.onWoundTypeChangeSetDropdownValues(12);

                break;
        }
        // let optionTemplate = _woundType.map((v) => (
        //     <option key={v.id} value={v.id}>
        //         {v.name}
        //     </option>
        // ));
        // debugger
        // this.setState({ selectedWoundTypeSelect: optionTemplate })

        this.setState({
            selectedOption: selectedValue
        });


    }
    onTissueValueChange = (event) => {
        // debugger;
        if (this.state.Resident.wound.thicknessIdForBackStagging == 11 && event.target.value == 1214 && this.props.isReorder) {
            Common.showSuccessAlert("back stagging is not allow", "warning", "Warnng!", 3000);
            return false;

        }
        Common.updateInputValueInState(this.state.Resident.wound, event, this, this.state)
        // this.setState({  IsThicknessStageTwo: false, IsPartialThickness: false })
        this.setState({
            IsThicknessStageTwo: false, IsPartialThickness: false
        });

        this.onTissueValueChangeSetDropdownValues(event.target.value, this.state.WoundTypeSelected);
    }
    onTissueValueChangeSetDropdownValues = (tissueTypeId, woundType) => {

        //  var woundTypeId=this.state.Resident.wound.fkWoundTypeId;

        if (woundType === 'NonPressure') {
            if (tissueTypeId == '1214') {
                //

                // this.onThicknessChangeSetDropdownValues(10);

            } else {
                // Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 11, "fkWoundThicknessId")
                // this.onThicknessChangeSetDropdownValues(11);
            }
            var optionTemplate = [];
            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                tissueTypeId == menu.fkWoundSevereTissueTypeId).map(v => (
                    <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
                        {v.woundThicknessName}
                    </option>
                ));
            const uniqueTags = [];
            optionTemplate.map(item => {
                if (item.key != "null") {

                    var findItem = uniqueTags.find(x => x.key === item.key);
                    if (!findItem)
                        uniqueTags.push(item);

                }
            });

            this.setState({ thicknessStage: uniqueTags })

            //Body Region
            var bodyRegionOptionTemplate = [];
            bodyRegionOptionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                tissueTypeId == menu.fkWoundSevereTissueTypeId).map(v => (
                    <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
                        {v.woundBodyRegionName}
                    </option>
                ));

            const bodyRegionUniqueTags = [];
            bodyRegionOptionTemplate.map(item => {
                var findItem = bodyRegionUniqueTags.find(x => x.key === item.key);
                if (item.key != "null") {
                    if (!findItem)
                        bodyRegionUniqueTags.push(item);
                }
            });
            this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags })

            // if (uniqueTags.length > 0) {

            // }
        }
        else if (woundType === 'Surgical') {
            var woundTypeId = this.state.Resident.wound.fkWoundTypeId;
            var optionTemplate = [];
            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                woundTypeId == menu.fkWoundTypeId).map(v => (
                    <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
                        {v.woundThicknessName}
                    </option>
                ));
            const uniqueTags = [];
            optionTemplate.map(item => {
                var findItem = uniqueTags.find(x => x.key === item.key);
                if (item.key != "null") {
                    if (!findItem)
                        uniqueTags.push(item);
                }
            });

            this.setState({ thicknessStage: uniqueTags })

            //Body Region
            var bodyRegionOptionTemplate = [];
            bodyRegionOptionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                woundTypeId == menu.fkWoundTypeId).map(v => (
                    <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
                        {v.woundBodyRegionName}
                    </option>
                ));

            const bodyRegionUniqueTags = [];
            bodyRegionOptionTemplate.map(item => {
                var findItem = bodyRegionUniqueTags.find(x => x.key === item.key);
                if (item.key != "null") {
                    if (!findItem)
                        bodyRegionUniqueTags.push(item);
                }
            });
            this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags })


        }
        else {
            var woundTypeId = this.state.Resident.wound.fkWoundTypeId;
            var optionTemplate = [];
            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                woundTypeId == menu.fkWoundTypeId).map(v => (
                    <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
                        {v.fkThicknessStageId==2? "Stage 2":
                        v.fkThicknessStageId==3? "Stage 3":
                        v.fkThicknessStageId==4? "Stage 4":
                        v.woundThicknessName}
                    </option>
                ));
            const uniqueTags = [];
            debugger
            optionTemplate.map(item => {
                var findItem = uniqueTags.find(x => x.key === item.key);
                if (item.key != "null" && item.key != 10 && item.key != 11) {
                    if (!findItem)
                        uniqueTags.push(item);
                }
            });

            this.setState({ thicknessStage: uniqueTags })

            //Body Region
            var bodyRegionOptionTemplate = [];
            bodyRegionOptionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                woundTypeId == menu.fkWoundTypeId).map(v => (
                    <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
                        {v.woundBodyRegionName}
                    </option>
                ));

            const bodyRegionUniqueTags = [];
            bodyRegionOptionTemplate.map(item => {
                var findItem = bodyRegionUniqueTags.find(x => x.key === item.key);
                if (item.key != "null") {
                    if (!findItem)
                        bodyRegionUniqueTags.push(item);
                }
            });
            this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags })


        }


        //End
    }
    onBodyRegionChange = (e) => {


        Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
        this.onBodyRegionChangeSetValues(e.target.value);

        this.showHideOrientation(null, null, null, null, null, null, null, null, null, null, null, null, null);

    }
    onBodyRegionChangeSetValues = (bodyRegion) => {
        debugger
        var optionTemplate = [];
        if (this.state.WoundTypeSelected == 'NonPressure') {
            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                bodyRegion == menu.fkBodyRegionId && menu.fkThicknessStageId == this.state.Resident.wound.fkWoundThicknessId).map(v => (
                    <option key={v.fkWoundLocationId} value={v.fkWoundLocationId}>
                        {v.woundLocationName}
                    </option>
                ));
        } else {
            var woundTypeId = 0;
            var woundThicknessStage = this.state.Resident.wound.fkWoundThicknessId;
            if(this.state.Resident.wound.fkWoundThicknessId==1 || this.state.Resident.wound.fkWoundThicknessId==6){
                woundThicknessStage=2;
            }
            if (this.state.WoundTypeSelected == 'Pressure') {
                woundTypeId = Common.WoundType.pressure;
            }
            else {
                woundTypeId = this.state.Resident.wound.fkWoundTypeId;
            }

            optionTemplate = this.state.woundICDCodeSheet.filter((menu) =>
                bodyRegion == menu.fkBodyRegionId
                && menu.fkThicknessStageId == woundThicknessStage
                && menu.fkWoundTypeId == woundTypeId).map(v => (
                    <option key={v.fkWoundLocationId} value={v.fkWoundLocationId}>
                        {v.woundLocationName}
                    </option>
                ));
        }
        const uniqueTags = [];
        optionTemplate.map(item => {
            var findItem = uniqueTags.find(x => x.key === item.key);
            if (!findItem)
                uniqueTags.push(item);
        });
        this.setState({ pressureLocation: uniqueTags })
    }
    onWoundLocationChange = (e) => {
        Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state);
        if (this.props.isReorder) {
            this.setOrientationValues(e.target.value, false);
            this.setState({ ReoderAndIsDisableOrientation: false })

        } else {
            this.setOrientationValues(e.target.value, true);

        }
    }
    setOrientationValues = (woundLocation, setOrientation) => {
        debugger
        var icdCodes = this.state.woundICDCodeSheet.filter(x => x.fkWoundSevereTissueTypeId == this.state.Resident.wound.fkWoundSevereTissueTypeId
            && x.fkThicknessStageId == this.state.Resident.wound.fkWoundThicknessId &&
            x.fkBodyRegionId == this.state.Resident.wound.fkBodyRegionId
            && x.fkWoundLocationId == woundLocation)
        this.showHideOrientation(null, null, null, null, null, null, null, null, null, null, null, null, null);

        if (icdCodes) {

            for (var i = 0; i < icdCodes.length; i++) {
                let item = JSON.parse(JSON.stringify(icdCodes[i]));

                // item.orientationLeftRight, item.orientationUpperLower,
                // item.orientationInnerOuter, item.orientationAnteriorPosterior,

                if (item.orientationLeftRight == Common.WoundOrientation.right) {
                    this.setState({ orientationRight: item.orientationLeftRight })
                } else if (item.orientationLeftRight == Common.WoundOrientation.left) {
                    this.setState({ orientationLeft: item.orientationLeftRight })
                }
                if (item.orientationUpperLower == Common.WoundOrientation.upper) {
                    this.setState({ orientationUpper: item.orientationUpperLower })
                } else if (item.orientationUpperLower == Common.WoundOrientation.lower) {
                    this.setState({ orientationLower: item.orientationUpperLower })

                } if (item.orientationInnerOuter == Common.WoundOrientation.inner) {
                    this.setState({ orientationInner: item.orientationInnerOuter })
                }
                else if (item.orientationInnerOuter == Common.WoundOrientation.outer) {
                    this.setState({ orientationOuter: item.orientationInnerOuter })
                }
                if (item.orientationAnteriorPosterior == Common.WoundOrientation.anterior) {
                    this.setState({ orientationAnterior: item.orientationAnteriorPosterior })
                }
                else if (item.orientationAnteriorPosterior == Common.WoundOrientation.posterior) {
                    this.setState({ orientationPosterior: item.orientationAnteriorPosterior })
                }
                if (item.orientationMedialLateral == Common.WoundOrientation.medial) {
                    this.setState({ orientationMedial: item.orientationMedialLateral })
                }
                else if (item.orientationMedialLateral == Common.WoundOrientation.lateral) {
                    this.setState({ orientationLateral: item.orientationMedialLateral })
                }
                if (item.orientationDorsalDistalProximal == Common.WoundOrientation.dorsal) {
                    this.setState({ orientationDorsal: item.orientationDorsalDistalProximal })
                }
                else if (item.orientationDorsalDistalProximal == Common.WoundOrientation.distal) {
                    this.setState({ orientationDistal: item.orientationDorsalDistalProximal })
                }
                else if (item.orientationDorsalDistalProximal == Common.WoundOrientation.proximal) {
                    this.setState({ orientationProximal: item.orientationDorsalDistalProximal })
                }

            }
        }
        // else {
        //     this.showHideOrientation(null, null, null, null, null, null,null, null, null, null, null, null,null);

        // }
        if (setOrientation) {
            this.resetOrientation(this.state.Resident);

        }
    }
    // Render Methods
    resetOrientation(residentObj) {

        // var residentObj = this.state.Resident;
        residentObj.wound.orientationLeftRight = null;
        residentObj.wound.orientationAnteriorPosterior = null;
        residentObj.wound.orientationDorsalDistalProximal = null;
        residentObj.wound.orientationInnerOuter = null;
        residentObj.wound.orientationMedialLateral = null;
        residentObj.wound.orientationUpperLower = null;
        residentObj.wound.orientationUpperLower = null;

        this.setState({ Resident: residentObj });
    }
    showHideOrientation = (orientationLeft, orientationRight, orientationUpper, orientationLower,
        orientationInner, orientationOuter, orientationAnterior, orientationPosterior,
        orientationMedial, orientationLateral, orientationDorsal, orientationDistal, orientationProximal) => {
        this.setState({
            orientationLeft: orientationLeft,
            orientationRight: orientationRight,
            orientationUpper: orientationUpper,
            orientationLower: orientationLower,

            orientationInner: orientationInner,
            orientationOuter: orientationOuter,

            orientationAnterior: orientationAnterior,
            orientationPosterior: orientationPosterior,

            orientationMedial: orientationMedial,
            orientationLateral: orientationLateral,

            orientationDorsal: orientationDorsal,
            orientationDistal: orientationDistal,
            orientationProximal: orientationProximal

        })
    }

    checkWoundType(value) {
        debugger
        if (value == '5' || value == '23' || value == '24' || value == '25') {
            // this.setState({ WoundType: 'Pressure' })

            this.selectWoundType('pressure');
        } else if (value == '12' || value == '15') {

            this.selectWoundType('surgical');

        }else if(value == '30'){
            this.selectWoundType('other');
        }
        else {

            this.selectWoundType('nonpressure');


        }
    }

    handleSubmit = (event) => {



        //1243 is unstageable check

        const form = event.currentTarget.form;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {
            let obj = this.state.Resident;
            if (obj.wound.fkWoundThicknessId == '1243') {
                if ((obj.wound.sloughPercentage == '' || obj.wound.sloughPercentage == 0) && (obj.wound.necroticPercentage == '' || obj.wound.necroticPercentage == 0)) {
                    Common.showSuccessAlert("In Case of unstageable slough or Necrotic is required", "warning", "Warning!", 3000);
                } else {
                    this.URI_UpsertResidentWound();
                    this.props.close();
                }

            } else {
                this.URI_UpsertResidentWound();
            }

        }
    }

    URI_UpsertResidentWound() {
        let obj = this.state.Resident;

        if(obj.wound.fkDebridementTypeId==''){
            obj.wound.fkDebridementTypeId=null
        }
        if(obj.wound.debridementDate==''){
            obj.wound.debridementDate=null
        }
        this.setState({ IsLoading: false })
        Api.postRequest(Api.URI_UpsertResidentWound, obj).then((res) => {

            if (res.data.statusCode == 200) {
debugger
                if (res.data.result != null) {
                    if (this.props.isEdit) {
                        Common.showSuccessAlert("Wound assessment updated successfully.", "success", "Success!", 3000);
                        this.props.close();
                    }
                    else {

                        this.setProductSelectionDetailToSend(res.data.result,obj);
                    }

                }
            }
            this.setState({ IsLoading: true })
        }).catch((ex) => {
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
            this.setState({ IsLoading: true })
        });
    }



    setProductSelectionDetailToSend(result,woundDetail) {
        let selectedProducts =null;
        if(this.productSelection!=null){
            selectedProducts = this.productSelection.getSelectedProductList();
        }
        debugger
        // if (selectedProducts.products != null) {
        //     if (selectedProducts.products.length > 0) {
                let proudctObjToSend = {
                    "saleOrderId":selectedProducts!=null? selectedProducts.salesOrder.saleOrderId:null,
                    // "createdBy": this.props.user.userID,
                    // "modifiedBy": this.props.user.userID,
                    "fkResidentWoundId": result,
                    "FkDressingChangeFrequencyId":selectedProducts!=null?  selectedProducts.salesOrder.fkDressingChangeFrequencyId:null,
                    "OtherAdditionalSecondaryDressing":selectedProducts!=null?  selectedProducts.salesOrder.otherAdditionalSecondaryDressing:null,
                    "OtherAdditionalPrimaryDressing":selectedProducts!=null?  selectedProducts.salesOrder.otherAdditionalPrimaryDressing:null,
                    "OrderNote":selectedProducts!=null?  selectedProducts.salesOrder.orderNote:null,
                    "saleOrderViewModels": [],
                    "otherPrimaryProducts":selectedProducts!=null?  selectedProducts.otherPrimaryProducts:null,
                    "otherSecodaryProducts":selectedProducts!=null?  selectedProducts.selectedSecondaryProducts:null,
                    "otherFillerProducts":selectedProducts!=null?  selectedProducts.selectedOtherFillerProducts:null,
                    "otherTraditionalProducts":selectedProducts!=null?  selectedProducts.selectedOtherTraditionalProducts:null,
                    "isReorder": this.props.isReorder,
                    "isAssessmentRequired":true,
                    "isNoOrder":woundDetail.wound.fkWoundThicknessId==1 ||  woundDetail.wound.fkWoundThicknessId==6?true:false,
                };
                 if(selectedProducts!=null){
                selectedProducts.products.forEach((value) => {
                    let obj = {
                        "fkProductId": value.id,
                        "saleOrderProductId": value.saleOrderProductId,
                        "quantity": value.quantity,
                        "remainingQuantity": value.remainingQuantity,
                        "productLevel":value.productLevel,

                        // "createdBy": this.props.user.userID,
                        // "modifiedBy": this.props.user.userID,
                    }
                    proudctObjToSend.saleOrderViewModels.push(obj);

                });
            }
                this.UpsertSaleOrderApiCall(proudctObjToSend);
        //     }
        // }
    }//
    UpsertSaleOrderApiCall(productObject) {
        let obj = productObject;
        Api.postRequest(Api.URI_UpsertSaleOrder, obj).then((res) => {

            if (res.data.success) {
                if (res.data.result != null) {
                    Common.showSuccessAlertAutoClose("Sales Order Created Successfully", "success", "Success!", 3000);
                    this.props.close();
                }
                else {
                    Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {

            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }

    // Render Methods
    handleUtdChange=(event)=>{
        if(event.target.checked){
            Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, '0.00', "woundDepth")
        }
        Common.updateInputValueInState(this.state.Resident.wound, event, this, this.state)
    }
    handleCheckboxChange = (e, checkboxId) => {
        const isChecked = e.target.checked;
        const percentage = this.state.Resident?.wound?.woundBedDescriptions?.[`${checkboxId}_percentage`] || '';
    
        this.setState((prevState) => {
            const newState = { ...prevState };
    
            newState.Resident.wound.woundBedDescriptions = {
                ...newState.Resident.wound.woundBedDescriptions,
            };
    
            if (isChecked) {
                newState.Resident.wound.woundBedDescriptions[checkboxId] = {
                    Fk_WoundBedDescriptionID: checkboxId,
                    Percentage: percentage,
                };
            } else {
                delete newState.Resident.wound.woundBedDescriptions[checkboxId];
            }
    
            return newState;
        });
    };
    handleOtherPercentage=(e)=>{
        debugger
        const inputValue = e.target.value;
        const Id = e.target.id;
        
        this.setState((prevState) => {
            const newState = { ...prevState };
            newState.Resident.wound[Id]=inputValue;
            return newState;
        });
    }
    handlePercentageChange = (e) => {
        debugger;
    
        const inputValue = e.target.value;
        const percentage = inputValue !== '' ? parseInt(inputValue) : 0; // Convert to integer if not empty
        const Id = e.target.id;
        
        const { wound } = this.state.Resident;
        const otherInputs = ['granulationPerc', 'hyperGranulationPerc', 'sloughPerc', 'necroticPerc', 'epithelialPerc', 'musclePerc', 'otherPerc'];
    
        this.setState((prevState) => {
            const newState = { ...prevState };
    
            if (percentage >= 0 && percentage <= 100) {
                newState.Resident.wound[Id] = percentage;
               
                // Check if at least one input has a value
                const atLeastOneInput = otherInputs.some((inputId) => {
                    const inputPercentage = newState.Resident.wound[inputId] || 0;
                    return inputPercentage > 0;
                });
    
                // Check if combined input is greater than 100
                const combinedPercentage = otherInputs.reduce((sum, inputId) => {
                    return sum + (newState.Resident.wound[inputId] || 0);
                }, 0);
    
                if (atLeastOneInput && combinedPercentage <= 100) {
                    // Update state if validations pass
                    newState.hasValue = true;
                    this.setState({ errorMessage: '' }); // Clear any previous error
                    
                    return newState;
                } else {
                    // Show error message or handle validation failure
                    newState.hasValue = false;
                    this.setState({ errorMessage: 'Combined percentage should not exceed 100' });
                    newState.Resident.wound[Id] = 0;
                    return newState;
                    console.error('Validation failed.');
                }
            } else {
                // Show error message or handle validation failure
                newState.hasValue = false;
                this.setState({ errorMessage: 'Percentage should be between 0 and 100.' });
                console.error('Percentage should be between 0 and 100.');
            }
    
            // Return the original state if validations fail
            return prevState;
        });
    };
    renderWoundForm() {
        return (
            <>
                {
                    (this.state.IsLoading == true) ?
                        <div>
                            {
                                !this.props.canView ?
                                    <div className="d-flex justify-content-end py-3">
                                        <Button type="button" variant="dark" size="sm" onClick={() => this.props.close()}>View History</Button>
                                    </div>
                                    :
                                    null
                            }
                            <fieldset disabled={!this.props.canView ? true : false}>
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-lg-8 col-xl-8">
                                        <div className="form-group">
                                            <label className="form-label"
                                                htmlFor="ResidentName">Resident Name</label>
                                            <input type="text" id="ResidentName"
                                                name="residentName"
                                                disabled
                                                onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.residentName)}
                                                className="form-control form-control-sm" />
                                            <div className="invalid-feedback">Resident Name Is Required</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label className="form-label"
                                                htmlFor="EvaluationDate">Evaluation Date</label>
                                            <input type="date" id="EvaluationDate"
                                                name="evaluationDate"
                                                required
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                // value={this.state.Resident.wound.evaluationDate}
                                                defaultValue={Common.getInputStringDate(new Date(this.state.Resident.wound.evaluationDate))}
                                                className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                        <div className="form-group">
                                            <label className="form-label"

                                                htmlFor="FacilityName">Facility Name</label>
                                            <input type="text" id="FacilityName"
                                                name="facilityName"
                                                disabled
                                                onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.facilityName)}
                                                className="form-control form-control-sm" />
                                            <div className="invalid-feedback">Facility Name Is Required</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="FirstName">Room / Unit</label>
                                            <input type="text" id="RoomUnit"
                                                name="roomUnit"
                                                disabled
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.wound.roomUnit)}
                                                onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                // defaultValue={Common.replaceNullWithString(this.state.Resident.roomUnit)}

                                                className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="PhysicanName">Physican</label>
                                            <input type="text" id="PhysicanName"
                                                disabled
                                                name="physicanName"
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.physicanName)}
                                                onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                className="form-control form-control-sm" />
                                            <div className="invalid-feedback">Physican Name</div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="EvaluatorName">Evaluator Name</label>
                                            <input type="text" id="evaluatedBy"
                                                name="evaluatedBy"
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.evaluatedBy)}
                                                disabled
                                                onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* {this.state.WoundTypeSelected != 'Pressure' &&
<> */}
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="woundType">Wound Type</label>
                                            <select className="form-control form-control-sm"
                                                id="woundType"
                                                name="fkWoundTypeId"
                                                disabled={this.props.isReorder == true ? true : false}
                                                required
                                                onChange={e => this.onWoundTypeChange(e)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundTypeId)}
                                            >
                                                <option></option>
                                                {/* {this.state.selectedWoundTypeSelect} */}
                                                {this.state.selectedWoundTypeSelect}


                                            </select>
                                        </div>
                                        {this.state.Resident.wound.fkWoundTypeId == 30 &&
                                        <>
                                            {/* <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2"> */}
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Length">Other Wound Type</label>
                                                    <input type="text" id="otherWoundType"
                                                        name="otherWoundType"
                                                        required
                                                        onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.otherWoundType)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                            {/* </div> */}

                                        </>
                                    }
                                    </div>


                                    {/* </>
} */}
                                    {this.state.WoundTypeSelected == 'NonPressure' &&

                                        <>
                                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="IssueType">Most Severe Tissue Type </label>
                                                    <select className="form-control form-control-sm"
                                                        id="IssueType"
                                                        name="fkWoundSevereTissueTypeId"
                                                        required
                                                        onChange={this.onTissueValueChange}
                                                        // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundSevereTissueTypeId)}
                                                    >
                                                        <option ></option>
                                                        {this.state.WoundSevereTissue}
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="thicknessStage">Thickness/Stage</label>
                                            <select className="form-control form-control-sm" id="fkWoundThicknessId"
                                                name="fkWoundThicknessId"
                                                onChange={e => this.onThicknessStageChange(e)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundThicknessId)}
                                            >
                                                <option></option>
                                                {this.state.WoundTypeSelected == 'Pressure' &&
                                                <option value="1">Stage 1</option>
                                }
                                                {this.state.thicknessStage}
                                                {this.state.WoundTypeSelected == 'Pressure' &&
                                                 <option value="6">DTI</option>
                                }
                                               

                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label font-weight-bold" htmlFor="Country">Body Region</label>
                                            <select className="form-control form-control-sm"
                                                name="fkBodyRegionId"
                                                required
                                                onChange={e => this.onBodyRegionChange(e)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkBodyRegionId)}
                                                id="fkBodyRegionId"
                                                disabled={this.state.isReorderAndTissueTypeNotChange}
                                            >
                                                {/* {this.state.filterdWoundBodyRegionSelect} */}
                                                <option></option>
                                                {this.state.woundBodyRegionSelect}
                                                <option value="10011">Other</option>
                                            </select>
                                        </div>
                                        {this.state.Resident.wound.fkWoundLocationId == 10011 &&
                                        <>
                                            {/* <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"> */}
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="EvaluatorName">Other Body Region</label>
                                                    <input type="text" id="otherBodyRegion"
                                                        name="otherBodyRegion"
                                                        required
                                                        onChange={e => { Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state), this.setState({ isLocationChange: true }) }}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.otherBodyRegion)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                            {/* </div> */}
                                        </>
                                    }
                                    </div>
       
                                    {/* {this.state.WoundType == 'Pressure' &&
                                    <> */}
                                    <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                        <div className="form-group">
                                            <label className="form-label font-weight-bold" htmlFor="heel">Location</label>
                                            <select className="form-control form-control-sm" id="fk_PressureLocationID"
                                                name='fkWoundLocationId'
                                                required
                                                // disabled={this.state.isReorderAndTissueTypeNotChange}
                                                // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                onChange={e => this.onWoundLocationChange(e)}

                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundLocationId)}

                                            >
                                                <option></option>
                                                {this.state.pressureLocation}
                                                <option value="10012">Other</option>
                                            </select>
                                        </div>
                                        {this.state.Resident.wound.fkWoundLocationId == 10012 &&
                                        <>
                                            {/* <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"> */}
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="EvaluatorName">Other location</label>
                                                    <input type="text" id="woundlocation"
                                                        name="woundLocation"
                                                        required
                                                        onChange={e => { Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state), this.setState({ isLocationChange: true }) }}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.woundLocation)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                            {/* </div> */}
                                        </>
                                    }
                                                        {this.state.isLocationChange && !this.props.canView &&
                                        <>
                                            {/* <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2"> */}
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="EvaluatorName">Change location Reason</label>
                                                    <input type="text" id="woundlocation"
                                                        // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state),this.setState({isLocationChange:true})}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.woundLocation)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                            {/* </div> */}

                                        </>
                                    }
                                    </div>
                                 
                
                        
                                    <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Status">Acquisition</label>
                                            <select className="form-control form-control-sm"
                                                id="Acquisition"
                                                name="fkAcquisitionId"
                                                disabled={this.state.Resident.wound.id>0 &&this.state.Resident.wound.fkAcquisitionId }
                                                required
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkAcquisitionId)}
                                            >
                                                <option></option>
                                                {this.state.WoundAcquisition}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <label className="form-label font-weight-bold">Orientation</label>
                                        <div className="form-group">



                                            {/* {this.state.orientationLeftRight != null &&
                                            <> */}
                                            <div className="row">
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">

                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationLeftRight" id="inlineRadio1"

                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.right}
                                                            required={this.state.orientationLeft == Common.WoundOrientation.right
                                                                || this.state.orientationRight == Common.WoundOrientation.right && true}
                                                            checked={this.state.Resident.wound.orientationLeftRight == Common.WoundOrientation.right ? true : false}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                        />
                                                        <label className={this.state.orientationRight == Common.WoundOrientation.right ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="inlineRadio1"><small>Right</small>{this.state.orientationRight == Common.WoundOrientation.right && "*"}</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">

                                                    <div className="form-check form-check-inline ml-5">
                                                        <input className="form-check-input" type="radio" name="orientationLeftRight" id="inlineRadio2"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            checked={this.state.Resident.wound.orientationLeftRight == Common.WoundOrientation.left ? true : false}
                                                            value={Common.WoundOrientation.left}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                        />
                                                        <label className={this.state.orientationLeft == Common.WoundOrientation.left ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="inlineRadio2"><small>Left</small>{this.state.orientationLeft == Common.WoundOrientation.left && "*"}</label>
                                                    </div>
                                                </div>
                                            </div>



                                            {/* </>
                                        }
                                        {this.state.orientationUpperLower != null &&
                                            <> */}
                                            <div className="row">
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationUpperLower" id="Upper"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            checked={this.state.Resident.wound.orientationUpperLower == Common.WoundOrientation.upper ? true : false}
                                                            required={this.state.orientationUpper == Common.WoundOrientation.upper
                                                                || this.state.orientationLower == Common.WoundOrientation.lower && true}
                                                            value={Common.WoundOrientation.upper}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                        />
                                                        <label className={this.state.orientationUpper == Common.WoundOrientation.upper ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Upper"><small>Upper</small>{this.state.orientationUpper == Common.WoundOrientation.upper && "*"}</label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline ml-5">
                                                        <input className="form-check-input" type="radio" name="orientationUpperLower" id="Lower"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}

                                                            checked={this.state.Resident.wound.orientationUpperLower == Common.WoundOrientation.lower ? true : false}
                                                            value={Common.WoundOrientation.lower}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                        />
                                                        <label className={this.state.orientationUpperLower == Common.WoundOrientation.lower ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Lower"><small>Lower</small>{this.state.orientationLower == Common.WoundOrientation.lower && "*"}</label>
                                                    </div>
                                                </div>
                                            </div>



                                            {/* </>
                                        }
                                        {this.state.orientationInnerOuter != null &&
                                            <> */}
                                            <div className="row">
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationInnerOuter" id="Inner"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.inner}
                                                            checked={this.state.Resident.wound.orientationInnerOuter == Common.WoundOrientation.inner ? true : false}
                                                            required={this.state.orientationInner == Common.WoundOrientation.inner ||
                                                                this.state.orientationOuter == Common.WoundOrientation.outer && true}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                        />
                                                        <label className={this.state.orientationInner == Common.WoundOrientation.inner ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Inner"><small>Inner</small>{this.state.orientationInner == Common.WoundOrientation.inner && "*"}</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline ml-5">
                                                        <input className="form-check-input" type="radio" name="orientationInnerOuter" id="Outer"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.outer}
                                                            checked={this.state.Resident.wound.orientationInnerOuter == Common.WoundOrientation.outer ? true : false}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                        />
                                                        <label className={this.state.orientationOuter == Common.WoundOrientation.outer ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Outer"><small>Outer</small>{this.state.orientationOuter == Common.WoundOrientation.outer && "*"}</label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* </>
                                        }
                                        {this.state.orientationAnteriorPosterior != null &&
                                            <> */}
                                            <div className="row">
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationAnteriorPosterior" id="Anterior"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.anterior}
                                                            required={this.state.orientationAnterior == Common.WoundOrientation.anterior
                                                                || this.state.orientationPosterior == Common.WoundOrientation.posterior && true}
                                                            checked={this.state.Resident.wound.orientationAnteriorPosterior == Common.WoundOrientation.anterior ? true : false}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                        />
                                                        <label className={this.state.orientationAnterior == Common.WoundOrientation.anterior ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Anterior"><small>Anterior</small>{this.state.orientationAnterior == Common.WoundOrientation.anterior && "*"}</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline ml-5">
                                                        <input className="form-check-input" type="radio" name="orientationAnteriorPosterior" id="Posterior"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            checked={this.state.Resident.wound.orientationAnteriorPosterior == Common.WoundOrientation.posterior ? true : false}
                                                            value={Common.WoundOrientation.posterior}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                        />
                                                        <label className={this.state.orientationPosterior == Common.WoundOrientation.posterior ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Posterior"><small>Posterior</small>{this.state.orientationPosterior == Common.WoundOrientation.posterior && "*"}</label>
                                                    </div>
                                                </div>


                                            </div>
                                            {/* </>
                                        }
                                        {this.state.orientationMedialLateral != null &&
                                            <> */}


                                            <div className="row">
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationMedialLateral" id="Medial"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.medial}
                                                            checked={this.state.Resident.wound.orientationMedialLateral == Common.WoundOrientation.medial ? true : false}
                                                            required={this.state.orientationMedial == Common.WoundOrientation.medial
                                                                || this.state.orientationLateral == Common.WoundOrientation.lateral && true}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                        />
                                                        <label className={this.state.orientationMedial == Common.WoundOrientation.medial ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Medial"><small>Medial</small>{this.state.orientationMedial == Common.WoundOrientation.medial && "*"}</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline ml-5">
                                                        <input className="form-check-input" type="radio" name="orientationMedialLateral" id="Lateral"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.lateral}
                                                            checked={this.state.Resident.wound.orientationMedialLateral == Common.WoundOrientation.lateral ? true : false}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}
                                                        />
                                                        <label className={this.state.orientationLateral == Common.WoundOrientation.lateral ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Lateral"><small>Lateral</small>{this.state.orientationLateral == Common.WoundOrientation.lateral && "*"}</label>

                                                    </div>
                                                </div>
                                            </div>

                                            {/* </>
                                        }
                                        {this.state.orientationDorsalDistalProximal != null &&
                                            <> */}
                                            <div className="row">
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Dorsal"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.dorsal}
                                                            checked={this.state.Resident.wound.orientationDorsalDistalProximal == Common.WoundOrientation.dorsal ? true : false}
                                                            required={this.state.orientationDorsal == Common.WoundOrientation.dorsal
                                                                || this.state.orientationDistal == Common.WoundOrientation.distal
                                                                || this.state.orientationProximal == Common.WoundOrientation.proximal && true}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                        />
                                                        <label className={this.state.orientationDorsal == Common.WoundOrientation.dorsal ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Dorsal"><small>Dorsal</small>{this.state.orientationDorsal == Common.WoundOrientation.dorsal && "*"}</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline ml-5">
                                                        <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Distal"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.distal}
                                                            checked={this.state.Resident.wound.orientationDorsalDistalProximal == Common.WoundOrientation.distal ? true : false}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                        />
                                                        <label className={this.state.orientationDistal == Common.WoundOrientation.distal ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Distal"><small>Distal</small>{this.state.orientationDistal == Common.WoundOrientation.distal && "*"}</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <div className="form-check form-check-inline ml-5">
                                                        <input className="form-check-input" type="radio" name="orientationDorsalDistalProximal" id="Proximal"
                                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                            value={Common.WoundOrientation.proximal}
                                                            checked={this.state.Resident.wound.orientationDorsalDistalProximal == Common.WoundOrientation.proximal ? true : false}
                                                            disabled={this.state.isReorderAndTissueTypeNotChange && this.state.ReoderAndIsDisableOrientation}

                                                        />
                                                        <label className={this.state.orientationProximal == Common.WoundOrientation.proximal ? "form-check-label font-weight-bold" : "form-check-label"}
                                                            htmlFor="Proximal"><small>Proximal</small>{this.state.orientationProximal == Common.WoundOrientation.proximal && "*"}</label>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* </>
                                        } */}
                                            {(!this.state.isReorderAndTissueTypeNotChange || !this.state.ReoderAndIsDisableOrientation) &&
                                                <button type="button" onClick={e => this.resetOrientation(this.state.Resident)} className="btn btn-danger btn-xs">Reset Orientation</button>
                                            }
                                        </div>
                                    </div>
                                 
                                </div>

                                <div className="row">
                               
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Length">Length (cm)</label>
                                            <input type="text" id="Length"
                                                name="woundLength"
                                                required
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.wound.woundLength)}
                                                className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Width">Width (cm)</label>
                                            <input type="text" id="Width"
                                                name="woundWidth"
                                                required
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.wound.woundWidth)}
                                                className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Depth">Depth (cm)</label>
                                            <input type="text" id="Depth"
                                                name="woundDepth"
                                                required
                                                disabled={this.state.Resident.wound.isUtd}
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.wound.woundDepth)}
                                                className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={<Switch name="isUtd" checked={this.state.Resident.wound.isUtd} 
                                            onChange={e => this.handleUtdChange(e)} value="gilad" />}
                                            label="UTD"
                                            className="font-weight-bold"
                                        />
                                    </div>
                                </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label"
                                                htmlFor="EvaluationDate">Date First Observed</label>
                                            <input type="date" id="dateFirstObserved"
                                                name="dateFirstObserved"
                                                required
                                                disabled={this.state.Resident.wound.id>0 &&this.state.Resident.wound.dateFirstObserved }
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                defaultValue={Common.getInputStringDate(new Date(this.state.Resident.wound.dateFirstObserved))}
                                                className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <FormControlLabel
                                                control={<Switch name="tunneling" checked={this.state.Resident.wound.tunneling} onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)} value="gilad" />}
                                                label="Tunneling"
                                            />
                                        </div>
                                    </div>

                                    {this.state.Resident.wound.tunneling
                                        &&
                                        <>
                                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="tunnelingDepth">T Depth (cm)</label>
                                                    <input type="text" id="tunnelingDepth"
                                                        name="tunnelingDepth"
                                                        required
                                                        onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.tunnelingDepth)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold" htmlFor="underminingDepth font-weight-bold">T Clock Position</label>
                                                    <input type="number" id="TunnelingClockPosition"
                                                        name="tunnelingClockPosition"
                                                        required
                                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                        onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.tunnelingClockPosition)}

                                                        className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <FormControlLabel
                                                control={<Switch name="undermining" checked={this.state.Resident.wound.undermining} onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)} value="gilad" />}
                                                label="Undermining"
                                            />
                                        </div>
                                    </div>
                                    {this.state.Resident.wound.undermining &&
                                        <>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="underminingDepth">U Depth (cm)</label>
                                                    <input type="text" id="underminingDepth"
                                                        name="underminingDepth"
                                                        required
                                                        onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.underminingDepth)}

                                                        className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold" htmlFor="underminingDepth font-weight-bold">From U Clock Position</label>
                                                    <input type="number" id="UnderminingClockPosition"
                                                        name="underminingClockPosition"
                                                        required
                                                        min="1" max="12"
                                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                        onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.underminingClockPosition)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold" htmlFor="underminingDepth font-weight-bold">To U Clock Position</label>
                                                    <input type="number" id="toUnderminingClockPosition"
                                                        name="toUnderminingClockPosition"
                                                        required
                                                        min="1" max="12"
                                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                        onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.toUnderminingClockPosition)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                                </div>

                                        </>
                                    }
                                </div>


                                <div className="row">

                                    <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fkExudateAmountId">Exudate Amount</label>
                                            <select className="form-control form-control-sm"
                                                id="fkExudateAmountId"
                                                name="fkExudateAmountId"
                                                required
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkExudateAmountId)}

                                            >
                                                <option value=""></option>
                                                <option value="1">None</option>
                                                <option value="3">Light</option>
                                                <option value="4">Moderate</option>
                                                <option value="5">Heavy</option>
                                                {/* {this.state.WoundExudateAmount} */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="ExudateType">Exudate Type</label>
                                            <select className="form-control form-control-sm"
                                                id="ExudateType"
                                                name="fkExudateTypeId"
                                                onChange={e => this.onExudateTypeChange(e)}
                                                // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkExudateTypeId)}>
                                                <option></option>
                                                {this.state.WoundExudateType}
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.Resident.wound.fkExudateTypeId == 1192 &&
                                        <>
                                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Length">Other Exudate Type</label>
                                                    <input type="text" id="OtherExudateType"
                                                        name="otherExudateType"
                                                        required
                                                        onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.otherExudateType)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                            </div>

                                        </>
                                    }
                                         <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="ExudateType">Exudate Color</label>
                                            <select className="form-control form-control-sm"
                                                id="fkWoundColorId"
                                                name="fkWoundColorId"
                                                onChange={e => this.onExudateColorChange(e)}
                                                // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundColorId)}>
                                                <option></option>
                                                {this.state.WoundExudateColor}
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.Resident.wound.fkExudateTypeId == 1192 &&
                                        <>
                                    <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="ExudateColor">Exudate Color Other</label>
                                            <input type="text" id="ExudateColor"
                                                name="exudateColor"
                                                required
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.wound.exudateColor)}
                                                className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    </>
                                    }
                                    <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <FormControlLabel
                                                control={<Switch name="odor" checked={this.state.Resident.wound.odor} onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)} value="gilad" />}
                                                label="Odor"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <FormControlLabel
                                                control={<Switch name="pain" checked={this.state.Resident.wound.pain} onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)} value="gilad" />}
                                                label="Pain"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="DebridementDate">Debridement Date</label>
                                            <input type="Date" id="DebridementDate"
                                                name="debridementDate"
                                                max={Common.getInputStringDateUsingMoment(moment())}
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                defaultValue={
                                                    this.state.Resident.wound.debridementDate =='' 
                                                    || this.state.Resident.wound.debridementDate == null ?
                                                    this.state.Resident.wound.debridementDate
                                                    :Common.getInputStringDate(new Date(this.state.Resident.wound.debridementDate))}
                                                className="form-control form-control-sm"
                                                disabled={this.props.resident.wound.fkSelectedDebridementTypeId == Common.debridementType.sharp?
                                                    true : false}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fkDebridementTypeId">Debridement Type</label>
                                            <select className="form-control form-control-sm"
                                                id="fkDebridementTypeId"
                                                name="fkDebridementTypeId"
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkDebridementTypeId)}
                                                disabled={this.props.resident.wound.fkSelectedDebridementTypeId == Common.debridementType.sharp?
                                                    true : false}
                                            >
                                                <option value=""></option>
                                                {this.state.WoundDebridementType}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                    <label className="form-label" htmlFor="fkWoundBedDescription">
            Wound Bed Description
          </label>
                                    </div>
                                </div>
                                {/* <span className=''>{this.state.errorMessage}</span> */}
                                <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="form-group">
                                          
                                            <WoundBedDescriptionInput id={'granulationPerc'} value={this.state.Resident?.wound?.granulationPerc} label={'Granulation' } handlePercentageChange={(e)=>{this.handlePercentageChange(e)}}  hasValue={this.state.Resident?.wound?.granulationPerc>0 }/>
<WoundBedDescriptionInput id={'hyperGranulationPerc'} value={this.state.Resident?.wound?.hyperGranulationPerc} label={'HyperGranulation'} handlePercentageChange={(e)=>{this.handlePercentageChange(e)}} hasValue={this.state.Resident?.wound?.hyperGranulationPerc>0 }/>
<WoundBedDescriptionInput id={'sloughPerc'} value={this.state.Resident?.wound?.sloughPerc} label={'Slough'} handlePercentageChange={(e)=>{this.handlePercentageChange(e)}} hasValue={this.state.Resident?.wound?.sloughPerc>0}/>
<WoundBedDescriptionInput id={'necroticPerc'} value={this.state.Resident?.wound?.necroticPerc} label={'Necrotic/Eschar'} handlePercentageChange={(e)=>{this.handlePercentageChange(e)}} hasValue={this.state.Resident?.wound?.necroticPerc>0 } />
<WoundBedDescriptionInput id={'epithelialPerc'} value={this.state.Resident?.wound?.epithelialPerc} label={'Epithelial'} handlePercentageChange={(e)=>{this.handlePercentageChange(e)}} hasValue={this.state.Resident?.wound?.epithelialPerc>0 }/>
<WoundBedDescriptionInput id={'musclePerc'} value={this.state.Resident?.wound?.musclePerc} label={'Muscle, Tendon or Bone'} handlePercentageChange={(e)=>{this.handlePercentageChange(e)}} hasValue={this.state.Resident?.wound?.musclePerc>0 }/>
         
           
           
            
            <div  className="form-check form-check-inline">
              
              <label className="form-check-label" htmlFor={'OtherPerc'}>
                {'Other'}
              </label>
              {this.state.Resident?.wound?.otherPerc>0  && <textarea
                                                className="form-control ml-2"
                                                style={{height:'40px'}}
                                                name="otherWoundDesc"
                                        required={this.state.Resident?.wound?.otherPerc>0 }
                                                id="otherWoundDesc"
                                                value={this.state.Resident?.wound?.otherWoundDesc || ''}
                                                onChange={(e) => this.handleOtherPercentage(e)}
                                                cols={10}
                                            ></textarea>}
              <input
               id='otherPerc'
                type="text"
                min={0}
                max={100}
                className="form-control form-control-sm ml-3 w-60px hide-arrows"
                value={this.state.Resident?.wound?.otherPerc || ''}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onChange={(e) => this.handlePercentageChange(e)}
                required={!this.state.hasValue}
              /><span className='ml-1'>%</span> 
                
            </div>
                                            </div>
                                        </div>
                                    </div>
<div className='row'>
<div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fkWoundSurroundingSkin">Surrounding Skin</label>
                                            <select className="form-control form-control-sm"
                                                id="fkWoundSurroundingSkin"
                                                name="fkWoundSurroundingSkinId"
                                                onChange={e => this.onSurroundingSkinTypeChange(e)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundSurroundingSkinId)}
                                            >
                                                <option value=""></option>
                                                {this.state.SurroundingSkin}
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.Resident.wound.fkWoundSurroundingSkinId == 1056 &&
                                        <>
                                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Length">Other Surrounding Skin</label>
                                                    <input type="text" id="otherSurroundingSkin"
                                                        name="otherSurroundingSkin"
                                                        required
                                                        onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                        defaultValue={Common.replaceNullWithString(this.state.Resident.wound.otherSurroundingSkin)}
                                                        className="form-control form-control-sm" />
                                                </div>
                                            </div>

                                        </>
                                    }
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fkWoundEdges">Wound Edges</label>
                                            <select className="form-control form-control-sm"
                                                id="fkWoundEdges"
                                                name="fkWoundEdgesId"
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundEdgesId)}
                                            >
                                                 <option value=""></option>
                                                {this.state.WoundEdges}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fkWoundSkinAndUlcerTreatment">Skin & ulcer Treatment</label>
                                            <select className="form-control form-control-sm"
                                                id="fkWoundSkinAndUlcerTreatment"
                                                name="fkWoundSkinAndUlcerTreatmentId"
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.Resident.wound.fkWoundSkinAndUlcerTreatmentId)}
                                            >
                                                 <option value=""></option>
                                                {this.state.SkinAndUlcerTreatment}
                                            </select>
                                        </div>
                                    </div>
</div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="col-sm-3 col-md-3 col-lg-12 col-xl-12">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="DebridementType">Frequency of Change</label>
                                                <select className="form-control form-control-sm"
                                                    id="DebridementType"
                                                    required
                                                    name="fkDressingChangeFrequencyId"
                                                    onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                    value={Common.replaceNullWithString(this.state.Resident.wound.fkDressingChangeFrequencyId)}
                                                >
                                                    <option value=""></option>
                                                    {this.state.WoundDressingChangeFrequency}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="TreatmentDescription">
                                                Treatment Description
                                            </label>
                                            <textarea
                                                className="form-control"
                                                name="treatment"
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.wound.treatment)}
                                                id="Treatment"
                                                rows="3"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="GeneralComment">
                                                General Comment
                                            </label>
                                            <textarea
                                                className="form-control"
                                                name="comments"
                                                onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.Resident.wound.comments)}
                                                id="comments"
                                                rows="3"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.props.canView ?
                                        <div className="d-flex justify-content-end py-3">
                                            <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Submit</Button>

                                           { /*!this.props.isEdit?
                                            <button type="button"
                                                disabled={this.props.isReadOnly ? true : false}
                                                className="btn btn-danger btn-sm"
                                                onClick={e => this.setState({
                                                    showProduct: true,
                                                })}>Show Product</button>
                                                :null
                                            */}
                                        </div>
                                        :
                                        null
                                }






                            </fieldset>
                        </div>
                        :
                        <div className="d-flex justify-content-center">
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={50}
                                width={50}
                            />
                        </div>
                }
            </>

        );
    }

    renderProductSelectionForm() {
        return (
            <>
                <ProductSelection ref={(ref) => this.productSelection = ref}
                    primaryDressing=''
                    secondaryDressing=''
                    saleNote=''
                    frequencyId={null}
                />
            </>
        )
    }

    render() {

        return (
            <>
                <Form validated={this.state.formValidated}>
                    {this.renderWoundForm()}
                    {
                        this.state.showProduct
                            ?
                            this.renderProductSelectionForm()
                            :
                            null
                    }
                </Form>
            </>
        )
    }
    onFormSave() {
    }
}// end of class

/*eslint-enable */