import ICDReport from '../modal/reports/icdreport';
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from "react-animated-css";
import { useSelector } from 'react-redux';
import { Card, CardBody } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import WoundProducts from "../products/woundProducts";
import Loader from "react-loader-spinner";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";

import moment from 'moment';

import uuid from 'node-uuid'
import { common } from '@material-ui/core/colors';




export function ResidentHistoryWounds(props) {
    // const ICDReportModalRef= useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isReorder, setIsReOrder] = useState(false);



    const [woundDetail, setSelectedDetail] = useState(null);
    const [salesOrderId, setSalesOrderId] = useState(null);



    // const [isProductShow, setIsProductShow] = useState(false);



    const { user } = useSelector((state) => state.auth);



    const [residentWoundListUI, setResidentWoundListUI] = useState([]);
    useEffect(() => {
        getResidentWound();
        //setResidentListTemp();
        //Common.LoadDataTable("facilityList")
    }, []);

    //#region Set Resident List
    const getResidentWound = () => {
        debugger
        setIsLoading(false);

        Api.getRequestQry(Api.URI_GetResidentWoundHistory, "?residentId=" + props.residentId +
            "&processQueueId=" + props.processQueueId
            +"&isPartial=" + props.isPartial
            +"&cycleDate=" + props.cycleDate)

            .then((res) => {
                setIsLoading(false);
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            setResidentWoundListUI([]);
                            setResidentWoundList(res.data.result);

                        } else {
                            setResidentWoundListUI([]);
                        }
                        setIsLoading(true);
                    }
                }

                //Common.LoadDataTable("woundList"+props.Resident.id)
            }).catch((ex) => {
                Common.LogError(ex, "ResidentNote", "Get");

            });
    }

    const setResidentWoundList = (list) => {
        let rows = [];
        try {
            for (var i = 0; i < list.length; i++) {
                const obj = list[i];
                var residentWound = {};
                residentWound.wound = {}
                residentWound.wound.woundDetailId = obj.woundDetailId;
                rows.push(
                    <React.Fragment key={i}>
                        <tr key={uuid()}>
                            <th>Num</th>
                            <th>Body Region/Wound Location</th>
                            <th>{obj.fkWoundTypeId == Common.WoundType.pressure ? 'Stage/Thickness'
                                : obj.fkWoundTypeId == Common.WoundType.Surgical || obj.fkWoundTypeId == Common.WoundType.tubeSize ? 'Stage/Thickness' : 'Thickness/Most Severe Tissue'}</th>
                            <th>Is Partial</th>
                            <th>ICD10 Code</th>

                            <th>Wound Size</th>
                            <th>Exudate</th>


                            <th> Evaluated on</th>
                            <th>Evaluated By</th>
                            {/* <th>Actions</th> */}
                        </tr>
                        <tr key={uuid()}>
                            <td className={`justify-content-center`}>
                                {i + 1}
                            </td>
                            <td>
                                <button className={"btn btn-link"}
                                >

                                    {obj.orientationLeftRightName == null || obj.orientationLeftRightName == "" ? "" : obj.orientationLeftRightName + " - "} {Common.replaceNullWithString(obj.bodyRegion)}{obj.woundLocation != null ? "/" : ""}
                                    {Common.replaceNullWithString(obj.woundLocation)}
                                    {obj.orientation == null || obj.orientation == "" ? "" : " - " + obj.orientation}
                                </button>
                            </td>


                            <td>

                                {Common.replaceNullWithString(obj.woundThicknessName)}
                                {obj.fkWoundTypeId == Common.WoundType.pressure ||
                                    obj.fkWoundTypeId == Common.WoundType.Surgical
                                    || obj.fkWoundTypeId == Common.WoundType.tubeSize ? ''
                                    : obj.woundThicknessName != null && obj.woundSevereTissueName != null ?



                                        " - " + Common.replaceNullWithString(obj.woundSevereTissueName)



                                        : ""}

                            </td>
                            <td>
                                {(obj.isPartialOrder == true || obj.isOrderNow) == true ? "Yes" : 'No'}
                            </td>
                            <td>
                                {Common.replaceNullWithString(obj.icdcode) != '' ? obj.icdcode : 'N/A'}
                            </td>
                            <td >
                                {obj.woundLength + " x " + obj.woundWidth + " x " + obj.woundDepth+" "+obj.isDtd?"U":""}
                            </td>
                            <td >
                                {Common.replaceNullWithString(obj.exudateAmountName)}
                            </td>


                            <td>
                                {Common.replaceNullWithString(obj.evaluationDate) != '' ? Common.getInputStringDateUsingMoment2(moment.utc(obj.evaluationDate).local()) : 'N/A'}
                            </td>
                            <td>
                                {Common.replaceNullWithString(obj.evaluatedBy) != '' ? obj.evaluatedBy : 'N/A'}
                            </td>


                        </tr>
                        {

                            <tr key={uuid()}>
                                <td colSpan="9">
                                    <Card className="example example-compact">
                                        <CardBody>
                                            {

                                                <WoundProducts ModulesName={ModulesName.Module_MyWorkQueue}
                                                    isReadOnly={true}
                                                    residentWound={residentWound} />
                                            }
                                        </CardBody>
                                    </Card>



                                </td>

                            </tr>

                        }


                    </React.Fragment>
                );
            }
            debugger
            setResidentWoundListUI(rows);
            setIsLoading(true);


        } catch (error) {
            Common.LogError(error, "WorkqueueWounds", "SetWoundList");
        }
    }

    var componentArray = [];

    return (
        <>





            <Animated animationIn="fadeIn" animationInDuration={2000} >
                <div className="d-flex justify-content-end">

                </div>
                <table key={uuid()} className="table table-striped table-hover table-sm table-bordered" id={"woundList"}>
                    {/* <thead>


                    </thead> */}
                    <tbody key={uuid()}>


                        {(isLoading == true) ? residentWoundListUI :
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td> <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                /></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                        {/* {rows} */}

                    </tbody>
                </table>

            </Animated>

        </>
    );
}





export default ResidentHistoryWounds;