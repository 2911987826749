/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import RoutePaths from "../../../../../app/constants/routepaths";
import RBAC, {
  ModulesName,
} from "../../../../../app/General/roleModuleActions";
import { checkIsActive } from "../../../../_helpers";

function HeaderMenu(props) {
  const layoutProps = props.layoutProps;

  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  // const [ locationKeys, setLocationKeys ] = useState([])
  // const history = useHistory()

  // const [finishStatus, setfinishStatus] = useState(false);

  // const onBackButtonEvent = (e) => {
  //     e.preventDefault();
  //     if (!finishStatus) {
  //         if (window.confirm("Do you want to go back ?")) {
  //             setfinishStatus(true)
  //             // your logic
  //          history.push("/");
  //         } else {
  //           debugger
  //            history.push("", "",location.pathname);
  //             // history.goBack()
  //             setfinishStatus(false)
  //         }
  //     }
  // }

  //   useEffect(() => {
  //     window.history.pushState(null, null, window.location.pathname);
  //     window.addEventListener('popstate', onBackButtonEvent);
  //     return () => {
  //       window.removeEventListener('popstate', onBackButtonEvent);
  //     };
  //   }, []);
  const menuList = {
    /*begin::WorkQueu*/
    [ModulesName.Module_MyWorkQueue]: (
      <li
        key={ModulesName.Module_MyWorkQueue}
        className={`menu-item menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_WorkQueue
        )} `}
      >
        <NavLink className="menu-link" to={RoutePaths.Page_WorkQueue}>
          <span className="menu-text">My Work Queue</span>
          {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
        </NavLink>
      </li>
    ),
    /*end::WorkQueu*/ /*begin::BillingQueue*/
    [ModulesName.Module_BillingQueue]: (
      <li
        key={ModulesName.Module_BillingQueue}
        className={`menu-item menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_Billing
        )} `}
      >
        <NavLink className="menu-link" to={RoutePaths.Page_Billing}>
          <span className="menu-text">
            {RBAC.Module_BillingQueue}Processor Queue
          </span>
          {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
        </NavLink>
      </li>
    ),
    /*end::BillingQueue*/ /*begin::Facility*/
    [ModulesName.Module_Facility]: (
      <li
        key={ModulesName.Module_Facility}
        className={`menu-item menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_Facility
        )} `}
      >
        <NavLink className="menu-link" to={RoutePaths.Page_Facility}>
          <span className="menu-text">Facility</span>
          {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
        </NavLink>
      </li>
    ),
    [ModulesName.Module_FacilityQueue]: (
      <li
        key={ModulesName.Module_FacilityQueue}
        className={`menu-item menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_FacilityQueue
        )} `}
      >
        <NavLink className="menu-link" to={RoutePaths.Page_FacilityQueue}>
          <span className="menu-text">Assessment Queue</span>
          {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
        </NavLink>
      </li>
    ),
    /*end::Facility*/ // [ModulesName.Module_Residents]:/*begin::Resients*/
    //   <li
    //     className={`menu-item menu-item-rel ${getMenuItemActive(
    //       RoutePaths.Page_Resident
    //     )} `}
    //   >
    //     <NavLink className="menu-link" to={RoutePaths.Page_Resident}>
    //       <span className="menu-text">Residents</span>
    //     </NavLink>
    //   </li>

    // /*end::Resients*/,
    /*start:: Products*/

    [ModulesName.Module_Products]: (
      /*begin::1 Level*/
      <li
        key={ModulesName.Module_Products}
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_ProductsNav
        )} `}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={RoutePaths.Page_ProductsNav}
        >
          <span className="menu-text">Products</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            {/*begin::2 Level*/}
            {/* <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(RoutePaths.Page_NewProdcut)}`}
                    >
                        <NavLink className="menu-link" to={RoutePaths.Page_NewProdcut}>
                            <span className="menu-text">
                                New Products
                            </span>
                        </NavLink>
                    </li> */}
            <li
              key={RoutePaths.Page_ProductList}
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_ProductList
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_ProductList}>
                <span className="menu-text">Products</span>
              </NavLink>
            </li>
            <li
              key={RoutePaths.Page_ProductCategories}
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_ProductCategories
              )} `}
            >
              <NavLink
                className="menu-link"
                to={RoutePaths.Page_ProductCategories}
              >
                <span className="menu-text">Categories</span>
              </NavLink>
            </li>
            <li
              key={RoutePaths.Page_PurchasingOrganization}
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_PurchasingOrganization
              )} `}
            >
              <NavLink
                className="menu-link"
                to={RoutePaths.Page_PurchasingOrganization}
              >
                <span className="menu-text">Purchasing Organization</span>
              </NavLink>
            </li>
            {/*end::2 Level*/}
          </ul>
        </div>
      </li>
    ),
    /*end::1 Level*/

    /*end:: Products*/ // /*begin::1 LevelOrder*/
    //     [ModulesName.Module_Orders]:
    //       <li key={ModulesName.Module_Orders}
    //         data-menu-toggle={layoutProps.menuDesktopToggle}
    //         aria-haspopup="true"
    //         className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
    //           "/orderspage"
    //         )} `}
    //       >
    //         <NavLink className="menu-link menu-toggle" to="/orderspage">
    //           <span className="menu-text">Orders</span>
    //           <i className="menu-arrow"></i>
    //         </NavLink>
    //         <div className="menu-submenu menu-submenu-classic menu-submenu-left">
    //           <ul className="menu-subnav">
    //             <li key={"/orderspage/approveorders"}
    //               className={`menu-item menu-item-submenu ${getMenuItemActive(
    //                 "/orderspage/approveorders"
    //               )} `}
    //             >
    //               <NavLink className="menu-link" to="/orderspage/approveorders">
    //                 <span className="menu-text">Approve Orders</span>
    //               </NavLink>
    //             </li>

    //             <li key={"/orderspage/revieworders"}
    //               className={`menu-item menu-item-submenu ${getMenuItemActive(
    //                 "/orderspage/revieworders"
    //               )} `}
    //             >
    //               <NavLink className="menu-link" to="/orderspage/revieworders">
    //                 <span className="menu-text">Review Orders</span>
    //               </NavLink>
    //             </li>
    //             <li key={"/orderspage/processorders"}
    //               className={`menu-item menu-item-submenu ${getMenuItemActive(
    //                 "/orderspage/processorders"
    //               )} `}
    //             >
    //               <NavLink className="menu-link" to="/orderspage/processorders">
    //                 <span className="menu-text">Process Orders</span>
    //               </NavLink>
    //             </li>
    //             <li key={"/orderspage/recentorders"}
    //               className={`menu-item menu-item-submenu ${getMenuItemActive(
    //                 "/orderspage/recentorders"
    //               )} `}
    //             >
    //               <NavLink className="menu-link" to="/orderspage/recentorders">
    //                 <span className="menu-text">Recent Orders</span>
    //               </NavLink>
    //             </li>
    //             <li key={"/orderspage/newtreatmentorders"}
    //               className={`menu-item menu-item-submenu ${getMenuItemActive(
    //                 "/orderspage/newtreatmentorders"
    //               )} `}
    //             >
    //               <NavLink
    //                 className="menu-link"
    //                 to="/orderspage/newtreatmentorders"
    //               >
    //                 <span className="menu-text">New Treatment Orders</span>
    //               </NavLink>
    //             </li>
    //           </ul>
    //         </div>
    //       </li>

    //    /*end::1 LevelOrder*/,

    /*start:: TabletData*/
    [ModulesName.Module_TabletData]: (
      /*begin::1 Level*/
      <li
        key={ModulesName.Module_TabletData}
        className={`menu-item menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_TabletData
        )} `}
      >
        <NavLink className="menu-link" to={RoutePaths.Page_TabletData}>
          <span className="menu-text">Tablet Data</span>
          {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
        </NavLink>
      </li>
    ),
    /*end::1 Level*/
    /*end:: TabletData*/ /*start:: Recap*/
    [ModulesName.Module_Recap]: (
      /*begin::1 Level*/
      <li key={ModulesName.Module_Recap} className={`menu-item menu-item-rel `}>
        <a
          href="https://www.surveymonkey.com/r/recap2021"
          className="menu-link"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="menu-text">Recap</span>
        </a>
      </li>
    ),

    /*end:: Recap*/
    /*end::1 Level*/ /*start:: EHR Vendors*/
    [ModulesName.Module_EHRVendors]: (
      /*begin::1 Level*/
      <li
        key={ModulesName.Page_EHRVendors}
        className={`menu-item menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_EHRVendors
        )} `}
      >
        <NavLink className="menu-link" to={RoutePaths.Page_EHRVendors}>
          <span className="menu-text">EHR Vendors</span>
          {/* {/ {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} /} */}
        </NavLink>
      </li>
    ),
    /*end::1 Level*/
    /*end:: EHR Vendors*/ /*start:: Setup*/
    [ModulesName.Module_Setup]: (
      /*begin::1 Level*/
      <li
        key={ModulesName.Module_Setup}
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_SetupNav
        )} `}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={RoutePaths.Page_SetupNav}
        >
          <span className="menu-text">Admin</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            {/*begin::2 Level*/}
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                RoutePaths.Page_Lookuplist
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_Lookuplist}>
                <span className="menu-text">Lookups</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_Role
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_Role}>
                <span className="menu-text">Roles</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_SystemSetting
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_SystemSetting}>
                <span className="menu-text">System Settings</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_ICDCode
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_ICDCode}>
                <span className="menu-text">ICD Code</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_Insurance
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_Insurance}>
                <span className="menu-text">Sync Insurance</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_NightlySync
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_NightlySync}>
                <span className="menu-text">Nightly Sync</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                RoutePaths.Page_UserList
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_UserList}>
                <span className="menu-text">Users</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                RoutePaths.Page_EMRCredential
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_EMRCredential}>
                <span className="menu-text">EMR Credentail</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                RoutePaths.Page_WoundAndProductSpecialist
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_WoundAndProductSpecialist}>
                <span className="menu-text">Wound and Product Specialist</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                RoutePaths.Page_FacilityUser
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_FacilityUser}>
                <span className="menu-text">Facility User</span>
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                RoutePaths.Page_UploadUsers
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_UploadUsers}>
                <span className="menu-text">Upload Users</span>
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                RoutePaths.Page_vacation
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_vacation}>
                <span className="menu-text">Calendar</span>
              </NavLink>
            </li>
            {/*end::2 Level*/}
          </ul>
        </div>
      </li>
    ),
    /*end::1 Level*/
    /*end:: Setup*/ /*start:: Reports*/
    [ModulesName.Module_AssessmentReports]: (
      /*begin::1 Level*/
      <li
        key={ModulesName.Module_AssessmentReports}
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_AssessmentReportNav
        )} `}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={RoutePaths.Page_AssessmentReportNav}
        >
          <span className="menu-text"> Assessment Reports</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            {/*begin::2 Level*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_WoundEvaluationReport
              )} `}
            >
              <NavLink
                className="menu-link"
                to={RoutePaths.Page_WoundEvaluationReport}
              >
                <span className="menu-text">Wound Evaluation Report</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_FacilityReport
              )} `}
            >
              <NavLink
                className="menu-link"
                to={RoutePaths.Page_FacilityReport}
              >
                <span className="menu-text">Wound Report</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_WoundReport
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_WoundReport}>
                <span className="menu-text">Trend Report</span>
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_QAPIReport
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_QAPIReport}>
                <span className="menu-text">QAPI Report</span>
              </NavLink>
            </li>
            <li 
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_HistoricalReport
              )} `}
            >
              <NavLink className="menu-link" to={RoutePaths.Page_HistoricalReport}>
                <span className="menu-text">Historical Report</span>
              </NavLink>
            </li>
            {/*end::2 Level*/}
          </ul>
        </div>
      </li>
    ),

    [ModulesName.Module_Reports]: (
      /*begin::1 Level*/
      <li
        key={ModulesName.Module_Reports}
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_ReportNav
        )} `}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={RoutePaths.Page_ReportNav}
        >
          <span className="menu-text">Reports</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            {/*begin::2 Level*/}
         
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                RoutePaths.Page_FacilityCoverReport
              )} `}
            >
              <NavLink
                className="menu-link"
                to={RoutePaths.Page_FacilityCoverReport}
              >
                <span className="menu-text">Print Manual Reports</span>
              </NavLink>
            </li>

            {/*end::2 Level*/}
          </ul>
        </div>
      </li>
    ),
    /*end::1 Level*/
    /*end:: Reports*/ /*begin::Facility*/
    [ModulesName.Module_Physician]: (
      <li
        key={ModulesName.Module_Physician}
        className={`menu-item menu-item-rel ${getMenuItemActive(
          RoutePaths.Page_Physician
        )} `}
      >
        <NavLink className="menu-link" to={RoutePaths.Page_Physician}>
          <span className="menu-text">Physician</span>
          {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
        </NavLink>
      </li>
    ),
    /*end::Facility*/
  };

  const getUserModules = () => {
    let menus = [];
    const userRoles = props.user.roles.moduleList;
    const excludeModuleList = [
      ModulesName.Module_Physician,
      ModulesName.Module_Products,
      ModulesName.Module_TabletData,
    ];
    userRoles.map((module) => {
      let menu = menuList[module.moduleName];
      if (!excludeModuleList.includes(module.moduleName)) {
        if (menu) {
          menus.push(menu);
        }
      }
    });
    return menus;
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li key={"/dashboard"}
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::Users*/}
        {/* <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            RoutePaths.Page_RepFirmList
          )} `}
        >
          <NavLink className="menu-link" to={RoutePaths.Page_RepFirmList}>
            <span className="menu-text">Users</span>
          </NavLink>
        </li> */}
        {/*end::Users*/}

        {/*begin::UsersRole*/}
        {/* <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            RoutePaths.Page_UserRoleList
          )} `}
        >
          <NavLink className="menu-link" to={RoutePaths.Page_UserRoleList}>
            <span className="menu-text">User Role</span>
          </NavLink>
        </li> */}
        {/*end::UsersRole*/}

        {getUserModules()}

        {/* <li key={RoutePaths.Page_SetupBrightree}
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            RoutePaths.Page_SetupBrightree
          )} `}
        >
          <NavLink
            className="menu-link menu-toggle"
            to={RoutePaths.Page_SetupBrightree}
          >
            <span className="menu-text">Brightree</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {
              //begin::2 Level
              }
              <li key={RoutePaths.Page_ResidentBrigthree}
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  RoutePaths.Page_ResidentBrigthree
                )} `}
              >
                <NavLink className="menu-link" to={RoutePaths.Page_ResidentBrigthree}>
                  <span className="menu-text">Resident</span>
                </NavLink>
              </li>
              <li
                key={RoutePaths.Page_SaleOrder}
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  RoutePaths.Page_SaleOrder
                )} `}
              >
                <NavLink className="menu-link" to={RoutePaths.Page_SaleOrder}>
                  <span className="menu-text">Sales Order</span>
                </NavLink>
              </li>
              <li
                key={RoutePaths.Page_Note}
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  RoutePaths.Page_Note
                )} `}
              >
                <NavLink className="menu-link" to={RoutePaths.Page_Note}>
                  <span className="menu-text">Note</span>
                </NavLink>
              </li>
              <li
                key={RoutePaths.Page_Insurance}
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  RoutePaths.Page_Insurance
                )} `}
              >
                <NavLink className="menu-link" to={RoutePaths.Page_Insurance}>
                  <span className="menu-text">Insurance</span>
                </NavLink>
              </li>
              <li
                key={RoutePaths.Page_Physician}
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  RoutePaths.Page_Physician
                )} `}
              >
                <NavLink className="menu-link" to={RoutePaths.Page_Physician}>
                  <span className="menu-text">Physician</span>
                </NavLink>
              </li>
              {
              //end::2 Level
              }
            </ul>
          </div>

        </li> */}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}

const mapStateToProps = function(state) {
  const user = state.auth.user;
  return { user };
};

export default connect(mapStateToProps)(HeaderMenu);
