/*eslint-disable */

/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/app";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";
  //JQuery Datatable
  //Bootstrap and jQuery libraries
import 'jquery/dist/jquery.min.js';
 
import 'datatables.net'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.html5.js'
import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/src/sweetalert2.scss'
// ES6 Modules or TypeScript
// import Swal from 'sweetalert2'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
 /* const mock = */ //_redux.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);

export function showDecisionAlert(yesCallBack, noCallBack = null, title = 'Are you sure?', text = 'You want to perform this action', noButtonText = "No", yesButtonText = "Yes",) {
  Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: yesButtonText,
    cancelButtonText: noButtonText
  }).then((result) => {
    if (result.value) {
      // eslint-disable-next-line no-use-before-define
      if (typeof(yesCallBack) != null || typeof(yesCallBack) != 'undefind') { // eslint-disable-next-line no-use-before-define
        yesCallBack();
      }
    } else {
      // eslint-disable-next-line no-use-before-define
      if (typeof(noCallBack) != null || typeof(noCallBack) != 'undefind') {
        noCallBack();
      }
    }
  })
}

export function showErrorAlert(text = 'Something went wrong!', title = 'Oops...') {
  Swal.fire({
    icon: 'error',
    title: title,
    text: text,
  })
}

export function showWarningAlert(text = 'Something went wrong!', title = 'Oops...', confirmButtonText = 'OK') {
  Swal.fire({
    icon: 'warning',
    title: title,
    html: text,
    confirmButtonText: confirmButtonText
  })
}

export function showSuccessAlert(text = 'Request completed!', title = 'Congratulations!') {
  Swal.fire({
    icon: 'success',
    title: title,
    text: text,
  })
}


/*eslint-enable */
