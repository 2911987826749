
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import React from "react";
import { Col, Form } from "react-bootstrap";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";

//export function RepFirmForm () {
class AddDropdownValueForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formValidated: false,
            DropDownValue: {
                FkDropdownHeaderId: this.props.headerID,
                ValueText: '',
                ValueAlias: '',
                OrderNo: null,
                ValueBtid: null,
                Active: true

            }

        }
    }

    validate() {
        let isValid = { status: true, msg: "" }
        if (this.state.DropDownValue.ValueText == "") {
            isValid.status = false;
            isValid.msg = "Please provide value";
        }

        return isValid;
    }

    onSubmit = () => {
        // // debugger
        if (this.form.checkValidity() === false) {
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {

            const obj = this.state.DropDownValue;
            Api.postRequest(Api.Uri_AddHeaderValues, obj)
                .then(res => {
                    if (res.data.success) {
                        this.props.onSaveAPI();
                    } else {
                        Common.showSuccessAlertAutoClose(res.data.message, "error", "Error!", 3000);
                    }

                });
        }

    }
    render() {
        return (
            <Form
                ref={(ref) => { this.form = ref }}
                validated={this.state.formValidated}
                onSubmit={e => this.onSubmit(e)}
            >
                <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label  >Lookup Value Text</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="ValueText"
                            onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)}
                            value={this.state.DropDownValue.ValueText}
                            placeholder="Dropdown Value Text"
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">Lookup Value Text is Required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label  >Lookup Value Alias</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="ValueAlias"
                            onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)}
                            value={this.state.DropDownValue.ValueAlias}
                            placeholder="Dropdown Value Alias"
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">Lookup Value Alias is Required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label  >Order No</Form.Label>
                        <Form.Control
                            type="number"
                            name="OrderNo"
                            onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)}
                            value={this.state.DropDownValue.OrderNo}
                            placeholder="Order No"
                        />
                      
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label  >Brightree ID</Form.Label>
                        <Form.Control
                            type="number"
                            name="ValueBtid"
                            onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)}
                            value={this.state.DropDownValue.ValueBtid}
                            placeholder="Brightree ID"
                        />
                      
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group>
                        <FormControlLabel
                            control={<Switch name="Active" checked={this.state.DropDownValue.Active} onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)} value="gilad" />}
                            label="Active"
                        />
                    </Form.Group>
                </Form.Row>
            </Form>
        );
    }
}

export default AddDropdownValueForm;