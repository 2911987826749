/*eslint-disable */

import React from 'react';
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import PopupModal from "../modal/popupmodal";
import NewEHRVendor from './newehrvendor';
import $ from 'jquery';
import { showDecisionAlert } from "../../../index";
export function EHRVendors() {

    var newEHRVendorModal = null;

    const displayDecisionAlert = () => showDecisionAlert(function () { }, function () { }, "Are you sure?", "You want to deactivate this Vendor.");


    var rows = [];
    var rowData = ["AHT", "Matrix", "PCC", "Sigma",];
    var rowFNData = ["American Health Tech", "Matrix Care", "PointClickCare", "Sigma Care",];
    for (var i = 1; i < rowData.length; i++) {
        rows.push(<tr key={i.toString()}>
            <td>{rowData[i - 1]}</td>
            <td>{rowFNData[i - 1]}</td>
            <td>28/Nov/2019</td>
            <td align="center">
                <div className="text-center">
                    <DropdownButton
                        drop={"down"}
                        size="xs"
                        variant="primary"
                        title={'Actions'}
                        id={`dropdown-button-drop-actions`}
                        key={'down'}>
                        <Dropdown.Item eventKey="1" onClick={() => newEHRVendorModal.showModal()}>Edit Category</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className="text-danger" onClick={displayDecisionAlert}>Deactivate</Dropdown.Item>
                    </DropdownButton>
                </div>
            </td>
        </tr>);
    }
    const toolbar = (
        <div className="card-toolbar">
            <div className="example-tools">
                <Button variant="primary" size="sm" onClick={() => newEHRVendorModal.showModal()}>New EHR Vendors</Button>
            </div>
        </div>
    );

    $(document).ready(function () {
        if ($.fn.dataTable.isDataTable('#EHRVendortList')) {
            $('#EHRVendortList').DataTable = $('#EHRVendortList').DataTable();
        }
        else {
            $('#EHRVendortList').DataTable({
                responsive: true,
                pageLength: 25,
                lengthMenu: [[25, 50, 100, -1], [25, 50, 100, "All"]],
            });
        }

    });

    return (
        <>
            <PopupModal size="lg" title="New EHR Vendor" ref={ref => { newEHRVendorModal = ref; }}> <NewEHRVendor /> </PopupModal>
            <Card className="example example-compact">
                <CardHeader title='EHR Vendors' toolbar={toolbar} />
                <CardBody>
                    <table className="table table-striped table-hover table-sm table-bordered" id="EHRVendortList">
                        <thead>
                            <tr>
                                <th>Short Name</th>
                                <th>Full name</th>
                                <th>Created At</th>
                                <th width="130px">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </>
    );
}

export default EHRVendors;

/*eslint-disable */