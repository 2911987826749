import React from "react";
import { Form,Button } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import Loader from "react-loader-spinner";
import Insurance from "../../resident/insurance";
import NewBrightreePhysician from '../../physician/newbrightreephysician';
import { Animated } from "react-animated-css";
import PopupModal from "../../modal/popupmodal";
import AddEditPhysician from "../../physician/newPhysician";
import RBAC, { ModulesName, UserActions } from "../../../General/roleModuleActions";
import moment from 'moment';
var newPhysicianModal,addEditPhysicianRef = null;
const _SampleResidentJson = {
  id: null,
  fkFacilityId: null,
  btPatientBrightreeID:null,
  residentBtKey: null,
  fkResidentStatusId: Common.ResidentStatusID.open,
  firstName: "",
  middleName: "",
  lastName: "",
  gender: null,
  ssn: "",
  doB: null,
  unit: "",
  room: "",
  medicare: '',
  admissionDate: null,
  dischargeDate: null,
  primaryPhysicianName: null,
  fkPrimaryPhysicianId: null,
  primaryPhysicianNpi:null,
  signingPhysicianNpi:null,
  allergicToLatex: false,
  allergicToHoney:false,
  currentlyMedA: false,
  onHospice: false,
  isMedB: false,
  isEcp: false,
  isMedicaidOnly: false,
  fkOrderingPhysicianId: null,
  fkBillingTypeId: null,
  rfirstName: "",
  rlastName: "",
  rmiddleName: "",
  shippingAddressId: null,
  billingAddressId: null,
  raddressId: null,
  responsiblePartyHomePhone: "",
  responsiblePartyCellPhone: "",
  fkShipToPhysicianId: null,
  isHospicePatient: false,
  allergicToSulpha: false,

  allergicToCollagen: false,
  allergicToTape: false,
  allergicToIodine: false,
  createdDate: "2021-01-21T13:13:13.304Z",
  createdBy: null,
  modifiedBy: null,
  modifiedDate: "2021-01-21T13:13:13.304Z",

  raddress: {
    addressId: null,
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: null,
    zip: "",
    countryId: null,
    phone1: "",
    phone2: "",
    fax: "",
    mobile1: "",
    mobile2: "",
    email: "",
    active: true,
  },
  shippingAddress: {
    addressId: null,
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: null,
    zip: "",
    countryId: null,
    phone1: "",
    phone2: "",
    fax: "",
    mobile1: "",
    mobile2: "",
    email: "",
    active: true,
  },
  physicianViewModel: {
    physicianId: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    physicianNote: "",
    npi: "",
    stateMedicaidId: "",
    licenseNo: "",
    TaxonomyCode: "",
    brightreeId: 0,
    doctorGroupId: 0,

    address: {
      addressId: 0,
      addressLine1: "",
      addressLine2: "",
      city: "",
      sateId: 1,
      countryId: 1,
      zip: "",
      phone1: "",
      email: "",
      mobile1: "",
      fax: "",
    },
  },
  fkSigningPhysicianId: null,
  signingPhysicianName: '',
  signingContactPerson: '',
  signingPhysicianPhone: '',
  signingPhysicianEmail: '',

  physicianSigningOrder: {
    physicianId: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    physicianNote: "",
    npi: "",
    stateMedicaidId: "",
    licenseNo: "",
    TaxonomyCode: "",
    brightreeId: 0,
    doctorGroupId: 0,

    address: {
      addressId: 0,
      addressLine1: "",
      addressLine2: "",
      city: "",
      sateId: 1,
      countryId: 1,
      zip: "",
      phone1: "",
      email: "",
      mobile1: "",
      fax: "",
    },
  }
};

export default class Newresident extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addWoundComponent: null,
      physicianModal:false,
      isPrimaryModal:false,
      formValidated: false,
      singingPhysicianList: [],
       singingPhysician: (this.props.Resident != null ?  (this.props.Resident.fkSigningPhysicianId > 0 ? { value: props.Resident.fkSigningPhysicianId,label : props.Resident.signingPhysicianName} : null)
        :this.props.facility.fkPhysicianId>0 && this.props.facility.singingForAllCmns == true? { value: this.props.facility.fkPhysicianId,label:this.props.facility.physicianName }:null  ),
      singingphysiciansInputValue: '',

      primaryPhysicianList: [],
      primaryPhysician: (this.props.Resident != null && this.props.Resident.fkPrimaryPhysicianId > 0 ? { value: props.Resident.fkPrimaryPhysicianId,
                    label : props.Resident.primaryPhysicianName} : null),
      primaryPhysiciansInputValue: '',

      residentStatus: [],
      physicianList: [],
      residentInsuranceList: this.props.Resident != null ? this.props.Resident.residentInsuranceList : [],
      addBtPhysicianComponent: '',
      addBtPhysicianModal: false,
      gender: [],
      states: [],
      isLoading: false,
      residentUpserting:false,
      Resident: JSON.parse(
        JSON.stringify(
          this.props.Resident != null
            ? this.props.Resident
            : _SampleResidentJson
        )
      ),
    };
    this.insuranceSelection = null;
  }

  isLoading = (val) => {
    this.setState({ isLoading: val });
  };

  componentDidMount() {

    try {
      this.setState({ isLoading: false });
      if (this.state.Resident.id == null ||  this.state.Resident.isHospicePatient==false) {


        var resident = this.state.Resident;
        resident.shippingAddress.addressId = this.props.facility.addressId;
        resident.shippingAddressId = this.props.facility.addressId;
        resident.shippingAddress.addressLine1 = this.props.facility.addressLine1;
        resident.shippingAddress.addressLine2 = this.props.facility.addressLine2;
        resident.shippingAddress.city = this.props.facility.city;
        resident.shippingAddress.stateId = this.props.facility.stateId;
        resident.shippingAddress.countryId = this.props.facility.countryId;

        resident.shippingAddress.zip = this.props.facility.zip;
        resident.shippingAddress.phone1 = this.props.facility.phone1;
        resident.shippingAddress.fax = this.props.facility.fax;
        resident.shippingAddress.mobile1 = this.props.facility.mobile1;
        resident.shippingAddress.email = this.props.facility.email;

        resident.fkSigningPhysicianId = this.props.facility.fkPhysicianId;
        resident.signingPhysicianName = Common.replaceNullWithString(this.props.facility.physicianName);
        debugger
        this.setState({ Resident: resident })

      }
      if (Common.replaceNullWithString(this.props.FacilityId) == "") {
        Common.showSuccessAlertAutoClose(
          "Facility Id not found",
          "warning",
          "Warning!",
          3000
        );

        this.props.hideModal();
      }

      // if(this.props.Resident != null){
      //     this.setState({Resident: JSON.parse(JSON.stringify(this.props.Resident))})
      // }

      this.getDropDownValueApiCall(7, "residentStatus");
      this.getDropDownValueApiCall(1006, "gender");
      this.GetPhysicianList();
      this.getAllState();
      this.setState({ isLoading: true });


    } catch (error) { }
  }
  handleCloseAdd = () => {
    this.setState({ addBtPhysicianModal: false })

  };
  
  selectedResidentInsurance = (insurances) => {

    this.setState({ residentInsuranceList: insurances })

  }
  handleShowAddSigningPhysician = () => {

    this.setState({
      addBtPhysicianComponent: <NewBrightreePhysician close={this.handleCloseAdd}
        selectPhysician={this.selectSigningPhysician} />
    })
    this.setState({ addBtPhysicianModal: true })
  };
  handleShowAddBrightreePhysician = () => {

    this.setState({
      addBtPhysicianComponent: <NewBrightreePhysician close={this.handleCloseAdd}
        selectPhysician={this.selectPhysician} />
    })
    this.setState({ addBtPhysicianModal: true })
  };
  handleHospiceChange = (e) => {
    Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, e.target.checked, "onHospice")

  }
  handleMedAChange = (e) => {
    Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, e.target.checked, "currentlyMedA")

    Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, false, "onHospice")


  }
  handleMedBChange = (e) => {
    Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, e.target.checked, "IsMedB")

  }
  handleECPChange = (e) => {
    Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, e.target.checked, "isEcp")

  }
  handleMedicateOnlyChange = (e) => {
    Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, e.target.checked, "isMedicaidOnly")

  }

  onChangeHospicePt = (e) => {
    Common.updateInputValueInState(
      this.state.Resident,
      e,
      this,
      this.state
    );
    var resident = this.state.Resident;
    if (e.target.checked) {
      resident.shippingAddress.addressId = null;
      resident.shippingAddress.addressLine1 = "";
      resident.shippingAddress.addressLine2 = "";
      resident.shippingAddress.city = "";
      resident.shippingAddress.stateId = null;
      resident.shippingAddress.zip = "";
      resident.shippingAddress.phone1 = "";
      resident.shippingAddress.fax = "";
      resident.shippingAddress.mobile1 = "";
      resident.shippingAddress.email = "";
      resident.shippingAddressId = null;

    } else {

      resident.shippingAddress.addressId = this.props.facility.addressId;
      resident.shippingAddressId = this.props.facility.addressId;
      resident.shippingAddress.addressLine1 = this.props.facility.addressLine1;
      resident.shippingAddress.addressLine2 = this.props.facility.addressLine2;
      resident.shippingAddress.city = this.props.facility.city;
      resident.shippingAddress.stateId = this.props.facility.stateId;
      resident.shippingAddress.zip = this.props.facility.zip;
      resident.shippingAddress.phone1 = this.props.facility.phone1;
      resident.shippingAddress.fax = this.props.facility.fax;
      resident.shippingAddress.mobile1 = this.props.facility.mobile1;
      resident.shippingAddress.email = this.props.facility.email;

    }
    this.setState({ Resident: resident });
  }
  formatSocialSecurity(val) {
    if (val != null) {
      val = val.replace(/\D/g, '');
      val = val.replace(/^(\d{3})/, '$1-');
      val = val.replace(/-(\d{2})/, '-$1-');
      val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
      return val;
    }
  }
  loadSingingPhysicians = async (inputValue) => {
    try {
      if (inputValue.length > 1) {

        var { data } = await Api.getRequestQry(
          Api.apiUrls.doctorsSearch, `?searchTerm=${inputValue}`
        );
        if (data.success) {
          let orgList = [];
          if (data.result.length) {
            orgList = data.result;
          }
          this.setState({ singingPhysicianList: orgList })
          // setOrganizations(orgList)
          return orgList.map((e) => {
            return {
              label: e.lastName + " " + e.firstName,
              value: e.brightreeId
            }
          });
        }
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  setSingingPhysicianInputValue = async (value) => {
    this.setState({ singingphysiciansInputValue: value });
  }
  setPhysician = (physician) => {
    debugger
    var _selectedPhysician = this.state.Resident;
    if(this.state.isPrimaryModal){
      this.setState({ primaryPhysician: {value:physician.physicianId,label:physician.lastName +" "+ physician.firstName}});
      _selectedPhysician.physicianViewModel = physician;
      _selectedPhysician.fkPrimaryPhysicianId = null;
      _selectedPhysician.primaryPhysicianNpi = physician?.npi;
     
    }else{
      this.setState({ singingPhysician: {value:physician.physicianId,label:physician.lastName +" "+ physician.firstName} });
      _selectedPhysician.physicianSigningOrder = physician;
      _selectedPhysician.fkSigningPhysicianId = null;
      _selectedPhysician.signingPhysicianNpi = physician?.npi;
    }
  }
  setSingingPhysician = (physician) => {
    this.setState({ singingPhysician: physician });
    var _selectedPhysician = this.state.Resident;
    if (physician != null) {
      var obj = this.state.singingPhysicianList.find(x => x.brightreeId == physician.value)
      debugger
      _selectedPhysician.physicianSigningOrder = obj;
      _selectedPhysician.fkSigningPhysicianId = null;
      _selectedPhysician.signingPhysicianNpi = obj?.npi;

      _selectedPhysician.signingPhysicianName = Common.replaceNullWithString(obj.lastName) + " " + Common.replaceNullWithString(obj.firstName);
    } else {

      _selectedPhysician.physicianSigningOrder = null;
      _selectedPhysician.fkSigningPhysicianId = null;
      _selectedPhysician.signingPhysicianName = '';
      _selectedPhysician.signingPhysicianNpi = null;

    }

    this.setState({ Resident: _selectedPhysician })
  }

  loadPrimaryPhysicians = async (inputValue) => {
    try {
      if (inputValue.length > 1) {

        var { data } = await Api.getRequestQry(
          Api.apiUrls.doctorsSearch, `?searchTerm=${inputValue}`
        );
        if (data.success) {
          let orgList = [];
          if (data.result.length) {
            orgList = data.result;
          }
          this.setState({ primaryPhysicianList: orgList })
          // setOrganizations(orgList)
          return orgList.map((e) => {
            return {
              label: e.lastName + " " + e.firstName,
              value: e.brightreeId
            }
          });
        }
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  setPrimaryPhysicianInputValue = async (value) => {
    this.setState({ primaryPhysiciansInputValue: value });
  }
  setPrimaryPhysician = (physician) => {
debugger
    this.setState({ primaryPhysician: physician });
    var _selectedPhysician = this.state.Resident;
    if (physician != null) {
      var obj = this.state.primaryPhysicianList.find(x => x.brightreeId == physician.value)
      _selectedPhysician.physicianViewModel = obj;
      _selectedPhysician.fkPrimaryPhysicianId = null;
      _selectedPhysician.primaryPhysicianNpi = obj?.npi;
      _selectedPhysician.fkPrimaryPhysicianId = null;

      _selectedPhysician.primaryPhysicianName = Common.replaceNullWithString(obj?.lastName) + " " + Common.replaceNullWithString(obj?.firstName);
    } else {

      _selectedPhysician.physicianViewModel = null;
      _selectedPhysician.fkPrimaryPhysicianId = null;
      _selectedPhysician.primaryPhysicianName = ''
      _selectedPhysician.primaryPhysicianNpi = null;
    }

    this.setState({ Resident: _selectedPhysician })
  }

 
  render() {
    return (
      <>
        {this.state.addBtPhysicianModal && <Animated animationIn="fadeInLeft" animationInDuration={1000}>

          {this.state.addBtPhysicianComponent}

        </Animated>}
        {!this.state.addBtPhysicianModal && <Animated animationIn="fadeIn" animationInDuration={1000}>
          {!this.state.isLoading == true 
         && (
            <div className="d-flex justify-content-center">
              <Loader
                type="Puff"
                color="#00BFFF"
                height={50}
                width={50}
              />
            </div>
          )
         }
         {
          (

            <Form
              ref={(ref) => {
                this.form = ref;
              }}
              validated={this.state.formValidated}
            >
              {this.state.isLoading == true &&
              <>
              <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                <legend className="text-primary fw-500">
                  Basic Information{" "}
                </legend>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Status">
                        Status
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="fkResidentStatusId"
                        name="fkResidentStatusId"
                        disabled={this.state.Resident.id == null ? true : false}
                        required
                        value={Common.replaceNullWithString(
                          this.state.Resident.fkResidentStatusId
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      >
                        <option></option>
                        {this.setOptionsUI(this.state.residentStatus)}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="firstName">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        className="form-control form-control-sm"
                        name="firstName"
                        defaultValue={this.state.Resident.firstName}
                        required
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="lastName">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        className="form-control form-control-sm"
                        name="lastName"
                        defaultValue={this.state.Resident.lastName}
                        required
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="gender">
                        Gender
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="gender"
                        name="gender"
                        value={Common.replaceNullWithString(
                          this.state.Resident.gender
                        )}
                        required
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      >
                        <option></option>
                        {this.setOptionsUI(this.state.gender)}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="doB">
                        DOB
                      </label>
                      <input
                        type="date"
                        id="doB"
                        className="form-control form-control-sm"
                        name="doB"
                        min='1900-01-01'
                        max={Common.getInputStringDateUsingMoment(moment())}
                        required
                        defaultValue={this.state.Resident.doB != null ? Common.getInputStringDate(
                          new Date(this.state.Resident.doB)
                        ) : ""}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="ssn">
                        SSN
                      </label>
                      <input

                        type="text"
                        id="ssn"
                        placeholder="123-45-6789"
                        className="form-control form-control-sm"
                        name="ssn"
                        // required
                        pattern="\d{3}-?\d{2}-?\d{4}"
                        value={this.formatSocialSecurity(
                          this.state.Resident.ssn
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="lastName">
                        Medicare
                      </label>
                      <input
                        type="text"
                        id="medicare"
                        className="form-control form-control-sm"
                        name="medicare"
                        defaultValue={this.state.Resident.medicare}
                        onChange={(e) =>{
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                          this.insuranceSelection.addMedicareInsurance(e.target.value)
                        }
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="unit">
                        Unit
                      </label>
                      <input
                        type="text"
                        id="unit"
                        className="form-control form-control-sm"
                        name="unit"
                        defaultValue={Common.replaceNullWithString(
                          this.state.Resident.unit
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="room">
                        Room
                      </label>
                      <input
                        type="text"
                        id="room"
                        className="form-control form-control-sm"
                        name="room"
                        defaultValue={Common.replaceNullWithString(
                          this.state.Resident.room
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="admissionDate">
                        Current Admission Date
                      </label>
                      <input
                        type="date"
                        id="admissionDate"
                        className="form-control form-control-sm"
                        name="admissionDate"
                        min='1900-01-01'
                        max={Common.getInputStringDateUsingMoment(moment())}
                        required
                        defaultValue={this.state.Resident.admissionDate != null ? Common.getInputStringDate(
                          new Date(this.state.Resident.admissionDate)
                        ) : ""}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      />
                    </div>
                  </div>


                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
               
                        <div className="form-group">
                          <label className="form-label" htmlFor="gender">
                            Primary Physician

                          </label>
                             <AsyncSelect
                            name='primaryPhysicianName'
                            noOptionsMessage={() => 
                              this.state.primaryPhysiciansInputValue.length>=3? 
                              <Button  variant="success" className="mr-5 px-10" size="sm"
                              onClick={() => this.setState({physicianModal:true, isPrimaryModal:true})}>New</Button>:""}
                            required
                            inputValue={this.state.primaryPhysiciansInputValue}
                            onInputChange={this.setPrimaryPhysicianInputValue}
                            value={this.state.primaryPhysician}
                            onChange={(obj) => {
                              this.setPrimaryPhysician(obj)
                            }}

                            loadOptions={this.loadPrimaryPhysicians}
                            isClearable
                          />
                      {/* <div className="col-sm-4 col-md-8 col-lg-4 col-xl-4">
                        <button type="button" className="mt-5  btn  btn-success"

                          onClick={() => { this.handleShowAddBrightreePhysician() }}
                        >
                          Search
                        </button>

                      </div> */}
                    </div>


                  </div>
{/* {this.state?.Resident?.primaryPhysicianNpi!=null && this.state?.primaryPhysician!=null && */}
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div className="form-group">
                          <label className="form-label" htmlFor="State">
                            NPI
                          </label>
                          <input
                            id="primaryPhysicianNpi"
                            name="primaryPhysicianNpi"
                            disabled
                            value={Common.replaceNullWithString(
                              this.state?.Resident?.primaryPhysicianNpi
                            )}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      {/* } */}
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="onHospice"
                          name="onHospice"
                          disabled={this.state.Resident.currentlyMedA == true || this.state.Resident.isMedicaidOnly == true ? true : false}
                          defaultChecked={Common.replaceNullWithString(
                            this.state.Resident.onHospice,
                            false
                          )}
                          onChange={(e) => this.handleHospiceChange(e)}
                        />
                        <span className="mr-3" />
                        Hospice
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="allergicToSulpha"
                          name="isEcp"
                          disabled={this.state.Resident.isMedB == true ? true : false}
                          defaultChecked={(Common.replaceNullWithString(
                            this.state.Resident.isEcp,
                            false)
                          )}
                          onChange={(e) =>
                            this.handleECPChange(e)
                          }
                        />
                        <span className="mr-3" />
                        ECP
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="allergicToSulpha"
                          name="currentlyMedA"
                          disabled={this.state.Resident.isMedB == true
                            || this.state.Resident.onHospice == true
                            || this.state.Resident.isMedicaidOnly == true ? true : false}

                          defaultChecked={Common.replaceNullWithString(
                            this.state.Resident.currentlyMedA,
                            false
                          )}
                          onChange={(e) => this.handleMedAChange(e)
                          }
                        />
                        <span className="mr-3" />
                        Med A/Skilled
                      </label>
                    </div>
                  </div>



                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="allergicToSulpha"
                          name="isMedB"
                          disabled={this.state.Resident.currentlyMedA == true
                            || this.state.Resident.isEcp == true
                            || this.state.Resident.isMedicaidOnly == true ? true : false}
                          defaultChecked={Common.replaceNullWithString(
                            this.state.Resident.isMedB,
                            false
                          )}
                          onChange={(e) => this.handleMedBChange(e)
                          }
                        />
                        <span className="mr-3" />
                        Med B
                      </label>
                    </div>
                  </div>

            

                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="allergicToSulpha"
                          name="isMedicaidOnly"
                          disabled={this.state.Resident.isMedB == true
                            || this.state.Resident.onHospice == true
                            || this.state.Resident.currentlyMedA == true ? true : false}
                          defaultChecked={Common.replaceNullWithString(
                            this.state.Resident.isMedicaidOnly,
                            false
                          )}
                          onChange={(e) => this.handleMedicateOnlyChange(e)
                          }
                        />
                        <span className="mr-3" />
                        Medicaid Only
                      </label>
                    </div>
                  </div>


                </div>
                <div className="row">
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="allergicToSulpha"
                          name="allergicToSulpha"
                          defaultChecked={Common.replaceNullWithString(
                            this.state.Resident.allergicToSulpha,
                            false
                          )}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident,
                              e,
                              this,
                              this.state
                            )
                          }
                        />
                        <span className="mr-3" />
                        Allergic to Sulfa
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="allergicToCollagen"
                          checked={Common.replaceNullWithString(
                            this.state.Resident.allergicToCollagen,
                            false
                          )}
                          id="allergicToCollagen"
                          className="m-1"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident,
                              e,
                              this,
                              this.state
                            )
                          }
                        />
                        <span className="mr-3" />
                        Allergic to Collagen
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group mt-0 mt-sm-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="allergicToTape"
                          checked={Common.replaceNullWithString(
                            this.state.Resident.allergicToTape,
                            false
                          )}
                          id="allergicToTape"
                          className="m-1"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident,
                              e,
                              this,
                              this.state
                            )
                          }
                        />
                        <span className="mr-3" />
                        Allergic to Tape
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group mt-0 mt-sm-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="allergicToIodine"
                          id="allergicToIodine"
                          checked={Common.replaceNullWithString(
                            this.state.Resident.allergicToIodine,
                            false
                          )}
                          className="m-1"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident,
                              e,
                              this,
                              this.state
                            )
                          }
                        />
                        <span className="mr-3" />
                        Allergic to Iodine
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="allergicToLatex"
                          name="allergicToLatex"
                          defaultChecked={Common.replaceNullWithString(
                            this.state.Resident.allergicToLatex,
                            false
                          )}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident,
                              e,
                              this,
                              this.state
                            )
                          }
                        />
                        <span className="mr-3" />
                        Allergic to Latex
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="allergicToHoney"
                          name="allergicToHoney"
                          defaultChecked={Common.replaceNullWithString(
                            this.state.Resident.allergicToHoney,
                            false
                          )}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident,
                              e,
                              this,
                              this.state
                            )
                          }
                        />
                        <span className="mr-3" />
                        Allergic To Honey
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                    <legend className="text-primary fw-500">
                      Address Information
                    </legend>
                    <div className="row">

                      <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <div className="form-group mt-0 mt-sm-3">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="isHospicePatient"
                              id="isHospicePatient"
                              checked={Common.replaceNullWithString(
                                this.state.Resident.isHospicePatient,
                                false
                              )}
                              className="m-1"
                              onChange={(e) =>
                                this.onChangeHospicePt(e)
                              }
                            />
                            <span className="mr-3" />
                            Alternative Shipping Address
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="State">
                            Address Line 1
                          </label>
                          <input
                            id="addressLine1"
                            name="addressLine1"
                            disabled={this.state.Resident.isHospicePatient == false ? true : false}
                            value={Common.replaceNullWithString(
                              this.state.Resident.shippingAddress.addressLine1
                            )}
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.shippingAddress,
                                e,
                                this,
                                this.state
                              )
                            }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="addressLine2">
                            Address Line 2
                          </label>
                          <input
                            id="addressLine2"
                            name="addressLine2"
                            disabled={this.state.Resident.isHospicePatient == false ? true : false}
                            value={Common.replaceNullWithString(
                              this.state.Resident.shippingAddress.addressLine2
                            )}
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.shippingAddress,
                                e,
                                this,
                                this.state
                              )
                            }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="city">
                            City
                          </label>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            disabled={this.state.Resident.isHospicePatient == false ? true : false}
                            value={Common.replaceNullWithString(
                              this.state.Resident.shippingAddress.city
                            )}
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.shippingAddress,
                                e,
                                this,
                                this.state
                              )
                            }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="State">
                            State
                          </label>
                          <select
                            className="form-control form-control-sm"
                            name="stateId"
                            disabled={this.state.Resident.isHospicePatient == false ? true : false}
                            value={Common.replaceNullWithString(
                              this.state.Resident.shippingAddress.stateId
                            )}
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.shippingAddress,
                                e,
                                this,
                                this.state
                              )
                            }
                          >
                            <option></option>
                            {this.setOptionsUI(this.state.states)}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="zip">
                            Postal code
                          </label>
                          <input
                            type="text"
                            id="zip"
                            name="zip"
                            disabled={this.state.Resident.isHospicePatient == false ? true : false}
                            value={Common.replaceNullWithString(
                              this.state.Resident.shippingAddress.zip
                            )}
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.shippingAddress,
                                e,
                                this,
                                this.state
                              )
                            }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="countryId">
                            Country
                          </label>
                          <select
                            className="form-control form-control-sm"
                            name="countryId"
                            disabled={this.state.Resident.isHospicePatient == false ? true : false}
                            value={Common.replaceNullWithString(
                              this.state.Resident.shippingAddress.countryId
                            )}
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.shippingAddress,
                                e,
                                this,
                                this.state
                              )
                            }
                            id="countryId"
                          >
                            <option></option>
                            <option value="1">United States</option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </fieldset>
                </div>

              </div>
              </>
                }
              <fieldset className={"p-2 mb-2 bg-light border border-dark rounded " + (this.state.isLoading == true?"":"d-none")}>
                <legend className="text-primary fw-500">
                  Insurance Information{" "}
                </legend>
               

                <Insurance ref={(ref) => this.insuranceSelection = ref} residentID={this.state.Resident.id}
                  residentInsuranceList={this.state.residentInsuranceList}
                  selectInsurance={this.selectedResidentInsurance} />
              </fieldset>
              {this.state.isLoading == true &&
              <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                <legend className="text-primary fw-500">
                  Physician Signature Information{" "}
                </legend>
                <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="row">
                      <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="form-group">
                          <label className="form-label" htmlFor="gender">
                            Physician Signing Order (CMN)

                          </label>
                          {/* <input
                                                    type="text"
                                                    id="primaryPhysicianName"
                                                    className="form-control form-control-sm"
                                                    name="signingPhysicianName"
                                                    disabled
                                                    value={Common.replaceNullWithString(
                                                        this.state.Resident.signingPhysicianName)
                                                    }
                                                /> */}
                          <AsyncSelect
                            name='organizationID'
                            noOptionsMessage={() => 
                              this.state.singingphysiciansInputValue.length>=3? 
                              <Button  variant="success" className="mr-5 px-10" size="sm"
                              onClick={() => this.setState({physicianModal:true, isPrimaryModal:false})}>New</Button>:""}
                            required
                            inputValue={this.state.singingphysiciansInputValue}
                            onInputChange={this.setSingingPhysicianInputValue}
                            value={this.state.singingPhysician}
                            isDisabled={this.props.facility.singingForAllCmns == true ? true : false}
                            onChange={(obj) => {
                              this.setSingingPhysician(obj)
                            }}

                            loadOptions={this.loadSingingPhysicians}
                            isClearable
                          />

                        </div>
                      </div>
                      {/* <div className="col-sm-4 col-md-8 col-lg-4 col-xl-4">
                        <button type="button" className="mt-5  btn  btn-success"
                          disabled={this.props.facility.singingForAllCmns == true ? true : false}
                          onClick={() => { this.handleShowAddSigningPhysician() }}
                        >
                          Search
                        </button>

                      </div> */}
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div className="form-group">
                          <label className="form-label" htmlFor="State">
                            NPI
                          </label>
                          <input
                            id="signingPhysicianNpi"
                            name="signingPhysicianNpi"
                            disabled
                            value={Common.replaceNullWithString(
                              this.state?.Resident?.signingPhysicianNpi
                            )}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="LastName">Contact Person For Physician's Orders and Preferred Method</label>
                      <input type="text" id="ContactPerson"
                        name="signingContactPerson"
                        value={Common.replaceNullWithString(
                          this.state.Resident.signingContactPerson)
                        }
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="FirstName">Number/Fax Number</label>
                      <input type="text" id="FirstName"
                        name="signingPhysicianPhone"
                        value={Common.getFormattedPhoneNum(Common.replaceNullWithString(
                          this.state.Resident.signingPhysicianPhone))
                        }
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="NPI">Email Address</label>
                      <input type="text" id="NPI"
                        name="signingPhysicianEmail"
                        value={Common.replaceNullWithString(
                          this.state.Resident.signingPhysicianEmail)
                        }
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                </div>


              </fieldset>
  }
            </Form>
          ) 

          }
                 {this.state.physicianModal && (
        <AddEditPhysician
          onSaveAPI={this.setPhysician}
          open={this.state.physicianModal}
          toggleModal={()=>this.setState({physicianModal:false})}
        />
      )}
            
        </Animated>}
      </>
    );
  }

  //Function

  //#region
  GetPhysicianList() {
    Api.getRequest(Api.URI_GetAllPhysician)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ physicianList: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Resident", "GetPhysicianList");
      });
  }

  setPhysicianListUI(list) {
    return list.map((state) => (
      <option key={state.physicianId} value={state.physicianId}>
        {Common.replaceNullWithString(state.lastName) +
          " " +
          Common.replaceNullWithString(state.firstName)}
      </option>
    ));
  }
  //#endregion

  //#region  DropDownValue

  getDropDownValueApiCall(id, state) {
    Api.getRequestById(Api.Uri_GetLookupValuesByFkId, id)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ [state]: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {

        Common.LogError(ex, "Resident", "getDropDownValueApiCall");

      });
  }

  //#endregion

  //#region Get States
  getAllState() {
    Api.getRequest(Api.Uri_GetState)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ states: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Resident", "GetAllState");
      });
  }

  //#endregion

  //#region  set Options

  setOptionsUI(options) {
    return options.map((option) => (
      <option key={option.key} value={option.key}>
        {option.value}
      </option>
    ));
  }

  //#endregion

  //#region Create Resident

  handleSubmit = (event) => {
    // // debugger;
    if (this.form.checkValidity() === false) {
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      // let obj = this.state.Resident;
      this.UpsertResidentApiCall()
      // if(obj.id>0){
      //   this.UpsertResidentApiCall()
      // }else{
      //   this.handleResidentSearchFC();
      // }
      
    }
  };

  UpsertResidentApiCall(btPatientBrightreeID=null, residentBtKey=null) {

    this.isLoading(false);
    this.props.setResidentLoading(true)
    let obj = this.state.Resident;
    obj.signingPhysicianPhone = Common.replaceNullWithString(obj.signingPhysicianPhone) != ""
      ? obj.signingPhysicianPhone.replace(/[^0-9]/g, "").substr(0, 10)
      : null;

    if (obj.billingAddress != null) {
      obj.billingAddress.phone1 =
        Common.replaceNullWithString(obj.billingAddress.phone1) != ""
          ? obj.billingAddress.phone1.replace(/\D/g, "").substr(0, 10)
          : null;
      obj.billingAddress.mobile1 =
        Common.replaceNullWithString(obj.billingAddress.mobile1) != ""
          ? obj.billingAddress.mobile1.replace(/\D/g, "").substr(0, 10)
          : null;
    }

    if (obj.raddress != null) {
      obj.raddress.phone1 =
        Common.replaceNullWithString(obj.raddress.phone1) != ""
          ? obj.raddress.phone1.replace(/\D/g, "").substr(0, 10)
          : null;
      obj.raddress.mobile1 =
        Common.replaceNullWithString(obj.raddress.mobile1) != ""
          ? obj.raddress.mobile1.replace(/\D/g, "").substr(0, 10)
          : null;
    }

    if (obj.shippingAddress != null) {
      obj.shippingAddress.phone1 =
        Common.replaceNullWithString(obj.shippingAddress.phone1) != ""
          ? obj.shippingAddress.phone1.replace(/\D/g, "").substr(0, 10)
          : null;
      obj.shippingAddress.mobile1 =
        Common.replaceNullWithString(obj.shippingAddress.mobile1) != ""
          ? obj.shippingAddress.mobile1.replace(/\D/g, "").substr(0, 10)
          : null;
    }
    obj.ssn =
      Common.replaceNullWithString(obj.ssn) != ""
        ? obj.ssn.replace(/[^0-9]/g, "").substr(0, 9)
        : null;
    debugger
    obj.fkFacilityId = this.props.FacilityId;
    obj.createdBy = this.props.userID;
    obj.modifiedBy = this.props.userID;
    obj.btPatientBrightreeID = btPatientBrightreeID;
    obj.residentBtKey = residentBtKey;
    obj.residentInsuranceList = this.insuranceSelection?.getInsuranceList();
    Api.postRequest(Api.URI_UpsertResident, obj)
      .then((res) => {
        debugger
        if (res.data.success) {
        
          this.props.setResidentLoading(false)
            Common.showSuccessAlertAutoClose(
              "Resident Created Successfully",
              "success",
              "Success!",
              3000
            );
            this.props.close();
            if(obj.id==null){
                  this.isLoading(true);
              if (res.data.result != null) {
              obj.id=res.data.result.id
              }else {
                Common.showSuccessAlert(
                  res.data.message,
                  "warning",
                  "Failed!",
                  3000
                );
              }
            }
           
          } else {
            this.props.setResidentLoading(false)
            Common.showSuccessAlert(
              res.data.message,
              "warning",
              "Failed!",
              3000
            );
          }
       
      })
      .catch((ex) => {
        this.props.setResidentLoading(false)
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
        Common.LogError(ex, "Resident", "UpsertResident");

      })
      .then(() => {
        this.isLoading(true);
      });
  }
  handleResidentSearchFC =async () => {
    this.isLoading(false);
    this.props.setResidentLoading(true)
    let obj = this.state.Resident;
    obj.ssn =
      Common.replaceNullWithString(obj.ssn) != ""
        ? obj.ssn.replace(/[^0-9]/g, "").substr(0, 9)
        : null;
    var {data} = await Api.getRequestQry(
         Api.Uri_ResidentSearchFC,
         "?facilityId=" + this.props.FacilityId+ "&lastName=" + obj.lastName +"&ssn="+obj.ssn
         +"&dob="+obj.doB
     )
         // .then((res) => {
             if (data.result.isMatchInFC==true) {
        var displayDenayButton = obj.ssn.length>0?false:true;
        this.isLoading(true);
        this.props.setResidentLoading(false);
              Common.showConfirmation(data.result.message, "", this.showResidentAlreadyOnFileInFC, null, data.result,"Yes",
              displayDenayButton,displayDenayButton==true?this.userTapedNoAddResidentWhenAlreadyExistsInFC:null)
             }else{
              this.handleResidentSearchBT()
             }
 }
 showResidentAlreadyOnFileInFC=(result)=>{
  let obj = this.state.Resident;
  if(obj.ssn.length>0){
if(result.isOpen){
  Common.showSuccessAlert(
    "This Resident already exists in FC with this DOB and SSN and the Resident Status is Open. Navigate to the Facility Resident Wound screen in the Work Queue to process this Resident",
    "success",
    "Success!",
    3000
  );
}else{
  Common.showSuccessAlert(
    "There is a Resident which already exists in FC with this DOB and SSN but the  Resident Status is not Open. Navigate to tap the Facility Resident screen and tap on the Find Resident button at the top of the Facility Resident screen. Find the Resident and tap on the tap on Set Resident Status and select Open. Then close the Search Resident function and navigate to the Resident.",
    "success",
    "Success!",
    3000
  );
}
  }else{
    Common.showSuccessAlert(
      "There is a Resident which already exists in FC with this DOB and Last Name.  If the Resident Status is not Open, navigate to tap the “Find Resident” button at the top of the Facility Resident screen and set the Resident Status to Open. If the Status is Open, then the Resident is already available to process. Navigate to the Facility Resident Wound screen in the Work Queue to process this Resident",
      "success",
      "Success!",
      3000
    );
  }

 }
 userTapedNoAddResidentWhenAlreadyExistsInFC=()=>{

  Common.showConfirmation("Did you still want to add this new Resident to FC? This action will create a this new Resident in BT and FC", "", this.handleResidentSearchBT, null, null)

 }

 handleResidentSearchBT =async () => {
  debugger
  let obj = this.state.Resident;
  obj.ssn =
      Common.replaceNullWithString(obj.ssn) != ""
        ? obj.ssn.replace(/[^0-9]/g, "").substr(0, 9)
        : null;
  var {data} = await Api.getRequestQry(
       Api.Uri_ResidentSearchBt,
       "?facilityId=" + this.props.facility.brightreeId+ "&lastName=" + obj.lastName +"&ssn="+obj.ssn
       +"&dob="+obj.doB
   )
   debugger
       // .then((res) => {
        var displayDenayButton = obj?.ssn!="" || obj?.ssn!=null?false:true;
           if (data.result.isMatchInBt==true ) {
      if(displayDenayButton==true){
        Common.showConfirmation(data.result.message, "", this.showResidentAlreadyOnFileInBt, null, data.result,"Yes",true,this.UpsertResidentApiCall)

      }else{
       this.UpsertResidentApiCall(data.result.residentBtId,data.result.residentBtKey)

      }
           }else{
            this.UpsertResidentApiCall()
           }
}
showResidentAlreadyOnFileInBt=(residentDetail)=>{
 this.UpsertResidentApiCall(residentDetail.residentBtId,residentDetail.residentBtKey)
 }
} //end of class
