/* eslint-disable */
import React, { useCallback, useRef } from 'react';
import { Button, Form } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { Card, CardBody, CardFooter, CardHeader, CardHeaderToolbar } from "../../../_metronic/_partials/controls/Card";
// import './App.css';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { toJpeg } from "html-to-image";
import uuid from 'node-uuid'
import moment from 'moment';
import Select, { createFilter } from "react-select";
import { showSuccessAlertAutoClose } from '../../General/common'
const data = [
  {
    name: "20/1/22",
    uv: 100
  },
  {
    name: "21/1/22",
    uv: 70
  },
  {
    name: "22/1/22",
    uv: 50
  },
  {
    name: "23/1/22",
    uv: 30
  },
  {
    name: "24/1/23",
    uv: 10
  },
  {
    name: "25/1/22",
    uv: 0
  }
];
var _sampleWoundObj = {
  residentName: '',
  physicanName: '',
  woundLocation: '',
  woundType: '',
  woundEvaluationDetail: []
}
export default class WoundAreaTrendReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValidated: false,
      facilityName: null,
      residentName: null,
      woundType: null,
      facilityList: [],
      facilityResidentList: [],
      residentWoundList: [],
      IsLoading: false,
      showReport: false,
      wound: {
        FacilityId: null,
        ResidentId: null,
        WoundId: null,
        fromDate: "",
        toDate: ""
      },
      woundEvaluationList: [],
      woundEvaluations: {
        residentName: '',
        physicanName: '',
        woundLocation: '',
        woundType: '',
        woundEvaluationDetail: [],
        evaluationDateArray: []
      }

    }
  }
  componentDidMount() {
    this.getAllFacility();
  }
  getAllFacility() {
    Api.getRequest(`${Api.Uri_GetFacility}?isAssessmentView=1`,)
      .then((res) => {

        if (res.data.statusCode == 200) {
          if (res.data.result != null) {

            if (res.data.result.length > 0) {
              debugger
              let facilityList = res.data.result.map(facility => ({ value: facility.id, label: facility.facilityName })).sort((a, b) => a.label.localeCompare(b.label));

              // let facilityList = res.data.result.map(v => (
              //   <option key={v.id} value={v.id}>
              //     {v.facilityName}
              //   </option>
              // ));
              this.setState({ facilityList: facilityList, IsLoading: true })
            }
          }
        }
      }).catch((ex) => {
      });
  }
  onFacilitiChange = (e) => {

    this.setState({
      facilityResidentList: [],
      residentWoundList: [],
      residentName: null,
      woundType: null,
      facilityName: e,
    })

    if (e) {
      Api.getRequestQry(Api.Uri_GetResidentByFacilityId, "?facilityId=" + e.value)
        .then((res) => {
          if (res.data.statusCode == 200) {

            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                const data = res.data.result;

                console.log(data)
                // let optionTemplate = data.map((v) => (
                //   <option key={v.key} value={v.key}>
                //     {v.value}
                //   </option>
                // ));
                this.setState({
                  facilityResidentList: data.map((item) => {
                    return { value: item.key, label: item.value }
                  })
                })
              }
            }
          }
        })
        .catch((ex) => {


        })
        .then(() => {

        });
    }
  }
  onResidentChange = (e) => {
    debugger
    // Common.updateInputValueInState(this.state.wound, e, this, this.state)
    this.setState({
      residentWoundList: [],
      woundType: null,
      residentName: e,
    }, () => {

    })
    this.setState({ wound: { ...this.state.wound, residentId: e.value, woundId: e ? e.value : null} })

    console.log(this.state.wound)

    if (e) {
      // this.setState({ wound: { ...this.state.wound, residentId: e.value } })
      Api.getRequestById(Api.URI_GetResidentWoundByResidentID, e.value)
        .then((res) => {
          if (res.data.statusCode == 200) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                debugger
                // let residentWoundList = res.data.result.map(v => (
                //   <option key={v.wound.id} value={v.wound.id}>
                //     {Common.replaceNullWithString(v.wound.bodyRegion)}{v.residentWoundLocation != null ? "/" : ""}{Common.replaceNullWithString(v.residentWoundLocation)}
                //   </option>
                // ));

                let residentWoundList = res.data.result.map((item) => {
                  return { value: item.wound.id, label: `${Common.replaceNullWithString(item.wound.bodyRegion)}${(item.residentWoundLocation != null && item.wound.bodyRegion != null) ? "/" : ""}${Common.replaceNullWithString(item.residentWoundLocation)}` }
                })

                residentWoundList.unshift(
                  { value: 'all', label: 'All' }
                );
                this.setState({ residentWoundList: residentWoundList })

              }
            }
          }
        }).catch((ex) => {
          Common.LogError(ex, "ResidentWound", "Get");
        });
    }

  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ showReport: false })

      let obj = this.state.wound;

      var query = "?toDate=" + obj.toDate + "&fromDate=" + obj.fromDate + "&residentId=" + obj.residentId

      if (obj.woundId != null && obj.woundId != 'all') {
        query += `&woundId=${obj.woundId}`
      }


      Api.getRequestQry(Api.Uri_GetRptWoundAreaTrend, query)
        .then((res) => {

          if (res.data.statusCode == 200) {

            if (res.data.result != null && res.data.result.length == 0) {
              showSuccessAlertAutoClose('Record not found', 'warning', 'warning!', 3000)
            }
            debugger
            if (res.data.result != null) {

              let woundEvaluationList = [];

              for (let woundIndex in res.data.result) {
                let woundEvaluation = res.data.result[woundIndex];
                let evaluationDateArray = [];
                var uvc = 0

                for (let woun of woundEvaluation.woundEvaluationDetail) {
                  uvc += 10;
                  evaluationDateArray.push({
                    name: Common.getInputStringDateUsingMoment2(moment.utc(woun.evaluationDate).local()),
                    uv: uvc
                  });
                }

                let tmpEvaluation = {
                  residentName: woundEvaluation.residentName,
                  physicanName: woundEvaluation.physicanName,
                  woundLocation: woundEvaluation.woundLocation,
                  woundType: woundEvaluation.woundType,
                  woundEvaluationDetail: woundEvaluation.woundEvaluationDetail,
                  evaluationDateArray: evaluationDateArray
                };

                woundEvaluationList.push(tmpEvaluation);
              }


              this.setState({ woundEvaluationList, showReport: true })
            } else {
              Common.showSuccessAlert("Record not found", "warning", "Warnng!", 3000);

              this.setState({ showReport: false })

            }
          }
        })
        .catch((ex) => {


        })
        .then(() => {

        });
  }

  handleClearFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      facilityName: null,
      residentName: null,
      woundType: null,
      facilityResidentList: [],
      residentWoundList: [],
      wound: { fromDate: "", toDate: "" },
      showReport: false
    })
  }

  renderWoundForm() {
    return (
      <>{
        (this.state.IsLoading == true) ?
          <form onSubmit={e => this.handleSubmit(e)}>
            <div className='row'>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="FacilityName">Facility Name</label>
                  <Select
                    options={this.state.facilityList}
                    id="facilityId"
                    name="facilityId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={(value) => {
                      console.log(value)
                      this.onFacilitiChange(value)
                    }}
                    value={this.state.facilityName}
                  // onChange={e => setFacilitySelected({ value: e?.value, label: e?.label })}
                  />
                </div>
                {/* <div className="form-group">
                  <label className="form-label" for="FacilityName">Facility Name</label>

                  <select className="form-control form-control-sm"
                    id="facilityId"
                    name="facilityId"
                    required
                    onChange={e => this.onFacilitiChange(e)}
                    value={Common.replaceNullWithString(this.state.wound.facilityId)}
                  >
                    <option></option>
                    {this.state.facilityList}
                  </select>
                </div> */}
              </div>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="ResidentId">Resident Name</label>
                  <Select
                    options={this.state.facilityResidentList}
                    id="residentId"
                    name="residentId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={(value) => {
                      console.log(value)

                      this.onResidentChange(value)
                    }}
                    value={this.state.residentName}
                  // onChange={e => setFacilitySelected({ value: e?.value, label: e?.label })}
                  />
                </div>
                {/* <div className="form-group">
                  <label className="form-label" for="FacilityName">Facility Name</label>

                  <select className="form-control form-control-sm"
                    id="facilityId"
                    name="facilityId"
                    required
                    onChange={e => this.onFacilitiChange(e)}
                    value={Common.replaceNullWithString(this.state.wound.facilityId)}
                  >
                    <option></option>
                    {this.state.facilityList}
                  </select>
                </div> */}
              </div>
              {/* <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" for="PatientName">Resident Name</label>
                  <select className="form-control form-control-sm"
                    id="residentId"
                    name="residentId"
                    onChange={e => this.onResidentChange(e)}
                    value={Common.replaceNullWithString(this.state.wound.residentId)}
                  >
                    <option></option>
                    {this.state.facilityResidentList}
                  </select>
                </div>
              </div> */}

              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="WoundId">Wound</label>
                  <Select
                    options={this.state.residentWoundList}
                    id="woundId"
                    name="woundId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={e => {
                      this.setState({ woundType: e });
                      this.setState({ wound: { ...this.state.wound, woundId: e ? e.value : null } })
                    }}
                    value={this.state.woundType}
                  // onChange={e => setFacilitySelected({ value: e?.value, label: e?.label })}
                  />
                </div>
                {/* <div className="form-group">
                  <label className="form-label" for="FacilityName">Facility Name</label>

                  <select className="form-control form-control-sm"
                    id="facilityId"
                    name="facilityId"
                    required
                    onChange={e => this.onFacilitiChange(e)}
                    value={Common.replaceNullWithString(this.state.wound.facilityId)}
                  >
                    <option></option>
                    {this.state.facilityList}
                  </select>
                </div> */}
              </div>
              {/* <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" for="WoundType">Wound </label>
                  <select className="form-control form-control-sm"
                    id="woundId"
                    name="woundId"
                    // onChange={e => this.onWoundTypeChange(e)}
                    onChange={e => Common.updateInputValueInState(this.state.wound, e, this, this.state)}

                    value={Common.replaceNullWithString(this.state.wound.woundId)}
                  >
                    <option></option>
                    {this.state.residentWoundList}
                  </select>
                </div>
              </div> */}
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" for="ToDate">From Date</label>
                  <input type='date' id='FromDate'
                    name="fromDate"
                    min='1900-01-01'
                    max="3999-12-31"
                    required
                    value={this.state.wound.fromDate}
                    onChange={e => {
                      console.log(e.target.value)
                      this.setState({ wound: { ...this.state.wound, fromDate: e.target.value } })

                    }}

                      // Common.updateInputValueInState(this.state.wound, e, this, this.state)}

                    
                    className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" for="ToDate">To Date</label>
                  <input type='date' id='ToDate'
                    name="toDate"
                    min='1900-01-01'
                    max="3999-12-31"
                    required
                    onChange={e => Common.updateInputValueInState(this.state.wound, e, this, this.state)}
                    value={this.state.wound.toDate}
                    className="form-control form-control-sm" />
                </div>
              </div>

            </div>
            <div className='d-flex justify-content-end py-3'>
              {/* <button className='btn btn-primary btn-sm mr-3' onClick={e => this.handleSubmit(e)}>Search Report</button> */}
              <Button type='submit' variant="success" className="mr-5 px-10" size="sm">Search Report</Button>
              <button className='btn btn-danger btn-sm' onClick={(e) => this.handleClearFilters(e)}>Clear filter</button>

            </div>
          </form> :
          <div className="d-flex justify-content-center">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
      }
      </>
    );
  }
  render() {





    function PrintElem() {


      let elementProcessed = 0;
      let heightFromTop = 30;
      let elementsHeight = 400;
      let sheetHeight = 506;
      let widthFromLeft = 50;
      let doc = new jsPDF('p', 'pt', 'a4', false);
      const data = document.querySelectorAll("div[id^='tableForPrint-']");
      console.log(data)
      const canvas = document.getElementById('c');
      const ctx = canvas.getContext('2d');

      Array.from(data).forEach((item, index) => {

        console.log(item)

        html2canvas(item, { quality: 3, scale: 3 }).then(canvasItem => {

          canvas.width = 1236
          canvas.height = 900
          ctx.scale(1, 1);
          ctx.drawImage(canvasItem, 0, 0, 1236, 900);

          heightFromTop = 30;
          const imgData = canvas.toDataURL('image/png');

          doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetHeight, elementsHeight, index, 'NONE');

          elementProcessed++;

          if (elementProcessed != data.length) {
            doc.addPage();
            heightFromTop = 30;
          }

          if (elementProcessed === data.length) {
            const pageCount = doc.internal.getNumberOfPages();
            doc.setFont('Poppins', 'normal', 'bold');
            doc.setFontSize(8);
            doc.setTextColor(112, 108, 108);
            for (var i = 1; i <= pageCount; i++) {
              doc.setPage(i)
              doc.text('Page ' + i + ' of ' + pageCount, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 20);
            }
            doc.save("download.pdf");

          }

        });
      });

      // html2canvas(document.querySelector("#printit")).then(canvas => {
      //   // document.body.appendChild(canvas);  // if you want see your screenshot in body.

      //   const imgData = canvas.toDataURL('image/png');
      //   const pdf = new jsPDF('p', 'pt', 'a4', false);
      //   pdf.addImage(imgData, 'PNG', 10, 10, 576, 0, undefined, false);

      //   pdf.save("download.pdf");
      // })
      // // window.print();
      // // const elem = "printit";
      // // var mywindow = window.open("", "PRINT", "height=400,width=600");
      // // // if (mywindow == null) return;
      // // // mywindow.document.write(
      // // //   "<html><head><title>" + document.title + "</title>"
      // // // );
      // // mywindow.document.write("</head><body >");
      // // // mywindow.document.write(document.getElementById(elem).innerHTML);
      // // mywindow.document.write("</body></html>");

      // // // mywindow.document.close(); // necessary for IE >= 10
      // // mywindow.focus(); // necessary for IE >= 10*/

      // // mywindow.print();
      // // // mywindow.close();

      // // return true;
    }


    return (

      <>
        <Card>
          <CardHeader className='Text-center' title='Wound Area Trend Report'>
            <CardHeaderToolbar>
              <div className="example-tools">

              </div>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          {this.renderWoundForm()}
            <hr />
            {this.state.showReport &&
              <>
                <div className='row'>
                  <div className='col-12'>
                    <button type="button" onClick={PrintElem} className="mr-3 btn btn-success btn-sm float-right">Print Report</button>
                  </div>
                </div>
                <div className='trend-report'>
                  {
                    this.state.woundEvaluationList.map((woundEvalutation, index) => {
                      const elementId = `tableForPrint-${index}`;
                      return <div className='trend-report_content'>
                        <div id={elementId}>

                          <h2 className='text-center mb-10 mt-5 text-primary'>Wound Area Trend Report</h2>
                          <div className='border border-dark px-3 pt-3'>
                            {/* <h2 className='text-center mt-4'>Wound Area Trend Report</h2> */}
                            <table className='table border-0 table-sm trendReportInfo'>
                              <tbody className='font-weight-bolder'>
                                <tr>
                                  <td>Name:</td>
                                  <td>{woundEvalutation.residentName}</td>
                                </tr>
                                <tr>
                                  <td>MD Name:</td>
                                  <td>{woundEvalutation.physicanName}</td>
                                </tr>
                                <tr>
                                  <td>Location of Wound:</td>
                                  <td>{woundEvalutation.woundLocation}</td>
                                </tr>
                                <tr>
                                  <td>Type of Wound:</td>
                                  <td>{woundEvalutation.woundType}</td>
                                </tr>
                                <tr>
                                  <td>Date today:</td>
                                  <td>{Common.getInputStringDateUsingMoment2(moment.utc(woundEvalutation.dateToday).local())}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className='mt-10'>
                            <table className='table table-bordered table-sm w-100'>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Length</th>
                                  <th>Width</th>
                                  <th>Area</th>
                                  <th>Push Score</th>
                                </tr>
                              </thead>
                              <tbody>
                                {woundEvalutation.woundEvaluationDetail?.map((wound) => {
                                  debugger
                                  return (

                                    <tr key={uuid()}>
                                      <td>{Common.getInputStringDateUsingMoment2(moment.utc(wound.evaluationDate).local())}</td>
                                      <td>{wound.woundLength}</td>
                                      <td>{wound.woundWidth}</td>
                                      <td>{wound.woundArea}</td>
                                      <td>{wound.pushScore}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <h2 className='text-center mt-10'>Evalution Dates</h2>
                          <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                              style={{ width: "100% !important" }}
                              data={woundEvalutation.evaluationDateArray}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 10
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Line type="monotone" dataKey="uv" stroke="#3699FF" />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    })}
                </div>

              </>
            }
          </CardBody>
        </Card>

        <canvas id='c' width='100' height='100' style={{ display: 'none' }}></canvas>

      </>

    );
  }
}