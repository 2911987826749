import React, { useCallback, useEffect, useState } from 'react';
// import { Animated } from "react-animated-css";
import { FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Button,Col, Row, Badge, ButtonToolbar, Dropdown, DropdownButton, Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
export default class AddTracheostomy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tracheostomy: props.tracheostomy,

        }
    }

    componentDidMount() {

    }
    // const [diagCodes, setdiagCodes] = useState([{ value: "lleostomy Z93.2", label: "lleostomy Z93.2" }, { value: "Urostomy Z93.6", label: "Urostomy Z93.6" }, { value: "Colostomy Z93.3", label: "Colostomy Z93.3" }, { value: "Encounter for Colostomy Z43.3", label: "Encounter for Colostomy Z43.3" }, { value: "Encounter for Urostomy Z43.6", label: "Encounter for Urostomy Z43.6" }, { value: "Encounter for lleostomy Z43.2", label: "Encounter for lleostomy Z43.2" }]);
    handleSubmit = (event) => {
        //1243 is unstageable check

        // let selectedProducts = this.productSelection.getSelectedProductList();
        const form = event.currentTarget.form;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {

            this.URI_UpsertResidentTracheostomy();


        }
    }

    URI_UpsertResidentTracheostomy() {
        let obj = this.state.tracheostomy;
        debugger
        if(obj.tracheostomyType==2){
            obj.isEstablishedTracheostomy=true;
            obj.isNewTracheostomy=false;

        }else{
            obj.isNewTracheostomy=true;
            obj.isEstablishedTracheostomy=false;
        }
       
        Api.postRequest(Api.URI_UpsertTracheostomySaleOrder, obj).then((res) => {
            debugger
            if (res.data.success) {
                if (res.data.result != null) {


                    Common.showSuccessAlertAutoClose("Tracheostomy Order Created/Updated Successfully", "success", "Success!", 3000);
                    // this.props.refresh && this.props.refresh();
                    this.props.close();


                    //this.props.close();
                }
                else {
                    Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {

            // // // debugger
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }
    render() {
        return (
            <>
                <Form >


                    {/* <Form> */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div>
                                <fieldset className="p-2 mb-2 border rounded">
                                    <legend className="text-primary fw-500">
                                        Information{" "}
                                    </legend>
                                    {/* <div className='d-flex justify-content-left'>
                                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                                    <label className="checkbox text-danger">
                                        <input
                                            type="checkbox"
                                            className="m-1"
                                        />
                                        <span className="mr-3 " />
                        Reorder
                      </label>
                                </div>
                            </div> */}
                                    <div className="row">

                                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Status">
                                                    Diagnosis Code (ICD10)
                      </label>
                                                <select className="form-control form-control-sm"
                                                    id="diagnosisCode"
                                                    name="diagnosisCode"
                                                    onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                    value={Common.replaceNullWithString(this.state.tracheostomy.diagnosisCode)}
                                                >
                                                    <option value=""></option>
                                                    <option value="Z93.2">lleostomy Z93.2</option>
                                                    <option value="Z93.6">Urostomy Z93.6</option>
                                                    <option value="Z93.3">Colostomy Z93.3</option>
                                                    <option value="Z43.3">Colostomy Z43.3</option>
                                                    <option value="Z43.6">Encounter for Urostomy Z43.6</option>
                                                    <option value="Z43.2">Encounter for lleostomy Z43.2</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-4 col-lg-2 col-xl-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="phone1">
                                                    Surgery Date
                      </label>
                                                <input type="Date" id="surgeryDate"
                                                    name="surgeryDate"
                                                    required
                                                    onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                    defaultValue={Common.getInputStringDate2(new Date(this.state.tracheostomy.surgeryDate))}
                                                    className="form-control form-control-sm"

                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3">
                                    <div className="form-group mt-6">
                                        <Form.Check
                                            type="radio"
                                            label="New Tracheostomy"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios1"
                                        />
                                    </div>
                                </div> */}
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3">
                                            <div class="form-check form-check-inline ml-5">
                                                <input class="form-check-input" type="radio" name="tracheostomyType" id="Proximal"
                                                    onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                    value="1"
                                                    checked={this.state.tracheostomy.tracheostomyType==1?true:false}

                                                />
                                                <label class="form-check-label " htmlFor="isNewTracheostomy">New Tracheostomy</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3">
                                            <div class="form-check form-check-inline ml-5">
                                                <input class="form-check-input" type="radio" name="tracheostomyType" id="isEstablishedTracheostomy"
                                                    onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                    value="2"
                                                    checked={this.state.tracheostomy.tracheostomyType==2?true:false}

                                                />
                                                <label class="form-check-label " htmlFor="isNewTracheostomy">Established Tracheostomy</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3">
                                        <div className="form-group mt-6">
                                            <Form.Check
                                                type="radio"
                                                label="Established Tracheostomy"
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios1"
                                            />
                                        </div>
                                    </div> */}
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                            <div className="form-group mt-6">
                                                <FormControlLabel
                                                    control={<Switch name="isCuffed" checked={this.state.tracheostomy.isCuffed}
                                                        onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)} value="gilad" />}
                                                    label="Cuffed"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                            <div className="form-group mt-6">
                                                <FormControlLabel
                                                    control={<Switch name="isFrenestrated" checked={this.state.tracheostomy.isFrenestrated}
                                                        onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)} value="gilad" />}
                                                    label="Frenestrated"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3">
                                            <div className="form-group mt-6">
                                                <label className="checkbox ">
                                                    <input
                                                        type="checkbox"
                                                        name="isTracheostomyEstablishedTrach31mo"
                                                        onChange={(e) =>
                                                            Common.updateInputValueInState(
                                                                this.state.tracheostomy,
                                                                e,
                                                                this,
                                                                this.state
                                                            )
                                                        }
                                                        className="m-1"
                                                    />
                                                    <span className="mr-3 " />
                                        Tracheostomy care kit for established trach(31/mo)
                      </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3">
                                            <div className="form-group mt-6">
                                                {/* <Form.Check label="Tracheostomy collar/holder(3o/mo)" /> */}
                                                <label className="checkbox ">
                                                    <input
                                                        type="checkbox"
                                                        name="isTracheostomycollar30mo"
                                                        onChange={(e) =>
                                                            Common.updateInputValueInState(
                                                                this.state.tracheostomy,
                                                                e,
                                                                this,
                                                                this.state
                                                            )
                                                        }
                                                        className="m-1"
                                                    />
                                                    <span className="mr-3 " />
                                            Tracheostomy collar/holder(3o/mo)
                      </label>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Status">
                                                    Item#
                      </label>
                                                <input type="text" id="itemNumber" className="form-control form-control-sm"
                                                    name='itemNumber'
                                                    onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                    defaultValue={Common.replaceNullWithString(this.state.tracheostomy.itemNumber)}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>



                            </div>
                        </div>
                        <div className='col-md-6'>
                            <fieldset className="p-2 mb-2 border rounded">
                                <legend className="text-primary fw-500">
                                    Trach Tube (1/3mo){" "}
                                </legend>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Status">
                                                Size
                      </label>
                                            <select className="form-control form-control-sm"
                                                id="trachTubeSize"
                                                name="trachTubeSize"
                                                onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.tracheostomy.trachTubeSize)}
                                            >
                                                <option value=""></option>
                                                <option value="4 Inch">4 Inch</option>
                                                <option value="6 Inch">6 Inch</option>
                                                <option value="8 Inch">8 Inch</option>
                                                <option value="10 Inch">10 Inch</option>
                                                <option value="12 Inch">12 Inch</option>
                                                <option value="14 Inch">14 Inch</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Status">
                                                Item#
                      </label>
                                            <input type="text" id="trachTubeItemNumber" className="form-control form-control-sm"
                                                name='trachTubeItemNumber'
                                                onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.tracheostomy.trachTubeItemNumber)}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className='col-md-6'>
                            <fieldset className="p-2 mb-2 border rounded">
                                <legend className="text-primary fw-500">
                                    Inner Cannulas (62/mo){" "}
                                </legend>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Status">
                                                Size
                      </label>
                                            <select className="form-control form-control-sm"
                                                id="trachTubeSize"
                                                name="innerCannulasSize"
                                                onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                value={Common.replaceNullWithString(this.state.tracheostomy.innerCannulasSize)}
                                            >
                                                <option value=""></option>
                                                <option value="5 Inch">5 Inch</option>
                                                <option value="7 Inch">7 Inch</option>
                                                <option value="9 Inch">9 Inch</option>
                                                <option value="11 Inch">11 Inch</option>
                                                <option value="13 Inch">13 Inch</option>
                                                <option value="15 Inch">15 Inch</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Status">
                                                Item#
                      </label>
                                            <input type="text" id="innerCannulasItemNumber" className="form-control form-control-sm"
                                                name='innerCannulasItemNumber'
                                                onChange={e => Common.updateInputValueInState(this.state.tracheostomy, e, this, this.state)}
                                                defaultValue={Common.replaceNullWithString(this.state.tracheostomy.innerCannulasItemNumber)}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                    </div>
                    <div className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Process</Button>
                        {/* <Button type="button" variant="dark" size="sm" onClick={() => this.props.close()}>Back</Button> */}
                    </div>
                </Form >
            </>

        )
    }

}

