// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react'
import {
Button, Table
} from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import ViewWoundInformationChangeReason from './viewWoundInformationChangeReason';

const WoundInformationChangeReason = (props) => {
    // ** States
    const [woundInfoChangeReasons, setWoundInfoChangeReasons] = useState([])
    const [isLoading, setLoading] = useState(false);

    const Schema = Yup.object().shape({
        woundInfoChangeReasonId:   Yup.number().required("Reason is required").typeError("Reason is required").min(1, "Reason is required"),
        msg:Yup.string().max(500, "Maximum limit (500 characters)").typeError("Invalid Value").required("Note is required")
    });
    const formik = useFormik({
        initialValues: {
            woundInfoChangeReasonId:0,
            msg:''
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            handleValidSubmit();
            setSubmitting(false);
        },
    });


    const handleValidSubmit = () => {
        debugger
        Api.postRequest(Api.apiUrls.createWoundInfoChangeDetail, 
            {description:formik.values.msg,woundId:props.woundInfo.wound.id,
                residentId :props.woundInfo.id
            ,fKWoundChangeReasonID:formik.values.woundInfoChangeReasonId}).then((res) => {
            if (res.data.success) {
                props.close();
                if(props.isDocumentation){
                    props.showWound(props.woundInfo,true,true,props.woundEvalutionId)

                }else{
                    props.showWound(props.woundInfo,false,false,false,true,formik.values.woundInfoChangeReasonId)

                }
                
                Common.showSuccessAlertAutoClose(
                    "Data Saved Successfully",
                    "success",
                    "Success!",
                    3000
                );
               
            } else {
                Common.showSuccessAlert(
                    res.data.message,
                    "error",
                    "Error!",
                    3000
                );
            }
        });
          
       
    }
    useEffect(() => {
        getWoundInfoChangeReasons()
    }, []);

    const getWoundInfoChangeReasons = () => {
        debugger
        var residentNoOrderStatusID=Common.LookupsFK.Fk_WoundInfoChangeReasons;
      
            Api.getRequestById(Api.Uri_GetLookupValuesByFkId, residentNoOrderStatusID)
                .then((res) => {
                    debugger
                    if (res.data.statusCode == 200) {
                        if (res.data.result != null) {
                            if (res.data.result.length > 0) {
                                setWoundInfoChangeReasons(res.data.result);
                            }
                        }
                    }
                })
                .catch((ex) => {
    
                    Common.LogError(ex, "Resident", "GetLookupValuesByFkId");
    
                });
        }
    const  setOptionsUI = (options) => {
        return options.map((option) => (
            <option key={option.key} value={option.key}>
                {option.value}
            </option>
        ));
    }
    return (
        <Fragment>
            <div className="row" >
                <div className="col-12">
                <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                            Reason
                        </label>
                        <select
                        className="form-control form-control-sm"
                        id="woundInfoChangeReasonId"
                        name="woundInfoChangeReasonId"
                        value={formik.values.woundInfoChangeReasonId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option></option>
                            {setOptionsUI(woundInfoChangeReasons)}
                        </select>
                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                                    {formik.errors.woundInfoChangeReasonId}
                                </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className="col-12">
                    <div className="form-group">
                        <label className="form-label font-weight-bold" htmlFor="Length">Notes</label>
                        <textarea id="msg"
                            rows="3"
                            className="form-control"
                            name="msg"
                            value={formik.values.msg}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                            <div style={{ fontSize: ".8rem" }} className="text-danger">
                                    {formik.errors.msg}
                                </div>

                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end py-3">
                <Button variant="success" className="mr-5 px-10" size="sm" onClick={formik.handleSubmit}>Save</Button>
            </div>
            <ViewWoundInformationChangeReason
                 residentId={props.woundInfo.id}
                 woundId={props.woundInfo.wound.id}
            />
        </Fragment>
    )
}

export default WoundInformationChangeReason
