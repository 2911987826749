export default class UserTypes{

    static currentUserType = {
        name:"Gentell Administrators",
        group:"Gentell",
        detail:" approve/view orders; has access to products, reps, users, can add wounds, add residents, can edit facility info, change/assign reps, add a new facility; post visit recap, can view all facilities and corporations, can change head quarters",
    }
    
    static userTypes  = [
        {
            name:"Corporate Clinician",
            group:"Facility",
            detail:"gets access to all facilities in a corporation automatically",
        },
        {
            name:"Group Clinician ",
            group:"Facility",
            detail:"selected facilities within corporation assigned",
        },
        {
            name:"Level I",
            group:"Facility",
            detail:"gets access to reports, adds personnel, adds residents, adds wounds, deletes personnel",
        },
        {
            name:"Level II",
            group:"Facility",
            detail:"gets access to reports, adds residents, adds wounds",
        },
        {
            name:"MDS Coordinator",
            group:"Facility",
            detail:"read only access",
        },
        {
            name:"Surveyor",
            group:"Facility",
            detail:"read only access",
        },
        {
            name:"Physician",
            group:"Facility",
            detail:"read only access except for the ability to add notes (resident or wound)",
        },
        {
            name:"Gentell Administrators",
            group:"Gentell",
            detail:" approve/view orders; has access to products, reps, users, can add wounds, add residents, can edit facility info, change/assign reps, add a new facility; post visit recap, can view all facilities and corporations, can change head quarters",
        },
        {
            name:"Wound Care Specialist ",
            group:"Gentell",
            detail:"can add residents, wounds, physicians; edit facility info (except headquarters location and facility name), add personnel to facilities, post visit recap, Find functions",
        },
        {
            name:"Billing Clerk ",
            group:"Gentell",
            detail:"process orders, find order, approve/view orders, post visit recap, Find Patient and Find Orders, edit resident profile",
        },
        {
            name:"Regional Manager",
            group:"Gentell",
            detail:"same access as wound care specialist. Access to all facilities assigned to their clinicians",
        },
    ]

    
}